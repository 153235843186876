import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import HrConfigDocumentTypes from './HrConfigDocumentTypes';
import HrConfigDocumentCategories from './HrConfigDocumentCategories';

function HrConfigDocuments() {
    return (
        <MainCard title="Team Documents">
            <RbgGrid container>
                <RbgGrid item xs={6}>
                    <HrConfigDocumentTypes />
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <HrConfigDocumentCategories />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default HrConfigDocuments;
