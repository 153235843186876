import PropTypes from 'prop-types';
// mui imports
import { TableBody } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TableBody API](https://mui.com/material-ui/api/table-body/)
 */

function RbgTableBody({ children, ...others }) {
    return <TableBody {...others}>{children}</TableBody>;
}

export default RbgTableBody;

RbgTableBody.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
