import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Typography } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Breadcrumbs](https://mui.com/material-ui/react-breadcrumbs/)
 * - [Typography](https://mui.com/material-ui/react-typography/)
 *
 * API:
 *
 * - [Typography API](https://mui.com/material-ui/api/typography/)
 */

const RbgTypography = forwardRef(({ children, ...others }, ref) => (
    <Typography ref={ref} {...others}>
        {children}
    </Typography>
));

export default RbgTypography;

RbgTypography.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.array])
};
