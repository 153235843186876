import { object } from 'prop-types';
import ScAvatar from './ScAvatar';

function ScNode({ member }) {
    const children = member.staff ?? member.children;
    return children.map((node, index) => (
        <li key={index}>
            <ScAvatar
                image={node.selfie_image}
                name={node.full_display_name}
                forename={node.forename}
                surname={node.surname}
                position={node.role}
                id={node.id}
            />

            {node.children && node.children.length > 0 && (
                <ul>
                    <ScNode member={node} />
                </ul>
            )}
        </li>
    ));
}

export default ScNode;

ScNode.propTypes = {
    member: object.isRequired
};
