import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// mui imports
import { TableHead, TableRow } from '@mui/material';
// mui wrappers
import RbgTableCell from 'ui_component/mui-wrappers/TableCell/RbgTableCell';
// 3rd party
import dayjs from 'dayjs';

function RotaTableHead({ area }) {
    const { days } = useSelector((state) => state.rota);
    return (
        <TableHead>
            <TableRow>
                <RbgTableCell align="center">{area ? 'Area' : 'Name'}</RbgTableCell>
                {days.map((d) => {
                    const date = dayjs(d.date);
                    return (
                        <RbgTableCell key={d.id} align="center">
                            {date.format('ddd')}
                            <br />
                            {date.format('DD/MM/YYYY')}
                        </RbgTableCell>
                    );
                })}
                <RbgTableCell align="center">Hours </RbgTableCell>
                <RbgTableCell align="center">Cost </RbgTableCell>
            </TableRow>
        </TableHead>
    );
}

export default RotaTableHead;

RotaTableHead.propTypes = {
    area: PropTypes.bool
};
