import PropTypes from 'prop-types';
import { useTabContext } from '@mui/lab';
import RbgBox from '../mui-wrappers/Box/RbgBox';
import { Backdrop, CircularProgress } from '@mui/material';

/**
 * Hides the tab content based on its context without unmounting
 */

function TabPanel({ children, value, loading = false }) {
    const { value: contextValue } = useTabContext() || {};

    return (
        <RbgBox
            sx={{
                visibility: value === contextValue ? 'visible' : 'hidden',
                height: value === contextValue ? '100%' : '0px',
                width: value === contextValue ? '100%' : '0px',
                paddingTop: value === contextValue ? '6px' : '0px',
                paddingBottom: value === contextValue ? '6px' : '0px',
                marginTop: value === contextValue ? '-6px' : '0px',
                overflow: 'hidden',
                position: 'relative'
            }}
            key={value}
        >
            <Backdrop
                sx={{
                    color: '#fff',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 10
                }}
                open={loading}
            >
                <CircularProgress color="inherit" disableShrink />
            </Backdrop>
            {children}
        </RbgBox>
    );
}

export default TabPanel;

TabPanel.propTypes = {
    value: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.element, PropTypes.elementType])
};
