import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
// project imports
import Api from 'utils/api';
import { drawerWidth } from 'store/constant';
import { setMatchDownSm, setMenu } from 'store/reducers/customizationReducer';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import EventDispatcher from '../../utils/EventDispatcher';
import RcloudAuthContext from '../../contexts/RcloudAuthContext';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    }),
    display: 'flex',
    flexDirection: 'column'
}));

// ==============================|| MAIN LAYOUT ||============================== //

function MainLayout() {
    // media queries
    const theme = useTheme();
    const matchDown980 = useMediaQuery(theme.breakpoints.down(980));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    // global state
    const dispatch = useDispatch();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const { user } = useContext(RcloudAuthContext);

    const handleLeftDrawerToggle = () => {
        Api.post('/rest/preference/sidebar', { value: !leftDrawerOpened });
        dispatch(setMenu(!leftDrawerOpened));
    };

    useEffect(() => {
        dispatch(setMenu(!matchDown980));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDown980]);

    useEffect(() => {
        if (!user.preferences) {
            return;
        }
        user.preferences.forEach((p) => {
            if (p.name === 'sidebar') {
                dispatch(setMenu(p.value === '1'));
            }
        });
    }, [user.preferences, dispatch]);

    useEffect(() => {
        const sub = EventDispatcher.subscribe('pusher-message', (message) => {
            if (message.event === 'preference-update' && message.data.preference === 'sidebar') {
                dispatch(setMenu(message.data.value === '1'));
            }
        });
        return () => {
            EventDispatcher.unsubscribe(sub);
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setMatchDownSm(matchDownSm));
    }, [dispatch, matchDownSm]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main open={leftDrawerOpened}>
                <Outlet />
            </Main>
        </Box>
    );
}

export default MainLayout;
