import { useQuery } from '@tanstack/react-query';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EditRole from './EditRole';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import MainCard from '../../../../../ui_component/cards/MainCard';

function SupportCentreRoles() {
    const [showEmpty, setShowEmpty] = useState(false);
    const [search, setSearch] = useState('');
    const { error, data, isFetching, refetch } = useQuery({
        queryKey: [{ method: 'GET', url: '/staff/sc-roles' }]
    });

    const editRole = (role) => {
        DialogHelper.openSmDialog(
            role ? 'Edit Support Centre Role' : 'Add Support Centre Role',
            <EditRole role={role} onSave={() => refetch()} />
        );
    };
    let roles = data.data;
    if (showEmpty === false) {
        roles = roles.filter((role) => role.staff_count > 0);
    }
    if (search !== '') {
        roles = roles.filter((role) => role.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }
    return (
        <MainCard title="Support Centre Roles">
            <RbgGrid container sx={{ mt: 1 }}>
                <RbgGrid item xs={6}>
                    <RbgTextInput name="search" value={search} onChange={(e) => setSearch(e.target.value)} label="Search" />
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgCheckbox
                        label="Inclue Roles with no active team members"
                        checked={showEmpty}
                        onChange={(e) => setShowEmpty(e.target.checked)}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        loading={isFetching}
                        error={error}
                        headers={[
                            'ID',
                            'Name',
                            'Default Department',
                            'Live Staff Count',
                            <RbgButton onClick={() => editRole()}>
                                <IconPlus /> Add Role
                            </RbgButton>
                        ]}
                        keys={roles.map((row) => row.id)}
                        rows={roles.map((role) => {
                            return [
                                role.id,
                                role.name,
                                role.department_name,
                                role.staff_count,
                                <RbgButton onClick={() => editRole(role)}>
                                    <IconEdit />
                                </RbgButton>
                            ];
                        })}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default SupportCentreRoles;
