import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import Debug from '../../../../../ui_component/misc/Debug';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import _ from 'lodash';
import InfoCard from '../../../../../ui_component/error/InfoCard';

function AddEditField({ field }) {
    const [newField, setNewField] = useState(
        field || {
            id: null,
            allowed_values: [],
            help_text: null,
            input_pattern: null,
            name: '',
            new_starter_field: null,
            regex_validation: null,
            type: ''
        }
    );

    const handleFieldChange = (e) => {
        setNewField((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const types = ['title', 'content', 'text', 'email', 'date', 'select', 'file', 'radio'].map((type) => {
        return {
            value: type,
            label: `${type.slice(0, 1).toUpperCase()}${type.slice(1)}`
        };
    });
    const queryClient = useQueryClient();

    const saveMutation = useMutation({
        mutationFn: () => {
            if (field && field.id) {
                return Api.reactQueryMutation('PUT', `/staff/admin/onboard-field/${field.id}`, newField);
            }
            return Api.reactQueryMutation('POST', `/staff/admin/onboard-field`, newField);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('onboard_fields') > -1;
                }
            });
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        DialogHelper.addSaveButton(() => saveMutation.mutate(), 'Save');
    }, [newField, saveMutation]);
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="name"
                    value={newField.name}
                    onChange={handleFieldChange}
                    label="Field Label"
                    help="The name of this field that the end user will see"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSelect values={types} value={newField.type} label="Field Type" name="type" handleChange={handleFieldChange} />
            </RbgGrid>
            {(newField.type === 'select' || newField.type === 'radio') && newField.allowed_values_query && (
                <RbgGrid item xs={12}>
                    <InfoCard>This field uses a query to populate it&apos;s options</InfoCard>
                </RbgGrid>
            )}
            {(newField.type === 'select' || newField.type === 'radio') && !newField.allowed_values_query && (
                <RbgGrid item xs={12}>
                    <RbgStack gap={1}>
                        {newField.allowed_values.map((field, index) => {
                            return (
                                <RbgStack direction="row" key={index} gap={2}>
                                    <RbgTextInput
                                        name={`field_${index}_key`}
                                        value={field.key}
                                        onChange={(e) => {
                                            handleFieldChange({
                                                target: {
                                                    name: 'allowed_values',
                                                    value: newField.allowed_values.map((value, idx) => {
                                                        if (idx === index) {
                                                            value.key = e.target.value;
                                                        }
                                                        return value;
                                                    })
                                                }
                                            });
                                        }}
                                        label="Value"
                                    />
                                    <RbgTextInput
                                        name={`field_${index}_value`}
                                        value={field.value}
                                        onChange={(e) => {
                                            handleFieldChange({
                                                target: {
                                                    name: 'allowed_values',
                                                    value: newField.allowed_values.map((value, idx) => {
                                                        if (idx === index) {
                                                            value.value = e.target.value;
                                                        }
                                                        return value;
                                                    })
                                                }
                                            });
                                        }}
                                        label="Label"
                                    />
                                    <DeleteConfirm
                                        onDelete={() => {
                                            handleFieldChange({
                                                target: {
                                                    name: 'allowed_values',
                                                    value: newField.allowed_values.filter((value, idx) => {
                                                        return idx !== index;
                                                    })
                                                }
                                            });
                                        }}
                                    />
                                </RbgStack>
                            );
                        })}
                        <RbgButton
                            color="primary"
                            onClick={() => {
                                const fields = [...newField.allowed_values, { key: _.uniqueId('value'), value: '' }];
                                handleFieldChange({
                                    target: {
                                        name: 'allowed_values',
                                        value: fields
                                    }
                                });
                            }}
                        >
                            <IconPlus size={15} /> Add Option
                        </RbgButton>
                    </RbgStack>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/onboard-fields"
                    name="new_starter_field"
                    useAutoComplete
                    label="Mapped to Field"
                    help="What field this will map to on the team member record"
                    handleChange={handleFieldChange}
                    value={newField.new_starter_field}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="help_text"
                    label="Help Text"
                    value={newField.help_text}
                    onChange={handleFieldChange}
                    help="If you want to add any helper text to this field"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="regex_validation"
                    type="text"
                    value={newField.regex_validation}
                    onChange={handleFieldChange}
                    help="Any regex validation to be done on this field"
                    label="Regex Validation"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="input_pattern"
                    type="text"
                    value={newField.input_pattern}
                    help="Any input patterns to be provided to the form input"
                    onChange={handleFieldChange}
                    label="Input Pattern"
                />
            </RbgGrid>
        </RbgGrid>
    );
}

AddEditField.propTypes = {
    field: PropTypes.object
};

export default AddEditField;
