import PropTypes from 'prop-types';
import { staffPosition } from '../../StaffDataTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import { useEffect, useState } from 'react';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import RbgLocationSelect from '../../../../../ui_component/location-select/RbgLocationSelect';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import MultipleLocationSelect from '../../../../../ui_component/location-select/MultipleLocationSelect';

function HrConfigRoleEdit({ position }) {
    const [newPosition, setNewPosition] = useState(
        position || {
            id: null,
            type_id: '',
            type: {
                data: {}
            },
            name: '',
            locations: [],
            display_order: '',
            site_based: true,
            create_clerk: true,
            pay_type: {
                data: {}
            },
            archived: false,
            hierarchy_level: 1,
            support_centre: false,
            probation_length: '+3 month',
            onboard_manager_fieldset_id: '',
            onboard_team_member_fieldset_id: ''
        }
    );

    const handleFieldChange = (e) => {
        setNewPosition((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const handleObjectChange = (name, value) => {
        setNewPosition((prev) => ({ ...prev, [name]: { data: value } }));
    };
    const handleBooleanChange = (e) => {
        setNewPosition((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    };

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            if (position?.id) {
                return Api.reactQueryMutation('PATCH', `/staff/position/${position.id}`, newPosition);
            }
            return Api.reactQueryMutation('POST', '/staff/position', newPosition);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [{ url: '/staff/positions' }] });
            DialogHelper.closeDialog();
        }
    });

    const getError = (field) => {
        if (!mutation.error || !mutation.error.fields) {
            return null;
        }
        return mutation.error.fields
            .filter((f) => {
                return f.field === field;
            })
            .map((f) => f.error)
            .join(',');
    };

    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save');
    }, [mutation]);

    return (
        <RbgGrid container spacing={gridSpacing}>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput
                    help="Name for the role"
                    name="name"
                    error={getError('name')}
                    value={newPosition.name}
                    onChange={handleFieldChange}
                    label="Role Name"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/position-types"
                    name="type"
                    label="Position Type"
                    value={newPosition.type.data.id}
                    handleObjectChange={(e) => handleObjectChange('type', e.target.object)}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    help="How often this role will get paid"
                    endpoint="/staff/pay-types"
                    name="pay_type"
                    error={getError('pay_type')}
                    label="Pay Type"
                    value={newPosition.pay_type.data.id}
                    handleObjectChange={(e) => handleObjectChange('pay_type', e.target.object)}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <MultipleLocationSelect
                    help="What locations this role will be available at"
                    name="locations"
                    error={getError('pay_type')}
                    label="Locations"
                    multiple
                    limitTags={5}
                    value={newPosition.locations}
                    onChange={(e) => handleFieldChange({ target: { name: 'locations', value: e.target.value } })}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/admin/onboard-fieldsets"
                    name="onboard_manager_fieldset_id"
                    label="Hiring Manager Fieldset"
                    value={newPosition.onboard_manager_fieldset_id}
                    handleChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/admin/onboard-fieldsets"
                    name="onboard_team_member_fieldset_id"
                    label="Team Member Fieldset"
                    value={newPosition.onboard_team_member_fieldset_id}
                    handleChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgCheckbox name="archived" checked={newPosition.archived} onChange={handleBooleanChange} label="Archived" />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSelect
                    values={[
                        { value: '+3 month', label: '3 Months' },
                        { value: '+6 month', label: '6 Months' }
                    ]}
                    handleChange={handleFieldChange}
                    name="probation_length"
                    value={newPosition.probation_length}
                    label="Probation length"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/hierarchy-levels"
                    name="hierarchy_level"
                    filterData={(data) => {
                        return data.data.map((row) => ({ value: row.level, label: row.name }));
                    }}
                    handleChange={(e) => handleFieldChange(e)}
                    value={newPosition.hierarchy_level}
                    label="Hierachy Level"
                />
            </RbgGrid>
        </RbgGrid>
    );
}

HrConfigRoleEdit.propTypes = {
    position: PropTypes.shape(staffPosition)
};
export default HrConfigRoleEdit;
