import MainCard from '../../../../ui_component/cards/MainCard';
import { useState } from 'react';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import dayjs from 'dayjs';
import IntroCardPrimary from '../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../store/constant';
import RbgTextInput from '../../../../ui_component/extended/Form/RbgTextInput';
import PageableTable from '../../../../ui_component/pageable-table/PageableTable';
import DropdownMenu from '../../../../ui_component/buttons/DropdownMenu';
import StaticBreadcrumb from '../../../../ui_component/extended/StaticBreadcrumb';
import { useQuery } from '@tanstack/react-query';

function DocumentationApproval() {
    const [pagination, setPagination] = useState({});
    const [filter, setFilter] = useState({
        page: 1,
        search: ''
    });
    const {
        data,
        isLoading: loading,
        error
    } = useQuery({
        queryKey: [{ url: '/staff/right-to-work/pending', params: filter }]
    });
    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    if (loading) {
        return <CardLoader />;
    }

    const approvals = data.data;
    return (
        <>
            <StaticBreadcrumb items={[{ label: 'Staff' }, { label: 'HR Admin' }, { label: 'Right to work approvals' }]} />
            <MainCard>
                <RbgGrid container spacing={gridSpacing}>
                    <RbgGrid item xs={12}>
                        <IntroCardPrimary>
                            <RbgTypography>
                                Below is a list of all staff members who are currently awaiting documentation approval. All of these staff
                                are awaiting checks from RBG Support centre before the continue their onboarding journey
                            </RbgTypography>
                        </IntroCardPrimary>
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <PageableTable
                            topFilterContent={
                                <RbgTextInput
                                    name="search"
                                    value={filter.search}
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setFilter((prev) => ({ ...prev, search: e.target.value }));
                                    }}
                                />
                            }
                            headers={['Name', 'Location', 'Start Date', 'Role', 'RTW Status', 'Onboard Status', '']}
                            pagination={pagination}
                            data={approvals}
                            rowDecorator={(row) => {
                                return [
                                    row.display_name,
                                    row.location_name,
                                    dayjs(row.start_date).format('DD/MM/YYYY'),
                                    row.role_name,
                                    row.rtw_status,
                                    row.new_starter_status_name ? row.new_starter_status_name : 'N/A',
                                    <DropdownMenu
                                        menuLabel="Actions"
                                        menuItems={[
                                            {
                                                content: 'View Staff Record',
                                                onClick: (e) => {
                                                    document.location.href = `/staff/view/${row.id}`;
                                                }
                                            }
                                        ]}
                                    />
                                ];
                            }}
                            handlePageChange={(page) => setFilter({ ...filter, page })}
                        />
                    </RbgGrid>
                </RbgGrid>
            </MainCard>
        </>
    );
}

export default DocumentationApproval;
