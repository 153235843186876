import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material-ui imports
import { Box, ButtonBase, CircularProgress, useTheme } from '@mui/material';
// project imports
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';

const CircularProgressButton = forwardRef(({ handleToggle, value }, ref) => {
    const theme = useTheme();

    return (
        <ButtonBase
            centerRipple
            sx={{ color: theme.palette.mode === 'dark' ? 'primary.main' : 'inherit', ml: 0.5, cursor: 'pointer' }}
            onClick={handleToggle}
            ref={ref}
        >
            <CircularProgress variant="determinate" value={value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <RbgTypography data-testid="targets-button" variant="caption" component="div" color="text.secondary">
                    {`${value}%`}
                </RbgTypography>
            </Box>
        </ButtonBase>
    );
});

CircularProgressButton.propTypes = {
    handleToggle: PropTypes.func,
    value: PropTypes.number
};

export default CircularProgressButton;
