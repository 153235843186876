import PropTypes from 'prop-types';
// mui wrappers
import MainCard from 'ui_component/cards/MainCard';
import RbgButton from 'ui_component/mui-wrappers/Button/RbgButton';
import RbgList from 'ui_component/mui-wrappers/List/RbgList';
import RbgListItem from 'ui_component/mui-wrappers/ListItem/RbgListItem';
import RbgListItemText from 'ui_component/mui-wrappers/ListItemText/RbgListItemText';
import RbgDivider from 'ui_component/mui-wrappers/Divider/RbgDivider';
import { DialogHelper } from 'ui_component/modals/RbgDialog';
import EventDispatcher from 'utils/EventDispatcher';
import EditBank from './EditGroups/EditBank';
import { staffDetails } from '../../../StaffDataTypes';

function BankGroup({ staffDetails, getError, hasEditPermissions }) {
    const handleSave = () => {
        DialogHelper.closeDialog();
        EventDispatcher.dispatch('setTabLoading', true);
        setTimeout(() => {
            EventDispatcher.dispatch('setTabLoading', false);
        }, 1500);
    };

    const openEdit = () => {
        DialogHelper.openSmDialog('Edit Bank', <EditBank staffDetails={staffDetails} getError={getError} />);
    };

    return (
        <MainCard
            sx={{ height: '100%' }}
            title="Bank"
            secondary={
                <RbgButton size="small" variant="contained" color="primary" onClick={openEdit} disabled={hasEditPermissions === false}>
                    Edit
                </RbgButton>
            }
        >
            <RbgList>
                <RbgListItem>
                    <RbgListItemText primary="Sort Code" secondary={staffDetails.sort_code} />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="Bank Account" secondary={staffDetails.bank_account} />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="NI Number" secondary={staffDetails.ni_number} />
                </RbgListItem>
            </RbgList>
        </MainCard>
    );
}

export default BankGroup;

BankGroup.propTypes = {
    getError: PropTypes.func,
    staffDetails: PropTypes.shape(staffDetails),
    hasEditPermissions: PropTypes.bool
};
