import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useEffect, useState } from 'react';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import StaffFileUpload from '../../Component/StaffFileUpload';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { gridSpacing } from '../../../../../store/constant';
import { filesize } from 'filesize';
import { IconTrash } from '@tabler/icons-react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function AddStaffDocument({ staffMember }) {
    const [document, setDocument] = useState({
        type: { data: {} },
        file: { data: {} },
        staff_id: staffMember.id
    });
    const queryClient = useQueryClient();
    const handleFieldChange = (e) => {
        setDocument((prev) => ({ ...prev, [e.target.name]: { data: e.target.object ? e.target.object : null } }));
    };
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/document', document);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({ queryKey: [`staff_member_${staffMember.id}`] });
        }
    });

    useEffect(() => {
        let disabled = false;
        if (!document.type.data.id) {
            disabled = true;
        }
        if (!document.file.data.id) {
            disabled = true;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Add Document', disabled);
    }, [document, mutation]);

    const getError = (field) => {
        if (mutation.error && mutation.error.fields) {
            return mutation.error.fields
                .filter((f) => f.field === field)
                .map((f) => f.error)
                .join(', ');
        }
        return null;
    };
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    value={document.type.data.id}
                    endpoint="/staff/documents/types"
                    filterData={(data) => {
                        return data.data
                            .sort((type1, type2) => {
                                return type1.display_name.localeCompare(type2.display_name);
                            })
                            .map((type) => ({
                                value: type.id,
                                object: type,
                                label: type.display_name
                            }));
                    }}
                    name="type"
                    error={getError('type')}
                    label="Document Type"
                    useAutoComplete
                    handleObjectChange={(e) => handleFieldChange(e)}
                />
            </RbgGrid>
            {getError('file') && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{getError('file')}</ErrorCard>
                </RbgGrid>
            )}
            {document.file.data.id && (
                <RbgGrid item xs={12}>
                    {document.file.data.filename} ({filesize(document.file.data.size)})
                    <RbgButton
                        icon={<IconTrash />}
                        onClick={(e) => {
                            setDocument((prev) => ({ ...prev, file: { data: {} } }));
                        }}
                    />
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <StaffFileUpload
                    bucket="rbg-staff-files"
                    endpoint={`/staff/staff-member/${staffMember.id}/file`}
                    content={document.file.data.id ? 'Drag file here to click to replace' : 'Drag file here, or click to upload'}
                    onFileUploaded={(file) => setDocument((prev) => ({ ...prev, file: { data: file } }))}
                    staffId={staffMember.id}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

AddStaffDocument.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default AddStaffDocument;
