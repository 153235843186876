import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// 3rd party
import dayjs from 'dayjs';

function EditShit({ shift, onChange }) {
    const [timeString, setTimeString] = useState(`${dayjs(shift.shift_start).format('HH:mm')}-${dayjs(shift.shift_end).format('HH:mm')}`);
    const [initialValue] = useState(`${dayjs(shift.shift_start).format('HH:mm')}-${dayjs(shift.shift_end).format('HH:mm')}`);
    const ref = useRef();
    const parseTimePart = (time, context) => {
        if (!context) {
            context = 'start';
        }
        let hour = '';
        let minute = '';
        if (time.indexOf(':') === -1) {
            switch (time.length) {
                case 4:
                    hour = time.substring(0, 2);
                    minute = time.substring(2, 4);
                    break;
                case 3:
                    hour = time.substring(0, 1);
                    minute = time.substring(1, 3);
                    break;
                case 2:
                    hour = time;
                    minute = '00';
                    break;
                case 1:
                    hour = `${time}`;
                    minute = '0';
                    break;
                // no default
            }
        } else {
            [hour, minute] = time.split(':');
        }
        if (hour.length === 1 && context === 'start' && hour <= 6) {
            hour = `${parseInt(hour, 10) + 12}`;
        }
        if (!hour || !minute) {
            return null;
        }
        if (hour.length === 1) {
            hour = `0${hour}`;
        }
        if (minute.length === 1) {
            minute = `${minute}0`;
        }
        return `${hour}:${minute}`;
    };
    return (
        <input
            style={{ width: '100px' }}
            ref={ref}
            type="text"
            name="shift"
            value={timeString}
            onChange={(e) => setTimeString(e.target.value)}
            onBlur={(e) => {
                e.preventDefault();
                if (timeString.indexOf('-') === -1) {
                    setTimeString(initialValue);
                    return;
                }
                // ref.current.focus();
                const parts = timeString.split('-');
                const start = parseTimePart(parts[0].trim());
                const end = parseTimePart(parts[1].trim());

                onChange(`${start}:00-${end}:00`);
            }}
        />
    );
}

EditShit.propTypes = {
    shift: PropTypes.object.isRequired,
    onChange: PropTypes.func
};
export default EditShit;
