import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import ContractTemplateList from './ContractTemplateList';
import { useState } from 'react';
import ContractTemplateSections from './ContractTemplateSections';

function ContractTemplates() {
    const [tab, setTab] = useState('contract_templates');
    const tabs = [
        {
            loading: false,
            label: 'Templates',
            value: 'contract_templates',
            content: <ContractTemplateList />
        },
        {
            loading: false,
            label: 'Sections',
            value: 'contract_sections',
            content: <ContractTemplateSections />
        }
    ];
    return <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} />;
}

export default ContractTemplates;
