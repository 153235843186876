import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// material-ui imports
import { Pagination } from '@mui/material';
// mui wrappers
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';

function ServerSidePagination({
    pagination,
    changePage,
    direction,
    justifyContent,
    alignItems = 'flex-end',
    gap,
    spacing,
    stackStyles,
    prepend,
    append
}) {
    const [loading, setLoading] = useState(true);
    const [startNumber, setStartNumber] = useState(0);
    const [endNumber, setEndNumber] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const handleChange = (event, value) => changePage(value);

    useEffect(() => {
        if (pagination) {
            const start = (pagination.current_page - 1) * pagination.per_page;
            const total = pagination.total;
            let end = start + pagination.count;
            if (end > total) {
                end = total;
            }

            setStartNumber(start);
            setTotalCount(total);
            setEndNumber(end);
            setLoading(false);
        }
    }, [pagination]);
    if (pagination.total_pages === 0) {
        return <span />;
    }
    return (
        <RbgStack
            sx={stackStyles}
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
            gap={gap}
            spacing={spacing}
        >
            {!loading && (
                <>
                    {prepend}
                    <RbgTypography>
                        Showing {startNumber + 1} to {endNumber} of {totalCount} Results
                    </RbgTypography>
                    <Pagination
                        page={pagination.current_page}
                        count={pagination.total_pages}
                        onChange={handleChange}
                        defaultPage={pagination.current_page}
                        siblingCount={1}
                        boundaryCount={2}
                        showFirstButton
                        showLastButton
                    />
                    {append}
                </>
            )}
        </RbgStack>
    );
}

ServerSidePagination.propTypes = {
    pagination: PropTypes.object,
    changePage: PropTypes.func,
    direction: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    gap: PropTypes.number,
    spacing: PropTypes.number,
    stackStyles: PropTypes.object,
    prepend: PropTypes.node,
    append: PropTypes.node
};

export default ServerSidePagination;
