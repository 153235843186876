import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import dayjs from 'dayjs';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconCopy, IconEdit, IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ContractEditSection from './ContractEditSection';
import RbgFilter from '../../../../../ui_component/extended/Form/RbgFilter';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import RelativeTime from '../../../../../ui_component/relative-time/RelativeTime';

function ContractTemplateSections() {
    const [filter, setFilter] = useState({
        page: 1,
        title_search: '',
        include_archived: false,
        content_search: ''
    });
    const { data, refetch } = useQuery({
        queryKey: [{ url: '/staff/staff-contract-admin/sections', params: filter }, 'contract_sections']
    });
    const filters = [
        {
            name: 'title_search',
            value: filter.title_search,
            label: 'Title Search',
            type: 'text',
            useDebounce: true
        },
        {
            name: 'content_search',
            value: filter.content_search,
            label: 'Content Search',
            type: 'text',
            useDebounce: true
        },
        {
            type: 'checkbox',
            name: 'include_archived',
            label: 'Include Archived',
            value: filter.include_archived
        }
    ];
    const mutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('DELETE', `/staff/staff-contract-admin/section/${id}`);
        },
        onSuccess: () => {
            refetch();
        }
    });
    const archiveSection = (id) => {};
    return (
        <MainCard title="Contract Template Sections">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgFilter filter={filter} onChange={(filter) => setFilter(filter)} filters={filters} />
                </RbgGrid>
                {mutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{mutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <PageableTable
                        pagination={data.meta.pagination}
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                        data={data.data}
                        rowDecorator={(row) => {
                            return [
                                row.id,
                                row.name,
                                row.heading,
                                <RelativeTime time={row.date_updated} />,
                                <BooleanIcon value={row.archived} />,
                                <RbgStack direction="row" spacing={2}>
                                    <RbgButton
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                            DialogHelper.openLgDialog('Edit Section', <ContractEditSection section={row} />);
                                        }}
                                        icon={<IconEdit />}
                                    />
                                    {row.archived === false && <DeleteConfirm onDelete={() => mutation.mutate(row.id)} size="small" />}
                                    <RbgButton
                                        size="small"
                                        variant="contained"
                                        icon={<IconCopy />}
                                        color="warning"
                                        onClick={() => {
                                            const newRow = JSON.parse(JSON.stringify(row));
                                            newRow.id = null;
                                            newRow.archived = false;
                                            newRow.date_created = dayjs().format('YYYY-MM-DD HH:mm:ss');
                                            newRow.date_update = dayjs().format('YYYY-MM-DD HH:mm:ss');
                                            DialogHelper.openLgDialog('Edit Section', <ContractEditSection section={newRow} />);
                                        }}
                                    />
                                </RbgStack>
                            ];
                        }}
                        headers={[
                            'ID',
                            'Name',
                            'Heading',
                            'Last Updated',
                            'Archived',
                            <RbgButton
                                onClick={() => {
                                    const newRow = {
                                        archived: false,
                                        content: '',
                                        date_created: '',
                                        date_updated: '',
                                        heading: '',
                                        id: null,
                                        name: '',
                                        plain_text: '',
                                        preview: ''
                                    };
                                    DialogHelper.openLgDialog('Edit Section', <ContractEditSection section={newRow} />);
                                }}
                            >
                                <IconPlus /> Add
                            </RbgButton>
                        ]}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default ContractTemplateSections;
