import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { ListItemButton } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Lists](https://mui.com/material-ui/react-list/)
 *
 * API:
 *
 * - [ListItemButton API](https://mui.com/material-ui/api/list-item-button/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */

const RbgListItemButton = forwardRef(({ children, ...others }, ref) => (
    <ListItemButton ref={ref} {...others}>
        {children}
    </ListItemButton>
));

export default RbgListItemButton;

RbgListItemButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
