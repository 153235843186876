import { staffNotificationEndpoint, staffNotificationType } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import _ from 'lodash';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import DeleteIcon from '@mui/icons-material/Delete';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgTooltip from '../../../../../ui_component/tooltip/RbgTooltip';
import { Help } from '@mui/icons-material';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';

function NotificationEndpoints({ endpoints, handleChange, type }) {
    const addNewEndpoint = () => {
        const newEndpoints = _.extend([], endpoints);
        newEndpoints.push({
            id: _.uniqueId('new'),
            target: '',
            enabled: true
        });
        handleChange(newEndpoints);
    };

    const handleFieldChange = (endpoint, e) => {
        endpoint[e.target.name] = e.target.value;
        const newEndpoints = _.extend([], endpoints).map((ep) => {
            if (ep.id === endpoint.id) {
                return endpoint;
            }
            return ep;
        });
        handleChange(newEndpoints);
    };

    const removeEndpoint = (endpoint) => {
        const newEndpoints = _.extend([], endpoints).filter((ep) => {
            if (ep.id === endpoint.id) {
                return false;
            }
            return true;
        });
        handleChange(newEndpoints);
    };
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={[
                        type.id === 1 ? (
                            <RbgTooltip
                                title={
                                    <RbgStack gap={2}>
                                        <RbgTypography>The following replacement can be used in targets for e-mails</RbgTypography>
                                        <RbgTypography>{`{line_manager_email}: Line Manager Email Address`}</RbgTypography>
                                        <RbgTypography>{`{team_member_email}: Team Member Email Address`}</RbgTypography>
                                        <RbgTypography>{`{area_manager_email}: Area Manager Email Address`}</RbgTypography>
                                        <RbgTypography>
                                            To add multiple addresses separate by a semi-colon (;), the first address will be the main
                                            followed by CC&apos;s to the other addresses
                                        </RbgTypography>
                                    </RbgStack>
                                }
                            >
                                Target&nbsp;&nbsp;
                                <Help />
                            </RbgTooltip>
                        ) : (
                            'Target'
                        ),
                        'Enabled',
                        ''
                    ]}
                    keys={endpoints.map((endpoint) => endpoint.id)}
                    rows={endpoints.map((endpoint) => {
                        return [
                            <RbgTextInput name="target" value={endpoint.target} onChange={(e) => handleFieldChange(endpoint, e)} />,
                            <BooleanIcon
                                value={endpoint.enabled}
                                onClick={() => {
                                    handleFieldChange(endpoint, {
                                        target: {
                                            name: 'enabled',
                                            value: !endpoint.enabled
                                        }
                                    });
                                }}
                            />,
                            <RbgButton onClick={() => removeEndpoint(endpoint)}>
                                <DeleteIcon />
                            </RbgButton>
                        ];
                    })}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgGrid item xs={12}>
                    <RbgButton onClick={addNewEndpoint}>
                        <IconPlus /> Add New Target
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        </RbgGrid>
    );
}

NotificationEndpoints.propTypes = {
    endpoints: PropTypes.arrayOf(PropTypes.shape(staffNotificationEndpoint)),
    type: PropTypes.shape(staffNotificationType),
    handleChange: PropTypes.func.isRequired
};
export default NotificationEndpoints;
