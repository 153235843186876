import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconClipboard } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ViewContract from '../../StaffMember/StaffEmployment/ViewContract';

function ContractApprovals() {
    const [filter, setFilter] = useState({
        page: 1,
        search: '',
        status_id: 2,
        include: 'staff_member'
    });
    const { data, error } = useQuery({
        queryKey: [{ url: '/staff/contracts', params: filter }, 'contract_approvals']
    });
    if (error) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <PageableTable
                    headers={['Team Member Name', 'Location', 'Current Contract', 'New Contract', '']}
                    pagination={data.meta.pagination}
                    data={data.data}
                    rowDecorator={(row) => {
                        let currentContract = 'N/A';
                        if (row.staff_member.data.contract.data.id) {
                            currentContract = `${row.staff_member.data.contract.data.template.data.name} (${row.staff_member.data.contract.data.contracted_hours})`;
                        }
                        return [
                            row.staff_member.data.details.data.display_name,
                            row.staff_member.data.role.data.location_name,
                            currentContract,
                            `${row.template.data.name} (${row.contracted_hours})`,
                            <RbgStack direction="row" gap={2} justifyContent="center">
                                <RbgButton
                                    icon={<IconClipboard size={15} />}
                                    variant="contained"
                                    onClick={() => {
                                        DialogHelper.openMdDialog('Approve Contract', <ViewContract contract={row} />);
                                    }}
                                />
                            </RbgStack>
                        ];
                    }}
                    handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

export default ContractApprovals;
