import PropTypes from 'prop-types';
// mui imports
import { Link, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

/**
 *
 * Demos:
 *
 * - [Breadcrumbs](https://mui.com/material-ui/react-breadcrumbs/)
 * - [Links](https://mui.com/material-ui/react-link/)
 *
 * API:
 *
 * - [Link API](https://mui.com/material-ui/api/link/)
 * - inherits [Typography API](https://mui.com/material-ui/api/typography/)
 */

function RbgLink({ children, underline = 'always', sx, router = false, ...others }) {
    const theme = useTheme();
    return (
        <Link
            component={router ? RouterLink : 'a'}
            underline={underline}
            sx={{ cursor: 'pointer', color: theme.palette.info.main, ...sx }}
            {...others}
        >
            {children}
        </Link>
    );
}

export default RbgLink;

RbgLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.array, PropTypes.element]),
    sx: PropTypes.object,
    router: PropTypes.bool,
    underline: PropTypes.oneOf(['none', 'hover', 'always'])
};
