import PropTypes from 'prop-types';
import { contractTemplateSection } from '../../StaffDataTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import { ButtonGroup } from '@mui/material';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconArrowDown, IconArrowUp, IconTrash } from '@tabler/icons-react';
import SubCard from '../../../../../ui_component/cards/SubCard';
import { useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function EditContractTemplateSection({ sectionTemplate, removeSection, editSection, max }) {
    const [expanded, setExpanded] = useState(false);
    const section = sectionTemplate.section.data;
    return (
        <RbgGrid item xs={12}>
            <SubCard contentStyles={{ p: 1 }}>
                <RbgGrid container>
                    <RbgGrid item xs={9}>
                        <RbgStack onClick={() => setExpanded(!expanded)}>
                            <RbgTypography variant="h3">{section.name}</RbgTypography>
                            {expanded ? <Markdown remarkPlugins={[remarkGfm]}>{section.content}</Markdown> : null}
                        </RbgStack>
                    </RbgGrid>
                    <RbgGrid item xs={3}>
                        <ButtonGroup>
                            <RbgButton
                                icon={<IconArrowUp />}
                                size="small"
                                disabled={sectionTemplate.display_order === 1}
                                onClick={() => {
                                    const newSection = {
                                        ...sectionTemplate,
                                        display_order: sectionTemplate.display_order - 1
                                    };
                                    editSection(newSection);
                                }}
                            />
                            <RbgButton
                                icon={<IconArrowDown />}
                                size="small"
                                disabled={sectionTemplate.display_order === max}
                                onClick={() => {
                                    const newSection = {
                                        ...sectionTemplate,
                                        display_order: sectionTemplate.display_order + 1
                                    };
                                    editSection(newSection);
                                }}
                            />
                            <RbgButton
                                icon={<IconTrash />}
                                size="small"
                                onClick={() => {
                                    removeSection(sectionTemplate.section_id);
                                }}
                            />
                        </ButtonGroup>
                    </RbgGrid>
                </RbgGrid>
            </SubCard>
        </RbgGrid>
    );
}

EditContractTemplateSection.propTypes = {
    sectionTemplate: PropTypes.shape(contractTemplateSection).isRequired,
    editSection: PropTypes.func.isRequired,
    removeSection: PropTypes.func.isRequired,
    max: PropTypes.number.isRequired
};
export default EditContractTemplateSection;
