import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import HrConfigEditInitialTrainingSet from './HrConfigEditInitialTrainingSet';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { Divider, Stack } from '@mui/material';

function HrConfigInitialTraining() {
    const { data, isFetched } = useQuery({
        queryKey: [{ url: '/staff/initial-training-configs' }, 'initial-training-configs']
    });
    const editInitialTraining = (config) => {
        DialogHelper.openSmDialog(config ? 'Edit Config Set ' : 'Add Config Set', <HrConfigEditInitialTrainingSet config={config} />);
    };
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('DELETE', `/staff/initial-training-config/${id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/initial-training-configs' }]
            });
        }
    });
    const deleteInitialTraining = (config) => {
        DialogHelper.openSmDialog(config ? 'Edit ConfigSet ' : 'Add Config Set', <HrConfigEditInitialTrainingSet config={config} />);
    };
    if (isFetched === false) {
        return <CardLoader />;
    }
    return (
        <MainCard title="Initial Training Config Sets">
            <RbgGrid container>
                {deleteMutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{deleteMutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={[
                            'ID',
                            'Name',
                            'External Name',
                            'Courses',
                            <RbgButton size="small" color="secondary" onClick={() => editInitialTraining()}>
                                <IconPlus size={20} /> Add
                            </RbgButton>
                        ]}
                        kys={data.data.map((row) => row.id)}
                        rows={data.data.map((row) => {
                            return [
                                row.id,
                                row.name,
                                row.external_name,
                                <ul>
                                    {row.courses.data.map((course) => {
                                        return <li key={course.id}>{course.title}</li>;
                                    })}
                                </ul>,
                                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                                    <RbgButton
                                        size="small"
                                        onClick={() => editInitialTraining(row)}
                                        icon={<IconEdit size={20} />}
                                        variant="contained"
                                    />
                                    <DeleteConfirm size="small" onDelete={() => deleteMutation.mutate(row.id)} />
                                </Stack>
                            ];
                        })}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default HrConfigInitialTraining;
