import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import Debug from '../../../../../ui_component/misc/Debug';
import { useEffect, useState } from 'react';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';

function StaffOnboarding({ staffMember }) {
    const [currentStep, setCurrentStep] = useState(0);
    const { data, isFetched, isPending, isLoading } = useQuery({
        queryKey: [{ url: `/staff/staff-member/${staffMember.id}/onboard` }]
    });
    useEffect(() => {
        if (currentStep === '' && data.data.length) {
            setCurrentStep(data.data[0].name);
        }
    }, [currentStep, data.data]);
    if (isPending || isLoading || !isFetched) {
        return <CardLoader />;
    }
    const currentStepData = data.data[currentStep] ? data.data.currentStep : [];

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Date', 'User', 'Entry', '']}
                    rows={data.data.map((entry) => [entry.date, entry.user, entry.entry, ''])}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <Debug source={currentStepData} />
            </RbgGrid>
        </RbgGrid>
    );
}

StaffOnboarding.propTypes = {
    staffMember: PropTypes.object
};

export default StaffOnboarding;
