import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import StaffFileUpload from '../../Component/StaffFileUpload';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function AddOperaImport() {
    const queryClient = useQueryClient();
    const saveMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', '/staff/admin/opera/import', { file_id: id });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('opera_imports') > -1;
                }
            });
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgGrid container>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            {saveMutation.isPending ? (
                <RbgGrid item xs={12}>
                    <CardLoader />
                </RbgGrid>
            ) : (
                <RbgGrid item xs={12}>
                    <StaffFileUpload staffId={0} onFileUploaded={(f) => saveMutation.mutate(f.id)} bucket="rbg-staff-documents" />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

export default AddOperaImport;
