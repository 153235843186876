import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { useState } from 'react';
import Debug from '../../../../../ui_component/misc/Debug';
import MainCard from '../../../../../ui_component/cards/MainCard';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function HrConfigWagestream() {
    const [checkStatus, setCheckStatus] = useState(null);
    const [exportStatus, setExportStatus] = useState(null);
    const checkMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/wagestream/import');
        },
        onSuccess: (data) => {
            setCheckStatus(data.data);
        }
    });
    const exportMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/wagestream/export');
        },
        onSuccess: (data) => {
            setExportStatus(data.data);
        }
    });
    return (
        <MainCard title="Wagestream Status">
            <RbgGrid container>
                <RbgGrid item xs={6} container>
                    <RbgGrid item xs={12}>
                        <RbgButton disabled={checkMutation.isPending} onClick={() => checkMutation.mutate()}>
                            Import files
                        </RbgButton>
                    </RbgGrid>
                    {checkMutation.error && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{checkMutation.error}</ErrorCard>
                        </RbgGrid>
                    )}
                    {checkStatus !== null && (
                        <RbgGrid item xs={12}>
                            <Debug source={checkStatus} />
                        </RbgGrid>
                    )}
                </RbgGrid>
                <RbgGrid item xs={6} container>
                    <RbgGrid item xs={12}>
                        <RbgButton disabled={exportMutation.isPending} onClick={() => exportMutation.mutate()}>
                            Export employee list
                        </RbgButton>
                    </RbgGrid>
                    {exportMutation.error && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{exportMutation.error}</ErrorCard>
                        </RbgGrid>
                    )}
                    {exportStatus !== null && (
                        <RbgGrid item xs={12}>
                            <Debug source={exportStatus} />
                        </RbgGrid>
                    )}
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default HrConfigWagestream;
