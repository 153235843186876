import { useEffect, useState } from 'react';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import dayjs from 'dayjs';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EventDispatcher from '../../../../../utils/EventDispatcher';
import { useSelector } from 'react-redux';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { useMutation, useQuery } from '@tanstack/react-query';

function AddAbsence({ staffMember = null, location, absenceData }) {
    const [absence, setAbsence] = useState(
        absenceData || {
            type_id: '',
            staff_id: staffMember?.id,
            reason: '',
            start_date: dayjs().format('YYYY-MM-DD'),
            end_date: null,
            location_id: location,
            minutes: 0
        }
    );
    const [type, setType] = useState({});
    const { bar } = useSelector((state) => state.bar);

    const { data: typeData } = useQuery({
        queryKey: [{ url: '/staff/absence/types' }]
    });

    const mutation = useMutation({
        mutationFn: () => {
            if (absenceData && absenceData.id) {
                return Api.reactQueryMutation('POST', `/staff/absence/${absenceData.id}`, absence);
            }
            return Api.reactQueryMutation('PUT', '/staff/absence', absence);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            EventDispatcher.dispatch('reload-staff-member', absence.staff_id);
        }
    });

    useEffect(() => {
        if (!absence.type_id) {
            setType({});
            return;
        }
        const type = typeData.data.find((t) => {
            return t.id === absence.type_id;
        });
        if (type) {
            setType(type);
        } else {
            setType({});
        }
    }, [absence.type_id, typeData]);

    useEffect(() => {
        let completed = true;
        if (!absence.type_id) {
            completed = false;
        }
        if (!absence.start_date) {
            completed = false;
        }
        if (!absence.reason) {
            completed = false;
        }
        if (!absence.staff_id) {
            completed = false;
        }
        if (completed === true) {
            DialogHelper.addSaveButton(() => mutation.mutate(), absenceData ? 'Save Absence' : 'Add Absence');
        } else {
            DialogHelper.addSaveButton(() => mutation.mutate(), absenceData ? 'Save Absence' : 'Add Absence', true);
        }
    }, [absence, mutation, absenceData]);

    return (
        <div>
            <RbgGrid container spacing={gridSpacing}>
                {mutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{mutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                {staffMember === null && (
                    <RbgGrid item xs={12}>
                        <RbgRemoteSelect
                            label="Team Member"
                            filterData={(data) => {
                                return data.data
                                    .sort((s1, s2) => s1.display_name.localeCompare(s2.display_name))
                                    .map((item) => {
                                        return {
                                            value: item.id,
                                            label: item.display_name,
                                            object: item
                                        };
                                    });
                            }}
                            value={absence.staff_id}
                            useAutoComplete
                            handleChange={(e) => setAbsence({ ...absence, staff_id: e.target.value })}
                            endpoint="/staff/staff-search"
                            endpointParameters={{ location, active_only: 1, page: 1, per_page: 500 }}
                            name="staff_id"
                        />
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint="/staff/absence/types"
                        name="type_id"
                        handleObjectChange={(e) => setType(e.target.object)}
                        value={absence.type_id}
                        required
                        handleChange={(v) =>
                            setAbsence((prev) => {
                                return { ...prev, type_id: v.target.value };
                            })
                        }
                        label="Absence Type"
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgTextInput
                        name="reason"
                        value={absence.reason}
                        required
                        onChange={(e) => setAbsence({ ...absence, reason: e.target.value })}
                        label="Comments"
                    />
                </RbgGrid>
                <RbgGrid item xs={type && type.date_range_allowed ? 6 : 12}>
                    <RbgDatePicker
                        value={absence.start_date}
                        onChange={(v) => setAbsence({ ...absence, start_date: v ? v.format('YYYY-MM-DD') : null })}
                        label="Date"
                    />
                </RbgGrid>
                {type && type.date_range_allowed && (
                    <RbgGrid item xs={6}>
                        <RbgDatePicker
                            value={absence.end_date}
                            onChange={(v) =>
                                setAbsence({
                                    ...absence,
                                    end_date: v ? v.format('YYYY-MM-DD') : null
                                })
                            }
                            label="End Date"
                        />
                    </RbgGrid>
                )}
                {type && type.hours_required && (
                    <RbgGrid item xs={12}>
                        <RbgTextInput
                            name="minutes"
                            type="number"
                            required
                            value={absence.minutes}
                            onChange={(v) => {
                                setAbsence({
                                    ...absence,
                                    minutes: v.target.value
                                });
                            }}
                            label="Minutes"
                        />
                    </RbgGrid>
                )}
            </RbgGrid>
        </div>
    );
}

AddAbsence.propTypes = {
    staffMember: PropTypes.object,
    location: PropTypes.string.isRequired,
    absenceData: PropTypes.object
};
export default AddAbsence;
