import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Grid } from '@mui/material';
import { gridSpacing } from '../../../store/constant';

/**
 *
 * Demos:
 *
 * - [Grid](https://mui.com/material-ui/react-grid/)
 *
 * API:
 *
 * - [Grid API](https://mui.com/material-ui/api/grid/)
 */

const RbgGrid = forwardRef(({ item, container, children, spacing = gridSpacing, ...others }, ref) => {
    if (container && !item) {
        return (
            <Grid ref={ref} container {...others} spacing={spacing}>
                {children}
            </Grid>
        );
    }
    if (item && !container) {
        return (
            <Grid ref={ref} item {...others}>
                {children}
            </Grid>
        );
    }
    if (item && container) {
        return (
            <Grid ref={ref} container item {...others} spacing={spacing}>
                {children}
            </Grid>
        );
    }
    return null;
});

export default RbgGrid;

RbgGrid.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
    item: PropTypes.bool,
    container: PropTypes.bool,
    spacing: PropTypes.number
};
