import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material-ui imports
import { Tab, useTheme } from '@mui/material';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
import { TabContext } from '@mui/lab';
// 3rd party imports
import { IconX } from '@tabler/icons-react';
// project imports
import { gridSpacing } from 'store/constant';
import CircularLoading from 'ui_component/loading/CircularLoading';
import TabPanel from './TabPanel';
import TabListStyled from './TabListStyled';
import './badgeStyles.scss';

function RbgTabsHorizontal({
    currentTab,
    setCurrentTab,
    handleTabClose,
    tabs,
    gridContainerStyles,
    tabGridStyles,
    tabPanelGridStyles,
    tabStyles,
    tabListStyles,
    scrollButtons = false
}) {
    const theme = useTheme();
    // local state
    const [clickedTabs, setClickedTabs] = useState([currentTab]);

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };
    const tabList = tabs.map((tab, idx) => (
        <Tab
            onClick={() => {
                if (clickedTabs.indexOf(tab.value) === -1) {
                    setClickedTabs((prev) => [...prev, tab.value]);
                }
            }}
            sx={{
                py: '4px',
                minHeight: '24px',
                ...tabStyles
            }}
            className={tab.className}
            key={idx}
            label={
                <RbgStack sx={{ position: 'relative' }} direction="row" alignItems="center" justifyContent="space-between">
                    {tab.notifications ? (
                        <>
                            <span>{tab.label}</span>
                            <span className="rbg-tab-badge" style={{ backgroundColor: theme.palette.error.main }}>
                                {tab.notifications}
                            </span>
                        </>
                    ) : (
                        <span>{tab.label}</span>
                    )}
                    {tab.canClose && (
                        <IconX
                            style={{
                                marginLeft: '8px'
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleTabClose(event, tab.value);
                            }}
                        />
                    )}
                </RbgStack>
            }
            value={tab.value}
        />
    ));

    useEffect(() => {
        if (clickedTabs.indexOf(currentTab) === -1) {
            setClickedTabs((prev) => [...prev, currentTab]);
        }
    }, [currentTab, clickedTabs]);

    return (
        <TabContext value={currentTab}>
            <RbgGrid sx={{ ...gridContainerStyles }} container spacing={gridSpacing} flexWrap="wrap">
                <RbgGrid sx={{ paddingTop: 0, ...tabGridStyles }} item xs={12}>
                    <TabListStyled
                        sx={{
                            minHeight: '24px',
                            borderBottom: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                            ...tabListStyles
                        }}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons={scrollButtons}
                        orientation="horizontal"
                    >
                        {tabList}
                    </TabListStyled>
                </RbgGrid>
                <RbgGrid sx={{ paddingTop: '14px !important', width: '100%', ...tabPanelGridStyles }} item xs={12}>
                    {tabs.map((tab, idx) => (
                        <TabPanel key={idx} value={tab.value} loading={tab.loading}>
                            {clickedTabs.indexOf(tab.value) > -1 ? tab.content : <CircularLoading />}
                        </TabPanel>
                    ))}
                </RbgGrid>
            </RbgGrid>
        </TabContext>
    );
}

RbgTabsHorizontal.propTypes = {
    /**
     * Current visible tab
     */
    currentTab: PropTypes.string.isRequired,

    /**
     * Set current visible tab
     */
    setCurrentTab: PropTypes.func.isRequired,

    /**
     * Tabs array
     *
     *   [{
     *   className: string,
     *
     *   canClose: boolean,
     *
     *   label: string,
     *
     *   value: string,
     *
     *   notifications: ptn,
     *
     *   content: node or React component
     *   }]
     */
    tabs: PropTypes.array.isRequired,

    /**
     * Removes a tab from the array
     */
    handleTabClose: PropTypes.func,

    /**
     * Grid container styles - parent of RbgVerticalTabs
     */
    gridContainerStyles: PropTypes.object,

    /**
     * Grid item styles - parent of tab list
     */
    tabGridStyles: PropTypes.object,

    /**
     * List styles - parent of tabs
     */
    tabListStyles: PropTypes.object,

    /**
     * Tab styles
     */
    tabStyles: PropTypes.object,

    /**
     * Grid item styles - parent of tab panels
     */
    tabPanelGridStyles: PropTypes.object,
    scrollButtons: PropTypes.bool
};

TabPanel.propTypes = {
    children: PropTypes.object,
    value: PropTypes.string
};
export default RbgTabsHorizontal;
