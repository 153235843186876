import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Button, Chip, InputAdornment, ListSubheader, useTheme } from '@mui/material';
import RbgStack from '../mui-wrappers/Stack/RbgStack';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';
import RbgTooltip from '../tooltip/RbgTooltip';
import { IconInfoCircle } from '@tabler/icons-react';
import RbgButton from '../mui-wrappers/Button/RbgButton';

function MultipleLocationSelect({
    name,
    label,
    value,
    onChange,
    onObjectChange,
    onlyLive = true,
    onlyAssignedLocations = false,
    limitTags = 1,
    help,
    startAdornment = null
}) {
    const theme = useTheme();
    const [focused, setFocused] = React.useState(false);
    const { data } = useQuery({
        queryKey: [{ url: onlyAssignedLocations ? '/staff/my-locations' : '/staff/locations', parameters: { live_only: onlyLive ? 1 : 0 } }]
    });

    const locations = data.data.sort((location1, location2) => {
        if (location1.brand.data.name === location2.brand.data.name) {
            return location1.name.localeCompare(location2.name);
        }
        return location1.brand.data.id > location2.brand.data.id ? 1 : -1;
    });
    const selectedValues = value
        .map((location) => data.data.find((data) => data.cost_centre === location))
        .filter((location) => location !== undefined);

    const processChange = (e, selected) => {
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: selected.map((s) => s.cost_centre)
                }
            });
        }
        if (onObjectChange) {
            onObjectChange({
                target: {
                    name,
                    value: selected.map((s) => s.cost_centre),
                    object: selected
                }
            });
        }
    };
    const handleGroupSelect = (group) => {
        const selected = locations.filter((location) => location.brand.data.name === group);

        const allSelected = selected.every((selLocation) =>
            selectedValues.some((selValue) => selValue.cost_centre === selLocation.cost_centre)
        );

        if (allSelected) {
            const newSelectedValues = selectedValues.filter(
                (selValue) => !selected.some((selLocation) => selLocation.cost_centre === selValue.cost_centre)
            );
            processChange(null, newSelectedValues);
        } else {
            const newSelectedValues = Array.from(new Set([...selectedValues, ...selected]));
            processChange(null, newSelectedValues);
        }
    };

    const renderInput = (params) => {
        const useStartAdornment = (
            <>
                <RbgButton
                    sx={{ px: 1, mr: 1, minWidth: '20px', borderRadius: '15px' }}
                    size="small"
                    variant="contained"
                    tooltip="Select All Locations"
                    onClick={() => processChange(null, locations)}
                >
                    Select All
                </RbgButton>
                {startAdornment}
                {params.InputProps.startAdornment}
            </>
        );

        return (
            <TextField
                sx={{ '.MuiOutlinedInput-root': { paddingRight: '50px !important' } }}
                {...params}
                label={label}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: help ? (
                        <>
                            <InputAdornment position="start">
                                <RbgTooltip title={help}>
                                    <IconInfoCircle color={theme.palette.info.main} />
                                </RbgTooltip>
                            </InputAdornment>
                            {useStartAdornment}
                        </>
                    ) : (
                        useStartAdornment
                    )
                }}
            />
        );
    };
    const renderGroup = (params) => {
        return [
            <ListSubheader
                component={Button}
                key={params.key}
                color="primary"
                sx={{
                    top: '-8px',
                    width: '100%',
                    height: '40px',
                    lineHeight: '40px',
                    '&:hover': { backgroundColor: theme.palette.background.paper }
                }}
                onClick={() => handleGroupSelect(params.group)}
            >
                {params.group}
            </ListSubheader>,
            params.children
        ];
    };
    const renderTags = (selectedOptions, getTagProps) => {
        const tags = selectedOptions
            .slice(0, focused ? selectedOptions.length : limitTags)
            .map((option, index) => (
                <Chip
                    key={index}
                    size="small"
                    sx={{ borderRadius: '100px !important' }}
                    variant="outlined"
                    label={option?.name}
                    {...getTagProps({ index })}
                />
            ));
        const moreTagCount = selectedOptions.length - tags.length;
        return (
            <RbgStack direction="row" alignItems="center" flexWrap={focused ? 'wrap' : 'no-wrap'} spacing={1}>
                {tags}
                {moreTagCount > 0 && <RbgTypography variant="subtitle2">{`+${moreTagCount} more`}</RbgTypography>}
            </RbgStack>
        );
    };

    return (
        <Autocomplete
            sx={{ '.MuiInputBase-root': { minWidth: '300px !important' } }}
            size="small"
            fullWidth
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
            multiple
            disableCloseOnSelect
            value={selectedValues}
            onChange={processChange}
            options={locations}
            groupBy={(option) => {
                return option.brand.data.name;
            }}
            renderGroup={renderGroup}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => {
                return option.cost_centre === value?.cost_centre;
            }}
            renderInput={renderInput}
            renderTags={renderTags}
        />
    );
}

export default MultipleLocationSelect;

MultipleLocationSelect.propTypes = {
    onlyAssignedLocations: PropTypes.bool,
    onlyLive: PropTypes.bool,
    includeCentral: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onObjectChange: PropTypes.func,
    limitTags: PropTypes.number,
    startAdornment: PropTypes.node,
    help: PropTypes.string
};
