import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import { staffMember } from '../../StaffDataTypes';
import StaffContractWidget from './Widget/StaffContractWidget';
import JobRoleWidget from './Widget/JobRoleWidget';
import PayDetailsWidget from './Widget/PayDetailsWidget';
import EmploymentWidget from './Widget/EmploymentWidget';
import dayjs from 'dayjs';

function StaffEmployment({ staffMember }) {
    const leaver = staffMember.leave_date && dayjs(staffMember.leave_date).isBefore(dayjs());
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item md={6} xs={12}>
                <EmploymentWidget staffMember={staffMember} />
            </RbgGrid>
            <RbgGrid item md={6} xs={12}>
                <StaffContractWidget staffMember={staffMember} />
            </RbgGrid>
            {!leaver && (
                <RbgGrid item md={6} xs={12}>
                    <JobRoleWidget staffMember={staffMember} />
                </RbgGrid>
            )}
            {!leaver && (
                <RbgGrid item md={6} xs={12}>
                    <PayDetailsWidget staffMember={staffMember} />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

StaffEmployment.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default StaffEmployment;
