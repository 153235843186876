import MainCard from '../../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../../store/constant';
import IntroCardPrimary from '../../../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import CurrencyFormatter from '../../../../../../utils/currencyFormatter';
import DateFormatter from '../../../../../../utils/dateFormatter';
import IntroCardSecondary from '../../../../../../ui_component/cards/IntroCardSecondary';
import RbgStack from '../../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { staffMember } from '../../../StaffDataTypes';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import ScheduleRateChange from '../ScheduleRateChange';
import EditRate from '../EditRate';
import PreviousRates from '../PreviousRates';
import DeleteConfirm from '../../../../../../ui_component/buttons/DeleteConfirm';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../../utils/api';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../../contexts/RcloudAuthContext';

function PayDetailsWidget({ staffMember }) {
    const details = staffMember.details.data;
    const { user } = useContext(RcloudAuthContext);

    const currentPay = staffMember.pay_rate.data;
    const previousRates = staffMember.pay_rates.data.filter((rate) => rate.end_date);
    const futureRates = staffMember.pay_rates.data.filter((rate) => rate.id !== staffMember.pay_rate?.data?.id && rate.end_date === null);
    const rateAuths = staffMember.pay_rate_auths.data;
    const addPayRate = () => {
        DialogHelper.openMdDialog('Rate Change', <ScheduleRateChange staffMember={staffMember} />);
    };
    const editPayRate = () => {
        DialogHelper.openMdDialog('Rate Change', <EditRate rate={staffMember.pay_rate.data} />);
    };
    const viewPreviousRates = () => {
        DialogHelper.openMdDialog('Previous Pay Rates', <PreviousRates rates={staffMember.pay_rates.data} />);
    };
    const deleteMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('DELETE', `/staff/staff-member/${staffMember.id}/pay-rate/${id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    if (dayjs(staffMember.employment.data.start_date).isAfter(dayjs())) {
        return (
            <MainCard title="Pay Details">
                <RbgGrid container spacing={gridSpacing}>
                    <RbgGrid item xs={12}>
                        <IntroCardPrimary>
                            <RbgTypography>
                                When {staffMember.display_forename} starts they will have a pay rate of{' '}
                                <strong>
                                    <CurrencyFormatter value={staffMember.pay_rate.data.rate} />{' '}
                                    {staffMember.pay_rate.data.type.data.display_type}
                                </strong>
                            </RbgTypography>
                        </IntroCardPrimary>
                    </RbgGrid>
                </RbgGrid>
            </MainCard>
        );
    }
    const actions = [];
    if (user.permissions.indexOf('hr_staff_edit') > -1) {
        if (rateAuths.length === 0) {
            actions.push({
                content: 'Schedule Pay Rate Change',
                onClick: () => addPayRate()
            });
        }
        if (user.permissions.indexOf('hr_config') > 1) {
            actions.push({
                content: 'Edit Pay Rate',
                onClick: () => editPayRate()
            });
        }
    }
    if (previousRates.length > 0 || futureRates.length > 0) {
        actions.push({ content: 'View Pay Rate History', onClick: () => viewPreviousRates() });
    }
    if (staffMember.pay_rate.data.redacted === true) {
        return (
            <MainCard title="Pay Details">
                <RbgGrid container>
                    <RbgGrid item xs={12}>
                        <ErrorCard>You are not authorised to view this team members pay information</ErrorCard>
                    </RbgGrid>
                </RbgGrid>
            </MainCard>
        );
    }
    return (
        <MainCard title="Pay Details" actionButtons={{ label: 'Actions', items: actions }}>
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgTypography>
                            {staffMember.display_forename}&apos;s current pay rate is{' '}
                            <strong>
                                <CurrencyFormatter value={staffMember.pay_rate.data.rate} />
                            </strong>{' '}
                            {staffMember.pay_rate.data.type.data.display_type}. Their last pay change occurred on{' '}
                            <strong>
                                <DateFormatter date={currentPay.start_date} />.
                            </strong>
                        </RbgTypography>
                    </IntroCardPrimary>
                </RbgGrid>

                {futureRates.length > 0 && (
                    <RbgGrid item xs={12}>
                        <IntroCardSecondary>
                            <RbgStack gap={2}>
                                <RbgTypography>
                                    <strong>{staffMember.display_forename}&apos;s</strong> rate is due to be changed to{' '}
                                    <strong>
                                        <CurrencyFormatter value={futureRates[0].rate} />
                                    </strong>{' '}
                                    on <strong>{dayjs(futureRates[0].start_date).format('DD/MM/YYYY')}</strong>
                                </RbgTypography>
                                <DeleteConfirm onDelete={() => deleteMutation.mutate(futureRates[0].id)} btnText="Cancel future pay rate" />
                            </RbgStack>
                        </IntroCardSecondary>
                    </RbgGrid>
                )}
                {rateAuths.length > 0 && (
                    <RbgGrid item xs={12}>
                        <IntroCardSecondary>
                            <RbgStack>
                                <RbgTypography>
                                    {details.display_name} has a future rate authorisation outstanding for{' '}
                                    <strong>
                                        <CurrencyFormatter value={rateAuths[0].rate} />
                                    </strong>{' '}
                                    effective from <strong>{dayjs(rateAuths[0].effective_from_date).format('DD/MM/YYYY')}</strong>
                                </RbgTypography>
                            </RbgStack>
                        </IntroCardSecondary>
                    </RbgGrid>
                )}
            </RbgGrid>
        </MainCard>
    );
}

PayDetailsWidget.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default PayDetailsWidget;
