import PropTypes from 'prop-types';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import StaffFileUpload from '../../Component/StaffFileUpload';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { payrollPeriod } from './draftPayslipTypes';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function UploadDraftPayslips({ payrollPeriod }) {
    const [file, setFile] = useState({});
    const queryClient = useQueryClient();
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/payslips/process', {
                file_id: file.id,
                payroll_period: payrollPeriod.id
            });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('draft_payslips') > -1;
                }
            });
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>
                    Upload payslip file for{' '}
                    <strong>
                        {payrollPeriod.weeks.join(', ')} {payrollPeriod.type.data.name}
                    </strong>
                </InfoCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <StaffFileUpload staffId={0} onFileUploaded={(e) => setFile(e)} bucket="rbg-staff-documents" />
            </RbgGrid>
            {file.id && (
                <RbgGrid item xs={12}>
                    <RbgButton fullWidth color="secondary" disabled={saveMutation.isPending} onClick={() => saveMutation.mutate()}>
                        Import {file.original_filename}
                        {saveMutation.isPending && <i className="fa fa-cog fa-spin" />}
                    </RbgButton>
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

UploadDraftPayslips.propTypes = {
    payrollPeriod: PropTypes.shape(payrollPeriod).isRequired
};
export default UploadDraftPayslips;
