import { lazy } from 'react';
// project imports
import config from '../config';
import MainLayout from 'layout/MainLayout/MainLayout';
import Loadable from 'ui_component/loading/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NavigationScroll from 'layout/NavigationScroll';
import RotaList from '../views/app/Staff/Rota/RotaList';
import ViewRota from '../views/app/Staff/Rota/ViewRota';
// dashboard routing
const ErrorBoundary = Loadable(lazy(() => import('ui_component/error/ErrorBoundary/ErrorBoundary')));
const Dashboard = Loadable(lazy(() => import('views/welcome/Dashboard/Dashboard')));
// bookings management routing
const PartyPro = Loadable(lazy(() => import('views/bookingsManagement/PartyPro/PartyPro')));
const CentralPartyPro = Loadable(lazy(() => import('views/bookingsManagement/CentralPartyPro/CentralPartyPro')));
const PreOrders = Loadable(lazy(() => import('views/bookingsManagement/PreOrders/PreOrders')));
const TableBookings = Loadable(lazy(() => import('views/bookingsManagement/TableBookings/TableBookings')));
// application routing
const AuditApp = Loadable(lazy(() => import('views/app/AuditApp/AuditApp')));
const Campaigns = Loadable(lazy(() => import('views/app/Campaigns/Campaigns')));
const Customer = Loadable(lazy(() => import('views/app/Customer/Customer')));
const Feedback = Loadable(lazy(() => import('views/app/Feedback/Feedback')));
const Fileshare = Loadable(lazy(() => import('views/app/Fileshare/Fileshare')));
const Reports = Loadable(lazy(() => import('views/app/Reports/Reports')));
const Hub = Loadable(lazy(() => import('views/app/Hub/Hub')));
const Payroll = Loadable(lazy(() => import('views/app/Payroll/Payroll')));
// release notes routing
const Version = Loadable(lazy(() => import('views/releaseNotes/Version/Version')));
// user profile routing
const Profile = Loadable(lazy(() => import('views/user/Profile/Profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    name: 'main-routes',
    basename: config.basename,
    path: '/',
    element: (
        <ErrorBoundary>
            <AuthGuard>
                <NavigationScroll>
                    <MainLayout />
                </NavigationScroll>
            </AuthGuard>
        </ErrorBoundary>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/partypro-new',
            element: <PartyPro />
        },
        {
            path: '/central-partypro',
            element: <CentralPartyPro />
        },
        {
            path: '/partycentral',
            element: <CentralPartyPro />
        },
        {
            path: '/pre-orders',
            element: <PreOrders />,
            handle: {
                crumb: () => 'Pre Orders'
            }
        },
        {
            path: '/table-bookings',
            element: <TableBookings />,
            handle: {
                crumb: () => 'Table Bookings'
            }
        },
        {
            path: '/audit-app',
            element: <AuditApp />,
            handle: {
                crumb: () => 'Audit App'
            }
        },
        {
            path: '/campaigns',
            element: <Campaigns />,
            handle: {
                crumb: () => 'Campaigns'
            }
        },
        {
            path: '/customer',
            element: <Customer />,
            handle: {
                crumb: () => 'Customer'
            }
        },
        {
            path: '/feedback',
            element: <Feedback />,
            handle: {
                crumb: () => 'Feedback'
            }
        },
        {
            path: '/fileshare',
            element: <Fileshare />,
            handle: {
                crumb: () => 'Fileshare'
            }
        },
        {
            path: '/reports',
            element: <Reports />,
            handle: {
                crumb: () => 'Reports'
            }
        },
        {
            path: '/hub',
            element: <Hub />,
            handle: {
                crumb: () => 'Hub'
            }
        },
        {
            path: '/payroll',
            element: <Payroll />,
            handle: {
                crumb: () => 'Payroll'
            }
        },
        {
            path: '/version',
            element: <Version />,
            handle: {
                crumb: () => 'Version'
            }
        },
        {
            path: '/profile',
            element: <Profile />,
            handle: {
                crumb: () => 'Profile'
            }
        }
    ]
};

export default MainRoutes;
