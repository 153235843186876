import PropTypes from 'prop-types';
import { ListItemButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';

export default function ListItem({ onClick, Icon, textPrimary, textSecondary }) {
    const theme = useTheme();
    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <ListItemButton onClick={onClick}>
            <RbgStack sx={{ width: '100%' }} direction={matchSmDown ? 'column' : 'row'} alignItems="center" justifyContent="space-between">
                <RbgStack direction="row" spacing={2}>
                    <Icon size={20} />
                    <Typography variant="subtitle1">{textPrimary}</Typography>
                </RbgStack>
                <Typography variant="subtitle2" align="right">
                    {textSecondary}
                </Typography>
            </RbgStack>
        </ListItemButton>
    );
}

ListItem.propTypes = {
    onClick: PropTypes.func,
    Icon: PropTypes.object.isRequired,
    textPrimary: PropTypes.string.isRequired,
    textSecondary: PropTypes.string
};
