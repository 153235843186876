import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import Debug from '../../../../../ui_component/misc/Debug';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import dayjs from 'dayjs';
import HourlyRateRateCustomField from './CustomFields/HourlyRateRateCustomField';
import ContractCustomField from './CustomFields/ContractCustomField';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import FileUploadCustomField from './CustomFields/FileUploadCustomField';
import SalaryCustomField from './CustomFields/SalaryCustomField';

function EditNewStarterFields({ data, fields, handleFieldChange, setTab }) {
    const params = useParams();
    const id = params.id;
    const queryClient = useQueryClient();
    const positionMutation = useMutation({
        mutationFn: (position) => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${id}/position`, { position });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${id}` }]
            });
        }
    });

    const updateMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${id}/fields`, {
                fields
            });
        },
        onSuccess: () => {
            setTab('rtw');
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${id}` }]
            });
        }
    });
    const cancelMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff/onboarding/team-member/${id}`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${id}` }]
            });
        }
    });
    const handlePositionChange = (e) => {
        const value = e.target.value;
        positionMutation.mutate(value);
    };
    const handleFieldChangeEvent = (e) => {
        handleFieldChange(e.target.name, e.target.value);
    };

    const getFieldByFieldId = (id) => {
        return data.data.manager_fieldset.data.fields.data.find((field) => {
            return field.field_id === parseInt(id, 10);
        });
    };

    const fieldData = data.data.manager_fieldset.data.id
        ? data.data.manager_fieldset.data.fields.data.filter((field) => {
              if (field.dependent_field) {
                  const dep = getFieldByFieldId(field.dependent_field);
                  if (!dep) {
                      return false;
                  }
                  const value = fields[dep.name];
                  if (field.dependent_value !== value) {
                      return false;
                  }
              }
              return field;
          })
        : null;

    const { data: positions } = useQuery({
        queryKey: [{ url: `/staff/onboarding/positions?cost_centre=${data.data.location}` }]
    });
    if (!data.data.position.data.id) {
        const importJobTitle = data.data.data.job_title ? data.data.data.job_title : null;
        return (
            <RbgGrid container sx={{ mt: 0 }}>
                {updateMutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{updateMutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    {importJobTitle ? (
                        <InfoCard>
                            First step, please enter the job role that this team member has been hired for. The role imported was{' '}
                            <strong>{importJobTitle}</strong>
                        </InfoCard>
                    ) : (
                        <InfoCard>First step, please enter the job role that this team member has been hired for.</InfoCard>
                    )}
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSelect
                        values={positions.data
                            .sort((pos1, pos2) => pos1.pay_type.data.name.localeCompare(pos2.pay_type.data.name))
                            .map((pos) => {
                                return {
                                    value: pos.id,
                                    label: `${pos.pay_type.data.name} - ${pos.name}`,
                                    object: pos
                                };
                            })}
                        label="Position"
                        useAutoComplete
                        value={data.data.position.data.id}
                        name="position"
                        groupBy={(opt) => {
                            return opt.object.pay_type.data.name;
                        }}
                        handleChange={(e) => handlePositionChange(e)}
                        help="Chose the role the team member will be onboarded to"
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    let completed = true;
    fieldData.forEach((field) => {
        const value = fields[field.name];
        if ((field.required === true && typeof value === 'undefined') || value === '') {
            completed = false;
        }
    });
    return (
        <RbgGrid container sx={{ mt: 0 }}>
            {updateMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{updateMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgSelect
                    endpoint={`/staff/onboarding/positions?cost_centre=${data.data.location}`}
                    values={positions.data
                        .sort((pos1, pos2) => pos1.pay_type.data.name.localeCompare(pos2.pay_type.data.name))
                        .map((pos) => {
                            return {
                                value: pos.id,
                                label: `${pos.pay_type.data.name} - ${pos.name}`,
                                object: pos
                            };
                        })}
                    label="Position"
                    useAutoComplete
                    value={data.data.position.data.id}
                    name="position"
                    groupBy={(opt) => {
                        return opt.object.pay_type.data.name;
                    }}
                    handleChange={(e) => handlePositionChange(e)}
                    help="Chose the role the team member will be onboarded to"
                />
            </RbgGrid>
            {data.data.position.data.id && (
                <>
                    {fieldData.map((field) => {
                        let content = null;
                        const xs = 12;
                        let md = 6;
                        let lg = 4;
                        if (field.type === 'title') {
                            lg = 12;
                            md = 12;
                            content = <RbgTypography variant="h2">{field.label}</RbgTypography>;
                        }
                        if (field.type === 'content') {
                            lg = 12;
                            md = 12;
                            content = <RbgTypography>{field.label}</RbgTypography>;
                        }
                        if (field.type === 'text') {
                            content = (
                                <RbgTextInput
                                    name={field.name}
                                    value={fields[field.name]}
                                    label={field.label}
                                    required={field.required}
                                    onChange={(e) => handleFieldChangeEvent(e)}
                                />
                            );
                        }
                        if (field.type === 'email') {
                            content = (
                                <RbgTextInput
                                    type="email"
                                    name={field.name}
                                    value={fields[field.name]}
                                    required={field.required}
                                    label={field.label}
                                    onChange={(e) => handleFieldChangeEvent(e)}
                                />
                            );
                        }
                        if (field.type === 'number') {
                            content = (
                                <RbgTextInput
                                    type="number"
                                    min={field.allowed_values.min}
                                    max={field.allowed_values.max}
                                    required={field.required}
                                    name={field.name}
                                    value={fields[field.name]}
                                    label={field.label}
                                    onChange={(e) => handleFieldChangeEvent(e)}
                                />
                            );
                        }
                        if (field.type === 'salary') {
                            content = (
                                <SalaryCustomField
                                    field={field}
                                    value={fields[field.name]}
                                    fields={fields}
                                    onChange={(e) => handleFieldChangeEvent(e)}
                                    position={data.data.position.data}
                                    location={data.data.location}
                                />
                            );
                        }
                        if (field.type === 'select') {
                            content = (
                                <RbgSelect
                                    required={field.required}
                                    values={field.allowed_values.map((field) => ({
                                        value: field.key,
                                        label: field.value
                                    }))}
                                    useAutoComplete
                                    value={fields[field.name]}
                                    handleChange={(e) => handleFieldChangeEvent(e)}
                                    label={field.label}
                                    name={field.name}
                                />
                            );
                        }
                        if (field.type === 'date') {
                            let min = null;
                            if (field.allowed_values.future === true) {
                                min = dayjs();
                            }
                            content = (
                                <RbgDatePicker
                                    name={field.name}
                                    label={field.label}
                                    minDate={min}
                                    onChange={(e) =>
                                        handleFieldChangeEvent({
                                            target: {
                                                name: field.name,
                                                value: e ? e.format('YYYY-MM-DD') : null
                                            }
                                        })
                                    }
                                    value={fields[field.name]}
                                />
                            );
                        }
                        if (field.type === 'hourly_rate') {
                            content = (
                                <HourlyRateRateCustomField
                                    fields={fields}
                                    value={fields[field.name]}
                                    position={data.data.position.data}
                                    location={data.data.location}
                                    handleChange={(rate) =>
                                        handleFieldChangeEvent({
                                            target: {
                                                name: field.name,
                                                value: rate
                                            }
                                        })
                                    }
                                />
                            );
                        }
                        if (field.type === 'contract') {
                            content = (
                                <ContractCustomField
                                    newStarterId={id}
                                    fields={fields}
                                    value={fields[field.name]}
                                    handleChange={(contractId) =>
                                        handleFieldChangeEvent({
                                            target: {
                                                name: field.name,
                                                value: contractId
                                            }
                                        })
                                    }
                                />
                            );
                        }
                        if (field.type === 'file') {
                            content = (
                                <FileUploadCustomField
                                    handleChange={(file) => {
                                        handleFieldChangeEvent({
                                            target: {
                                                name: field.name,
                                                value: file
                                            }
                                        });
                                    }}
                                    field={field}
                                    value={fields[field.name]}
                                />
                            );
                        }
                        return (
                            <RbgGrid item xs={xs} md={md} lg={lg} key={field.id}>
                                {content || <Debug source={field} key={field.id} />}
                            </RbgGrid>
                        );
                    })}
                </>
            )}
            <RbgGrid item xs={12}>
                <RbgButton
                    onClick={() => updateMutation.mutate()}
                    sx={{ width: '100%' }}
                    disabled={completed === false || updateMutation.isPending}
                >
                    Next
                </RbgButton>
            </RbgGrid>
            {data.data.status.data.can_cancel && (
                <RbgGrid item xs={12}>
                    <DeleteConfirm
                        onDelete={() => cancelMutation.mutate()}
                        confirmText="Are you sure you want to cancel this new starter?"
                        btnText="Cancel new starter"
                        fullWidth
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

EditNewStarterFields.propTypes = {
    data: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired
};

export default EditNewStarterFields;
