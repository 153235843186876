import PropTypes from 'prop-types';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { icons } from './Icons';
import RbgStack from '../Stack/RbgStack';
import RbgTooltip from '../../tooltip/RbgTooltip';
import { IconInfoCircle } from '@tabler/icons-react';
import RbgIcon from './RbgIcon';

export default function RbgIconsAutocomplete({ value, onChange, label, disabled, name, required, size = 'small' }) {
    const [selected, setSelected] = useState(value);

    const handleChange = (event, option, reason) => {
        if (reason === 'selectOption') {
            setSelected(option);
            onChange({ target: { name, value: option.value } });
        }
        if (reason === 'clear') {
            setSelected('');
            onChange({ target: { name, value: '' } });
        }
    };

    useEffect(() => {
        if (!value) return;
        const icon = icons.find((icon) => icon.value === value);
        setSelected(icon || '');
    }, [value]);
    if (typeof value === 'undefined') {
        value = '';
    }
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            size={size}
            value={selected}
            options={icons}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => {
                return option.value === value;
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
            renderOption={(props, option) => {
                const Icon = option.icon;
                return (
                    <li {...props}>
                        <RbgStack sx={{ width: '100%' }} direction="row" gap={2}>
                            <Icon />
                            {option.label}
                        </RbgStack>
                    </li>
                );
            }}
        />
    );
}

RbgIconsAutocomplete.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    name: PropTypes.string
};
