import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { staffContract } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconCheck, IconDownload } from '@tabler/icons-react';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useContext, useState } from 'react';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import ContractHistory from './Contract/ContractHistory';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function ViewContract({ contract }) {
    const [tab, setTab] = useState('info');
    const [reason, setReason] = useState('');
    const { user } = useContext(RcloudAuthContext);

    const queryClient = useQueryClient();
    const download = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('GET', `/staff/staff-member/${contract.staff_id}/contract/${contract.id}/download`);
        },
        onSuccess: (data) => {
            window.open(data.data.uri);
        }
    });

    const cancelMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff/staff-member/${contract.staff_id}/contract/${contract.id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    const approveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${contract.staff_id}/contract/${contract.id}/authorise`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('contract_approvals') > -1 || query.queryKey.indexOf('auth_counts') > -1;
                }
            });
            DialogHelper.closeDialog();
        }
    });

    const resendMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${contract.staff_id}/contract/${contract.id}/resend`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    const declineMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${contract.staff_id}/contract/${contract.id}/decline`, { reason });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('contract_approvals') > -1 || query.queryKey.indexOf('auth_counts') > -1;
                }
            });
            DialogHelper.closeDialog();
        }
    });

    const tabs = [
        {
            value: 'info',
            label: 'Information',
            content: (
                <RbgGrid container>
                    <RbgGrid item xs={12}>
                        <RbgTable>
                            <RbgTableBody>
                                <RbgTableRow>
                                    <RbgTableCell>
                                        <strong>ID</strong>
                                    </RbgTableCell>
                                    <RbgTableCell>{contract.id}</RbgTableCell>
                                </RbgTableRow>
                                <RbgTableRow>
                                    <RbgTableCell>
                                        <strong>Contract Template</strong>
                                    </RbgTableCell>
                                    <RbgTableCell>{contract.template.data.name}</RbgTableCell>
                                </RbgTableRow>
                                <RbgTableRow>
                                    <RbgTableCell>
                                        <strong>Status</strong>
                                    </RbgTableCell>
                                    <RbgTableCell>
                                        <RbgStack direction="column" justifyContent="center" gap={2}>
                                            <RbgTypography>{contract.status.data.name}</RbgTypography>
                                        </RbgStack>
                                    </RbgTableCell>
                                </RbgTableRow>
                                <RbgTableRow>
                                    <RbgTableCell>
                                        <strong>Added By</strong>
                                    </RbgTableCell>
                                    <RbgTableCell>{contract.added_by_name}</RbgTableCell>
                                </RbgTableRow>
                                <RbgTableRow>
                                    <RbgTableCell>
                                        <strong>Contracted Hours</strong>
                                    </RbgTableCell>
                                    <RbgTableCell>{contract.contracted_hours}</RbgTableCell>
                                </RbgTableRow>
                                {download.error && (
                                    <RbgTableRow>
                                        <RbgTableCell colSpan={2}>
                                            <ErrorCard>{download.error}</ErrorCard>
                                        </RbgTableCell>
                                    </RbgTableRow>
                                )}
                                {contract.file.data.id && (
                                    <RbgTableRow>
                                        <RbgTableCell>
                                            <strong>PDF</strong>
                                        </RbgTableCell>
                                        <RbgTableCell>
                                            {contract.file.data.filename}
                                            <RbgButton
                                                icon={<IconDownload />}
                                                onClick={() => download.mutate()}
                                                disabled={download.isPending}
                                            />
                                        </RbgTableCell>
                                    </RbgTableRow>
                                )}
                            </RbgTableBody>
                        </RbgTable>
                    </RbgGrid>
                    {cancelMutation.error && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{cancelMutation.error}</ErrorCard>
                        </RbgGrid>
                    )}
                    {contract.status.data.staff_member_action === true && (
                        <RbgGrid item xs={12}>
                            <RbgButton
                                fullWidth
                                color="info"
                                onClick={() => {
                                    resendMutation.mutate();
                                }}
                            >
                                Resend Contract
                            </RbgButton>
                        </RbgGrid>
                    )}
                    {contract.status.data.complete === false && (
                        <RbgGrid item xs={12}>
                            <DeleteConfirm
                                btnText="Cancel contract"
                                fullWidth
                                confirmText="Are you sure you want to cancel this contract?"
                                onDelete={() => cancelMutation.mutate()}
                            />
                        </RbgGrid>
                    )}
                    {approveMutation.error && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{approveMutation.error}</ErrorCard>
                        </RbgGrid>
                    )}
                    {contract.status.data.auth === true && user.permissions.indexOf('hr_staff_auth') > -1 && (
                        <>
                            <RbgGrid item xs={12}>
                                <RbgTypography variant="h3">Authorisation Options</RbgTypography>
                            </RbgGrid>
                            <RbgGrid item xs={6}>
                                <RbgButton
                                    color="success"
                                    fullWidth
                                    disabled={approveMutation.isPending}
                                    onClick={() => approveMutation.mutate()}
                                >
                                    <IconCheck />
                                    Authorise
                                </RbgButton>
                            </RbgGrid>
                            <RbgGrid item xs={6} container>
                                <RbgGrid item xs={12}>
                                    <RbgTextInput
                                        label="Decline Reason"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        textSecondary={
                                            <DeleteConfirm onDelete={() => declineMutation.mutate()} confirmText="Sure?" title="Decline" />
                                        }
                                    />
                                </RbgGrid>
                            </RbgGrid>
                        </>
                    )}
                </RbgGrid>
            )
        },
        {
            value: 'history',
            label: 'History',
            content: <ContractHistory contract={contract} />
        }
    ];
    return <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} />;
}

ViewContract.propTypes = {
    contract: PropTypes.shape(staffContract).isRequired
};
export default ViewContract;
