import PropTypes from 'prop-types';
import RbgSelect from '../extended/Form/RbgSelect';
import { IconLoader } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

function RbgRemoteSelect({
    name,
    value,
    endpoint,
    useCached,
    handleChange,
    handleObjectChange,
    filterData,
    label,
    error,
    endpointParameters,
    disableClearable,
    useAutoComplete = false,
    disableBlankOption,
    primaryIcon,
    help,
    size = 'small',
    warning,
    sx,
    autoSelectSingleOption = false,
    multiple = false,
    groupBy,
    ...others
}) {
    const {
        isPending,
        error: queryErr,
        data: options,
        isFetching: loading
    } = useQuery({
        queryKey: [{ method: 'GET', url: endpoint, params: endpointParameters }],
        select: (data) => {
            const returnData = data;
            if (filterData) {
                return filterData(returnData);
            }
            if (data.data) {
                return data.data.map((row) => ({
                    value: row.id,
                    label: row.name,
                    object: row
                }));
            }
            return returnData;
        }
    });

    return (
        <RbgSelect
            name={name}
            value={value}
            useAutoComplete={useAutoComplete}
            disableClearable={disableClearable}
            primaryIcon={primaryIcon}
            help={help}
            size={size}
            warning={warning}
            groupBy={groupBy}
            multiple={multiple}
            values={options}
            label={label}
            secondaryIcon={isPending ? <IconLoader /> : null}
            handleChange={handleChange}
            handleObjectChange={handleObjectChange}
            {...others}
        />
    );
}

RbgRemoteSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    endpoint: PropTypes.string.isRequired,
    useCached: PropTypes.bool,
    handleChange: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleObjectChange: PropTypes.func,
    filterData: PropTypes.func,
    label: PropTypes.string,
    endpointParameters: PropTypes.object,
    disableClearable: PropTypes.bool,
    useAutoComplete: PropTypes.bool,
    disableBlankOption: PropTypes.bool,
    autoSelectSingleOption: PropTypes.bool,
    groupBy: PropTypes.func,
    primaryIcon: PropTypes.node,
    multiple: PropTypes.bool,
    help: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium']),
    warning: PropTypes.string,
    sx: PropTypes.object
};

export default RbgRemoteSelect;
