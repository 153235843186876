import PropTypes from 'prop-types';
import { staffNotification } from '../../StaffDataTypes';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import { useQuery } from '@tanstack/react-query';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import ServerSidePagination from '../../../../../ui_component/pagination/ServerSidePagination';

function NotificationHistory({ notification }) {
    const [filter, setFilter] = useState({
        page: 1,
        search: ''
    });

    const { data, error } = useQuery({
        queryKey: [{ url: `/staff/notification/${notification.id}/history`, params: filter }]
    });

    return (
        <RbgGrid container spacing={gridSpacing}>
            {error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Date', 'Entry']}
                    keys={data.data.map((row) => row.id)}
                    rows={data.data.map((row) => [dayjs(row.date).format('DD/MM/YYYY HH:mm'), row.entry])}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <ServerSidePagination changePage={(page) => setFilter({ ...filter, page })} pagination={data.meta.pagination} />
            </RbgGrid>
        </RbgGrid>
    );
}

NotificationHistory.propTypes = {
    notification: PropTypes.shape(staffNotification)
};
export default NotificationHistory;
