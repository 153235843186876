import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';

function ViewExternalIdentifierDetail({ typeId, staffId }) {
    const { data, isPending, isLoading, isFetched } = useQuery({
        queryKey: [{ url: `/staff/staff-member/${staffId}/external-identifier/${typeId}` }]
    });
    if (isPending || isLoading || !isFetched) {
        return <CardLoader />;
    }
    const headers = ['Field', 'Value'];
    let rows = [];
    if (data.data.length > 0) {
        rows = Object.keys(data.data[0]).map((k) => {
            return [k, data.data[0][k]];
        });
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable headers={headers} rows={rows} />
            </RbgGrid>
        </RbgGrid>
    );
}

ViewExternalIdentifierDetail.propTypes = {
    typeId: PropTypes.number.isRequired,
    staffId: PropTypes.number.isRequired
};
export default ViewExternalIdentifierDetail;
