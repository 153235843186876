import { useQuery } from '@tanstack/react-query';
import MainCard from '../../../../ui_component/cards/MainCard';
import UtilityChart from '../../../../ui_component/charts/UtilityChart';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../store/constant';
import { useState } from 'react';
import RbgRemoteSelect from '../../../../ui_component/remote-select/RbgRemoteSelect';

function DiReporting() {
    const [filter, setFilter] = useState({
        area_manager: '',
        location: ''
    });
    const { data, isFetching } = useQuery({
        queryKey: [{ url: '/staff/di/report', params: filter }]
    });
    return (
        <MainCard title="Diversity & Inclusion Reporting">
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <RbgGrid container spacing={gridSpacing}>
                        <RbgGrid item xs={12} md={6} lg={4}>
                            <RbgRemoteSelect
                                endpoint="/staff/locations/area-managers"
                                name="area_manager"
                                value={filter.area_manager}
                                label="Area Manager"
                                handleChange={(e) => setFilter((prev) => ({ ...prev, area_manager: e.target.value }))}
                                filterData={(data) => data.data}
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={4}>
                            <RbgRemoteSelect
                                useAutoComplete
                                endpoint="/staff/locations"
                                name="location"
                                placeholder="Location"
                                label="Location"
                                handleChange={(e) => setFilter((prev) => ({ ...prev, location: e.target.value }))}
                                value={filter.location}
                                disableClearable={false}
                                filterData={(data) =>
                                    data.data
                                        // .filter((location) => location.type.data.central === false)
                                        .map((location) => ({
                                            id: location.cost_centre,
                                            label: location.name,
                                            value: location.cost_centre,
                                            object: location
                                        }))
                                }
                            />
                        </RbgGrid>
                    </RbgGrid>
                </RbgGrid>
                {data.data.map((chart, index) => {
                    return (
                        <RbgGrid item xs={6} key={index}>
                            <UtilityChart key={index} config={chart} exportChart height="400px" />
                        </RbgGrid>
                    );
                })}
            </RbgGrid>
        </MainCard>
    );
}

export default DiReporting;
