import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import PropTypes from 'prop-types';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import FileDownload from '../../../../../ui_component/download-file/FileDownload';
import IntroCardSecondary from '../../../../../ui_component/cards/IntroCardSecondary';

function ViewRightToWorkInformation({ rightToWorkInfo }) {
    return (
        <RbgGrid container spacing={gridSpacing}>
            {rightToWorkInfo.status.data.id === 5 && (
                <RbgGrid item xs={12}>
                    <WarningCard>
                        This right to work information was imported from a legacy system. Not all information (i.e. history) may be
                        available
                    </WarningCard>
                </RbgGrid>
            )}
            {rightToWorkInfo.authorizer_notes && (
                <RbgGrid item xs={12}>
                    <WarningCard>{rightToWorkInfo.authorizer_notes}</WarningCard>
                </RbgGrid>
            )}
            {rightToWorkInfo.share_code && (
                <RbgGrid item xs={12}>
                    <IntroCardSecondary>A share code was provided. This share code was {rightToWorkInfo.share_code}</IntroCardSecondary>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <IntroCardSecondary>
                    This staff member is from <strong>{rightToWorkInfo.country_name}</strong>
                </IntroCardSecondary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Document Type', 'Approved', 'File']}
                    keys={rightToWorkInfo.documents.data.map((doc) => doc.id)}
                    rows={rightToWorkInfo.documents.data.map((doc) => {
                        return [
                            doc.type.data.name,
                            <BooleanIcon value={doc.approved} />,
                            <FileDownload file={doc.file.data} showFilename />
                        ];
                    })}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

ViewRightToWorkInformation.propTypes = {
    rightToWorkInfo: PropTypes.object.isRequired
};
export default ViewRightToWorkInformation;
