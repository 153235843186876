import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// material ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
// mui wrappers
import RbgDivider from 'ui_component/mui-wrappers/Divider/RbgDivider';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import RbgTableContainer from 'ui_component/mui-wrappers/TableContainer/RbgTableContainer';
import RbgTableCell from 'ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTable from 'ui_component/mui-wrappers/Table/RbgTable';
import RbgTableBody from 'ui_component/mui-wrappers/TableBody/RbgTableBody';
import RbgTableHead from 'ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from 'ui_component/mui-wrappers/TableRow/RbgTableRow';
// third party imports
import { IconCircleArrowUp, IconCircleArrowDown } from '@tabler/icons-react';
// project imports
import Api from 'utils/api';
import CurrencyFormatter from 'utils/currencyFormatter';
import CircularProgressButton from 'ui_component/buttons/CircularProgressButton';
import MainCard from 'ui_component/cards/MainCard';
import RbgTooltip from 'ui_component/tooltip/RbgTooltip';
import RbgPopper from 'ui_component/popper/RbgPopper';
import DateRangeSelect from 'ui_component/date-pickers/DateRangeSelect';

function Targets() {
    const theme = useTheme();
    const matchDown980 = useMediaQuery(theme.breakpoints.down(980));
    const matchDown450 = useMediaQuery(theme.breakpoints.down(450));
    // redux
    const { bar } = useSelector((state) => state);
    // state
    const [current, setCurrent] = useState({
        actual: 0,
        period: 0,
        target: 0,
        target_type: { data: { id: 1, name: 'Overall PBR' } },
        year: 0
    });
    const [targets, setTargets] = useState([]);
    const [period, setPeriod] = useState({ period: null, year: null });
    // local state
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const onPeriodChange = (value) => {
        setPeriod({ period: value.period, year: value.year });
    };

    useEffect(() => {
        Api.getCached('rest/periods').then((response) => {
            if (response.ok) {
                response.data.forEach((t) => {
                    if (t.current === true) {
                        setPeriod({ period: t.period, year: t.financial_year });
                    }
                });
            }
        });
    }, []);

    useEffect(() => {
        const updateTargets = () => {
            if (period.period !== null) {
                Api.get('rest/targets', {
                    bars: bar.barId,
                    financial_year: period.year,
                    period: period.period,
                    include_actuals: 1
                }).then((response) => {
                    if (response.ok) {
                        setTargets(response.data.data);
                        const current = response.data.data.find((t) => t.target_type.data.id === 1);
                        setCurrent(
                            current || {
                                actual: 0,
                                period: 0,
                                target: 0,
                                target_type: { data: { id: 1, name: 'Overall PBR' } },
                                year: 0
                            }
                        );
                    }
                });
            }
        };

        updateTargets();
    }, [period, bar.barId]);

    return (
        <>
            {matchDown980 ? (
                <Box
                    ref={anchorRef}
                    sx={{
                        position: 'relative',
                        display: 'inline-flex'
                    }}
                >
                    <CircularProgressButton value={70} handleToggle={handleToggle} ref={anchorRef} />
                </Box>
            ) : (
                <MainCard ref={anchorRef} sx={{ py: 0.5, px: 2 }} border content={false}>
                    <RbgStack direction="row" alignContent="center" spacing={1}>
                        <RbgStack>
                            <RbgStack direction="row" justifyContent="center">
                                {current.actual < current.target ? (
                                    <IconCircleArrowDown size={18} color="red" />
                                ) : (
                                    <IconCircleArrowUp size={18} color="green" />
                                )}
                                <RbgTypography sx={{ pl: 1, fontWeight: 'bold' }} variant="h3" textAlign="center">
                                    <CurrencyFormatter value={current.actual} minDigits={0} />
                                </RbgTypography>
                            </RbgStack>
                            <RbgDivider />
                            <RbgStack direction="row" spacing={1}>
                                <RbgTypography variant="subtitle2">{`P${current.period} - FY${current.year}`}</RbgTypography>
                                <RbgDivider orientation="vertical" flexItem />
                                <RbgTypography variant="subtitle2">
                                    Target: <CurrencyFormatter value={current.target} minDigits={0} />
                                </RbgTypography>
                            </RbgStack>
                        </RbgStack>
                        <RbgDivider orientation="vertical" flexItem />
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-flex',
                                [theme.breakpoints.down(400)]: {
                                    display: 'none'
                                }
                            }}
                        >
                            <RbgTooltip title="Target Achieved" position="bottom">
                                <CircularProgressButton
                                    value={
                                        current.target === 0 || current.actual === 0
                                            ? 0
                                            : Math.round((current.actual / current.target) * 100)
                                    }
                                    handleToggle={handleToggle}
                                    ref={anchorRef}
                                />
                            </RbgTooltip>
                        </Box>
                    </RbgStack>
                </MainCard>
            )}

            <RbgPopper
                ref={anchorRef}
                open={open}
                setOpen={setOpen}
                popperPlacement="bottom"
                transitionPosition="top"
                disablePortal
                popperStyles={{ width: matchDown450 ? '100%' : '460px', zIndex: 2 }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 18]
                            }
                        }
                    ]
                }}
            >
                <MainCard
                    sx={{
                        p: 2
                    }}
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[8]}
                >
                    <DateRangeSelect defaultType="period" size="small" showPrepend={false} onChange={onPeriodChange} />
                    <RbgDivider sx={{ my: 2 }} />
                    <RbgTableContainer>
                        <RbgTable sx={{ width: '425px' }} size="small" aria-label="data table">
                            <RbgTableHead>
                                <RbgTableRow>
                                    <RbgTableCell align="center">Name</RbgTableCell>
                                    <RbgTableCell align="center">Target</RbgTableCell>
                                    <RbgTableCell align="center">Actual</RbgTableCell>
                                    <RbgTableCell align="center">%</RbgTableCell>
                                </RbgTableRow>
                            </RbgTableHead>
                            <RbgTableBody>
                                {targets.map((row, idx) => (
                                    <RbgTableRow key={idx}>
                                        <RbgTableCell align="center">{row.target_type.data.name}</RbgTableCell>
                                        <RbgTableCell align="center">
                                            <CurrencyFormatter value={row.target} />
                                        </RbgTableCell>
                                        <RbgTableCell align="center">
                                            <CurrencyFormatter value={row.actual} />
                                        </RbgTableCell>
                                        <RbgTableCell align="center">
                                            {row.actual === 0 ? 0 : Math.round((current.actual / current.target) * 100)}%
                                        </RbgTableCell>
                                    </RbgTableRow>
                                ))}
                            </RbgTableBody>
                        </RbgTable>
                    </RbgTableContainer>
                </MainCard>
            </RbgPopper>
        </>
    );
}

export default Targets;
