import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ViewExternalIdentifierDetail from './ViewExternalIdentifierDetail';
import UpdateExternalIdentifier from './UpdateExternalIdentifier';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function ExternalIdentifiers({ staffMember }) {
    const { user } = useContext(RcloudAuthContext);

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>Details of any external systems this user is added to will be displayed here</InfoCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['System', 'Identifier', 'Error']}
                    keys={staffMember.external_identifiers.data.map((row) => `${row.type.data.id}-${row.identifier}`)}
                    rows={staffMember.external_identifiers.data.map((row) => {
                        const showDetail = row.type.data.has_detail_query && row.identifier && user.permissions.indexOf('hr_config') > -1;
                        // const edit = user.permissions.indexOf('hr_config') && !row.identifier;
                        const edit = true;
                        return [
                            row.type.data.name,
                            <RbgStack>
                                <RbgTypography>{row.identifier}</RbgTypography>
                                <RbgStack direction="row" alignItems="center" justifyContent="center" gap={2}>
                                    {edit && (
                                        <RbgButton
                                            onClick={() => {
                                                DialogHelper.openSmDialog(
                                                    'Edit ',
                                                    <UpdateExternalIdentifier staffMember={staffMember} type={row.type.data} />
                                                );
                                            }}
                                        >
                                            Edit
                                        </RbgButton>
                                    )}
                                    {showDetail && (
                                        <RbgButton
                                            size="xs"
                                            onClick={() => {
                                                DialogHelper.openMdDialog(
                                                    `View ${row.type.data.name} detail`,
                                                    <ViewExternalIdentifierDetail typeId={row.type.data.id} staffId={staffMember.id} />
                                                );
                                            }}
                                        >
                                            View Detail
                                        </RbgButton>
                                    )}
                                </RbgStack>
                            </RbgStack>,
                            row.error ? row.error : 'N/A'
                        ];
                    })}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

ExternalIdentifiers.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default ExternalIdentifiers;
