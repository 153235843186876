import PropTypes from 'prop-types';
// mui imports
import { TableCell } from '@mui/material';

/**
 * The component renders a `<th>` element when the parent context is a header
 * or otherwise a `<td>` element.
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TableCell API](https://mui.com/material-ui/api/table-cell/)
 * - Inherits [Typography API](https://mui.com/material-ui/api/typography/) when "typography" prop is true
 *   @default false
 */

const tableCellStyles = {
    border: '1px solid #E0E0E0'
};

function RbgTableCell({ sx, children, ...others }) {
    return (
        <TableCell align="center" sx={{ ...tableCellStyles, ...sx }} {...others}>
            {children}
        </TableCell>
    );
}

export default RbgTableCell;

RbgTableCell.propTypes = {
    sx: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.array, PropTypes.element])
};
