import { useQuery } from '@tanstack/react-query';
import MainCard from '../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import RbgTextInput from '../../../../ui_component/extended/Form/RbgTextInput';
import { useDebounce } from '@uidotdev/usehooks';
import PageableTable from '../../../../ui_component/pageable-table/PageableTable';
import InfoCard from '../../../../ui_component/error/InfoCard';
import { DialogHelper } from '../../../../ui_component/modals/RbgDialog';
import { useSelector } from 'react-redux';
import ViewPayslip from './ViewPayslip';
import RbgSelect from '../../../../ui_component/extended/Form/RbgSelect';
import ErrorCard from '../../../../ui_component/error/ErrorCard';

function DraftPayslips() {
    const { bar } = useSelector((state) => state.bar);

    const [filter, setFilter] = useState({
        page: 1,
        pay_type: 1,
        location: bar.cost_centre,
        search: ''
    });
    const useFilter = useDebounce(filter, 500);
    const { data, isFetched, error } = useQuery({
        queryKey: [{ url: '/staff/payslips/draft', params: useFilter }]
    });
    useEffect(() => {
        setFilter((prev) => ({ ...prev, location: bar.cost_centre }));
    }, [bar]);
    return (
        <MainCard title="Draft Payslips">
            <RbgGrid container>
                {isFetched === true && data.data.length === 0 && (
                    <RbgGrid item xs={12}>
                        <InfoCard>No draft payslips available</InfoCard>
                    </RbgGrid>
                )}
                {error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={6}>
                    <RbgTextInput
                        name="search"
                        value={filter.search}
                        onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
                        label="Search"
                    />
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgSelect
                        name="pay_type"
                        values={[
                            { value: 1, label: '2 Weekly Hourly' },
                            { value: 2, label: '4 Weekly Salary' }
                        ]}
                        value={filter.pay_type}
                        handleChange={(e) => setFilter((prev) => ({ ...prev, pay_type: e.target.value }))}
                        label="Type"
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <PageableTable
                        pagination={data.meta.pagination}
                        data={data.data}
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                        headers={['Type', 'Payroll Ref', 'Dates', 'Weeks', 'Department', 'Forename', 'Surname', 'Position', '']}
                        rowDecorator={(row) => [
                            row.period.data.type.data.name,
                            row.payroll_ref,
                            <RbgStack direction="row" gap={0}>
                                <RbgTypography>{dayjs(row.period.data.start_date).format('Do MMM')}</RbgTypography>
                                <RbgTypography>-</RbgTypography>
                                <RbgTypography>{dayjs(row.period.data.end_date).format('Do MMM')}</RbgTypography>
                            </RbgStack>,
                            row.period.data.weeks.join(', '),
                            row.department,
                            row.forename,
                            row.surname,
                            row.position,
                            <RbgButton
                                onClick={() => {
                                    DialogHelper.openLgDialog('View Payslip Data', <ViewPayslip id={row.id} />);
                                }}
                            >
                                View
                            </RbgButton>
                        ]}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default DraftPayslips;
