import PropTypes from 'prop-types';
import { staffDetails } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgTextArea from '../../../../../ui_component/text-area/RbgTextArea';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import StaffFileUpload from '../../Component/StaffFileUpload';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function EditSuspension({ staffDetails, suspension }) {
    const [newSuspension, setNewSuspension] = useState(
        suspension || {
            id: null,
            staff_id: staffDetails.staff_id,
            active: true,
            reason: { data: {} },
            type: { data: {} },
            files: { data: [] },
            date_added: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            notes: ''
        }
    );

    const [files, setFiles] = useState(suspension ? suspension.files.data : []);

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation(
                suspension ? 'PATCH' : 'POST',
                suspension ? `/staff/suspension/${suspension.id}` : '/staff/suspension',
                newSuspension
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['staff_suspensions'] });
            DialogHelper.closeDialog();
        }
    });
    const handleFileUpload = (file) => {
        setFiles((prev) => [...prev, ...[file]]);
    };

    useEffect(() => {
        setNewSuspension((prev) => ({ ...prev, files: { data: files } }));
    }, [files]);

    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save');
    }, [mutation]);

    useEffect(() => {
        setNewSuspension((prev) => ({ ...prev, type_id: newSuspension.type.data.id }));
    }, [newSuspension.type.data]);

    useEffect(() => {
        setNewSuspension((prev) => ({ ...prev, reason_id: newSuspension.reason.data.id }));
    }, [newSuspension.reason.data]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                mutation.mutate();
            }}
        >
            <RbgGrid container spacing={gridSpacing}>
                {mutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{mutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint="/staff/suspensions/types"
                        name="type"
                        label="Suspension Type"
                        value={newSuspension.type.data.id}
                        handleObjectChange={(e) =>
                            setNewSuspension((prev) => ({
                                ...prev,
                                type: { data: e.target.object ? e.target.object : {} }
                            }))
                        }
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint="/staff/suspensions/reasons"
                        name="reason"
                        label="Suspension Reason"
                        value={newSuspension.reason.data.id}
                        handleObjectChange={(e) =>
                            setNewSuspension((prev) => ({
                                ...prev,
                                reason: { data: e.target.object ? e.target.object : {} }
                            }))
                        }
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgTextArea
                        value={newSuspension.notes}
                        label="Notes"
                        name="notes"
                        onChange={(e) => setNewSuspension((prev) => ({ ...prev, notes: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgCheckbox
                        name="active"
                        label="Active"
                        checked={newSuspension.active}
                        onChange={(e) => setNewSuspension((prev) => ({ ...prev, active: e.target.checked }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={['File', 'Date Created', 'Size']}
                        keys={newSuspension.files.data.map((row) => row.id)}
                        rows={newSuspension.files.data.map((row) => [
                            row.filename,
                            dayjs(row.date_uploaded).format('DD/MM/YYYY HH:mm'),
                            row.size
                        ])}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <StaffFileUpload
                        staffId={staffDetails.staff_id}
                        onFileUploaded={handleFileUpload}
                        bucket="rbg-staff-files"
                        endpoint={`/staff/staff-member/${staffDetails.staff_id}/file`}
                    />
                </RbgGrid>
            </RbgGrid>
        </form>
    );
}

EditSuspension.propTypes = {
    staffDetails: PropTypes.shape(staffDetails).isRequired,
    suspension: PropTypes.object
};
export default EditSuspension;
