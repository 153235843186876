import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Stack } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Stack](https://mui.com/material-ui/react-stack/)
 *
 * API:
 *
 * - [Stack API](https://mui.com/material-ui/api/stack/)
 */

const RbgStack = forwardRef(({ children, ...others }, ref) => (
    <Stack ref={ref} {...others}>
        {children}
    </Stack>
));

export default RbgStack;

RbgStack.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node])
};
