import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import RelativeTime from '../../../../../ui_component/relative-time/RelativeTime';
import { useState } from 'react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';

function StaffAdminEmailTemplateLog({ template }) {
    const [previews, setPreviews] = useState([]);
    const { data, isPending, isFetched, error } = useQuery({
        queryKey: [{ url: `/staff/staff-admin/email-template/${template.id}/send-log`, params: { include: 'html' } }]
    });
    if (!isFetched || isPending) {
        return <CardLoader />;
    }

    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgTable>
                    <RbgTableHead>
                        <RbgTableRow>
                            <RbgTableCell>ID</RbgTableCell>
                            <RbgTableCell>Date</RbgTableCell>
                            <RbgTableCell>Subject</RbgTableCell>
                            <RbgTableCell>To</RbgTableCell>
                            <RbgTableCell>Status</RbgTableCell>
                            <RbgTableCell />
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        {data.data.map((row) => {
                            return (
                                <>
                                    <RbgTableRow key={row.send_id}>
                                        <RbgTableCell>{row.send_id}</RbgTableCell>
                                        <RbgTableCell>
                                            <RelativeTime time={row.date_sent} />
                                        </RbgTableCell>
                                        <RbgTableCell>{row.subject}</RbgTableCell>
                                        <RbgTableCell>{row.display_to}</RbgTableCell>
                                        <RbgTableCell>{row.status}</RbgTableCell>
                                        <RbgTableCell>
                                            <RbgButton
                                                onClick={() => {
                                                    setPreviews((prev) => {
                                                        const index = prev.indexOf(row.send_id);
                                                        if (index === -1) {
                                                            return [...prev, row.send_id];
                                                        }
                                                        return prev.filter((id) => id !== row.send_id);
                                                    });
                                                }}
                                            >
                                                Preview
                                            </RbgButton>
                                        </RbgTableCell>
                                    </RbgTableRow>
                                    {previews.indexOf(row.send_id) > -1 && (
                                        <RbgTableRow key={`${row.send_id}_preview`}>
                                            <RbgTableCell colSpan={6}>
                                                <iframe
                                                    srcDoc={row.html.data[0]}
                                                    style={{ width: '100%', height: '400px', border: 'none' }}
                                                    title="Preview"
                                                />
                                            </RbgTableCell>
                                        </RbgTableRow>
                                    )}
                                </>
                            );
                        })}
                    </RbgTableBody>
                </RbgTable>
            </RbgGrid>
        </RbgGrid>
    );
}

StaffAdminEmailTemplateLog.propTypes = {
    template: PropTypes.object.isRequired
};
export default StaffAdminEmailTemplateLog;
