import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import RbgSelect from '../../../../../../ui_component/extended/Form/RbgSelect';
import { useEffect } from 'react';

function ContractCustomField({ fields, newStarterId, value, handleChange }) {
    const enabled = !!fields.contract_temp_perm;
    const { data, isFetched } = useQuery({
        queryKey: [
            {
                method: 'POST',
                url: `/staff/onboarding/team-member/${newStarterId}/available-contracts`,
                params: { fields: { contract_temp_perm: fields.contract_temp_perm, contracted_hours: fields.contracted_hours } }
            }
        ],
        enabled
    });
    useEffect(() => {
        if (data.data.length === 1) {
            handleChange(data.data[0].id);
        }
    }, [data, handleChange]);
    if (!enabled) {
        return <RbgSelect values={[]} label="Contract" disabled />;
    }
    if (data.data.length === 0 && isFetched) {
        return <ErrorCard>No contract types available</ErrorCard>;
    }
    return (
        <RbgSelect
            name="contract_type"
            label="Contract"
            handleChange={(e) => handleChange(`${e.target.value}`)}
            values={data.data.map((row) => ({ value: row.id, label: row.name }))}
            value={value}
        />
    );
}

ContractCustomField.propTypes = {
    fields: PropTypes.object.isRequired,
    newStarterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired
};
export default ContractCustomField;
