import { useMutation, useQuery } from '@tanstack/react-query';
import InfoCard from '../error/InfoCard';
import RbgStack from '../mui-wrappers/Stack/RbgStack';
import RbgButton from '../mui-wrappers/Button/RbgButton';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';
import Api from '../../utils/api';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorCard from '../error/ErrorCard';

function TwoFactorAuthInitiateMethods({ onAttemptCreate }) {
    const [type, setType] = useState(null);
    const { data, loading, isFetched } = useQuery({
        queryKey: [{ url: '/bouncer/tfa/my-methods', params: { page: 1, include: 'type' } }]
    });
    const methods = data.data.filter((method) => {
        return method.setup_complete === true && method.deleted === false;
    });
    const typeMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `bouncer/tfa-logged-in/initiate/${type.id}`);
        },
        onSuccess: (data) => {
            onAttemptCreate(data.data);
        }
    });

    const chooseType = (type) => {
        setType(type);
        typeMutation.mutate();
    };
    return (
        <div>
            <InfoCard>
                To complete this action you will need to verify that this is actually you. Chose a method from below to complete this
                verification
            </InfoCard>
            <RbgStack gap={2} sx={{ marginTop: '10px' }}>
                {typeMutation.error && <ErrorCard>{typeMutation.error}</ErrorCard>}
                {methods.map((method) => {
                    return (
                        <RbgButton
                            sx={{ width: '100%', height: '100%' }}
                            key={method.id}
                            disabled={typeMutation.isPending}
                            onClick={() => chooseType(method)}
                        >
                            <RbgStack direction="column">
                                <RbgTypography variant="h3">{method.type.data.name}</RbgTypography>
                                <RbgTypography variant="p">{method.type.data.description}</RbgTypography>
                            </RbgStack>
                        </RbgButton>
                    );
                })}
            </RbgStack>
        </div>
    );
}

TwoFactorAuthInitiateMethods.propTypes = {
    onAttemptCreate: PropTypes.func.isRequired
};
export default TwoFactorAuthInitiateMethods;
