import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import { useState } from 'react';
import MainCard from '../../../../ui_component/cards/MainCard';
import TeamApprovals from './CurrentTeamApprovals/TeamApprovals';
import NewStarterApprovals from './NewStarterApprovals/NewStarterApprovals';
import StaticBreadcrumb from '../../../../ui_component/extended/StaticBreadcrumb';
import ContractApprovals from './ContractApprovals/ContractApprovals';
import { useQuery } from '@tanstack/react-query';

function Approvals() {
    const [tab, setTab] = useState(window.location.hash ? window.location.hash.replace('#', '') : 'current_team');
    const { data } = useQuery({
        initialData: {
            data: {
                contracts: 0,
                new_starters: 0,
                role: 0
            }
        },
        queryKey: [{ url: '/staff/staff-admin/auth-counts' }, 'auth_counts']
    });
    const tabs = [
        {
            value: 'current_team',
            label: 'Role/Rate Changes',
            notifications: data.data.role,
            content: <TeamApprovals />
        },
        {
            value: 'new_starters',
            label: 'New Starters',
            notifications: data.data.new_starters,
            content: <NewStarterApprovals />
        },
        {
            value: 'contracts',
            label: 'Contracts',
            notifications: data.data.contracts,
            content: <ContractApprovals />
        }
    ];
    return (
        <>
            <StaticBreadcrumb
                items={[
                    {
                        label: 'Team',
                        link: '/staff'
                    },
                    { label: 'HR Admin' },
                    { label: 'Team Member Approvals' }
                ]}
            />
            <MainCard>
                <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} />
            </MainCard>
            ]
        </>
    );
}

export default Approvals;
