const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only
    // '/' use blank('') instead, like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/rcloud',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    outlinedFilled: false,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    pusherKey: 'e9dd067ab87c8f32afaf',
    pusherHost: 'ws.revbars.cloud',
    api: {
        url: '/api',
        timeout: 60000
    },
    defaultBar: '163'
};

export default config;
