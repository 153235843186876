import _ from 'lodash';

const EventDispatcher = {
    events: [],
    subscribe(event, callback) {
        const id = Math.floor(Math.random() * 10000000);
        this.events.push({
            id,
            event,
            callback
        });
        return id;
    },
    unsubscribe(id) {
        const index = this.getIndexForId(id);
        if (index > -1) {
            this.events.splice(index, 1);
        }
    },
    getIndexForId(id) {
        for (let x = 0; x < this.events.length; x += 1) {
            if (this.events[x].id === id) {
                return x;
            }
        }
        return null;
    },
    dispatch(eventName, data, completeCallback) {
        const events = _.filter(this.events, (event) => event.event === eventName);

        for (let x = 0; x < events.length; x += 1) {
            events[x].callback(data);
        }

        if (completeCallback) {
            completeCallback();
        }
    }
};

export default EventDispatcher;
