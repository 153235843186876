import PropTypes from 'prop-types';
import { icons } from './Icons';
import { Icon } from '@mui/material';
import { IconError404 } from '@tabler/icons-react';

function RbgIcon({ name, color, size }) {
    const iconData = icons.find((icon) => icon.value === name);
    if (!iconData) {
        return <IconError404 color={color} size={size} />;
    }
    const Icon = iconData.icon;
    return Icon && <Icon color={color} fontSize={size} className={iconData.className} />;
}

RbgIcon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number
};

export default RbgIcon;
