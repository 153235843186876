import PropTypes from 'prop-types';

// material-ui
import { Divider, InputAdornment, TextField, useTheme } from '@mui/material';
import MUIFormControl from '@mui/material/FormControl';
import RbgTooltip from '../../tooltip/RbgTooltip';
import { IconInfoCircle } from '@tabler/icons-react';
import { forwardRef } from 'react';

// ==============================|| FORM CONTROL ||============================== //

const RbgTextInput = forwardRef(
    (
        {
            label,
            name,
            iconPrimary,
            iconSecondary,
            placeholder,
            textPrimary,
            textSecondary,
            size = 'small',
            value,
            onChange,
            error,
            onBlur,
            onKeyDown,
            required,
            disabled,
            type = 'text',
            sx,
            formStyles,
            help,
            warning,
            min,
            max,
            inputProps,
            autoComplete = 'off',
            ...delegated
        },
        ref
    ) => {
        const theme = useTheme();

        const pwdManagersOverrides =
            autoComplete === 'off'
                ? {
                      'data-1p-ignore': true, // 1Password
                      'data-form-type': 'other', // Dashlane
                      'data-lpignore': 'true', // LastPass
                      'data-bwignore': true // Bitwarden
                  }
                : {};

        const inputStyles =
            warning && !error
                ? {
                      '.MuiFormLabel-root': { color: `${theme.palette.warning.dark} !important` },
                      '.MuiFormHelperText-root': warning && !error ? { color: theme.palette.warning.dark } : {},
                      fieldset:
                          warning && !error
                              ? {
                                    borderColor: theme.palette.warning.dark,
                                    outlineColor: theme.palette.warning.dark
                                }
                              : {},
                      ...sx
                  }
                : sx;

        return (
            <MUIFormControl sx={{ '.MuiFormLabel-asterisk': { color: 'red' }, ...formStyles }} fullWidth>
                <TextField
                    sx={inputStyles}
                    error={!!error}
                    placeholder={placeholder}
                    value={value ?? ''}
                    name={name}
                    helperText={error || warning || null}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    required={required}
                    disabled={disabled}
                    variant="outlined"
                    size={size}
                    label={label}
                    type={type}
                    inputRef={ref}
                    InputProps={{
                        inputProps: { min, max, ...pwdManagersOverrides, ...inputProps, ...delegated },
                        autoComplete,
                        startAdornment: help ? (
                            <InputAdornment position="start">
                                <RbgTooltip title={help}>
                                    <IconInfoCircle color={theme.palette.info.main} />
                                </RbgTooltip>
                            </InputAdornment>
                        ) : (
                            (textPrimary || iconPrimary) && (
                                <>
                                    {iconPrimary && <InputAdornment position="start">{iconPrimary}</InputAdornment>}
                                    {textPrimary && (
                                        <>
                                            <InputAdornment position="start">{textPrimary}</InputAdornment>
                                            <Divider sx={{ height: 28, m: 0.5, mr: 1.5 }} orientation="vertical" />
                                        </>
                                    )}
                                </>
                            )
                        ),
                        endAdornment:
                            textSecondary || iconSecondary ? (
                                <>
                                    {iconSecondary && <InputAdornment position="end">{iconSecondary}</InputAdornment>}
                                    {textSecondary && (
                                        <>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <InputAdornment position="end">{textSecondary}</InputAdornment>
                                        </>
                                    )}
                                </>
                            ) : null
                    }}
                />
            </MUIFormControl>
        );
    }
);

RbgTextInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    iconPrimary: PropTypes.node,
    iconSecondary: PropTypes.node,
    placeholder: PropTypes.string,
    textPrimary: PropTypes.node,
    textSecondary: PropTypes.node,
    size: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    sx: PropTypes.object,
    formStyles: PropTypes.object,
    help: PropTypes.string,
    onKeyDown: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    warning: PropTypes.string,
    inputProps: PropTypes.object,
    autoComplete: PropTypes.oneOf(['off', 'on'])
};

export default RbgTextInput;
