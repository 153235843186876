import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconEdit } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import HrConfigEditLocation from './HrConfigEditLocation';

function HrConfigLocations() {
    const { data, isFetched, error } = useQuery({
        queryKey: [{ url: '/staff/locations', params: { include: 'staff_config' } }]
    });
    return (
        <MainCard title="Locations">
            <RbgGrid container>
                {error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        stickyHeader
                        tableContainerSx={{ maxHeight: '800px' }}
                        headers={['Type', 'Cost Centre', 'Name', 'Brand', 'Payroll Ref Prefix', 'Initial Training Config', '']}
                        keys={data.data.map((row) => row.id)}
                        rows={data.data.map((row) => {
                            return [
                                row.type.data.name,
                                row.cost_centre,
                                row.name,
                                row.brand.data.name,
                                row.staff_config.data.payroll_ref_prefix,
                                <>
                                    {row.staff_config.data.location && row.staff_config.data.initial_training_config.data
                                        ? row.staff_config?.data?.initial_training_config.data?.name
                                        : 'N/A'}{' '}
                                </>,
                                <RbgButton
                                    variant="contained"
                                    icon={<IconEdit />}
                                    onClick={() => {
                                        DialogHelper.openMdDialog('Edit Location', <HrConfigEditLocation location={row} />);
                                    }}
                                />
                            ];
                        })}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default HrConfigLocations;
