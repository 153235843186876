import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { ListItemText } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Lists](https://mui.com/material-ui/react-list/)
 *
 * API:
 *
 * - [ListItemText API](https://mui.com/material-ui/api/list-item-text/)
 */

const RbgListItemText = forwardRef(({ children, ...others }, ref) => (
    <ListItemText ref={ref} {...others}>
        {children}
    </ListItemText>
));

export default RbgListItemText;

RbgListItemText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
