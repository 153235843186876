import { useContext } from 'react';

// auth provider
import RcloudAuthContext from 'contexts/RcloudAuthContext';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
    const context = useContext(RcloudAuthContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useAuth;
