import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { TableContainer } from '@mui/material';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import PropTypes from 'prop-types';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import { useState } from 'react';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function OperaImportErrors({ errors, staffId = null }) {
    const [selected, setSelected] = useState([]);
    const saveMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', '/staff/admin/opera/import-items/bulk', {
                items: selected,
                status: id
            });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <TableContainer>
                    <RbgTable>
                        <RbgTableHead>
                            <RbgTableRow>
                                <RbgTableCell>
                                    <input
                                        type="checkbox"
                                        checked={selected.length === errors.length}
                                        onChange={(e) =>
                                            e.target.checked === true ? setSelected(errors.map((e) => e.id)) : setSelected([])
                                        }
                                    />
                                </RbgTableCell>
                                <RbgTableCell>Field</RbgTableCell>
                                <RbgTableCell>Status</RbgTableCell>
                                <RbgTableCell>Opera Value</RbgTableCell>
                                <RbgTableCell>R-People Value</RbgTableCell>
                            </RbgTableRow>
                        </RbgTableHead>
                        <RbgTableBody>
                            {errors.map((error) => (
                                <RbgTableRow key={error.id}>
                                    <RbgTableCell>
                                        <input
                                            type="checkbox"
                                            checked={selected.indexOf(error.id) > -1}
                                            onChange={() =>
                                                setSelected((prev) =>
                                                    prev.indexOf(error.id) > -1 ? prev.filter((id) => id !== error.id) : [...prev, error.id]
                                                )
                                            }
                                        />
                                    </RbgTableCell>
                                    <RbgTableCell>{error.field}</RbgTableCell>
                                    <RbgTableCell>{error.status.data.name}</RbgTableCell>
                                    <RbgTableCell>{error.opera_value}</RbgTableCell>
                                    <RbgTableCell>{error.rpeople_value}</RbgTableCell>
                                </RbgTableRow>
                            ))}
                        </RbgTableBody>
                    </RbgTable>
                </TableContainer>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgStack gap={2} direction="row">
                    <RbgButton
                        fullWidth
                        color="success"
                        disabled={selected.length === 0 || saveMutation.isPending}
                        onClick={() => saveMutation.mutate(3)}
                    >
                        Fix R-People
                    </RbgButton>
                    <RbgButton
                        fullWidth
                        color="warning"
                        disabled={selected.length === 0 || saveMutation.isPending}
                        onClick={() => saveMutation.mutate(2)}
                    >
                        Mark as ignored
                    </RbgButton>
                    <RbgButton
                        fullWidth
                        color="primary"
                        disabled={selected.length === 0 || saveMutation.isPending}
                        onClick={() => saveMutation.mutate(4)}
                    >
                        Mark as opera fixed
                    </RbgButton>
                </RbgStack>
            </RbgGrid>
        </RbgGrid>
    );
}

OperaImportErrors.propTypes = {
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            error: PropTypes.string,
            field: PropTypes.string,
            rpeople_value: PropTypes.any,
            opera_value: PropTypes.any
        })
    ).isRequired,
    staffId: PropTypes.number
};

export default OperaImportErrors;
