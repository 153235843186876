import PropTypes from 'prop-types';
import { onboardFieldset } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import Debug from '../../../../../ui_component/misc/Debug';
import EditOnboardFieldsetField from './EditOnboardFieldsetField';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import RbgDraggableList from '../../../../../ui_component/dragable-list/RbgDraggableList';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import RbgDivider from '../../../../../ui_component/mui-wrappers/Divider/RbgDivider';
import _ from 'lodash';

function EditOnboardFieldset({ fieldset }) {
    const [newFieldset, setNewFieldSet] = useState(
        fieldset ?? {
            id: null,
            name: '',
            fields: {
                data: []
            }
        }
    );

    const { data: fields } = useQuery({
        queryKey: [{ url: '/staff/admin/onboard-fields', params: { page: 1 } }]
    });

    const handleFieldChange = (newField) => {
        const fields = [...newFieldset.fields.data].map((field) => {
            if (field.id === newField.id) {
                return newField;
            }
            return field;
        });
        setNewFieldSet((prev) => ({ ...prev, fields: { data: fields } }));
    };

    const handleFieldRemove = (deleteField) => {
        const fields = [...newFieldset.fields.data]
            .filter((field) => {
                if (field.id === deleteField.id) {
                    return false;
                }
                return true;
            })
            .map((field, index) => {
                field.display_order = index;
                return field;
            });
        setNewFieldSet((prev) => ({ ...prev, fields: { data: fields } }));
    };

    const queryClient = useQueryClient();
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/admin/onboard-fieldset', newFieldset);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('onboard_fieldsets') > -1;
                }
            });
        }
    });

    useEffect(() => {
        DialogHelper.addSaveButton(() => saveMutation.mutate(), 'Save');
    }, [saveMutation]);

    const fieldDecorator = (field) => {
        return <EditOnboardFieldsetField field={field} handleFieldChange={handleFieldChange} handleFieldDelete={handleFieldRemove} />;
    };

    const handleFieldAdd = (fieldType) => {
        let maxDisplayOrder = 0;
        newFieldset.fields.data.forEach((field) => {
            if (field.display_order > maxDisplayOrder) {
                maxDisplayOrder = field.display_order;
            }
        });
        const newField = {
            id: _.uniqueId('new'),
            dependent_field: null,
            dependent_fields: null,
            display_order: maxDisplayOrder,
            field: {
                data: fieldType
            },
            field_id: fieldType.id,
            field_name: fieldType.name,
            label: '',
            required: false,
            type: fieldType.type
        };
        setNewFieldSet((prev) => ({ ...prev, fields: { data: [...newFieldset.fields.data, newField] } }));
    };

    return (
        <RbgGrid container gap={1}>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput name="name" value={newFieldset.name} onChange={handleFieldChange} label="Fieldset Name" />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDraggableList
                    onChange={(items) => {
                        items = items.map((item, index) => {
                            item.display_order = index;
                            return item;
                        });
                        setNewFieldSet((prev) => ({ ...prev, fields: { data: items } }));
                    }}
                    items={newFieldset.fields.data}
                    itemDecorator={fieldDecorator}
                />
            </RbgGrid>
            <RbgGrid xs={12}>
                <RbgDivider />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSelect
                    values={fields.data.map((field) => ({ value: field.id, label: field.name, object: field }))}
                    name="field_id"
                    useAutoComplete
                    label="Add New Field"
                    handleObjectChange={(e) => {
                        handleFieldAdd(e.target.object);
                    }}
                />
            </RbgGrid>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

EditOnboardFieldset.propTypes = {
    fieldset: PropTypes.shape(onboardFieldset)
};
export default EditOnboardFieldset;
