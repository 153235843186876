import PropTypes from 'prop-types';

// material-ui
import { Autocomplete, Divider, FormControl, InputAdornment, MenuItem, TextField, useTheme } from '@mui/material';
import RbgTooltip from '../../tooltip/RbgTooltip';
import { IconInfoCircle } from '@tabler/icons-react';
import { forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';

// ==============================|| FORM CONTROL SELECT ||============================== //

const RbgSelect = forwardRef(
    (
        {
            label,
            values,
            name,
            value = '',
            handleChange,
            error,
            primaryIcon,
            secondaryIcon,
            textPrimary,
            textSecondary,
            formStyles,
            textFieldStyles,
            size = 'small',
            disableBlankOption = false,
            blankOptionText,
            disabled,
            required,
            help,
            warning,
            groupBy,
            multiple = false,
            useAutoComplete = false,
            handleObjectChange = null,
            disableClearable = false,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        const [selectedValue, setSelectedValue] = useState(value || (multiple ? [] : ''));
        const [inputText, setInputText] = useState('');
        const inputStyles =
            warning && !error
                ? {
                      '.MuiFormLabel-root': { color: `${theme.palette.warning.dark} !important` },
                      '.MuiFormHelperText-root': warning && !error ? { color: theme.palette.warning.dark } : {},
                      fieldset:
                          warning && !error ? { borderColor: theme.palette.warning.dark, outlineColor: theme.palette.warning.dark } : {},
                      ...textFieldStyles
                  }
                : textFieldStyles;
        const processChange = (value) => {
            if (handleChange) {
                handleChange({
                    target: {
                        name,
                        value
                    }
                });
            }
            if (handleObjectChange) {
                const object = values.find((opt) => {
                    return opt.value === value;
                });
                if (object) {
                    handleObjectChange({
                        target: {
                            name,
                            value,
                            object: object.object
                        }
                    });
                }
            }
        };
        if (multiple === true && typeof value === 'string') {
            console.error('Using a multiple select with a string value instead of array');
        }

        useEffect(() => {
            if (values.length > 0) {
                if (!multiple) {
                    setSelectedValue(values.find((v) => v.value === value));
                }
                if (multiple) {
                    setSelectedValue(
                        values.filter((v) => {
                            return value.includes(`${v.value}`) || value.includes(v.value);
                        })
                    );
                }
            }
        }, [values, value, multiple]);

        const defaultValue = multiple ? [] : null;

        if (useAutoComplete === true) {
            let autoCompleteText = selectedValue ? selectedValue.label : inputText;
            if (multiple && selectedValue) {
                autoCompleteText = selectedValue.map((v) => v.label).join(', ');
            }
            return (
                <Autocomplete
                    sx={inputStyles}
                    size={size}
                    fullWidth
                    autoComplete
                    error={error || undefined}
                    autoHighlight
                    onChange={(event, selected, reason) => {
                        if (reason === 'clear') {
                            if (multiple) {
                                processChange([]);
                            } else {
                                processChange(null);
                            }
                        }
                        if (reason === 'selectOption') {
                            if (multiple === true) {
                                processChange(
                                    selected.map((item) => {
                                        return item.value;
                                    })
                                );
                            } else {
                                processChange(selected.value);
                            }
                        }

                        if (reason === 'removeOption') {
                            if (multiple === true) {
                                processChange(
                                    selected.map((item) => {
                                        return item.value;
                                    })
                                );
                            }
                        }
                    }}
                    blurOnSelect
                    options={values}
                    multiple={multiple}
                    groupBy={groupBy}
                    value={selectedValue || defaultValue}
                    getOptionLabel={(option) => option.label || ''}
                    renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    )}
                    isOptionEqualToValue={(option, value) => {
                        if (selectedValue) {
                            if (typeof value === 'string') {
                                return option.value === value;
                            }
                            return option.value === value.value;
                        }
                        return false;
                    }}
                    disableClearable={disableClearable}
                    renderInput={(params) => {
                        if (help) {
                            if (!params.InputProps.startAdornment) {
                                params.InputProps.startAdornment = [];
                            }
                            params.InputProps.startAdornment.push(
                                <InputAdornment position="start" key={_.uniqueId()}>
                                    <RbgTooltip title={help}>
                                        <IconInfoCircle color={theme.palette.info.main} />
                                    </RbgTooltip>
                                </InputAdornment>
                            );
                        } else if (textPrimary || primaryIcon) {
                            if (!params.InputProps.startAdornment) {
                                params.InputProps.startAdornment = [];
                            }
                            params.InputProps.startAdornment.push(
                                <>
                                    {primaryIcon && primaryIcon}
                                    {textPrimary && (
                                        <>
                                            <InputAdornment position="start">{textPrimary}</InputAdornment>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        </>
                                    )}
                                </>
                            );
                        }
                        if (textSecondary || secondaryIcon) {
                            if (!params.InputProps.endAdornment) {
                                params.InputProps.endAdornment = [];
                            }
                            params.InputProps.endAdornment.push(
                                <>
                                    {secondaryIcon && secondaryIcon}
                                    {textSecondary && (
                                        <>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <InputAdornment position="end">{textSecondary}</InputAdornment>
                                        </>
                                    )}
                                </>
                            );
                        }
                        return (
                            <TextField
                                autoComplete="off"
                                {...params}
                                disabled={disabled}
                                onChange={(e) => setInputText(e.target.value)}
                                label={label}
                                error={!!error}
                                helperText={error || warning}
                            />
                        );
                    }}
                    {...others}
                />
            );
        }
        return (
            <FormControl sx={{ '.MuiFormLabel-asterisk': { color: 'red' }, ...formStyles }} fullWidth>
                <TextField
                    required={required}
                    sx={inputStyles}
                    id={name}
                    name={name}
                    select
                    error={!!error}
                    helperText={error || warning || null}
                    size={size}
                    fullWidth
                    label={label}
                    placeholder={label}
                    value={value === null ? defaultValue : value}
                    onChange={(e) => processChange(e.target.value)}
                    SelectProps={{
                        multiple,
                        displayEmpty: (primaryIcon || secondaryIcon) && true,
                        MenuProps: { PaperProps: { sx: { maxHeight: 400 } } }
                    }}
                    inputRef={ref}
                    InputProps={{
                        disabled,
                        startAdornment: help ? (
                            <InputAdornment position="start" key={_.uniqueId()}>
                                <RbgTooltip title={help}>
                                    <IconInfoCircle color={theme.palette.info.main} />
                                </RbgTooltip>
                            </InputAdornment>
                        ) : (
                            (textPrimary || primaryIcon) && (
                                <>
                                    {primaryIcon && primaryIcon}
                                    {textPrimary && (
                                        <>
                                            <InputAdornment position="start">{textPrimary}</InputAdornment>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        </>
                                    )}
                                </>
                            )
                        ),
                        endAdornment:
                            textSecondary || secondaryIcon ? (
                                <>
                                    {secondaryIcon && secondaryIcon}
                                    {textSecondary && (
                                        <>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <InputAdornment position="end">{textSecondary}</InputAdornment>
                                        </>
                                    )}
                                </>
                            ) : null
                    }}
                >
                    <MenuItem disabled={disableBlankOption} value="">
                        <span>{blankOptionText || '- Select -'}</span>
                    </MenuItem>
                    {values?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
        );
    }
);

RbgSelect.propTypes = {
    values: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    handleChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    primaryIcon: PropTypes.node,
    secondaryIcon: PropTypes.node,
    textPrimary: PropTypes.node,
    textSecondary: PropTypes.node,
    textFieldStyles: PropTypes.object,
    formStyles: PropTypes.object,
    size: PropTypes.string,
    multiple: PropTypes.bool,
    disableBlankOption: PropTypes.bool,
    blankOptionText: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    help: PropTypes.string,
    warning: PropTypes.string,
    useAutoComplete: PropTypes.bool,
    disableClearable: PropTypes.bool,
    handleObjectChange: PropTypes.func,
    groupBy: PropTypes.func
};

export const SelectOptionFormatter = (input) => {
    if (Array.isArray(input)) {
        return input.map((opt) => {
            if (typeof opt === 'object') {
                let value = '';
                let label = '';
                if (opt.id) {
                    value = opt.id;
                }
                if (opt.key) {
                    value = opt.key;
                }
                if (opt.title) {
                    label = opt.title;
                }
                if (opt.name) {
                    label = opt.name;
                }

                return {
                    value,
                    label
                };
            }
            if (typeof opt === 'string') {
                return {
                    value: opt,
                    label: opt
                };
            }
            return {
                value: 'unknown',
                label: 'Unknown'
            };
        });
    }
    return [];
};

export default RbgSelect;
