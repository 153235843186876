import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// mui
import { useMediaQuery, useTheme } from '@mui/material';
// mui wrappers
import RbgTableContainer from 'ui_component/mui-wrappers/TableContainer/RbgTableContainer';
import RbgTable from 'ui_component/mui-wrappers/Table/RbgTable';
import RbgTableRow from 'ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from 'ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableHead from 'ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableBody from 'ui_component/mui-wrappers/TableBody/RbgTableBody';
// 3rd party
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IconUserCheck } from '@tabler/icons-react';
// project
import { iconSize } from 'store/constant';
import { snackbarOpen } from 'store/reducers/snackbarReducer';
import MainCard from 'ui_component/cards/MainCard';
import CircularLoading from 'ui_component/loading/CircularLoading';
import StaticBreadcrumb from '../../../../../ui_component/extended/StaticBreadcrumb';
import { useQuery } from '@tanstack/react-query';
import ServerSidePagination from '../../../../../ui_component/pagination/ServerSidePagination';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { useNavigate } from 'react-router-dom';
import NewStarterApprovalRow from './NewStarterApprovalRow';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { useDebounce } from '@uidotdev/usehooks';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';

dayjs.extend(relativeTime);

function NewStarterApprovals({ preview }) {
    const theme = useTheme();
    const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [filter, setFilter] = useState({
        page: 1,
        status_id: 2,
        search: ''
    });
    const debouncedFilter = useDebounce(filter, 400);
    // redux
    const dispatch = useDispatch();

    const { data, isLoading, error, isPending, refetch, isFetched } = useQuery({
        queryKey: [{ url: '/staff/onboarding/auth', method: 'GET', params: debouncedFilter }],
        initialData: {
            data: {},
            meta: {
                pagination: {
                    current_page: 1,
                    total_pages: 1
                }
            }
        }
    });

    useEffect(() => {
        if (error) {
            dispatch(snackbarOpen({ open: true, severity: 'error', message: error.toString() }));
        }
    }, [error, dispatch]);
    const handlePageChange = (page) => {
        setFilter((prev) => ({ ...prev, page }));
    };
    if (isLoading || isPending || isFetched === false) return <CircularLoading />;
    return (
        <RbgStack gap={2}>
            <RbgStack sx={{ p: 1 }} direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                <RbgSelect
                    label="Status"
                    value={filter.status_id}
                    values={[
                        { value: 1, label: 'Pending' },
                        { value: 2, label: 'Submitted for approval' },
                        { value: 3, label: 'Approval Declined' },
                        { value: 6, label: 'Completed' }
                    ]}
                    handleChange={(e) => setFilter((prev) => ({ ...prev, status_id: e.target.value }))}
                />
                <RbgTextInput
                    name="search"
                    label="Search"
                    value={filter.search}
                    onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
                />
            </RbgStack>
            <ServerSidePagination pagination={data.meta.pagination} changePage={handlePageChange} />
            <RbgTableContainer sx={{ height: '100%' }}>
                <RbgTable size="small">
                    <RbgTableHead>
                        <RbgTableRow>
                            {!preview && <RbgTableCell width={200}>Status</RbgTableCell>}
                            <RbgTableCell>Name</RbgTableCell>
                            {!preview && (
                                <>
                                    <RbgTableCell>Site</RbgTableCell>
                                    <RbgTableCell>Position</RbgTableCell>
                                </>
                            )}
                            <RbgTableCell>Start Date</RbgTableCell>
                            <RbgTableCell>Pay Rate</RbgTableCell>
                            <RbgTableCell>Contracted Hours</RbgTableCell>
                            <RbgTableCell width={100} />
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        {data.data.length > 0 ? (
                            data.data.map((newStarter) => {
                                return (
                                    <NewStarterApprovalRow
                                        preview={preview}
                                        newStarter={newStarter}
                                        key={newStarter.id}
                                        refetch={refetch}
                                    />
                                );
                            })
                        ) : (
                            <RbgTableRow>
                                <RbgTableCell colSpan={8}>Nothing to approve</RbgTableCell>
                            </RbgTableRow>
                        )}
                    </RbgTableBody>
                </RbgTable>
            </RbgTableContainer>
            <ServerSidePagination pagination={data.meta.pagination} changePage={handlePageChange} />
        </RbgStack>
    );
}

export default NewStarterApprovals;

NewStarterApprovals.propTypes = {
    preview: PropTypes.bool
};
