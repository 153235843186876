import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddOperaImport from './AddOperaImport';
import Debug from '../../../../../ui_component/misc/Debug';
import { useEffect, useState } from 'react';
import MainCard from '../../../../../ui_component/cards/MainCard';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import { Pagination } from '@mui/material';
import ServerSidePagination from '../../../../../ui_component/pagination/ServerSidePagination';
import OperaImportErrors from './OperaImportErrors';
import { Link } from 'react-router-dom';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import dayjs from 'dayjs';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import { useDebounce } from '@uidotdev/usehooks';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import PusherWrapper from '../../../../../utils/pusher-wrapper';
import EventDispatcher from '../../../../../utils/EventDispatcher';

function OperaImport() {
    const [filter, setFilter] = useState({
        page: 1,
        status_id: '1',
        search: '',
        field: ''
    });
    const debouncedFilter = useDebounce(filter, 500);
    const { data, error, refetch } = useQuery({
        initialData: {
            data: [],
            meta: {
                import: { date_created: '', created_by_name: '', fields: [] },
                pagination: {
                    total: 0,
                    count: 20,
                    per_page: 10,
                    current_page: 1,
                    total_pages: 1
                }
            }
        },
        queryKey: [{ url: '/staff/admin/opera/import', params: debouncedFilter }, 'opera_imports']
    });

    useEffect(() => {
        if (data.meta.import.id) {
            EventDispatcher.dispatch('pusher-subscribe', `private-opera_import-${data.meta.import.id}`);
        }
    }, [data.meta.import]);
    const ids = data.data.map((row) => row.id);

    useEffect(() => {
        const id = EventDispatcher.subscribe('pusher-message', (data) => {
            if (data.event === 'opera_entry_updated') {
                if (ids.includes(data.data.id)) {
                    console.log('Yep');
                    refetch();
                    return;
                }
                console.log('Nope');
            }
        });
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [refetch, ids]);
    return (
        <MainCard
            title="Opera Import"
            secondary={
                <RbgButton
                    onClick={() => {
                        DialogHelper.openSmDialog('Opera Import', <AddOperaImport />);
                    }}
                >
                    Add New
                </RbgButton>
            }
        >
            <RbgGrid container>
                {error ? (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{error}</ErrorCard>
                    </RbgGrid>
                ) : (
                    <RbgGrid item xs={12}>
                        <InfoCard>
                            Import from opera uploaded {dayjs(data.meta.import.date_created).format('DD/MM/YYYY')} by{' '}
                            {data.meta.import.created_by_name}{' '}
                            {data.meta.import.processed === false && (
                                <strong>
                                    This import is still being processed <RbgButton onClick={() => refetch()}>Refresh</RbgButton>
                                </strong>
                            )}
                        </InfoCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgStack gap={2} direction="row">
                        <RbgTextInput
                            name="search"
                            label="Search"
                            value={filter.search}
                            onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
                        />
                        <RbgRemoteSelect
                            label="Status"
                            endpoint="/staff/admin/opera/import-statuses"
                            name="status_id"
                            value={filter.status_id}
                            handleChange={(e) => {
                                setFilter((prev) => ({ ...prev, status_id: e.target.value }));
                            }}
                        />
                        <RbgSelect
                            label="Has Problem"
                            values={data.meta.import.fields}
                            name="field"
                            value={filter.field}
                            handleChange={(e) => setFilter((prev) => ({ ...prev, field: e.target.value }))}
                        />
                    </RbgStack>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination pagination={data.meta.pagination} changePage={(page) => setFilter({ ...filter, page })} />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={['Payroll Ref', 'Name', 'Cost Centre', 'Errors']}
                        rows={data.data.map((row) => {
                            let errors = '';
                            if (row.errors.data.length > 0) {
                                errors = row.errors.data.map((e) => e.error).join(', ');
                            }
                            return [
                                row.payroll_ref,
                                row.staff_id ? (
                                    <Link to={`/staff/view/${row.staff_id}`}>
                                        {row.forename} {row.surname}
                                    </Link>
                                ) : (
                                    `${row.forename} ${row.surname}`
                                ),
                                row.cost_centre,
                                <a
                                    href="#"
                                    onClick={() => {
                                        DialogHelper.openSmDialog(
                                            'Opera Import Mismatches',
                                            <OperaImportErrors errors={row.errors.data} staffId={row.staff_id} />
                                        );
                                    }}
                                >
                                    {errors}
                                </a>
                            ];
                        })}
                        keys={data.data.map((row) => row.id)}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination pagination={data.meta.pagination} changePage={(page) => setFilter({ ...filter, page })} />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default OperaImport;
