import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import StaffAdminEmailsEditMappingRow from './StaffAdminEmailsEditMappingRow';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import _ from 'lodash';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function StaffAdminEmailsEditMapping({ mapping }) {
    const [newMapping, setNewMapping] = useState(mapping);
    const queryClient = useQueryClient();
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-admin/email-type-mapping/${mapping.id}`, newMapping);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('email_template_mapping') > -1;
                }
            });
        }
    });
    const addMapping = () => {
        const mappingData = JSON.parse(JSON.stringify(newMapping.mapping.data));
        mappingData.push({
            id: _.uniqueId(),
            template_id: '',
            type_id: mapping.id,
            cost_centre: '0001',
            template: { data: {} }
        });
        setNewMapping((prev) => ({ ...prev, mapping: { data: mappingData } }));
    };

    const handleMappingChange = (mapping) => {
        let found = false;
        const mappingData = JSON.parse(JSON.stringify(newMapping.mapping.data)).map((current) => {
            if (current.id === mapping.id) {
                found = true;
                return mapping;
            }
            return current;
        });
        if (found === false) {
            mappingData.push(mapping);
        }
        setNewMapping((prev) => ({ ...prev, mapping: { data: mappingData } }));
    };
    const handleMappingDelete = (mapping) => {
        const mappingData = JSON.parse(JSON.stringify(newMapping.mapping.data)).filter((current) => {
            if (current.id === mapping.id) {
                return false;
            }
            return true;
        });
        setNewMapping((prev) => ({ ...prev, mapping: { data: mappingData } }));
    };
    useEffect(() => {
        DialogHelper.addSaveButton(() => saveMutation.mutate());
    }, [saveMutation]);

    const overrides = newMapping.mapping.data.filter((row) => row.cost_centre !== null);
    const defaultMapping = newMapping.mapping.data.find((row) => row.cost_centre === null);

    return (
        <RbgGrid container>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={6}>
                <RbgTextInput disabled value={newMapping.short_name} label="Short Name" />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgTextInput disabled value={newMapping.name} label="Name" />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/staff-admin/email-templates?page=1"
                    name="template_id"
                    label="Default Template"
                    value={defaultMapping?.template_id}
                    handleChange={(e) => {
                        if (!defaultMapping) {
                            handleMappingChange({
                                id: _.uniqueId(),
                                template_id: e.target.value,
                                type_id: mapping.id,
                                cost_centre: null,
                                template: { data: {} }
                            });
                        } else {
                            handleMappingChange({ ...defaultMapping, template_id: e.target.value });
                        }
                    }}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTable>
                    <RbgTableHead>
                        <RbgTableRow>
                            <RbgTableCell>Location</RbgTableCell>
                            <RbgTableCell>Template</RbgTableCell>
                            <RbgTableCell />
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        {overrides.map((row) => {
                            return (
                                <StaffAdminEmailsEditMappingRow
                                    mapping={row}
                                    key={row.id}
                                    handleMappingChange={handleMappingChange}
                                    handleDelete={handleMappingDelete}
                                />
                            );
                        })}
                    </RbgTableBody>
                </RbgTable>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton onClick={() => addMapping()} fullWidth>
                    <IconPlus /> Add Mapping
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

StaffAdminEmailsEditMapping.propTypes = {
    mapping: PropTypes.object.isRequired
};

export default StaffAdminEmailsEditMapping;
