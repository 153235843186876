import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, ButtonBase, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
import { IconChecks, IconPalette } from '@tabler/icons-react';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// project imports
import SubCard from 'ui_component/cards/SubCard';
import { menuType, presetColors } from 'store/reducers/customizationReducer';
import { gridSpacing } from 'store/constant';
// color import
import colors from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme3 from 'assets/scss/_theme3.module.scss';
import theme4 from 'assets/scss/_theme4.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';
import Api from '../../utils/api';

function PresetColor({ color, presetColor, setPresetColor }) {
    return (
        <RbgGrid item>
            <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => setPresetColor(color.id)}>
                <Avatar
                    variant="rounded"
                    color="inherit"
                    sx={{
                        background: `linear-gradient(135deg, ${color.primary} 50%, ${color.secondary} 50%)`,
                        opacity: presetColor === color.id ? 0.6 : 1
                    }}
                >
                    {presetColor === color.id && <IconChecks color="#fff" />}
                </Avatar>
            </ButtonBase>
        </RbgGrid>
    );
}

PresetColor.propTypes = {
    color: PropTypes.shape({
        id: PropTypes.string,
        primary: PropTypes.string,
        secondary: PropTypes.string
    }),
    presetColor: PropTypes.string,
    setPresetColor: PropTypes.func
};

// ==============================|| LIVE CUSTOMIZATION ||============================== //

function Customization({ open, setOpen }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    // state - layout type
    const [navType, setNavTypeState] = useState(customization.navType);
    useEffect(() => {
        dispatch(menuType(navType));
    }, [navType, dispatch]);

    // state - preset color
    const [presetColor, setPresetColorState] = useState(customization.presetColor);
    useEffect(() => {
        dispatch(presetColors(presetColor));
    }, [presetColor, dispatch]);

    const updatePreference = (name, value) => {
        switch (name) {
            case 'theme':
                setNavTypeState(value);
                break;
            case 'presetColor':
                setPresetColorState(value);
                break;

            // no default
        }

        // Don't need to do anything with response, as that is handled by websocket updates
        Api.post(`rest/preference/${name}`, { value });
    };

    const setNavType = (e) => {
        updatePreference('theme', e);
    };
    const setPresetColor = (e) => {
        updatePreference('presetColor', e);
    };

    const colorOptions = [
        {
            id: 'default',
            primary: theme.palette.mode === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
            secondary: theme.palette.mode === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain
        },
        {
            id: 'theme1',
            primary: theme.palette.mode === 'dark' ? theme1.darkPrimaryMain : theme1.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme1.darkSecondaryMain : theme1.secondaryMain
        },
        {
            id: 'theme2',
            primary: theme.palette.mode === 'dark' ? theme2.darkPrimaryMain : theme2.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme2.darkSecondaryMain : theme2.secondaryMain
        },
        {
            id: 'theme3',
            primary: theme.palette.mode === 'dark' ? theme3.darkPrimaryMain : theme3.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme3.darkSecondaryMain : theme3.secondaryMain
        },
        {
            id: 'theme4',
            primary: theme.palette.mode === 'dark' ? theme4.darkPrimaryMain : theme4.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme4.darkSecondaryMain : theme4.secondaryMain
        },
        {
            id: 'theme5',
            primary: theme.palette.mode === 'dark' ? theme5.darkPrimaryMain : theme5.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme5.darkSecondaryMain : theme5.secondaryMain
        },
        {
            id: 'theme6',
            primary: theme.palette.mode === 'dark' ? theme6.darkPrimaryMain : theme6.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme6.darkSecondaryMain : theme6.secondaryMain
        }
    ];

    return (
        <>
            {/* toggle button */}
            <IconButton sx={{ p: 0 }} color="inherit" size="small" disableRipple>
                <IconPalette stroke={1.5} size="1.3rem" />
            </IconButton>

            <Drawer
                anchor="right"
                open={open}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <RbgGrid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <RbgGrid item xs={12}>
                            {/* layout type */}
                            <SubCard title="Layout">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Mode</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="layout"
                                        value={navType}
                                        onChange={(e) => setNavType(e.target.value)}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="light"
                                            control={<Radio />}
                                            label="Light"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="dark"
                                            control={<Radio />}
                                            label="Dark"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </RbgGrid>
                        <RbgGrid item xs={12}>
                            {/* Theme Preset Color */}
                            <SubCard title="Preset Colour">
                                <RbgGrid item container spacing={2} alignItems="center">
                                    {colorOptions.map((color, index) => (
                                        <PresetColor key={index} color={color} presetColor={presetColor} setPresetColor={setPresetColor} />
                                    ))}
                                </RbgGrid>
                            </SubCard>
                        </RbgGrid>
                    </RbgGrid>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
}

Customization.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default Customization;
