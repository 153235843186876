import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { contractTemplate } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import { useState } from 'react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';

function EditContractTemplateNewSections({ currentTemplate, editSection }) {
    const [selected, setSelected] = useState(null);
    const [insertBefore, setInsertBefore] = useState(null);
    const ignoreKeys = currentTemplate.sections.data.map((item) => {
        return item.section_id;
    });
    const { data } = useQuery({
        queryKey: [
            {
                url: '/staff/staff-contract-admin/sections',
                params: { per_page: 9999, page: 1 }
            },
            'contract_sections'
        ]
    });
    const list = data.data
        .filter((item) => {
            return ignoreKeys.indexOf(item.id) === -1;
        })
        .map((item) => {
            return {
                value: item.id,
                label: item.name,
                object: item
            };
        });

    const handleAdd = () => {
        let max = 0;
        currentTemplate.sections.data.forEach((s) => {
            if (s.display_order > max) {
                max = s.display_order;
            }
        });
        const displayOrder = insertBefore ? insertBefore.display_order : max + 1;
        editSection({
            section_id: selected.id,
            display_order: displayOrder,
            section: { data: selected }
        });
        setSelected(null);
        setInsertBefore(null);
    };
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSelect
                    values={list}
                    label="Add New Section"
                    useAutoComplete
                    value={selected ? selected.id : ''}
                    handleObjectChange={(e) => {
                        setSelected(e.target.object);
                    }}
                    handleChange={(e) => {
                        if (!e.target.value) {
                            setSelected(null);
                        }
                    }}
                />
            </RbgGrid>
            {selected && currentTemplate.sections.data.length > 0 && (
                <RbgGrid item xs={12}>
                    <RbgSelect
                        values={currentTemplate.sections.data.map((item) => ({
                            value: item.section_id,
                            label: item.section.data.name,
                            object: item
                        }))}
                        value={insertBefore ? insertBefore.section_id : ''}
                        handleObjectChange={(e) => setInsertBefore(e.target.object)}
                        handleChange={(e) => {
                            if (!e.target.value) {
                                setInsertBefore(null);
                            }
                        }}
                        label="Insert before"
                    />
                </RbgGrid>
            )}
            {selected && (
                <RbgGrid item xs={12}>
                    <RbgButton
                        color="success"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            handleAdd();
                        }}
                    >
                        Add
                    </RbgButton>
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

EditContractTemplateNewSections.propTypes = {
    currentTemplate: PropTypes.shape(contractTemplate).isRequired,
    editSection: PropTypes.func.isRequired
};
export default EditContractTemplateNewSections;
