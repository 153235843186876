import PropTypes from 'prop-types';
import { useState } from 'react';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import RbgTextArea from '../../../../ui_component/text-area/RbgTextArea';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import Api from '../../../../utils/api';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';

function PayslipQuery({ id }) {
    const [query, setQuery] = useState('');

    const { data, refetch } = useQuery({
        queryKey: [{ url: `/staff/payslip/${id}/queries` }]
    });

    const queryMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/payslip/${id}/query`, { query });
        },
        onSuccess: () => {
            setQuery('');
            refetch();
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Date', 'User', 'Query']}
                    keys={data.data.map((row) => row.id)}
                    rows={data.data.map((row) => [dayjs(row.date).format('DD/MM/YYYY'), row.user_name, row.query])}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextArea name="query" value={query} onChange={(e) => setQuery(e.target.value)} label="Query" />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton fullWidth color="success" onClick={() => queryMutation.mutate()} disabled={queryMutation.isPending}>
                    Send new query
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

PayslipQuery.propTypes = {
    id: PropTypes.number.isRequired
};

export default PayslipQuery;
