import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { payRateAuth } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import ChangeTable from './ChangeTable';

function ApproveAuthorisation({ rate }) {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/pay-rate-approval/${rate.id}`, { status: 3 });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('pay_rate_approvals') > -1;
                }
            });
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    Are you sure you want to approve the following changes for this team member? These changes will be effective from{' '}
                    {rate.effective_from}
                </IntroCardPrimary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <ChangeTable change={rate} />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgStack direction="row" spacing={4} justifyContent="center">
                    <RbgButton color="success" onClick={() => mutation.mutate()} variant="contained" fullWidth>
                        Approve
                    </RbgButton>
                </RbgStack>
            </RbgGrid>
        </RbgGrid>
    );
}

ApproveAuthorisation.propTypes = {
    rate: PropTypes.shape(payRateAuth)
};

export default ApproveAuthorisation;
