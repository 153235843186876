import PropTypes from 'prop-types';
import { staffMember } from '../../../StaffDataTypes';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../../store/constant';
import IntroCardPrimary from '../../../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import DateFormatter from '../../../../../../utils/dateFormatter';
import dayjs from 'dayjs';
import RbgStack from '../../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import EditFutureRole from '../EditFutureRole';
import RoleHistory from '../RoleHistory';
import MainCard from '../../../../../../ui_component/cards/MainCard';
import NewRoleWizard from '../NewRole/NewRoleWizard';
import IntroCardSecondary from '../../../../../../ui_component/cards/IntroCardSecondary';
import ManageAdditionalRoles from '../Roles/ManageAdditionalRoles';
import RegeneratePayrollReference from '../Roles/RegeneratePayrollReference';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../../contexts/RcloudAuthContext';

function JobRoleWidget({ staffMember }) {
    const futureRoles = staffMember.all_roles.data.filter((role) => {
        const after =
            dayjs(role.start_date).format('DDMMYYYY') === dayjs().format('DDMMYYYY') || dayjs(role.start_date).isSameOrAfter(dayjs());
        if (after && role.id !== staffMember.role.data.id) {
            return true;
        }
        return false;
    });
    const { user } = useContext(RcloudAuthContext);

    const addRole = () => {
        DialogHelper.openMdDialog(
            'Role Change',
            <NewRoleWizard
                staffMember={staffMember}
                initialData={{
                    same_location: true,
                    location: staffMember.role.data.cost_centre,
                    pay_type: 'hourly',
                    pay_change: true,
                    pay_type_change: false,
                    location_name: staffMember.role.data.location_name
                }}
            />
        );
    };
    const addSalaryRole = () => {
        DialogHelper.openMdDialog(
            'Role Change',
            <NewRoleWizard
                staffMember={staffMember}
                initialData={{
                    same_location: true,
                    location: staffMember.role.data.cost_centre,
                    pay_type: 'salary',
                    pay_change: true,
                    pay_type_change: false,
                    location_name: staffMember.role.data.location_name
                }}
            />
        );
    };

    const salaryToHourly = () => {
        DialogHelper.openMdDialog(
            'Role Change',
            <NewRoleWizard
                staffMember={staffMember}
                initialData={{
                    same_location: true,
                    location: staffMember.role.data.cost_centre,
                    pay_type: 'hourly',
                    pay_change: true,
                    pay_type_change: true,
                    location_name: staffMember.role.data.location_name
                }}
            />
        );
    };
    const hourlyToSalary = () => {
        DialogHelper.openMdDialog(
            'Role Change',
            <NewRoleWizard
                staffMember={staffMember}
                initialData={{
                    same_location: true,
                    location: staffMember.role.data.cost_centre,
                    pay_type: 'salary',
                    pay_change: true,
                    pay_type_change: true,
                    new_contract: true,
                    location_name: staffMember.role.data.location_name
                }}
            />
        );
    };
    const hasPreviousRole = staffMember.all_roles.data.length > 1;
    const details = staffMember.details.data;
    const currentRole = staffMember.role.data;
    const futureAuths = staffMember.pay_rate_auths.data
        .filter((auth) => auth.status.data.id === 1)
        .filter((auth) => {
            if (auth.position_name !== staffMember.role.data.position.data.name) {
                return true;
            }
            return false;
        });
    const actions = [];
    if (user.permissions.indexOf('hr_staff_edit') > -1) {
        actions.push({
            content: 'Manage additional roles',
            onClick: () =>
                DialogHelper.openMdDialog(
                    'Manage Additional Roles',
                    <ManageAdditionalRoles staffMember={staffMember} role={staffMember.role.data} />
                )
        });
        if (futureRoles.length === 0) {
            if (staffMember.pay_rate.data.type.data.hourly) {
                actions.push({ content: 'Schedule new hourly role', onClick: () => addRole() });
                actions.push({ content: 'Change from hourly to salary', onClick: () => hourlyToSalary() });
            }
            if (staffMember.pay_rate.data.type.data.salary) {
                actions.push({ content: 'Schedule new salary role', onClick: () => addSalaryRole() });
                actions.push({ content: 'Change from salary to hourly', onClick: () => salaryToHourly() });
            }
        } else {
            actions.push({
                content: 'Edit Future Role',
                onClick: () => {
                    DialogHelper.openSmDialog('Edit Future Role', <EditFutureRole role={futureRoles[0]} />);
                }
            });
        }
    }
    if (hasPreviousRole) {
        actions.push({
            content: 'Role History',
            onClick: () => {
                DialogHelper.openSmDialog('Role History', <RoleHistory staffMember={staffMember} />);
            }
        });
    }
    if (user.permissions.indexOf('hr_config') > -1) {
        actions.push({
            content: 'Regenerate Payroll Reference',
            onClick: () => {
                DialogHelper.openMdDialog('Regenerate Payroll Reference', <RegeneratePayrollReference staffMember={staffMember} />);
            }
        });
    }
    return (
        <MainCard title="Job Role" actionButtons={{ label: 'Actions', items: actions }}>
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgStack gap={2}>
                            <RbgTypography>
                                {staffMember.display_forename}&apos;s current role is <strong>{staffMember.role.data.position_name}</strong>{' '}
                                at <strong>{staffMember.role.data.location_name}</strong>. They started this role on{' '}
                                <strong>
                                    <DateFormatter date={staffMember.role.data.start_date} />
                                </strong>
                            </RbgTypography>
                            {currentRole.additional_locations.length > 0 && (
                                <RbgTypography>
                                    This role has the additional locations of{' '}
                                    <strong>{currentRole.additional_location_names.join(', ')}</strong>
                                </RbgTypography>
                            )}
                            {currentRole.additional_positions.data.length > 0 && (
                                <RbgTypography>
                                    This role has the additional positions of{' '}
                                    <strong>{currentRole.additional_positions.data.map((pos) => pos.name).join(', ')}</strong>
                                </RbgTypography>
                            )}
                            {futureRoles.map((role) => {
                                if (role.cost_centre !== staffMember.role.data.cost_centre) {
                                    return (
                                        <RbgTypography key={role.id}>
                                            {staffMember.display_forename} is due to be transferred to <strong>{role.location_name}</strong>{' '}
                                            on <strong>{dayjs(role.start_date).format('DD/MM/YYYY')}</strong>.
                                            {role.position_id !== staffMember.role.data.position_id && (
                                                <>
                                                    {' '}
                                                    Their new job role will be <strong>{role.position.data.name}</strong>
                                                </>
                                            )}
                                        </RbgTypography>
                                    );
                                }
                                return (
                                    <RbgTypography key={role.id}>
                                        {staffMember.display_forename} is due to start a new role of{' '}
                                        <strong>{role.position.data.name}</strong> on{' '}
                                        <strong>{dayjs(role.start_date).format('DD/MM/YYYY')}</strong>
                                    </RbgTypography>
                                );
                            })}
                        </RbgStack>
                    </IntroCardPrimary>
                </RbgGrid>
                {futureAuths.map((auth) => {
                    if (!auth.position_name) {
                        return <span />;
                    }
                    return (
                        <RbgGrid item xs={12} key={auth.id}>
                            <IntroCardSecondary>
                                <RbgTypography>
                                    {staffMember.display_forename} has an outstanding position change authorisation to go from{' '}
                                    <strong>{staffMember.role.data.position.data.name}</strong> to <strong>{auth.position_name}</strong>
                                </RbgTypography>
                            </IntroCardSecondary>
                        </RbgGrid>
                    );
                })}
            </RbgGrid>
        </MainCard>
    );
}

JobRoleWidget.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default JobRoleWidget;
