import { lazy } from 'react';
// project imports
import config from '../config';
import MainLayout from 'layout/MainLayout/MainLayout';
import Loadable from 'ui_component/loading/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NavigationScroll from 'layout/NavigationScroll';
import HrSystemConfig from '../views/app/Staff/SystemConfig/HrSystemConfig';
import StaffSearch from '../views/app/Staff/StaffSearch/StaffSearch';
import DiReporting from '../views/app/Staff/Reporting/DiReporting';
import BulkActions from '../views/app/Staff/BulkActions/BulkActions';
import ActionReporting from '../views/app/Staff/Reporting/ActionReporting';
import StaffAreaManagerDashboard from '../views/app/Staff/StaffAreaManagerDashboard/StaffAreaManagerDashboard';
import EditNewStarter from '../views/app/Staff/NewStarters/EditNewStarter';
import ManualAddNewStarter from '../views/app/Staff/NewStarters/ManualAddNewStarter';
import StaffMember from '../views/app/Staff/StaffMember/StaffMember';
import StaffDashboard from '../views/app/Staff/StaffDashboard/StaffDashboard';
import StaffList from '../views/app/Staff/StaffList/StaffList';
import StaffName from '../views/app/Staff/StaffMember/StaffName';
import DocumentationApproval from '../views/app/Staff/DocumentationApproval/DocumentationApproval';
import Approvals from '../views/app/Staff/TeamApprovals/Approvals';
import CompanySearch from '../views/app/Staff/StaffSearch/CompanySearch';
import FourthImport from '../views/app/Staff/SystemConfig/FourthImport/FourthImport';
import S4Differences from '../views/app/Staff/SystemConfig/S4Differences/S4Differences';
import OnboardAdminList from '../views/app/Staff/SystemConfig/OnboardAdmin/OnboardAdminList';
import FirstAidReporting from '../views/app/Staff/Reporting/FirstAid/FirstAidReporting';
import ScFamilyTree from '../views/app/Staff/SupportCentreFamilyTree/ScFamilyTree';
import PeopleDashboard from '../views/app/Staff/PeopleDashboard/PeopleDashboard';
import DraftPayslips from '../views/app/Staff/DraftPayslips/DraftPayslips';
import DraftPayslipAdmin from '../views/app/Staff/SystemConfig/DraftPayslips/DraftPayslipAdmin';
import OperaImport from '../views/app/Staff/SystemConfig/OperaImport/OperaImport';
// application routing
const RotaList = Loadable(lazy(() => import('views/app/Staff/Rota/RotaList')));
const ViewRota = Loadable(lazy(() => import('views/app/Staff/Rota/ViewRota')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    name: 'staff-routes',
    basename: config.basename,
    path: '/staff',
    element: (
        <AuthGuard>
            <NavigationScroll>
                <MainLayout />
            </NavigationScroll>
        </AuthGuard>
    ),
    children: [
        {
            path: '/staff',
            element: <StaffDashboard />,
            handle: {
                crumb: () => 'Team Dashboard'
            }
        },
        {
            path: '/staff/area',
            element: <StaffAreaManagerDashboard />,
            handle: {
                crumb: () => 'Area Dashboard'
            }
        },
        {
            path: '/staff/people-dashboard',
            element: <PeopleDashboard />,
            handle: {
                crumb: () => 'People Dashboard'
            }
        },
        {
            path: '/staff/list',
            element: <StaffList />,
            handle: {
                crumb: () => 'Team List'
            }
        },
        {
            path: '/staff/rota',
            element: <RotaList />,
            handle: {
                crumb: () => 'Rota'
            }
        },
        {
            path: '/staff/rota/:rota_id',
            element: <ViewRota />,
            handle: {
                crumb: () => 'View Rota'
            }
        },
        {
            path: '/staff/view/:staff_id',
            element: <StaffMember />,
            handle: {
                crumb: () => <StaffName />
            }
        },
        {
            path: '/staff/onboarding/:id',
            element: <EditNewStarter />,
            handle: {
                crumb: () => <StaffName />
            }
        },
        {
            path: '/staff/team-approvals',
            element: <Approvals />,
            handle: {
                crumb: () => 'Pay Rate Approval'
            }
        },
        {
            path: '/staff/documentation-approvals',
            element: <DocumentationApproval />,
            handle: {
                crumb: () => 'Documentation Approvals'
            }
        },
        {
            path: '/staff/configuration',
            element: <HrSystemConfig />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/reporting/first-aid',
            element: <FirstAidReporting />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/search',
            element: <StaffSearch />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/company-search',
            element: <CompanySearch />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/reporting/di',
            element: <DiReporting />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/reporting/actions',
            element: <ActionReporting />,
            handle: {
                crumb: () => 'Action Reporting'
            }
        },
        {
            path: '/staff/bulk-actions',
            element: <BulkActions />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/admin/manual-new-starter',
            element: <ManualAddNewStarter />,
            handle: {
                crumb: () => 'HR System Config'
            }
        },
        {
            path: '/staff/admin/fourth-import',
            element: <FourthImport />,
            handle: {
                crumb: () => 'Fourth Import'
            }
        },
        {
            path: '/staff/admin/s4-differences',
            element: <S4Differences />,
            handle: {
                crumb: () => 'S4 Difference'
            }
        },
        {
            path: '/staff/admin/onboarding',
            element: <OnboardAdminList />,
            handle: {
                crumb: () => 'S4 Difference'
            }
        },
        {
            path: '/staff/family-tree',
            element: <ScFamilyTree />,
            handle: {
                crumb: () => 'Family Tree'
            }
        },
        {
            path: '/staff/draft-payslips',
            element: <DraftPayslips />,
            handle: {
                crumb: () => 'Draft Payslips'
            }
        },
        {
            path: '/staff/admin/draft-payslips',
            element: <DraftPayslipAdmin />,
            handle: {
                crumb: () => 'Draft Payslips'
            }
        },
        {
            path: '/staff/admin/opera-import',
            element: <OperaImport />,
            handle: {
                crumb: () => 'Draft Payslips'
            }
        }
    ]
};

export default MainRoutes;
