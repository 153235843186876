import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useEffect, useState } from 'react';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';

function AddStaffTrainingCourse({ staffMember }) {
    const [course, setCourse] = useState('');
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/training-course/${course.id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        if (course.id) {
            DialogHelper.addSaveButton(() => mutation.mutate(), 'Add Training Record', false);
            return;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Add Training Record', true);
    }, [course, mutation]);
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <WarningCard>
                    You should only be adding courses manually if there has been some kind of issue with Flow, or a course which is not on
                    Flow
                </WarningCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/training-courses"
                    name="training-course"
                    value={course.id}
                    useAutoComplete
                    handleObjectChange={(e) => {
                        if (e.target.object) {
                            setCourse(e.target.object);
                        }
                    }}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

AddStaffTrainingCourse.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default AddStaffTrainingCourse;
