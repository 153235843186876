import { staffEmployment } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import RbgTextArea from '../../../../../ui_component/text-area/RbgTextArea';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';

function ExtendProbation({ staffEmployment }) {
    const [date, setDate] = useState(staffEmployment.probation_end_date);
    const [reason, setReason] = useState('');

    const minDate = staffEmployment.probation_end_date ? dayjs(staffEmployment.probation_end_date) : dayjs();
    const maxDate = dayjs(minDate).add(6, 'month');

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffEmployment.staff_id}/extend-probation`, {
                date,
                reason
            });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    const getError = (field) => {
        if (mutation.error && mutation.error.fields) {
            return mutation.error.fields
                .filter((f) => {
                    return f.field === field;
                })
                .map((f) => {
                    return f.error;
                })
                .join(', ');
        }
        return null;
    };
    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save');
    }, [reason, date, mutation]);

    useEffect(() => {
        if (mutation.isPending) {
            DialogHelper.disableSaveButton();
        } else {
            DialogHelper.enableSaveButton();
        }
    }, [mutation.isPending]);

    if (!staffEmployment.id) {
        return (
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <ErrorCard>This staff member does not have a current employment record</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                {staffEmployment.probation_end_date && (
                    <IntroCardPrimary>
                        This will extend the probation period of this employee to the date specified. You must provide a reason for the
                        extension
                    </IntroCardPrimary>
                )}
            </RbgGrid>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    value={date}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(e) => setDate(e.format('YYYY-MM-DD'))}
                    name="end_date"
                    error={getError('end_date')}
                    label="New Probation End Date"
                />
            </RbgGrid>
            {staffEmployment.probation_end_date && (
                <RbgGrid item xs={12}>
                    <RbgTextArea
                        name="reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        label="Reason"
                        error={getError('reason')}
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

ExtendProbation.propTypes = {
    staffEmployment: PropTypes.shape(staffEmployment)
};
export default ExtendProbation;
