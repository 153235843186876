import PropTypes from 'prop-types';
import { useState } from 'react';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import dayjs from 'dayjs';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import RbgTooltip from '../../../../../ui_component/tooltip/RbgTooltip';
import { useQuery } from '@tanstack/react-query';

function StaffHistory({ staffMember }) {
    const [filter, setFilter] = useState({
        page: 1,
        search: ''
    });
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState({});

    const { data: history, isLoading: loading } = useQuery({
        queryKey: [{ url: `staff/staff-member/${staffMember.id}/history`, params: filter }]
    });
    if (loading) {
        return <CardLoader />;
    }
    return (
        <RbgGrid container gap={gridSpacing}>
            <RbgGrid item xs={12}>
                <PageableTable
                    pagination={pagination}
                    data={history.data}
                    headers={['Date', 'Logged By', 'Entry']}
                    handlePageChange={(page) => setFilter({ ...filter, page })}
                    topFilterContent={
                        <RbgTextInput
                            label="Search"
                            name="search"
                            size="small"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onBlur={() => setFilter({ ...filter, search })}
                        />
                    }
                    rowDecorator={(row) => [
                        <RbgTooltip title={dayjs(row.date).format('DD/MM/YYYY HH:mm:ss')}>{dayjs(row.date).fromNow()}</RbgTooltip>,
                        row.logged_by_name,
                        row.entry
                    ]}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

StaffHistory.propTypes = {
    staffMember: PropTypes.object.isRequired
};
export default StaffHistory;
