import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
// project imports
import config from '../config';
import NavigationScroll from 'layout/NavigationScroll';
import Loadable from 'ui_component/loading/Loadable';
// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import StaffRoutes from './StaffRoutes';
import NotFound from './NotFound';
import AdminRoutes from './AdminRoutes';

const MainLayout = Loadable(lazy(() => import('../layout/MainLayout/MainLayout')));
const ErrorBoundary = Loadable(lazy(() => import('ui_component/error/ErrorBoundary/ErrorBoundary')));

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = createBrowserRouter([
    {
        basename: config.basename,
        path: '/',
        element: (
            <ErrorBoundary>
                <NavigationScroll>
                    <MainLayout />
                </NavigationScroll>
            </ErrorBoundary>
        )
    },
    MainRoutes,
    LoginRoutes,
    AuthenticationRoutes,
    StaffRoutes,
    NotFound,
    AdminRoutes
]);

export default ThemeRoutes;
