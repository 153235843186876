import { gridSpacing, iconSize } from '../../store/constant';
import { Breadcrumbs, Card, useTheme } from '@mui/material';
import { IconChevronRight, IconHome } from '@tabler/icons-react';
import RbgLink from '../mui-wrappers/Link/RbgLink';
import RbgGrid from '../mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';

function StaticBreadcrumb({ items, standaloneItem, disableHomeLink = false, maxItems = 8, hideFirstSeparator }) {
    const theme = useTheme();
    return (
        <Card
            sx={{
                marginBottom: theme.spacing(gridSpacing),
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                background: theme.palette.background.default,
                p: 2.5,
                pl: 2
            }}
        >
            <RbgGrid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <RbgGrid item>
                    <Breadcrumbs
                        sx={{
                            '& .MuiBreadcrumbs-li:first-of-type': {
                                ...(hideFirstSeparator ? { marginRight: '10px' } : undefined)
                            },
                            '& .MuiBreadcrumbs-separator:nth-of-type(2)': { ...(hideFirstSeparator ? { display: 'none' } : undefined) },
                            '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 },
                            '& li': { display: 'flex', alignItems: 'center' }
                        }}
                        aria-label="breadcrumb"
                        maxItems={maxItems}
                        separator={<IconChevronRight />}
                    >
                        {standaloneItem}
                        {!disableHomeLink && (
                            <RbgLink
                                sx={{ height: `${iconSize}px`, color: theme.palette.secondary.main }}
                                href="/dashboard"
                                color="inherit"
                                variant="subtitle1"
                            >
                                <IconHome size={iconSize} />
                            </RbgLink>
                        )}
                        {items.map((item, index) => {
                            if (!item.label) return null;
                            if (!item.link) {
                                return (
                                    <RbgTypography key={index} sx={{ color: 'grey.500' }} variant="subtitle1">
                                        {item.label}
                                    </RbgTypography>
                                );
                            }
                            return (
                                <RbgLink
                                    key={index}
                                    sx={{ textDecoration: 'none', color: theme.palette.text.primary }}
                                    href={item.link}
                                    color="inherit"
                                    variant="subtitle1"
                                >
                                    {item.label}
                                </RbgLink>
                            );
                        })}
                    </Breadcrumbs>
                </RbgGrid>
            </RbgGrid>
        </Card>
    );
}

StaticBreadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            label: PropTypes.string
        })
    ).isRequired,
    maxItems: PropTypes.number,
    standaloneItem: PropTypes.node,
    hideFirstSeparator: PropTypes.bool,
    disableHomeLink: PropTypes.bool
};

export default StaticBreadcrumb;
