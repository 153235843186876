import ServerSidePagination from '../pagination/ServerSidePagination';
import PropTypes from 'prop-types';
import RbgGrid from '../mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../store/constant';
import RbgSimpleTable from '../mui-wrappers/Table/RbgSimpleTable';

function PageableTable({
    headers,
    data,
    rowDecorator,
    pagination,
    handlePageChange,
    topFilterContent,
    loading,
    stickyHeader,
    tableContainerSx,
    keys = []
}) {
    if (keys.length === 0) {
        keys = data.map((row) => row.id);
    }
    return (
        <RbgGrid container spacing={gridSpacing} sx={{ pt: 1 }}>
            {topFilterContent ? (
                <RbgGrid item xs={4}>
                    {topFilterContent}
                </RbgGrid>
            ) : null}
            {pagination.total_pages && (
                <RbgGrid item xs={topFilterContent ? 8 : 12}>
                    <ServerSidePagination
                        pagination={pagination}
                        changePage={handlePageChange}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    />
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    loading={loading}
                    stickyHeader={stickyHeader}
                    tableContainerSx={tableContainerSx}
                    headers={headers}
                    keys={keys}
                    rows={data.map((row) => {
                        if (rowDecorator) {
                            row = rowDecorator(row);
                        }
                        return row;
                    })}
                />
            </RbgGrid>
            {pagination.total_pages && (
                <RbgGrid item xs={12}>
                    <ServerSidePagination
                        pagination={pagination}
                        changePage={handlePageChange}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

PageableTable.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    rowDecorator: PropTypes.func.isRequired,
    topFilterContent: PropTypes.element,
    loading: PropTypes.bool,
    stickyHeader: PropTypes.bool,
    tableContainerSx: PropTypes.object,
    keys: PropTypes.array
};

export default PageableTable;
