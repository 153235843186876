import { object } from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import ScTree from './ScTree';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconRotateClockwise, IconZoomIn, IconZoomOut } from '@tabler/icons-react';

function ScDepartmentTab({ department }) {
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [scale, setScale] = useState(1);

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setStartY(e.pageY - containerRef.current.offsetTop);
        setScrollLeft(containerRef.current.scrollLeft);
        setScrollTop(containerRef.current.scrollTop);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const y = e.pageY - containerRef.current.offsetTop;
        const walkX = x - startX;
        const walkY = y - startY;
        containerRef.current.scrollLeft = scrollLeft - walkX;
        containerRef.current.scrollTop = scrollTop - walkY;
    };
    const centerTree = () => {
        const container = containerRef.current;
        const content = contentRef.current;
        if (container && content) {
            const containerWidth = container.clientWidth;
            const contentWidth = content.scrollWidth;
            container.scrollLeft = (contentWidth - containerWidth) / 2;
        }
    };
    const handleMouseUp = () => setIsDragging(false);
    const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.3, 2)); // Max scale 2
    const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.3, 0.5)); // Min scale 0.5
    const handleResetScale = () => {
        setScale(1);
        centerTree();
    };

    useLayoutEffect(() => {
        if (!department) return;
        centerTree();
    }, [department]);

    return (
        <MainCard
            sx={{ height: '74.5vh' }}
            contentStyles={{ overflow: 'hidden', height: '100%' }}
            title=" "
            secondary={
                <RbgStack direction="row" gap={1}>
                    <RbgButton onClick={handleZoomIn} icon={<IconZoomIn />} tooltip="Zoom In" />
                    <RbgButton onClick={handleResetScale} icon={<IconRotateClockwise />} tooltip="Reset" />
                    <RbgButton onClick={handleZoomOut} icon={<IconZoomOut />} tooltip="Zoom Out" />
                </RbgStack>
            }
        >
            <div
                ref={containerRef}
                id="family-tree-container"
                role="button"
                tabIndex="0"
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    cursor: isDragging ? 'grabbing' : 'grab',
                    position: 'relative'
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <div
                    ref={contentRef}
                    style={{
                        minWidth: '1000vw',
                        transform: `scale(${scale})`,
                        transformOrigin: 'top',
                        transition: 'transform 0.2s ease'
                    }}
                >
                    {department.data.map((dep, idx) => (
                        <RbgStack
                            key={idx}
                            alignItems="center"
                            sx={{
                                pb: department.data.length === idx + 1 ? 4 : 2
                            }}
                        >
                            <ScTree department={dep} />
                        </RbgStack>
                    ))}
                </div>
            </div>
        </MainCard>
    );
}

export default ScDepartmentTab;

ScDepartmentTab.propTypes = {
    department: object.isRequired
};
