import { createSlice } from '@reduxjs/toolkit';

// ==============================|| BAR SLICE ||============================== //

export const barReducer = createSlice({
    name: 'bar',
    initialState: {
        barId: '',
        bar: {},
        costCentre: '',
        showTargets: false,
        metadata: null
    },
    reducers: {
        setBarId: (state, action) => {
            state.barId = action.payload;
        },
        setBar: (state, action) => {
            state.bar = action.payload;
            state.costCentre = action.payload.cost_centre;
        },
        showTargets: (state, action) => {
            state.showTargets = action.payload;
        },
        setMetadata: (state, action) => {
            state.metadata = action.payload;
        },
        setPersonalCallUsers: (state, action) => {
            state.metadata.personal_call_users = action.payload;
        },
        clearMetadata: (state) => {
            state.metadata = null;
        }
    }
});
export const { setBarId, setBar, showTargets, setMetadata, clearMetadata, setPersonalCallUsers } = barReducer.actions;
