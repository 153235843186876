import PropTypes from 'prop-types';
import RbgTable from './RbgTable';
import RbgTableRow from '../TableRow/RbgTableRow';
import RbgTableCell from '../TableCell/RbgTableCell';
import RbgTableHead from '../TableHead/RbgTableHead';
import RbgTableBody from '../TableBody/RbgTableBody';
import RbgLoadingRow from '../TableRow/RbgLoadingRow';
import RbgTableContainer from '../TableContainer/RbgTableContainer';
import _ from 'lodash';

// mui imports

/**
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [Table API](https://mui.com/material-ui/api/table/)
 */

function RbgSimpleTable({ headers, rows, loading, error, stickyHeader, tableContainerSx = {}, emptyResultText = 'No Results', keys = [] }) {
    if (loading === true) {
        return (
            <RbgTableContainer sx={tableContainerSx}>
                <RbgTable>
                    <RbgTableHead>
                        <RbgTableRow>
                            {headers.map((header) => (
                                <RbgTableCell key={header}>{header}</RbgTableCell>
                            ))}
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        <RbgLoadingRow colSpan={headers.length} />
                    </RbgTableBody>
                </RbgTable>
            </RbgTableContainer>
        );
    }
    if (error) {
        if (error instanceof Error) {
            error = error.toString();
        }
        return (
            <RbgTableContainer sx={tableContainerSx}>
                <RbgTable>
                    <RbgTableHead>
                        <RbgTableRow>
                            {headers.map((header) => (
                                <RbgTableCell key={header}>{header}</RbgTableCell>
                            ))}
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        <RbgTableRow>
                            <RbgTableCell colSpan={headers.length}>{error}</RbgTableCell>
                        </RbgTableRow>
                    </RbgTableBody>
                </RbgTable>
            </RbgTableContainer>
        );
    }
    if (stickyHeader && !tableContainerSx.maxHeight) {
        tableContainerSx.maxHeight = '400px';
    }
    if (rows.length === 0) {
        return (
            <RbgTableContainer sx={tableContainerSx}>
                <RbgTable stickyHeader={stickyHeader}>
                    <RbgTableHead>
                        <RbgTableRow>
                            {headers.map((header) => (
                                <RbgTableCell key={header}>{header}</RbgTableCell>
                            ))}
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        <RbgTableRow>
                            <RbgTableCell colSpan={headers.length}>{emptyResultText}</RbgTableCell>
                        </RbgTableRow>
                    </RbgTableBody>
                </RbgTable>
            </RbgTableContainer>
        );
    }
    return (
        <RbgTableContainer sx={tableContainerSx}>
            <RbgTable stickyHeader={stickyHeader}>
                <RbgTableHead>
                    <RbgTableRow>
                        {headers.map((header) => (
                            <RbgTableCell key={header}>{header}</RbgTableCell>
                        ))}
                    </RbgTableRow>
                </RbgTableHead>
                <RbgTableBody>
                    {rows.map((row, rowNum) => {
                        let key = _.uniqueId('simple_table');
                        if (keys.length >= rowNum) {
                            key = keys[rowNum];
                        }
                        return (
                            <RbgTableRow key={key}>
                                {row.map((cell, cellNum) => {
                                    return <RbgTableCell key={cellNum}>{cell}</RbgTableCell>;
                                })}
                            </RbgTableRow>
                        );
                    })}
                </RbgTableBody>
            </RbgTable>
        </RbgTableContainer>
    );
}

export default RbgSimpleTable;

RbgSimpleTable.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
    stickyHeader: PropTypes.bool,
    tableContainerSx: PropTypes.object,
    keys: PropTypes.array.isRequired,
    emptyResultText: PropTypes.string
};
