import PropTypes from 'prop-types';
import { staffNotificationRule } from '../../StaffDataTypes';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import { IconTrash } from '@tabler/icons-react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { useQuery } from '@tanstack/react-query';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';

function NotificationRuleField({ rule, handleChange, deleteRule }) {
    const [field, setField] = useState(null);

    const { data: fieldData, isPending } = useQuery({
        queryKey: [{ url: '/staff/staff-fields' }]
    });
    const processChange = (e) => {
        const newRule = _.extend({}, rule);
        newRule[e.target.name] = e.target.value;
        if (e.target.name === 'field') {
            newRule.value = '';
            newRule.operator = '';
        }
        handleChange(newRule);
    };
    const type = field ? field.type : null;
    let operators = [
        { value: '=', label: 'Equal To' },
        { value: '<>', label: 'Not Equal To' },
        { value: '>', label: 'Greater Than' },
        { value: '<', label: 'Less Than' },
        { value: '>=', label: 'Greater Than Or Equal To' },
        { value: '<=', label: 'Less Than Or Equal To' },
        { value: 'in', label: 'Is One Of' },
        { value: 'not in', label: 'Is Not One Of' },
        { value: 'empty', label: 'Is Empty' },
        { value: 'not empty', label: 'Is Not Empty' }
    ];
    if (type === 'list') {
        operators = [
            { value: '=', label: 'Equal To' },
            { value: '<>', label: 'Not Equal To' },
            { value: 'in', label: 'Is One Of' },
            { value: 'not in', label: 'Is Not One Of' }
        ];
    }
    if (type === 'number') {
        operators = [
            { value: '=', label: 'Equal To' },
            { value: '<>', label: 'Not Equal To' },
            { value: '>', label: 'Greater Than' },
            { value: '<', label: 'Less Than' },
            { value: '>=', label: 'Greater Than Or Equal To' },
            { value: '<=', label: 'Less Than Or Equal To' }
        ];
    }
    if (type === 'bool') {
        operators = [{ value: '=', label: 'Equal To' }];
    }

    useEffect(() => {
        if (rule.field) {
            setField(fieldData.data.find((f) => f.field === rule.field));
        }
    }, [rule.field, fieldData]);
    return (
        <RbgTableRow>
            <RbgTableCell sx={{ width: '30%' }}>
                <RbgRemoteSelect
                    name="field"
                    endpoint="/staff/staff-fields"
                    label="Field"
                    useAutoComplete
                    filterData={(data) => {
                        return data.data.map((row) => {
                            return {
                                value: row.field,
                                label: row.label,
                                object: row
                            };
                        });
                    }}
                    handleChange={(e) => processChange(e)}
                    handleObjectChange={(o) => setField(o.target.object)}
                    value={rule.field}
                />
            </RbgTableCell>
            <RbgTableCell sx={{ width: '30%' }}>
                <RbgSelect
                    name="operator"
                    label="Operator"
                    value={rule.operator}
                    handleChange={(e) => processChange(e)}
                    values={operators}
                />
            </RbgTableCell>

            <RbgTableCell sx={{ width: '30%' }}>
                {type === 'list' && ['not in', 'in'].indexOf(rule.operator) > -1 && (
                    <RbgSelect
                        values={field.allowed_values}
                        label="Value"
                        useAutoComplete
                        name="value"
                        multiple={rule.operator === 'in' || rule.operator === 'not in'}
                        handleChange={(e) => {
                            processChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.value.join(',')
                                }
                            });
                        }}
                        value={rule.value.split(',')}
                    />
                )}
                {type === 'list' && ['not in', 'in'].indexOf(rule.operator) === -1 && (
                    <RbgSelect
                        values={field.allowed_values}
                        label="Value"
                        useAutoComplete
                        name="value"
                        multiple={false}
                        handleChange={(e) => processChange(e)}
                        value={rule.value}
                    />
                )}
                {(type === 'number' || type === 'text') && rule.operator !== 'empty' && rule.operator !== 'not empty' && (
                    <RbgTextInput type={field.type} name="value" label="Value" value={rule.value} onChange={(e) => processChange(e)} />
                )}
                {type === 'date' && (
                    <RbgDatePicker
                        type={field.type}
                        name="value"
                        label="Value"
                        value={rule.value}
                        onChange={(date) =>
                            processChange({
                                target: {
                                    name: 'value',
                                    value: date ? date.format('YYYY-MM-DD') : ''
                                }
                            })
                        }
                    />
                )}
                {type === 'bool' && (
                    <RbgStack direction="row" spacing={2} justifyContent="center">
                        <RbgCheckbox
                            name="value"
                            checked={rule.value === 1 || rule.value === '1'}
                            onChange={() => {
                                processChange({
                                    target: {
                                        name: 'value',
                                        value: 1
                                    }
                                });
                            }}
                            label="Yes"
                        />
                        <RbgCheckbox
                            name="value"
                            checked={rule.value === 0 || rule.value === '0'}
                            onChange={() => {
                                processChange({
                                    target: {
                                        name: 'value',
                                        value: 0
                                    }
                                });
                            }}
                            label="No"
                        />
                    </RbgStack>
                )}
            </RbgTableCell>
            <RbgTableCell sx={{ width: '10%' }}>
                <RbgButton onClick={() => deleteRule(rule)} color="error">
                    <IconTrash size={15} />
                </RbgButton>
            </RbgTableCell>
        </RbgTableRow>
    );
}

NotificationRuleField.propTypes = {
    rule: PropTypes.shape(staffNotificationRule),
    handleChange: PropTypes.any,
    deleteRule: PropTypes.any
};
export default NotificationRuleField;
