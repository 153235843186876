import PropTypes from 'prop-types';

// material-ui imports
import { ButtonBase, Avatar, useTheme } from '@mui/material';
import { forwardRef } from 'react';

const AvatarIconButton = forwardRef(({ handleClick, variant = 'rounded', icon, ...others }, ref) => {
    const theme = useTheme();
    const Icon = icon;
    return (
        <ButtonBase disableRipple sx={{ borderRadius: '12px' }}>
            <Avatar
                variant={variant}
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                    '&:hover': {
                        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                    }
                }}
                onClick={handleClick}
                color="inherit"
                ref={ref}
                {...others}
            >
                <Icon stroke={1.5} size="1.3rem" />
            </Avatar>
        </ButtonBase>
    );
});

AvatarIconButton.propTypes = {
    handleClick: PropTypes.func,
    variant: PropTypes.string,
    icon: PropTypes.object
};

export default AvatarIconButton;
