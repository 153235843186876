import PropTypes from 'prop-types';
import RbgTableCell from './RbgTableCell';
import RbgStack from '../Stack/RbgStack';
import RbgTypography from '../Typography/RbgTypography';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { ButtonGroup } from '@mui/material';
import RbgButton from '../Button/RbgButton';

function SortableTableHead({ text, sortField, currentSort, currentSortDir, handleSortChange }) {
    return (
        <RbgTableCell>
            <RbgStack direction="row" justifyContent="center" spacing={1}>
                <RbgTypography sx={{ fontSize: '0.85rem', fontWeight: 500, padding: '6px 16px' }}>{text}</RbgTypography>
                <ButtonGroup>
                    <RbgButton onClick={() => handleSortChange(sortField, 'asc')} variant="text">
                        <IconArrowDown color={sortField === currentSort && currentSortDir === 'asc' ? 'black' : 'grey'} size="15" />
                    </RbgButton>
                    <RbgButton onClick={() => handleSortChange(sortField, 'desc')} variant="text">
                        <IconArrowUp color={sortField === currentSort && currentSortDir === 'desc' ? 'black' : 'grey'} size="15" />
                    </RbgButton>
                </ButtonGroup>
            </RbgStack>
        </RbgTableCell>
    );
}

SortableTableHead.propTypes = {
    text: PropTypes.string.isRequired,
    sortField: PropTypes.string.isRequired,
    currentSort: PropTypes.string.isRequired,
    currentSortDir: PropTypes.string.isRequired,
    handleSortChange: PropTypes.func.isRequired
};

export default SortableTableHead;
