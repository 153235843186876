import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import StaffFileUpload from '../../Component/StaffFileUpload';
import MainCard from '../../../../../ui_component/cards/MainCard';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import FourthImportRow from './FourthImportRow';
import { useEffect } from 'react';
import EventDispatcher from '../../../../../utils/EventDispatcher';

function FourthImport() {
    const { data, refetch } = useQuery({
        queryKey: [{ url: '/staff/staff-admin/fourth-import' }]
    });

    const createMutation = useMutation({
        mutationFn: (fileId) => {
            return Api.reactQueryMutation('POST', '/staff/staff-admin/fourth-import', { file_id: fileId });
        },
        onSuccess: () => {
            refetch();
        }
    });
    useEffect(() => {
        setTimeout(() => {
            EventDispatcher.dispatch('pusher-subscribe', 'private-fourth_import');
        }, 200);
    }, []);

    return (
        <MainCard title="Fourth Import">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgTable>
                        <RbgTableHead>
                            <RbgTableRow>
                                <RbgTableCell>File</RbgTableCell>
                                <RbgTableCell>Date Created</RbgTableCell>
                                <RbgTableCell>Date Updated</RbgTableCell>
                                <RbgTableCell>Date Started</RbgTableCell>
                                <RbgTableCell>Date Completed</RbgTableCell>
                                <RbgTableCell>Result</RbgTableCell>
                            </RbgTableRow>
                        </RbgTableHead>
                        <RbgTableBody>
                            {data.data.map((row) => (
                                <FourthImportRow rowData={row} key={row.id} />
                            ))}
                        </RbgTableBody>
                    </RbgTable>
                </RbgGrid>
                {createMutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{createMutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <StaffFileUpload onFileUploaded={(file) => createMutation.mutate(file.id)} staffId={0} bucket="rbg-staff-documents" />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default FourthImport;
