import PropTypes from 'prop-types';
// mui imports
import { Alert } from '@mui/material';

function InfoCard({ variant = 'outlined', styles, children, ...others }) {
    return (
        <Alert severity="info" variant={variant} {...others}>
            {children}
        </Alert>
    );
}

export default InfoCard;

InfoCard.propTypes = {
    variant: PropTypes.string,
    styles: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string])
};
