import PropTypes from 'prop-types';
import { staffMember } from '../../../StaffDataTypes';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgSimpleTable from '../../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import RelativeTime from '../../../../../../ui_component/relative-time/RelativeTime';
import BooleanIcon from '../../../../../../ui_component/icons/boolean-icon';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconDownload } from '@tabler/icons-react';
import StaffFileUpload from '../../../Component/StaffFileUpload';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../../utils/api';
import CardLoader from '../../../../../../ui_component/loading/CardLoader';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import { useContext, useEffect } from 'react';
import EventDispatcher from '../../../../../../utils/EventDispatcher';
import RcloudAuthContext from '../../../../../../contexts/RcloudAuthContext';

function StaffMemberRightToWork({ staffMember }) {
    const { user } = useContext(RcloudAuthContext);

    const { data, loading, isPending, error, refetch } = useQuery({
        queryKey: [{ url: `/staff/staff-member/${staffMember.id}/rtw-checks` }]
    });
    const newRtwFileMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/rtw-check`, { file_id: id });
        },
        onSuccess: () => {
            refetch();
        }
    });
    const downloadMutation = useMutation({
        mutationFn: (check) => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/rtw-check/${check.check}/file/${check.file}`);
        },
        onSuccess: (data) => {
            window.open(data.data.uri);
        }
    });
    const queryClient = useQueryClient();
    useEffect(() => {
        const sub = EventDispatcher.subscribe('pusher-message', async (data) => {
            if (data.event === `rtw_updated_${staffMember.id}`) {
                await queryClient.invalidateQueries({
                    queryKey: { url: `/staff/staff-member/${staffMember.id}/rtw-checks` }
                });
                await refetch();
            }
        });
        return () => {
            EventDispatcher.unsubscribe(sub);
        };
    }, [refetch, staffMember.id, queryClient]);
    if (loading || isPending) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <CardLoader />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (error) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }

    const current = data.data.filter((check) => check.employment_id === staffMember.employment.data.id);
    const previous = data.data.filter((check) => check.employment_id !== staffMember.employment.data.id);
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <strong>Current Employment</strong>
            </RbgGrid>
            {downloadMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{downloadMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Date Created', 'Status', 'Date Of Check', 'Check Expires', 'Hours', 'Admin Check Required', 'Download']}
                    keys={current.map((check) => check.id)}
                    rows={current.map((check) => [
                        dayjs(check.date_created).format('DD/MM/YYYY'),
                        check.status.data.name,
                        check.check_data.date_of_check ? <RelativeTime time={check.check_data.date_of_check} /> : 'N/A',
                        check.check_data.check_expires ? <RelativeTime time={check.check_data.check_expires} /> : 'N/A',
                        check.check_data.hours,
                        <BooleanIcon value={check.check_data.admin_check_required} />,
                        check.file.data.id ? (
                            <RbgButton
                                icon={<IconDownload />}
                                disabled={downloadMutation.isPending}
                                onClick={() => {
                                    downloadMutation.mutate({ check: check.id, file: check.file.data.id });
                                }}
                            />
                        ) : (
                            'N/A'
                        )
                    ])}
                />
            </RbgGrid>
            {previous.length > 0 && (
                <>
                    <RbgGrid item xs={12}>
                        <strong>Previous Employment(s)</strong>
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <RbgSimpleTable
                            headers={[
                                'Date Created',
                                'Status',
                                'Date Of Check',
                                'Check Expires',
                                'Hours',
                                'Admin Check Required',
                                'Download'
                            ]}
                            keys={previous.map((check) => check.id)}
                            rows={previous.map((check) => [
                                dayjs(check.date_created).format('DD/MM/YYYY'),
                                check.status.data.name,
                                check.check_data.date_of_check ? <RelativeTime time={check.check_data.date_of_check} /> : 'N/A',
                                check.check_data.date_expires ? <RelativeTime time={check.check_data.date_expires} /> : 'N/A',
                                check.check_data.hours,
                                <BooleanIcon value={check.check_data.admin_check_required} />,
                                check.file.data.id ? (
                                    <RbgButton
                                        icon={<IconDownload />}
                                        disabled={downloadMutation.isPending}
                                        onClick={() => {
                                            downloadMutation.mutate({ check: check.id, file: check.file.data.id });
                                        }}
                                    />
                                ) : (
                                    'N/A'
                                )
                            ])}
                        />
                    </RbgGrid>
                </>
            )}
            {user.permissions.indexOf('hr_document_auth') > -1 && (
                <RbgGrid item xs={12}>
                    <StaffFileUpload
                        onFileUploaded={(file) => newRtwFileMutation.mutate(file.id)}
                        staffId={staffMember.id}
                        bucket="rbg-staff-documents"
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

StaffMemberRightToWork.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default StaffMemberRightToWork;
