import PropTypes from 'prop-types';
import { staffAccreditation, staffMember } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import StaffFileUpload from '../../Component/StaffFileUpload';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconDownload } from '@tabler/icons-react';

function AddEditAccreditation({ staffMember, accreditation }) {
    const [newAccreditation, setNewAccreditation] = useState(
        accreditation || {
            valid_from: dayjs().format('YYYY-MM-DD'),
            valid_to: null,
            type_id: '',
            type: { data: {} },
            files: { data: [] }
        }
    );

    const handleRemoveFile = (file) => {
        const files = newAccreditation.files.data.filter((f) => f.id !== file.id);
        setNewAccreditation((prev) => ({ ...prev, files: { data: files } }));
    };

    const mutation = useMutation({
        mutationFn: () => {
            if (accreditation?.id) {
                return Api.reactQueryMutation('PUT', `/staff/accreditation/staff/${staffMember.id}/${accreditation.id}`, newAccreditation);
            }
            return Api.reactQueryMutation('POST', `staff/accreditation/staff/${staffMember.id}`, newAccreditation);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    const deleteMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff/accreditation/staff/${staffMember.id}/${accreditation.id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    const downloadMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('GET', `/staff/accreditation/staff/${staffMember.id}/${accreditation.id}/file/${id}`);
        },
        onSuccess: (data) => {
            window.open(data.data.uri);
        }
    });

    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save');
    }, [mutation]);
    return (
        <RbgGrid container spacing={gridSpacing}>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    label="Type"
                    filterData={(data) => data.data.map((row) => ({ value: row.id, label: row.name, object: row }))}
                    endpoint="/staff/accreditation-types"
                    name="type"
                    value={newAccreditation.type.data.id}
                    handleObjectChange={(e) => {
                        setNewAccreditation((prev) => ({
                            ...prev,
                            type: { data: e.target.object ? e.target.object : {} },
                            accreditation_id: e.target.value
                        }));
                    }}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    label="Valid From"
                    value={newAccreditation.valid_from}
                    onChange={(date) =>
                        setNewAccreditation((prev) => ({
                            ...prev,
                            valid_from: date ? date.format('YYYY-MM-DD') : null
                        }))
                    }
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    label="Valid To"
                    help="Expiry Date of accreditation"
                    value={newAccreditation.valid_to}
                    onChange={(date) =>
                        setNewAccreditation((prev) => ({
                            ...prev,
                            valid_to: date ? date.format('YYYY-MM-DD') : null
                        }))
                    }
                />
            </RbgGrid>
            {newAccreditation.files.data.map((file) => {
                return (
                    <RbgGrid item xs={12} key={file.id}>
                        <RbgStack direction="row" spacing={2} alignItems="center">
                            <RbgTypography>{file.filename}</RbgTypography>
                            <DeleteConfirm onDelete={() => handleRemoveFile(file)} />
                            <RbgButton
                                icon={<IconDownload />}
                                onClick={() => downloadMutation.mutate(file.id)}
                                disabled={downloadMutation.isPending}
                            />
                        </RbgStack>
                    </RbgGrid>
                );
            })}
            {newAccreditation.type.data.requires_file && (
                <RbgGrid item xs={12}>
                    <StaffFileUpload
                        onFileUploaded={(file) =>
                            setNewAccreditation((prev) => ({
                                ...prev,
                                files: { data: [...newAccreditation.files.data, file] }
                            }))
                        }
                        staffId={0}
                        bucket="rbg-staff-documents"
                    />
                </RbgGrid>
            )}
            {newAccreditation.id && (
                <RbgGrid item xs={12}>
                    <DeleteConfirm
                        onDelete={() => deleteMutation.mutate()}
                        fullWidth
                        btnText="Delete Accreditation"
                        confirmText="Are you sure you want to delete this accreditation?"
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

AddEditAccreditation.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired,
    accreditation: PropTypes.shape(staffAccreditation)
};
export default AddEditAccreditation;
