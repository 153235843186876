import { useContext, useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import dayjs from 'dayjs';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function ScheduleRateChange({ staffMember }) {
    const [change, setChange] = useState({
        start_date: '',
        rate: staffMember.pay_rate.data.rate
    });
    const { user } = useContext(RcloudAuthContext);

    const handleFieldChange = (name, value) => {
        setChange((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/pay-rate`, change);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        const disabled = !(change.start_date && change.rate > 0 && change.rate !== staffMember.pay_rate.data.rate);

        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', disabled);
    }, [change, mutation, staffMember.pay_rate.data.rate]);
    return (
        <RbgGrid container>
            {user.permissions.indexOf('hr_staff_auth') > -1 ? (
                <RbgGrid item xs={12}>
                    <InfoCard>This pay rate change will be automatically authorised as you have permissions to do so.</InfoCard>
                </RbgGrid>
            ) : (
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        Ensure that you schedule rate changes with enough time for your area manager to approve. Issues with pay rates may
                        occur if they are not approved before the effective from date
                    </IntroCardPrimary>
                </RbgGrid>
            )}
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    name="start_date"
                    minDate={dayjs()}
                    label="Effective From Date"
                    value={change.start_date}
                    onChange={(date) => handleFieldChange('start_date', date ? date.format('YYYY-MM-DD') : '')}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    type="number"
                    name="rate"
                    label="New Rate"
                    min={staffMember.pay_rate.data.rate}
                    value={change.rate}
                    onChange={(e) => handleFieldChange('rate', e.target.value)}
                    textPrimary="£"
                />
            </RbgGrid>
        </RbgGrid>
    );
}

ScheduleRateChange.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default ScheduleRateChange;
