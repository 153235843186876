import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import MainCard from '../../../../ui_component/cards/MainCard';
import RbgFilter from '../../../../ui_component/extended/Form/RbgFilter';
import ServerSidePagination from '../../../../ui_component/pagination/ServerSidePagination';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import { DialogHelper } from '../../../../ui_component/modals/RbgDialog';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { Avatar } from '@mui/material';
import RbgLink from '../../../../ui_component/mui-wrappers/Link/RbgLink';

function CompanySearch() {
    const [filter, setFilter] = useState({
        search: '',
        location: '',
        page: 1
    });

    const { data, isPending } = useQuery({
        initialData: {
            data: [],
            meta: {
                pagination: {
                    current_page: 1
                }
            }
        },
        queryKey: [{ url: '/staff/staff-search', params: filter }]
    });

    const handlePageChange = (page) => {
        setFilter((prev) => ({ ...prev, page }));
    };

    const filters = [
        {
            name: 'search',
            type: 'text',
            label: 'Search',
            useDebounce: true,
            value: filter.search
        },
        {
            name: 'location',
            value: filter.location,
            type: 'remote_select',
            useAutoComplete: true,
            label: 'Location',
            endpoint: `/staff/locations`
        }
    ];
    if (isPending) {
        return <CardLoader />;
    }
    return (
        <MainCard title="RBG Team Member Directory">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgFilter filter={filter} onChange={(filter) => setFilter(filter)} filters={filters} />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination pagination={data.meta.pagination} changePage={(page) => handlePageChange(page)} />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={['ID', 'Image', 'Name', 'Location', 'Start Date', 'Leave Date']}
                        rows={data.data.map((row) => [
                            row.id,
                            row.image ? (
                                <RbgStack alignItems="center">
                                    <a
                                        href="#"
                                        onClick={() => {
                                            DialogHelper.openMdDialog(
                                                'Team Member Image',
                                                <RbgStack justifyContent="center" alignItems="center">
                                                    <img
                                                        src={row.image}
                                                        alt={row.display_name}
                                                        style={{ maxWidth: '400px', maxHeight: '400px' }}
                                                    />
                                                </RbgStack>
                                            );
                                        }}
                                    >
                                        <Avatar alt={row.display_name} src={row.image} />
                                    </a>
                                </RbgStack>
                            ) : (
                                'N/A'
                            ),
                            <RbgLink href={`/staff/view/${row.id}`}>{row.display_name}</RbgLink>,
                            row.location_name,
                            dayjs(row.start_date).format('DD/MM/YYYY'),
                            row.leave_date ? dayjs(row.leave_date).format('DD/MM/YYYY') : 'N/A'
                        ])}
                        keys={data.data.map((row) => row.id)}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination pagination={data.meta.pagination} changePage={(page) => handlePageChange(page)} />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default CompanySearch;
