import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import Debug from '../../../../../ui_component/misc/Debug';

function FirstAidCountsPerSite() {
    const { data, error, isPending } = useQuery({
        queryKey: [{ url: '/staff/reporting/first-aid' }]
    });

    if (error) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (isPending) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <CardLoader />
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Cost Centre', 'Name', 'Active', 'Expiring', 'Expired', 'Missing']}
                    rows={data.data.map((row) => [row.cost_centre, row.name, row.active, row.expiring, row.expired, row.missing])}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

export default FirstAidCountsPerSite;
