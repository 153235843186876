import PropTypes from 'prop-types';
import { staffMember } from '../../../StaffDataTypes';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useState } from 'react';
import IntroCardPrimary from '../../../../../../ui_component/cards/IntroCardPrimary';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgLocationSelect from '../../../../../../ui_component/location-select/RbgLocationSelect';
import RbgTypography from '../../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgRemoteSelect from '../../../../../../ui_component/remote-select/RbgRemoteSelect';
import dayjs from 'dayjs';
import RbgDatePicker from '../../../../../../ui_component/date-pickers/RbgDatePicker';
import NewRoleWizardHourlyRate from './NewRoleWizardHourlyRate';
import RbgTextInput from '../../../../../../ui_component/extended/Form/RbgTextInput';
import RbgTable from '../../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableBody from '../../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import RbgTableRow from '../../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableHead from '../../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableCell from '../../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import CurrencyFormatter from '../../../../../../utils/currencyFormatter';
import BooleanIcon from '../../../../../../ui_component/icons/boolean-icon';
import InfoCard from '../../../../../../ui_component/error/InfoCard';
import RbgIcon from '../../../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import RbgStack from '../../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTooltip from '../../../../../../ui_component/tooltip/RbgTooltip';
import { useMutation, useQuery } from '@tanstack/react-query';
import RbgSelect from '../../../../../../ui_component/extended/Form/RbgSelect';
import Api from '../../../../../../utils/api';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import StaffFileUpload from '../../../Component/StaffFileUpload';

function NewRoleWizard({ staffMember, initialData }) {
    const initialState = {
        same_location: null,
        location: null,
        location_name: null,
        pay_type_change: null,
        new_contract: null,
        hourly: null,
        pay_change: null,
        pay_type: null,
        pay_type_id: null,
        reinstate: false,
        pay_rate: null,
        contract_template: null,
        contract_change: false,
        manual_contract_file: null,
        contracted_hours: null,
        position: null,
        position_name: null,
        start_date: null,
        sc_line_manager_id: null,
        sc_department_id: null,
        sc_role_id: null
    };
    const [newRole, setNewRole] = useState({ ...initialState, ...initialData });
    console.log(newRole);
    const [salary, setSalary] = useState(staffMember.pay_rate.data.type.data.hourly === true ? '0' : staffMember.pay_rate.data.rate);
    const [contractedHours, setContractedHours] = useState(null);
    const [defaultRate, setDefaultRate] = useState(0);
    const name = staffMember.details.data.known_as ? staffMember.details.data.known_as : staffMember.details.data.forename;

    const [supportCentre, setSupportCentre] = useState({
        line_manager: {},
        department: {},
        role: {}
    });

    const { data: contractedHoursList } = useQuery({
        queryKey: [{ url: '/staff/contracts/contracted-hours' }]
    });
    const { data: locationData } = useQuery({
        queryKey: [{ url: `/staff/location/${newRole.location}` }],
        enabled: newRole.location !== null
    });
    const getContractedHourName = (hours) => {
        if (hours === undefined) {
            return 'N/A';
        }
        return contractedHoursList.data.find((c) => c.hours === hours)?.name;
    };
    const saveMutation = useMutation({
        mutationFn: () => {
            const postData = {
                ...newRole,
                position_id: newRole.position.id,
                contract_template_id: newRole.contract_template?.id,
                manual_contract_file_id: newRole.manual_contract_file ? newRole.manual_contract_file.id : null,
                contract_template: undefined,
                position: undefined
            };
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/role`, postData);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    const isCentral = locationData?.data?.type?.data?.central === true;
    const { data: scMetaData } = useQuery({
        queryKey: [{ url: '/staff/sc-meta', method: 'GET' }],
        enabled: isCentral,
        initialData: {
            data: {
                roles: {
                    data: []
                },
                departments: {
                    data: []
                },
                staff: {
                    data: []
                }
            }
        }
    });
    if (newRole.same_location === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    {newRole.reinstate ? (
                        <IntroCardPrimary>
                            Is <strong>{name}</strong> rejoining us at <strong>{staffMember.role.data.location_name}</strong>?
                        </IntroCardPrimary>
                    ) : (
                        <IntroCardPrimary>
                            Is <strong>{name}</strong> staying at <strong>{staffMember.role.data.location_name}</strong>?
                        </IntroCardPrimary>
                    )}
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton
                        fullWidth
                        onClick={() =>
                            setNewRole((prev) => ({
                                ...prev,
                                same_location: true,
                                location: staffMember.role.data.cost_centre,
                                location_name: staffMember.role.data.location_name
                            }))
                        }
                    >
                        Yes
                    </RbgButton>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton fullWidth onClick={() => setNewRole((prev) => ({ ...prev, same_location: false }))}>
                        No
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    }

    if (newRole.same_location === false && newRole.location === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        Where is <strong>{name}</strong> going to be based
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgLocationSelect
                        name="cost_centre"
                        label="Location"
                        onObjectChange={(e) =>
                            setNewRole((prev) => ({
                                ...prev,
                                location: e.target.value,
                                location_name: e.target.object.name
                            }))
                        }
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.pay_change === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        {newRole.reinstate ? (
                            <RbgTypography>
                                Is <strong>{name}&apos;s</strong> pay going to change in this new role? (Last rate:{' '}
                                <CurrencyFormatter value={staffMember.pay_rate.data.rate} />)
                            </RbgTypography>
                        ) : (
                            <RbgTypography>
                                Is <strong>{name}&apos;s</strong> pay going to change in this new role? (Current rate:{' '}
                                <CurrencyFormatter value={staffMember.pay_rate.data.rate} />)
                            </RbgTypography>
                        )}
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton
                        fullWidth
                        onClick={() =>
                            setNewRole((prev) => ({
                                ...prev,
                                pay_change: true
                            }))
                        }
                    >
                        Yes
                    </RbgButton>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton
                        fullWidth
                        onClick={() =>
                            setNewRole((prev) => ({
                                ...prev,
                                pay_change: false,
                                pay_type_change: false,
                                pay_type: staffMember.pay_rate.data.type.data.hourly ? 'hourly' : 'salary'
                            }))
                        }
                    >
                        No
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.pay_type_change === null) {
        const hourly = staffMember.pay_rate.data.type.data.hourly;
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        {hourly ? (
                            <RbgTypography>
                                Is <strong>{name}</strong> going to be transferring from <strong>Hourly</strong> to <strong>Salary</strong>{' '}
                                pay?
                            </RbgTypography>
                        ) : (
                            <RbgTypography>
                                Is <strong>{name}</strong> going to be transferring from <strong>Salary</strong> to <strong>Hourly</strong>{' '}
                                pay?
                            </RbgTypography>
                        )}
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton
                        fullWidth
                        onClick={() =>
                            setNewRole((prev) => ({
                                ...prev,
                                pay_type_change: true,
                                pay_type: hourly ? 'salary' : 'hourly'
                            }))
                        }
                    >
                        Yes
                    </RbgButton>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton
                        fullWidth
                        onClick={() =>
                            setNewRole((prev) => ({
                                ...prev,
                                pay_type_change: true,
                                pay_type: hourly ? 'hourly' : 'salary'
                            }))
                        }
                    >
                        No
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.position === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgTypography>
                            What role is <strong>{name}</strong> going to be starting? (Current: {staffMember.role.data.position.data.name})
                        </RbgTypography>
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        filterData={(data) => {
                            return data.data
                                .filter((r) => {
                                    if (newRole.pay_type === 'hourly') {
                                        return r.pay_type.data.hourly === true;
                                    }

                                    return r.pay_type.data.hourly === false;
                                })
                                .sort((r1, r2) => {
                                    if (r1.hierarchy_level === r2.hierarchy_level) {
                                        return r1.name.localeCompare(r2.name);
                                    }
                                    return r1.hierarchy_level > r2.hierarchy_level ? -1 : 1;
                                })
                                .map((r) => {
                                    return {
                                        value: r.id,
                                        label: `${r.name} (${r.pay_type.data.name})`,
                                        object: r
                                    };
                                });
                        }}
                        value={newRole.position_id}
                        label="Role"
                        help="The new role this team member will be starting"
                        endpoint="/staff/onboarding/positions"
                        endpointParameters={{ cost_centre: newRole.location }}
                        name="role_id"
                        useAutoComplete
                        handleObjectChange={(e) => {
                            setNewRole((prev) => ({ ...prev, position: e.target.object }));
                        }}
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.start_date === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        {newRole.reinstate && (
                            <RbgTypography>
                                What date will <strong>{name}</strong> be rejoining us in the new role of{' '}
                                <strong>{newRole.position.name}</strong>?
                            </RbgTypography>
                        )}
                        {newRole.new_contract && (
                            <RbgTypography>
                                What date will <strong>{name}</strong>&apos;s new contract be effective from?
                            </RbgTypography>
                        )}
                        {!newRole.reinstate && !newRole.new_contract && (
                            <RbgTypography>
                                What date will <strong>{name}</strong> be starting the new role of <strong>{newRole.position.name}</strong>?
                            </RbgTypography>
                        )}
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgDatePicker
                        name="start_date"
                        help="Start date of the new role"
                        value={newRole.start_date}
                        label="Start Date"
                        minDate={dayjs().add(-7, 'day')}
                        onChange={(date) =>
                            setNewRole((prev) => ({
                                ...prev,
                                start_date: date ? date.format('YYYY-MM-DD') : ''
                            }))
                        }
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.pay_rate === null && newRole.pay_type === 'hourly') {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        What hourly rate will <strong>{name}</strong> be on? (Current rate:{' '}
                        <CurrencyFormatter value={staffMember.pay_rate.data.rate} />)
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <NewRoleWizardHourlyRate
                        location={newRole.location}
                        dateOfBirth={staffMember.details.data.birth_date}
                        position={newRole.position.id}
                        startDate={newRole.start_date}
                        handleChange={(rate) => setNewRole((prev) => ({ ...prev, pay_rate: rate }))}
                        handleDefaultChange={(rate) => setDefaultRate(rate)}
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.pay_rate === null && newRole.pay_type === 'salary') {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        What salary will <strong>{name}</strong> be on?
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgTextInput name="salary" value={salary} onChange={(e) => setSalary(e.target.value)} textPrimary="£" />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgButton fullWidth onClick={() => setNewRole((prev) => ({ ...prev, pay_rate: salary }))}>
                        Next
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.new_contract === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgTypography>Do you want to send {name} a new contract for this role?</RbgTypography>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton fullWidth onClick={() => setNewRole((prev) => ({ ...prev, new_contract: true }))}>
                        Yes
                    </RbgButton>
                </RbgGrid>
                <RbgGrid item xs={6}>
                    <RbgButton fullWidth onClick={() => setNewRole((prev) => ({ ...prev, new_contract: false }))}>
                        No
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.new_contract === true && newRole.contracted_hours === null) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgTypography>
                        How many hours will <strong>{name}&apos;s</strong> contract be for? (Current:{' '}
                        {getContractedHourName(staffMember.contract.data.contracted_hours)})
                    </RbgTypography>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSelect
                        values={contractedHoursList.data.map((c) => ({
                            value: c.id,
                            label: c.label,
                            object: c
                        }))}
                        name="contracted_hours"
                        label="Contracted Hours"
                        value={newRole.contracted_hours !== null ? newRole.contracted_hours : ''}
                        handleObjectChange={(e) => setContractedHours(e.target.object)}
                        handleChange={(e) => setNewRole((prev) => ({ ...prev, contracted_hours: e.target.value }))}
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (newRole.new_contract === true && !newRole.contract_template) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgTypography>What contract do you want to send {name}</RbgTypography>
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint={`/staff/contracts/templates?location=${newRole.location}&contracted_hours=${newRole.contracted_hours}&position=${newRole.position.id}`}
                        label="Contract Type"
                        handleObjectChange={(e) => {
                            setNewRole((prev) => ({ ...prev, contract_template: e.target.object }));
                        }}
                        value={newRole.contract_template?.id}
                        name="template_id"
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (
        newRole.new_contract === true &&
        newRole.contract_template &&
        newRole.contract_template.allow_manual_upload &&
        !newRole.manual_contract_file
    ) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <StaffFileUpload
                        staffId={staffMember.id}
                        onFileUploaded={(f) => setNewRole((prev) => ({ ...prev, manual_contract_file: f }))}
                        bucket="rbg-staff-documents"
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (isCentral === true && (!newRole.sc_role_id || !newRole.sc_department_id || !newRole.sc_line_manager_id)) {
        const roles = scMetaData.data.roles.data;
        const departments = scMetaData.data.departments.data;
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgTypography>
                            What role will <strong>{name}</strong> be performing?
                        </RbgTypography>
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item sm={12} lg={4}>
                    <RbgSelect
                        name="role"
                        label="Role"
                        useAutoComplete
                        value={newRole.sc_role_id}
                        handleChange={(e) => setNewRole((prev) => ({ ...prev, sc_role_id: e.target.value }))}
                        handleObjectChange={(e) => setSupportCentre((prev) => ({ ...prev, role: e.target.object }))}
                        values={roles.map((role) => ({ value: role.id, label: role.name, object: role }))}
                    />
                </RbgGrid>
                <RbgGrid item sm={12} lg={4}>
                    <RbgSelect
                        name="department"
                        label="Department"
                        useAutoComplete
                        value={newRole.sc_department_id ?? ''}
                        handleChange={(e) => setNewRole((prev) => ({ ...prev, sc_department_id: e.target.value }))}
                        handleObjectChange={(e) =>
                            setSupportCentre((prev) => ({
                                ...prev,
                                department: e.target.object
                            }))
                        }
                        values={departments.map((role) => ({ value: role.id, label: role.name, object: role }))}
                    />
                </RbgGrid>
                <RbgGrid item sm={12} lg={4}>
                    <RbgSelect
                        name="line_manager"
                        label="Line Manager"
                        useAutoComplete
                        value={newRole.sc_line_manager_id ?? ''}
                        handleChange={(e) => setNewRole((prev) => ({ ...prev, sc_line_manager_id: e.target.value }))}
                        handleObjectChange={(e) =>
                            setSupportCentre((prev) => ({
                                ...prev,
                                line_manager: e.target.object
                            }))
                        }
                        values={scMetaData.data.staff.data.map((role) => ({
                            value: role.id,
                            label: role.name,
                            object: role
                        }))}
                    />
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>Please check all of this information below is correct before saving new role</InfoCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTable>
                    <RbgTableHead>
                        <RbgTableRow>
                            <RbgTableCell />
                            <RbgTableCell>Previous</RbgTableCell>
                            <RbgTableCell>New</RbgTableCell>
                        </RbgTableRow>
                    </RbgTableHead>
                    <RbgTableBody>
                        <RbgTableRow>
                            <RbgTableCell>Role</RbgTableCell>
                            <RbgTableCell>{staffMember.role.data.position.data.name}</RbgTableCell>
                            <RbgTableCell>{newRole.position.name}</RbgTableCell>
                        </RbgTableRow>
                        <RbgTableRow>
                            <RbgTableCell>Pay Rate</RbgTableCell>
                            <RbgTableCell>
                                <CurrencyFormatter value={staffMember.pay_rate.data.rate} />
                            </RbgTableCell>
                            <RbgTableCell>
                                <RbgStack direction="row" justifyContent="center" alignItems="center">
                                    <CurrencyFormatter value={newRole.pay_rate} />
                                    {newRole.pay_rate !== defaultRate && (
                                        <RbgTooltip title="This will require authorisation from your area manager / pod director">
                                            <RbgIcon name="warning" color="warning" />
                                        </RbgTooltip>
                                    )}
                                </RbgStack>
                            </RbgTableCell>
                        </RbgTableRow>
                        {newRole.same_location === false && (
                            <RbgTableRow>
                                <RbgTableCell>Location</RbgTableCell>
                                <RbgTableCell>{staffMember.role.data.location_name}</RbgTableCell>
                                <RbgTableCell>{newRole.location_name}</RbgTableCell>
                            </RbgTableRow>
                        )}
                        <RbgTableRow>
                            <RbgTableCell>Contracted Hours</RbgTableCell>
                            <RbgTableCell>{staffMember.contract.data.contracted_hours}</RbgTableCell>
                            <RbgTableCell>
                                <RbgStack direction="row" justifyContent="center" alignItems="center">
                                    <RbgTypography>{contractedHours?.hours}</RbgTypography>
                                    {contractedHours?.hours > 0 &&
                                        contractedHours?.hours !== staffMember.contract.data.contracted_hours && (
                                            <RbgTooltip title="This will require authorisation from your area manager / pod director">
                                                <RbgIcon name="warning" color="warning" />
                                            </RbgTooltip>
                                        )}
                                </RbgStack>
                            </RbgTableCell>
                        </RbgTableRow>
                        <RbgTableRow>
                            <RbgTableCell>Send New Contract</RbgTableCell>
                            <RbgTableCell colSpan={2}>
                                <BooleanIcon value={newRole.new_contract} />
                            </RbgTableCell>
                        </RbgTableRow>
                        {newRole.manual_contract_file && (
                            <RbgTableRow>
                                <RbgTableCell>New Contract File</RbgTableCell>
                                <RbgTableCell colSpan={2}>{newRole.manual_contract_file.original_filename}</RbgTableCell>
                            </RbgTableRow>
                        )}
                        <RbgTableRow>
                            <RbgTableCell>Start Date</RbgTableCell>
                            <RbgTableCell colSpan={2}>
                                {dayjs(newRole.start_date).fromNow()} ({dayjs(newRole.start_date).format('DD/MM/YYYY')})
                            </RbgTableCell>
                        </RbgTableRow>
                        {supportCentre.role && (
                            <RbgTableRow>
                                <RbgTableCell>Support Centre Role</RbgTableCell>
                                <RbgTableCell colSpan={2}>{supportCentre.role.name}</RbgTableCell>
                            </RbgTableRow>
                        )}
                        {supportCentre.department && (
                            <RbgTableRow>
                                <RbgTableCell>Support Centre Department</RbgTableCell>
                                <RbgTableCell colSpan={2}>{supportCentre.department.name}</RbgTableCell>
                            </RbgTableRow>
                        )}
                        {supportCentre.line_manager && (
                            <RbgTableRow>
                                <RbgTableCell>Support Centre Line Manager</RbgTableCell>
                                <RbgTableCell colSpan={2}>{supportCentre.line_manager.name}</RbgTableCell>
                            </RbgTableRow>
                        )}
                    </RbgTableBody>
                </RbgTable>
            </RbgGrid>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgButton
                    color="success"
                    fullWidth
                    variant="contained"
                    onClick={() => {
                        saveMutation.mutate();
                    }}
                >
                    Save new role
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

NewRoleWizard.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired,
    initialData: PropTypes.object
};
export default NewRoleWizard;
