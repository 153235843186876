import PropTypes from 'prop-types';
import { IconCheck, IconX } from '@tabler/icons-react';

function BooleanIcon({ value, onClick }) {
    return value ? <IconCheck color="green" onClick={onClick} /> : <IconX color="red" onClick={onClick} />;
}

BooleanIcon.propTypes = {
    value: PropTypes.bool.isRequired,
    onClick: PropTypes.func
};
export default BooleanIcon;
