import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { MenuItem } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Menu](https://mui.com/material-ui/react-menu/)
 *
 * API:
 *
 * - [MenuItem API](https://mui.com/material-ui/api/menu-item/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */

const RbgMenuItem = forwardRef(({ children, ...others }, ref) => (
    <MenuItem ref={ref} {...others}>
        {children}
    </MenuItem>
));

export default RbgMenuItem;

RbgMenuItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node, PropTypes.string])
};
