import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// project imports
import { menuType, presetColors, setMenu } from 'store/reducers/customizationReducer';
import EventDispatcher from '../utils/EventDispatcher';
import Loader from '../ui_component/loading/Loader';
import Api from '../utils/api';

const RcloudContext = createContext(null);

export function RcloudProvider({ children }) {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const login = async () => {
        console.log('Login');
    };

    const logout = useCallback(() => {
        localStorage.clear();
        window.location.href = '/rcloud';
        setUser(null);
        setIsLoggedIn(false);
    }, []);

    const register = async () => {
        console.log('Register');
    };
    const resetPassword = async () => {
        console.log('resetPassword');
    };
    const updateProfile = async () => {
        console.log('updateProfile');
    };

    useEffect(() => {
        const loadPreferences = (prefs) => {
            prefs.forEach((p) => {
                switch (p.name) {
                    case 'theme':
                        dispatch(menuType(p.value));
                        break;
                    case 'presetColor':
                        dispatch(presetColors(p.value));
                        break;
                    case 'sidebarOpen':
                        if (p.value === '1') {
                            dispatch(setMenu(true));
                        } else {
                            dispatch(setMenu(false));
                        }
                        break;
                    // no default
                }
            });
        };

        EventDispatcher.subscribe('logout', () => logout());
        const userData = JSON.parse(window.localStorage.getItem('user'));
        if (!userData || !userData.id) {
            Api.get('/rest/me').then((response) => {
                if (response.ok) {
                    setUser(response.data.data);
                    setIsLoggedIn(true);
                    setLoaded(true);
                    loadPreferences(userData.preferences);
                }
            });
        } else if (userData && userData.id) {
            const prefs = userData.preferences ? userData.preferences : [];
            loadPreferences(prefs);
            setUser(userData);
            setIsLoggedIn(true);
            setLoaded(true);
        } else {
            logout();
            setLoaded(true);
        }
    }, [dispatch, logout]);

    useEffect(() => {
        const subUpdate = EventDispatcher.subscribe('pusher-message', (message) => {
            if (message.event === 'user-update') {
                Api.get('/rest/me').then((response) => {
                    if (response.ok) {
                        setUser(response.data.data);
                        window.localStorage.setItem('user', JSON.stringify(response.data.data));
                    }
                });
            }
        });
        return () => {
            EventDispatcher.unsubscribe(subUpdate);
        };
    }, []);

    useEffect(() => {
        const id = EventDispatcher.subscribe('preference-update', (event) => {
            const userData = JSON.parse(JSON.stringify(user));
            if (userData) {
                let found = false;
                userData.preferences = userData.preferences.map((pref) => {
                    if (pref.name === event.preference) {
                        found = true;
                        if (pref.value !== event.value) {
                            pref.value = event.value;
                        }
                    }
                    return pref;
                });
                if (found === false) {
                    userData.preferences.push({
                        name: event.preference,
                        value: event.value
                    });
                }
                setUser(userData);
                setIsLoggedIn(true);
                setLoaded(true);
            }
        });
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [user]);

    useEffect(() => {
        window.localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    useEffect(() => {
        if (loaded === true && isLoggedIn === false) {
            logout();
        }
    }, [isLoggedIn, loaded, logout]);

    if (loaded === false) {
        return <Loader />;
    }
    return (
        <RcloudContext.Provider
            value={{
                user,
                isLoggedIn,
                login,
                logout,
                register,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </RcloudContext.Provider>
    );
}

RcloudProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default RcloudContext;
