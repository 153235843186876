import PropTypes from 'prop-types';
// mui imports
import { TableHead } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TableHead API](https://mui.com/material-ui/api/table-head/)
 */

function RbgTableHead({ children, ...others }) {
    return <TableHead {...others}>{children}</TableHead>;
}

export default RbgTableHead;

RbgTableHead.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
