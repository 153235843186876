import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { TableContainer } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TableContainer API](https://mui.com/material-ui/api/table-container/)
 */

const RbgTableContainer = forwardRef(({ children, ...others }, ref) => (
    <TableContainer ref={ref} {...others}>
        {children}
    </TableContainer>
));

export default RbgTableContainer;

RbgTableContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
