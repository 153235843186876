import PropTypes from 'prop-types';
// mui wrappers
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
// third party imports
// project imports
import { gridSpacing } from 'store/constant';
import ContactGroup from './Groups/ContactGroup';
import BankGroup from './Groups/BankGroup';
import DetailsGroup from './Groups/DetailsGroup';
import { staffDetails } from '../../StaffDataTypes';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function StaffDetails({ staffDetails }) {
    // redux
    const { user } = useContext(RcloudAuthContext);

    const editPermissions = user.permissions.indexOf('hr_staff_edit') > -1;
    const getError = (field, errors) => {
        const error = errors.find((err) => err.field === field);
        if (error) {
            return error.error;
        }
        return '';
    };

    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12} md={6} lg={4}>
                <DetailsGroup staffDetails={staffDetails} getError={getError} hasEditPermissions={editPermissions} />
            </RbgGrid>
            <RbgGrid item xs={12} md={6} lg={4}>
                <ContactGroup staffDetails={staffDetails} getError={getError} hasEditPermissions={editPermissions} />
            </RbgGrid>
            <RbgGrid item xs={12} md={6} lg={4}>
                <BankGroup staffDetails={staffDetails} getError={getError} hasEditPermissions={editPermissions} />
            </RbgGrid>
        </RbgGrid>
    );
}

StaffDetails.propTypes = {
    staffDetails: PropTypes.shape(staffDetails)
};

export default StaffDetails;
