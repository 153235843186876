import { useQuery } from '@tanstack/react-query';
import RbgSelect from '../extended/Form/RbgSelect';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function RbgLocationSelect({
    name,
    label,
    value,
    onChange,
    onObjectChange,
    includeCentral = true,
    onlyAssignedLocations = false,
    ...others
}) {
    const { data } = useQuery({
        queryKey: [{ url: onlyAssignedLocations ? '/staff/my-locations' : '/staff/locations' }]
    });
    let locations = data.data;
    const processChange = (e) => {
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: e.target.value
                }
            });
        }
        if (onObjectChange) {
            const object = locations.find((location) => location.cost_centre === e.target.value);
            onObjectChange({
                target: {
                    name,
                    value: e.target.value,
                    object
                }
            });
        }
    };
    locations = locations.sort((location1, location2) => {
        if (location1.brand.data.name === location2.brand.data.name) {
            return location1.name.localeCompare(location2.name);
        }
        return location1.brand.data.id > location2.brand.data.id ? 1 : -1;
    });

    return (
        <RbgSelect
            mame={name}
            useAutoComplete
            groupBy={(opt) => opt.brand}
            values={locations.map((location) => ({
                value: location.cost_centre,
                label: location.name,
                area_manager_id: location.area_manager_id,
                brand: location.brand.data.name
            }))}
            label={label}
            value={value}
            handleChange={processChange}
            {...others}
        />
    );
}

RbgLocationSelect.propTypes = {
    onlyAssignedLocations: PropTypes.bool,
    includeCentral: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
    onObjectChange: PropTypes.func
};

export default RbgLocationSelect;
