import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Debug from '../../../../../../ui_component/misc/Debug';
import RbgStack from '../../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import CardLoader from '../../../../../../ui_component/loading/CardLoader';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import { useEffect, useState } from 'react';
import RbgTextInput from '../../../../../../ui_component/extended/Form/RbgTextInput';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import WarningCard from '../../../../../../ui_component/error/WarningCard';

function NewRoleWizardHourlyRate({ location, dateOfBirth, position, startDate, handleChange, handleDefaultChange }) {
    const [rate, setRate] = useState('');
    const [override, setOverride] = useState(false);
    const { data, error, isPending, isLoading } = useQuery({
        initialData: {
            data: { rate: 0 }
        },
        queryKey: [
            {
                url: '/staff/onboarding/pay-rate',
                params: {
                    cost_centre: location,
                    date_of_birth: dateOfBirth,
                    position_id: position,
                    date: startDate
                }
            }
        ]
    });

    useEffect(() => {
        if (handleDefaultChange) {
            handleDefaultChange(data.data.rate);
        }
    }, [data.data.rate, handleDefaultChange]);
    if (isPending || isLoading) {
        return <CardLoader />;
    }
    if (error) {
        return (
            <RbgStack>
                <ErrorCard>{error}</ErrorCard>
            </RbgStack>
        );
    }
    if (override === false) {
        return (
            <RbgStack gap={2}>
                <RbgTextInput
                    name="rate"
                    value={data.data.rate}
                    onChange={(e) => setRate(e.target.value)}
                    disabled
                    textPrimary="£"
                    textSecondary={
                        <RbgButton
                            color="warning"
                            variant="outlined"
                            onClick={() => {
                                setOverride(true);
                                setRate(data.data.rate);
                            }}
                        >
                            Change
                        </RbgButton>
                    }
                />
                <RbgButton
                    onClick={() => {
                        handleChange(data.data.rate);
                    }}
                >
                    Next
                </RbgButton>
            </RbgStack>
        );
    }
    return (
        <RbgStack gap={2}>
            <RbgTextInput
                name="rate"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
                textPrimary="£"
                autofocus
                textSecondary={
                    <RbgButton
                        color="error"
                        variant="outlined"
                        onClick={() => {
                            setOverride(false);
                            setRate(data.data.rate);
                        }}
                    >
                        Cancel Override
                    </RbgButton>
                }
            />
            <WarningCard>Note: This will still go through the standard pay rate approval process</WarningCard>
            <RbgButton
                onClick={() => {
                    handleChange(parseFloat(rate, 10));
                }}
            >
                Save
            </RbgButton>
        </RbgStack>
    );
}

NewRoleWizardHourlyRate.propTypes = {
    location: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleDefaultChange: PropTypes.func
};

export default NewRoleWizardHourlyRate;
