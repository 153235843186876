import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// mui
import RbgPaper from '../mui-wrappers/Paper/RbgPaper';
import { useTheme } from '@mui/material';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';
import RbgPopper from '../popper/RbgPopper';
import RbgTooltip from '../tooltip/RbgTooltip';
import RbgButton from '../mui-wrappers/Button/RbgButton';
// 3rd party
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { IconExclamationCircle, IconPlus } from '@tabler/icons-react';
import RbgSimpleTable from '../mui-wrappers/Table/RbgSimpleTable';
import { useDispatch } from 'react-redux';

/**
 *
 * Demos & API:
 *
 * - [SunEditor](https://github.com/mkhstar/suneditor-react)
 *
 */

export default function RbgEditor({ name, setContents, onChange, value, label, help, required, height = '400px', variables, ...others }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const sunRefEditor = useRef();

    const handleToggleVariables = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleChange = (content) => {
        const event = {
            target: {
                name,
                value: content
            }
        };
        onChange(event);
    };

    const handleCopyVariable = async (textToCopy) => {
        sunRefEditor.current.insertHTML(textToCopy);
    };

    const getSunEditorInstance = (sunEditor) => {
        sunRefEditor.current = sunEditor;
    };

    return (
        <RbgPaper
            sx={{
                p: 1,
                pt: 1.5,
                border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.main}`,
                position: 'relative'
            }}
        >
            <RbgTypography sx={{ position: 'absolute', top: '-8px', background: theme.palette.background.paper }}>
                {label}
                {required && (
                    <RbgTypography color="red" variant="span">
                        *
                    </RbgTypography>
                )}
            </RbgTypography>
            <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                setDefaultStyle={` 
                    background-color: ${theme.palette.background.paper};
                    color: ${theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900]};
                `}
                name={name}
                onChange={handleChange}
                defaultValue={value}
                height={height}
                {...others}
            />
            {variables && (
                <>
                    <RbgButton
                        ref={anchorRef}
                        onClick={handleToggleVariables}
                        variant="contained"
                        sx={{ position: 'absolute', top: 18, right: 16, zIndex: 5 }}
                        icon={
                            <RbgTooltip title="Variables">
                                <IconExclamationCircle size={20} />
                            </RbgTooltip>
                        }
                    />
                    <RbgPopper
                        popperStyles={{ zIndex: 2 }}
                        popperPlacement="bottom"
                        transitionPosition="top"
                        open={open}
                        setOpen={setOpen}
                        ref={anchorRef}
                        disablePortal
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [-20, 20]
                                    }
                                }
                            ]
                        }}
                    >
                        <RbgPaper sx={{ p: 1 }}>
                            <RbgSimpleTable
                                headers={['Insert', 'Variable', 'Description']}
                                keys={variables.map((row) => row.variable)}
                                rows={variables.map((row) => [
                                    <RbgButton
                                        variant="contained"
                                        color="primary"
                                        icon={<IconPlus size={18} />}
                                        onClick={() => handleCopyVariable(row.variable)}
                                    />,
                                    row.variable,
                                    row.description
                                ])}
                            />
                        </RbgPaper>
                    </RbgPopper>
                </>
            )}
        </RbgPaper>
    );
}

RbgEditor.propTypes = {
    name: PropTypes.string,
    setContents: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.string,
    required: PropTypes.bool,
    height: PropTypes.string,
    variables: PropTypes.arrayOf(
        PropTypes.shape({
            variable: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired
        })
    )
};
