import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import RbgTooltip from '../tooltip/RbgTooltip';

function RelativeTime({ time, prefix, suffix }) {
    return (
        <RbgTooltip title={dayjs(time).format('DD/MM/YYYY HH:mm:ss')}>
            {prefix}
            {dayjs(time).fromNow()}
            {suffix}
        </RbgTooltip>
    );
}

RelativeTime.propTypes = {
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    prefix: PropTypes.string,
    suffix: PropTypes.string
};

export default RelativeTime;
