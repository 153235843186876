import PropTypes from 'prop-types';
import './RbgTextArea.scss';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import RbgTooltip from '../tooltip/RbgTooltip';
import { IconInfoCircle } from '@tabler/icons-react';
import { forwardRef } from 'react';

const RbgTextArea = forwardRef(
    (
        { placeholder, value, name, onChange, required, label, error, maxRows, minRows, help, warning, resize = 'both', disabled, sx },
        ref
    ) => {
        const theme = useTheme();
        const defaultStyles = {
            '.MuiInputBase-root': help ? { padding: '0 0 0 14px' } : {},
            '.MuiInputBase-inputMultiline': {
                resize: `${resize} !important`,
                ...(help ? { minWidth: 'calc(100% - 50px) !important' } : {})
            },
            ...sx
        };
        const inputStyles =
            warning && !error
                ? {
                      '.MuiFormLabel-root': { color: `${theme.palette.warning.dark} !important` },
                      '.MuiFormHelperText-root': warning && !error ? { color: theme.palette.warning.dark } : {},
                      fieldset:
                          warning && !error ? { borderColor: theme.palette.warning.dark, outlineColor: theme.palette.warning.dark } : {},
                      ...defaultStyles
                  }
                : defaultStyles;
        return (
            <TextField
                className="rbg-textarea"
                sx={inputStyles}
                error={!!error}
                helperText={error || warning || null}
                variant="outlined"
                fullWidth
                multiline
                placeholder={placeholder}
                value={value}
                name={name}
                label={label}
                required={required}
                onChange={onChange}
                maxRows={maxRows}
                minRows={minRows}
                disabled={disabled}
                inputRef={ref}
                InputProps={{
                    startAdornment: help && (
                        <InputAdornment position="start">
                            <RbgTooltip title={help}>
                                <IconInfoCircle color={theme.palette.info.main} />
                            </RbgTooltip>
                        </InputAdornment>
                    )
                }}
            />
        );
    }
);

RbgTextArea.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    sx: PropTypes.object,
    required: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    help: PropTypes.string,
    warning: PropTypes.string,
    resize: PropTypes.oneOf(['both', 'horizontal', 'vertical']),
    disabled: PropTypes.bool
};

export default RbgTextArea;
