import PropTypes from 'prop-types';
import { staffDocument } from '../../StaffDataTypes';
import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import dayjs from 'dayjs';
import { filesize } from 'filesize';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import { useState } from 'react';
import DocumentHistory from './DocumentHistory';

function DocumentInfo({ document }) {
    const info = (
        <RbgTable>
            <RbgTableBody>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>ID</strong>
                    </RbgTableCell>
                    <RbgTableCell>{document.id}</RbgTableCell>
                </RbgTableRow>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>Added By</strong>
                    </RbgTableCell>
                    <RbgTableCell>{document.added_by_name}</RbgTableCell>
                </RbgTableRow>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>Added Date</strong>
                    </RbgTableCell>
                    <RbgTableCell>{dayjs(document.added_date).format('DD/MM/YYYY HH:mm')}</RbgTableCell>
                </RbgTableRow>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>Document Type</strong>
                    </RbgTableCell>
                    <RbgTableCell>
                        {document.type.data.name} ({document.type.data.id})
                    </RbgTableCell>
                </RbgTableRow>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>Category</strong>
                    </RbgTableCell>
                    <RbgTableCell>
                        {document.type.data.category.data.name} ({document.type.data.category.data.id})
                    </RbgTableCell>
                </RbgTableRow>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>File</strong>
                    </RbgTableCell>
                    <RbgTableCell>{document.file.data.filename}</RbgTableCell>
                </RbgTableRow>
                <RbgTableRow>
                    <RbgTableCell>
                        <strong>File Size</strong>
                    </RbgTableCell>
                    <RbgTableCell>{filesize(document.file.data.size)}</RbgTableCell>
                </RbgTableRow>
            </RbgTableBody>
        </RbgTable>
    );

    const tabs = [
        {
            value: 'info',
            label: 'Document Info',
            content: info
        },
        {
            value: 'history',
            label: 'History',
            content: <DocumentHistory document={document} />
        }
    ];

    const [tab, setCurrentTab] = useState('info');
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <RbgTabs tabs={tabs} currentTab={tab} setCurrentTab={(tab) => setCurrentTab(tab)} />
            </RbgGrid>
        </RbgGrid>
    );
}

DocumentInfo.propTypes = {
    document: PropTypes.shape(staffDocument).isRequired
};
export default DocumentInfo;
