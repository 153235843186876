import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import dayjs from 'dayjs';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { useState } from 'react';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';

function StaffSystemLog({ id }) {
    const [page, setPage] = useState(1);
    const { data, isPending, isLoading, error, isFetched } = useQuery({
        queryKey: [{ url: `staff/staff-member/${id}/notification-log`, params: { page } }]
    });
    const showLoading = isPending || isLoading || !isFetched;
    return (
        <RbgGrid container>
            {error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            )}
            {showLoading ? (
                <RbgGrid item xs={12}>
                    <CardLoader />
                </RbgGrid>
            ) : (
                <RbgGrid item xs={12}>
                    <PageableTable
                        handlePageChange={(page) => setPage(page)}
                        pagination={data.meta.pagination}
                        headers={['Date', 'Type', 'Task Name', 'Target', 'Status']}
                        data={data.data}
                        rowDecorator={(row) => {
                            const statusItems = [];
                            if (row.task_picked_up) {
                                statusItems.push({
                                    name: 'Task Picked Up',
                                    value: `Task Picked Up: ${dayjs(row.task_picked_up).format('DD/MM/YYYY HH:mm:ss')}`
                                });
                            }
                            if (row.task_finished) {
                                statusItems.push({
                                    name: 'Task Finished',
                                    value: `Task Finished: ${dayjs(row.task_finished).format('DD/MM/YYYY HH:mm:ss')}`
                                });
                            }
                            if (row.task_error) {
                                statusItems.push({
                                    name: 'Task Error',
                                    value: `Task Error: ${row.task_error}`
                                });
                            }
                            if (row.task_status !== null) {
                                statusItems.push({
                                    name: 'Task Status',
                                    value: `Task Status: ${JSON.stringify(row.task_status)}`
                                });
                            }
                            if (row.email_date_sent) {
                                statusItems.push({
                                    name: 'Email Date Sent',
                                    value: `Email Date Sent: ${dayjs(row.task_picked_up).format('DD/MM/YYYY HH:mm:ss')}`
                                });
                            }
                            if (row.email_status) {
                                statusItems.push({
                                    name: 'Email Status',
                                    value: `Email Status: ${row.email_status}`
                                });
                            }
                            return [
                                dayjs(row.date).format('DD/MM/YYYY HH:mm:ss'),
                                row.type_name,
                                row.task_name,
                                row.target,
                                <RbgStack>
                                    {statusItems.map((item) => (
                                        <RbgTypography key={item.name}>{item.value}</RbgTypography>
                                    ))}
                                </RbgStack>
                            ];
                        }}
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

StaffSystemLog.propTypes = {
    id: PropTypes.number.isRequired
};
export default StaffSystemLog;
