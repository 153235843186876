import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextArea from '../../../../../ui_component/text-area/RbgTextArea';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus, IconX } from '@tabler/icons-react';
import StaffFileUpload from '../../Component/StaffFileUpload';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import _ from 'lodash';

function AddDisciplinary({ staffMember }) {
    const [type, setType] = useState(null);
    const [disciplinary, setDisciplinary] = useState({
        staff_id: staffMember.id,
        location_id: staffMember.details.data.location,
        type_id: '',
        date: dayjs().format('YYYY-MM-DD'),
        details: '',
        reason_id: '',
        expiry_date: dayjs().add(6, 'month').format('YYYY-MM-DD'),
        files: [],
        attendees: []
    });

    const addAttendee = useCallback(() => {
        const attendees = disciplinary.attendees;
        attendees.push({
            id: _.uniqueId('new'),
            name: '',
            role: ''
        });
        setDisciplinary({ ...disciplinary, attendees });
    }, [disciplinary]);

    const handleAttendeeFieldChange = useCallback(
        (field, index, value) => {
            const attendees = disciplinary.attendees;
            attendees[index][field] = value;
            setDisciplinary((prev) => ({ ...prev, attendees }));
        },
        [disciplinary]
    );

    const handleAttendeeDelete = useCallback(
        (index) => {
            const attendees = disciplinary.attendees;
            attendees.splice(index, 1);
            setDisciplinary({ ...disciplinary, attendees });
        },
        [disciplinary]
    );

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/disciplinary', disciplinary);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate());
    }, [mutation]);

    const getError = (field) => {
        if (mutation.error && mutation.error.fields) {
            return mutation.error.fields
                .filter((err) => {
                    return err.field === field;
                })
                .map((err) => err.error)
                .join(', ');
        }
        return null;
    };

    return (
        <div>
            <RbgGrid container spacing={gridSpacing}>
                {mutation.error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{mutation.error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint="/staff/disciplinary/reasons"
                        name="Reasons"
                        useAutoComplete
                        label="Reason"
                        value={disciplinary.reason_id}
                        handleChange={(e) => setDisciplinary((prev) => ({ ...prev, reason_id: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        filterData={(response) => {
                            return response.data
                                .filter((entry) => entry.archived === false)
                                .map((entry) => {
                                    return {
                                        value: entry.id,
                                        label: entry.name,
                                        object: entry
                                    };
                                });
                        }}
                        error={getError('type_id')}
                        label="Disciplinary Outcome"
                        value={disciplinary.type_id}
                        endpoint="/staff/disciplinary/types"
                        name="type_id"
                        handleObjectChange={(e) => setType(e.target.object)}
                        handleChange={(e) => setDisciplinary((prev) => ({ ...prev, type_id: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgDatePicker
                        label="Date"
                        name="date"
                        error={getError('date')}
                        value={disciplinary.date}
                        onChange={(date) =>
                            setDisciplinary({
                                ...disciplinary,
                                date: date ? date.format('YYYY-MM-DD') : null
                            })
                        }
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgStack>
                        {disciplinary.attendees.length > 0 && (
                            <RbgSimpleTable
                                headers={['Name', 'Role', '']}
                                keys={disciplinary.attendees.map((row) => `${row.id}`)}
                                rows={disciplinary.attendees.map((att, index) => {
                                    return [
                                        <RbgTextInput
                                            name={`att_name_${index}`}
                                            value={att.name}
                                            onChange={(e) => handleAttendeeFieldChange('name', index, e.target.value)}
                                        />,
                                        <RbgTextInput
                                            name={`att_role_${index}`}
                                            value={att.role}
                                            onChange={(e) => handleAttendeeFieldChange('role', index, e.target.value)}
                                        />,
                                        <RbgButton icon={<IconX />} onClick={() => handleAttendeeDelete(index)} />
                                    ];
                                })}
                            />
                        )}
                        <RbgButton onClick={() => addAttendee()}>
                            <IconPlus /> Add Attendee
                        </RbgButton>
                    </RbgStack>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgTextArea
                        name="details"
                        error={getError('details')}
                        placeholder="Notes"
                        value={disciplinary.reason}
                        onChange={(e) => setDisciplinary({ ...disciplinary, details: e.target.value })}
                    />
                </RbgGrid>
                {disciplinary.files.length > 0 && (
                    <RbgGrid item xs={12}>
                        <RbgSimpleTable
                            headers={['File', '']}
                            keys={disciplinary.files.map((file) => file.id)}
                            rows={disciplinary.files.map((file) => [
                                file.original_filename,
                                <DeleteConfirm
                                    onDelete={() => {
                                        const files = disciplinary.files.filter((f) => f.id !== file.id);
                                        setDisciplinary({
                                            ...disciplinary,
                                            files
                                        });
                                    }}
                                />
                            ])}
                        />
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <StaffFileUpload
                        staffId={staffMember.id}
                        onFileUploaded={(file) => {
                            const files = disciplinary.files;
                            files.push(file);
                            setDisciplinary((prev) => ({
                                ...prev,
                                files
                            }));
                        }}
                        bucket="rbg-staff-files"
                        publicDownload
                    />
                </RbgGrid>
            </RbgGrid>
        </div>
    );
}

AddDisciplinary.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};

export default AddDisciplinary;
