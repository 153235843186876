import MainCard from '../../../../ui_component/cards/MainCard';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import { useState } from 'react';
import StaffAdminEmails from './Emails/StaffAdminEmails';
import { useMediaQuery, useTheme } from '@mui/material';
import SupportCentreRoles from './SupportCentre/SupportCentreRoles';
import SupportCentreDepartments from './SupportCentre/SupportCentreDepartments';
import HrConfigNotifications from './Notifications/HrConfigNotifications';
import HrConfigSiteRoles from './JobRoles/HrConfigSiteRoles';
import HrConfigDocuments from './Documents/HrConfigDocuments';
import HrConfigInitialTraining from './InitialTraining/HrConfigInitialTraining';
import HrConfigLocations from './Locations/HrConfigLocations';
import ContractTemplates from './ContractTemplates/ContractTemplates';
import HrConfigOnboardingFields from './OnboardingFields/HrConfigOnboardingFields';
import TalosIncoming from './TalosIncoming/TalosIncoming';
import HrConfigWagestream from './Wagestream/HrConfigWagestream';

function HrSystemConfig() {
    const theme = useTheme();
    const matchDown400 = useMediaQuery(theme.breakpoints.down(400));
    const [tab, setTab] = useState(window.location.hash ? window.location.hash.replace('#', '') : 'email_templates');
    const tabs = [
        {
            loading: false,
            label: 'Email Templates',
            value: 'email_templates',
            content: <StaffAdminEmails />
        },
        {
            loading: false,
            label: 'Contract Templates',
            value: 'contract_templates',
            content: <ContractTemplates />
        },
        {
            loading: false,
            label: 'Job Roles',
            value: 'job_roles',
            content: <HrConfigSiteRoles />
        },
        {
            loading: false,
            label: 'Support Centre Roles',
            value: 'support_centre_roles',
            content: <SupportCentreRoles />
        },
        {
            loading: false,
            label: 'Support Centre Departments',
            value: 'support_centre_departments',
            content: <SupportCentreDepartments />
        },
        {
            loading: false,
            label: 'Talos Data',
            value: 'talos_data',
            content: <TalosIncoming />
        },
        // {
        //     loading: false,
        //     label: 'D&I Fields',
        //     value: 'di_fields',
        //     content: <HrConfigDiFields />
        // },
        {
            loading: false,
            label: 'Onboarding Fields',
            value: 'onboarding_fields',
            content: <HrConfigOnboardingFields />
        },
        {
            loading: false,
            label: 'Documents',
            value: 'documents',
            content: <HrConfigDocuments />
        },
        {
            loading: false,
            label: 'Notifications',
            value: 'notifications',
            content: <HrConfigNotifications />
        },
        {
            loading: false,
            label: 'Initial Training',
            value: 'initial_training',
            content: <HrConfigInitialTraining />
        },
        {
            loading: false,
            label: 'Locations',
            value: 'locations',
            content: <HrConfigLocations />
        },
        {
            loading: false,
            label: 'Wagestream',
            value: 'wagestream',
            content: <HrConfigWagestream />
        }
    ];
    return (
        <MainCard title="R-People Configuration">
            <RbgTabs
                tabs={tabs}
                orientation="vertical"
                currentTab={tab}
                setCurrentTab={(tab) => {
                    setTab(tab);
                }}
                popperStyles={{ maxWidth: matchDown400 ? '175px' : '250px', position: 'absolute', left: '58px' }}
            />
        </MainCard>
    );
}

export default HrSystemConfig;
