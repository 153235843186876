import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import NewRoleWizard from './NewRole/NewRoleWizard';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';

function ReInstate({ staffMember }) {
    if (staffMember.can_reinstate === false) {
        return (
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <InfoCard>
                        <RbgStack gap={2}>
                            <RbgTypography>
                                Only previous team members which have had a successful continuous right to work check can be re-instated.
                            </RbgTypography>
                            <RbgTypography>
                                To re-hire {staffMember.details.data.display_name} they will need to complete a new right to work check and
                                go through the full onboarding process through Talos.
                            </RbgTypography>
                        </RbgStack>
                    </InfoCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>
                    As {staffMember.details.data.display_name} has previously had a successful continuous right to check they can be
                    re-instated instead of going through the recruitment platform.
                </InfoCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <NewRoleWizard staffMember={staffMember} initialData={{ new_contract: true, pay_change: true, reinstate: true }} />
            </RbgGrid>
        </RbgGrid>
    );
}

ReInstate.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};

export default ReInstate;
