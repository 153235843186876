import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ErrorCard from '../../../../ui_component/error/ErrorCard';

function StaffMemberAutocomplete({ name, label, onChange, disabled, required, value, size = 'small' }) {
    const [search, setSearch] = useState('');
    const [autoCompleteSearch, setAutoCompleteSearch] = useState('');
    const [selected] = useState(value);

    const { data, error } = useQuery({
        queryKey: [{ url: '/staff/search', params: { page: 1, partial_name: autoCompleteSearch, live_only: true } }],
        enabled: search !== ''
    });
    const optionList = data.data.map((row) => {
        return {
            label: row.display_name,
            value: `${row.id}`
        };
    });

    const handleChange = (e, v) => {
        if (v === null) {
            onChange({
                target: {
                    name,
                    value: null,
                    object: {}
                }
            });
            return;
        }
        const object = data.data.find((opt) => {
            return v.value === `${opt.id}`;
        });
        onChange({
            target: {
                name,
                value: v.value,
                object
            }
        });
    };
    useEffect(() => {
        const id = setTimeout(() => {
            setAutoCompleteSearch(search);
        }, 250);
        return () => {
            clearTimeout(id);
        };
    }, [search]);

    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }

    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            size={size}
            value={selected}
            options={optionList}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => {
                return option.value === value;
            }}
            renderInput={(params) => {
                return (
                    <TextField {...params} value={search} onChange={(e) => setSearch(e.target.value)} variant="outlined" label={label} />
                );
            }}
            renderOption={(props, option) => {
                props.key = option.value;
                return (
                    <li {...props}>
                        <RbgStack sx={{ width: '100%' }} direction="row" gap={2}>
                            {option.label}
                        </RbgStack>
                    </li>
                );
            }}
        />
    );
}

export default StaffMemberAutocomplete;

StaffMemberAutocomplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any, // as per MUI docs
    size: PropTypes.string
};
