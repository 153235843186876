import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui_component/misc/Logo';

// ==============================|| MAIN LOGO ||============================== //

function LogoSection() {
    return (
        <ButtonBase disableRipple component="a" href={config.defaultPath}>
            <Logo />
        </ButtonBase>
    );
}

export default LogoSection;
