import { useQuery } from '@tanstack/react-query';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconEdit } from '@tabler/icons-react';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddEditEmail from './AddEditEmail';
import StaffAdminEmailsEditMapping from './StaffAdminEmailsEditMapping';
import _ from 'lodash';

function StaffAdminEmailTemplateMapping() {
    const { data } = useQuery({
        queryKey: [
            {
                method: 'GET',
                url: '/staff/staff-admin/email-types',
                params: { include: 'mapping,mapping.template' }
            },
            'email_template_mapping'
        ]
    });

    const editTemplate = (template) => {
        DialogHelper.openLgDialog('Edit E-mail Template', <AddEditEmail email={template} />);
    };
    return (
        <RbgStack gap={2}>
            <IntroCardPrimary>
                <RbgTypography>Here you can modify what e-mails are sent for what type.</RbgTypography>
            </IntroCardPrimary>
            <RbgSimpleTable
                headers={['Short Name', 'Name', 'Mapping', '']}
                keys={data.data.map((row) => row.short_name)}
                rows={data.data.map((row) => {
                    const defaultMapping = row.mapping.data.find((mapping) => mapping.cost_centre === null);
                    const overrides = row.mapping.data.filter((mapping) => mapping.cost_centre !== null);
                    return [
                        row.short_name,
                        row.name,
                        <RbgStack gap={1}>
                            {defaultMapping && (
                                <RbgTypography>
                                    Default Template:{' '}
                                    <a
                                        href={`#${defaultMapping.template.data.id}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            editTemplate(defaultMapping.template.data);
                                        }}
                                    >
                                        {defaultMapping.template.data.name}
                                    </a>
                                </RbgTypography>
                            )}
                            {overrides.length > 0 && <RbgTypography sx={{ fontWeight: '900' }}>Overrides</RbgTypography>}
                            {overrides.map((override) => {
                                return (
                                    <RbgTypography key={_.uniqueId()}>
                                        {override.location_name} -{' '}
                                        <a
                                            href={`#${override.template.data.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                editTemplate(override.template.data);
                                            }}
                                        >
                                            {override.template.data.name}
                                        </a>
                                    </RbgTypography>
                                );
                            })}
                        </RbgStack>,
                        <RbgButton
                            variant="outlined"
                            icon={<IconEdit size={20} />}
                            onClick={() => {
                                DialogHelper.openMdDialog('Edit E-mail Mapping', <StaffAdminEmailsEditMapping mapping={row} />);
                            }}
                        >
                            Edit
                        </RbgButton>
                    ];
                })}
            />
        </RbgStack>
    );
}

export default StaffAdminEmailTemplateMapping;
