import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import IntroCardSecondary from '../../../../../ui_component/cards/IntroCardSecondary';
import { useMutation } from '@tanstack/react-query';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';

function ExtendLeaver({ staffMember }) {
    const [newDate, setNewDate] = useState(staffMember.leave_date);
    const mutation = useMutation({
        mutationFn: (data) => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/extend-leaver`, { date: newDate });
        },
        onSuccess: (data) => {
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Extend Leave Date', staffMember.leave_date === newDate);
    }, [newDate, staffMember.leave_date, mutation]);

    if (staffMember.employment.data.leave_date === null) {
        return <ErrorCard>{staffMember.details.data.display_name} is not marked as a leaver</ErrorCard>;
    }

    return (
        <RbgGrid container spacing={gridSpacing}>
            {mutation.failureReason && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.failureReason}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <IntroCardSecondary>
                    {staffMember.details.data.display_name}? is currently due to leave RBG in{' '}
                    {dayjs(staffMember.employment.data.leave_date).fromNow()}. Enter a new date below to extend
                </IntroCardSecondary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    name="new_date"
                    label="New Leave Date"
                    value={newDate}
                    onChange={(e) => setNewDate(e ? e.format('YYYY-MM-DD') : '')}
                    minDate={new Date()}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

ExtendLeaver.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default ExtendLeaver;
