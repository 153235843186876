// material-ui
import { useTheme } from '@mui/material/styles';

function Logo() {
    const theme = useTheme();

    return (
        <svg width="116" height="42" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 40" fill="none">
            <path
                d="M 33.14 16.58 33.01 16.17 32.57 14.82 23.64 14.82 20.97 6.62 20.88 6.32 20.44 6.33 19.03 6.33 16.27 14.82 7.64 14.82 7.33 14.82 7.2 15.23 6.76 16.58 13.99 21.83 11.32 30.03 11.23 30.33 11.58 30.59 12.72 31.42 19.94 26.17 26.93 31.24 27.18 31.42 27.53 31.16 28.68 30.33 25.91 21.84 32.89 16.77 33.14 16.58z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M20,38.68A18.68,18.68,0,1,1,38.64,20,18.7,18.7,0,0,1,20,38.68ZM20,3.29A16.71,16.71,0,1,0,36.66,20,16.73,16.73,0,0,0,20,3.29Z"
                fill={theme.palette.secondary.main}
            />
            <path
                d="M59.67,21.69,62.8,30.2H58.68l-3-7.81h-4.2V30.2h-4V8.54h9c3.89,0,6.38,2.47,6.38,6.53v.79C62.83,18.64,61.67,20.68,59.67,21.69Zm-8.19-3.57H56.7a1.93,1.93,0,0,0,2.15-2.05V14.85a1.91,1.91,0,0,0-2.12-2H51.48Z"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
            />
            <path
                d="M66.44,23.12v-7.5c0-4.61,3-7.54,8.37-7.54s8.38,2.93,8.38,7.54V17H79V16c0-2.22-1.41-3.66-4.13-3.66-2.9,0-4.43,1.44-4.43,3.66v6.71c0,2.23,1.51,3.66,4.4,3.66S79,25,79,22.72v-.94h4.22v1.34c0,4.61-3,7.53-8.35,7.53S66.44,27.73,66.44,23.12Z"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
            />
            <path
                d="M101.25,25.93V30.2H87.44V8.54h4V25.93Z"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
            />
            <path
                d="M104.4,23.12v-7.5c0-4.61,3-7.54,8.51-7.54s8.51,2.93,8.51,7.54v7.5c0,4.61-3,7.53-8.49,7.53S104.4,27.73,104.4,23.12Zm13-.4V16c0-2.22-1.41-3.66-4.51-3.66s-4.56,1.44-4.56,3.66v6.71c0,2.23,1.41,3.66,4.54,3.66S117.44,25,117.44,22.72Z"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
            />
            <path
                d="M125.41,23.12V8.54h4V22.72c0,2.23,1.51,3.66,4.28,3.66s4.28-1.43,4.28-3.66V8.54h4V23.12c0,4.61-3,7.53-8.22,7.53S125.41,27.73,125.41,23.12Z"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
            />
            <path
                d="M162.46,16.07v6.59c0,4.61-3.05,7.54-8.1,7.54h-8.2V8.54h8.18C159.41,8.54,162.46,11.47,162.46,16.07Zm-4,.4c0-2.23-1.41-3.66-3.87-3.66h-4.49V25.93h4.46c2.49,0,3.9-1.44,3.9-3.66Z"
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
            />
        </svg>
    );
}

export default Logo;
