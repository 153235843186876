import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import RbgPopper from 'ui_component/popper/RbgPopper';
// project imports
import MainCard from 'ui_component/cards/MainCard';
import useAuth from 'hooks/useAuth';
import Customization from '../../../Customization/Customization';
import AvatarButton from 'ui_component/buttons/AvatarButton';
import UserChip from './UserChip';
// assets
import { IconLogout, IconSettings, IconUserCircle } from '@tabler/icons-react';
// third-party
import dayjs from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

// ==============================|| PROFILE MENU ||============================== //

function ProfileSection() {
    // theme
    const theme = useTheme();
    const matchesXsDown = useMediaQuery(theme.breakpoints.down(400));
    // redux
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    // auth
    const { user } = useAuth();
    // lcoal state
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [themeDrawerOpen, setThemeDrawerOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleLogout = async () => {
        document.location.href = '/rcloud/logout';
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        if (index === 0) setOpen(false);
        if (route && route !== '') {
            navigate(route);
        }
    };

    const themeDrawerToggle = (e) => {
        setThemeDrawerOpen(true);
        if (e.target.className?.includes('MuiModal-backdrop')) {
            setThemeDrawerOpen(false);
        }
    };

    const greetingMessage = () => {
        const hourNow = dayjs().hour();
        let message;
        if (hourNow >= '0' && hourNow < '12') {
            message = 'Good Morning';
        } else if (hourNow >= '12' && hourNow <= '17') {
            message = 'Good Afternoon';
        } else if (hourNow >= '18' && hourNow < '23') {
            message = 'Good Evening';
        }
        return message;
    };

    useEffect(() => {
        if (!themeDrawerOpen) setOpen(false);
    }, [themeDrawerOpen]);

    return (
        <>
            {matchesXsDown ? (
                <AvatarButton
                    avatarStyles={{ height: '48px', minWidth: '48px', borderRadius: '50%' }}
                    ref={anchorRef}
                    buttonType="secondary"
                    onClick={handleToggle}
                    disableRipple
                    Icon={IconUserCircle}
                />
            ) : (
                <UserChip ref={anchorRef} open={open} onClick={handleToggle} label={user?.name} />
            )}
            <RbgPopper
                placement="bottom"
                open={open}
                setOpen={setOpen}
                ref={anchorRef}
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [-70, 20]
                            }
                        }
                    ]
                }}
            >
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <RbgStack sx={{ p: 2 }}>
                        <RbgStack direction="row" spacing={0.5} alignItems="center">
                            <RbgTypography variant="h4">{`${greetingMessage()},`}</RbgTypography>
                            <RbgTypography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                {user?.name}
                            </RbgTypography>
                        </RbgStack>
                        <RbgTypography variant="subtitle2">{user?.position}</RbgTypography>
                    </RbgStack>
                    <Divider />
                    <List
                        component="nav"
                        sx={{
                            p: 2,
                            '& .MuiListItemButton-root': {
                                mt: 0.5
                            }
                        }}
                    >
                        <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0, '/profile')}
                        >
                            <ListItemIcon>
                                <IconSettings stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<RbgTypography variant="body2">Account Settings</RbgTypography>} />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 1}
                            onClick={themeDrawerToggle}
                            disableRipple
                        >
                            <ListItemIcon>
                                <Customization open={themeDrawerOpen} setOpen={setThemeDrawerOpen} />
                            </ListItemIcon>
                            <ListItemText primary={<RbgTypography variant="body2">Theme Customization</RbgTypography>} />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 2}
                            onClick={handleLogout}
                        >
                            <ListItemIcon>
                                <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<RbgTypography variant="body2">Logout</RbgTypography>} />
                        </ListItemButton>
                    </List>
                </MainCard>
            </RbgPopper>
        </>
    );
}

export default ProfileSection;
