import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import IntroCardPrimary from '../../../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import ViewContract from '../ViewContract';
import MainCard from '../../../../../../ui_component/cards/MainCard';
import { staffMember } from '../../../StaffDataTypes';
import PropTypes from 'prop-types';
import AddManualContract from '../AddManualContract';
import RbgButtonDropdown from '../../../../../../ui_component/mui-wrappers/Button/RbgButtonDropdown';
import dayjs from 'dayjs';
import IntroCardSecondary from '../../../../../../ui_component/cards/IntroCardSecondary';
import AddEditAgreement from '../Agreements/AddEditAgreement';
import ViewAgreement from '../Agreements/ViewAgreement';
import NewRoleWizard from '../NewRole/NewRoleWizard';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../../contexts/RcloudAuthContext';

function StaffContractWidget({ staffMember }) {
    const { user } = useContext(RcloudAuthContext);

    const addContract = () => {
        DialogHelper.openMdDialog(
            'Issue Team Member Contract',
            <NewRoleWizard
                staffMember={staffMember}
                initialData={{
                    new_contract: true,
                    pay_type_change: false,
                    pay_change: false,
                    location: staffMember.role.data.cost_centre,
                    position: staffMember.role.data.position.data,
                    contract_change: true,
                    position_name: staffMember.role.data.position.data.name,
                    same_location: true
                }}
            />
        );
    };
    const details = staffMember.details.data;
    const previous = staffMember.all_contracts.data.filter((c) => {
        if (!staffMember) {
            return false;
        }
        if (!staffMember?.contract?.data?.id) {
            return true;
        }
        return c.id !== staffMember.contract.data.id;
    });
    const pending = staffMember.all_contracts.data.filter((c) => {
        return c.status.data.complete === false;
    });

    const futureAuths = staffMember.pay_rate_auths.data
        .filter((auth) => auth.status.data.id === 1)
        .filter((auth) => {
            if (auth.contract_template_name) {
                return true;
            }
            return false;
        });

    const actionButtons = [];
    const leaver = staffMember.leave_date && dayjs(staffMember.leave_date).isBefore(dayjs());
    if (pending.length === 0 && !leaver && futureAuths.length === 0) {
        actionButtons.push({ content: 'Issue new contract', onClick: () => addContract() });
    }
    if (user.permissions.indexOf('hr_config') > -1 && pending.length === 0 && !staffMember.leave_date) {
        actionButtons.push({
            content: 'Add manual contract',
            onClick: () => {
                DialogHelper.openMdDialog('Add Manual Contract', <AddManualContract staffMember={staffMember} />);
            }
        });
    }

    actionButtons.push({
        content: 'Add Live In Agreement',
        onClick: () => {
            DialogHelper.openMdDialog('Add Live In Agreement', <AddEditAgreement staffMember={staffMember} agreementType={1} />);
        }
    });

    return (
        <MainCard title="Contract Details" actionButtons={{ label: 'Actions', items: actionButtons }}>
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        {!staffMember.contract.data.id && (
                            <RbgTypography>
                                {staffMember.display_forename} does not have a signed contract assigned to their employee profile
                            </RbgTypography>
                        )}
                        {staffMember.contract.data.id && (
                            <RbgTypography>
                                {staffMember.display_forename} is currently on a{' '}
                                <strong>{staffMember.contract.data.contracted_hours} hour</strong> contract.
                            </RbgTypography>
                        )}
                    </IntroCardPrimary>
                </RbgGrid>
                {pending.length > 0 && (
                    <RbgGrid item xs={12}>
                        <IntroCardSecondary>
                            <RbgTypography>There is an outstanding contract for this team member.</RbgTypography>
                            <RbgButton
                                onClick={() => {
                                    DialogHelper.openMdDialog('View Contract', <ViewContract contract={pending[0]} />);
                                }}
                            >
                                View Pending Contract
                            </RbgButton>
                        </IntroCardSecondary>
                    </RbgGrid>
                )}
                {futureAuths.length > 0 && (
                    <RbgGrid item xs={12}>
                        <IntroCardSecondary>
                            <RbgTypography>
                                There is an outstanding contract change authorisation in process for {staffMember.display_forename}. Once
                                approved the new contract will be a{' '}
                                <strong>
                                    {futureAuths[0].contract_template_name} ({futureAuths[0].contracted_hours.data.name})
                                </strong>{' '}
                                contract
                            </RbgTypography>
                        </IntroCardSecondary>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgStack direction="row" gap={2}>
                        {staffMember.contract.data.id && (
                            <RbgButton
                                onClick={() => {
                                    DialogHelper.openMdDialog('View Contract', <ViewContract contract={staffMember.contract.data} />);
                                }}
                            >
                                View Current Contract
                            </RbgButton>
                        )}
                        {previous.length > 0 && (
                            <RbgButtonDropdown
                                label="Previous Contracts"
                                items={previous.map((c) => ({
                                    onClick: () => {
                                        DialogHelper.openMdDialog('View Contract', <ViewContract contract={c} />);
                                    },
                                    label: `${c.template.data.name} - ${dayjs(c.start_date).format('DD/MM/YYYY')} (${c.status.data.name})`
                                }))}
                            />
                        )}
                    </RbgStack>
                </RbgGrid>
                {staffMember.agreements.data.length > 0 && (
                    <RbgGrid item xs={12}>
                        <RbgStack gap={2}>
                            {staffMember.agreements.data.map((agreement) => {
                                if (agreement.date_signed === null) {
                                    return (
                                        <IntroCardPrimary key={agreement.id}>
                                            <strong>{staffMember.display_forename}</strong> has a <strong>{agreement.type}</strong>{' '}
                                            agreement which is yet to be signed
                                        </IntroCardPrimary>
                                    );
                                }
                                return (
                                    <IntroCardPrimary key={agreement.id}>
                                        <strong>{staffMember.display_forename}</strong> has an active <strong>{agreement.type}</strong>{' '}
                                        agreement.
                                        <RbgButton
                                            color="secondary"
                                            onClick={() => {
                                                DialogHelper.openMdDialog('View Agreement', <ViewAgreement agreement={agreement} />);
                                            }}
                                        >
                                            View Signed Agreement
                                        </RbgButton>
                                    </IntroCardPrimary>
                                );
                            })}
                        </RbgStack>
                    </RbgGrid>
                )}
            </RbgGrid>
        </MainCard>
    );
}

StaffContractWidget.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default StaffContractWidget;
