import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import MainCard from '../../../../ui_component/cards/MainCard';
import { useQuery } from '@tanstack/react-query';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../ui_component/modals/RbgDialog';
import CreateBulkAction from './CreateBulkAction';
import RbgTable from '../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableHead from '../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableCell from '../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableRow from '../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import BulkActionRow from './BulkActionRow';
import RbgTableBody from '../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';

function BulkActions() {
    const { data, error } = useQuery({
        queryKey: [{ url: '/staff/bulk-actions' }]
    });
    return (
        <MainCard title="Bulk Staff Actions">
            {error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgTable>
                        <RbgTableHead>
                            <RbgTableRow>
                                <RbgTableCell>#</RbgTableCell>
                                <RbgTableCell>File</RbgTableCell>
                                <RbgTableCell>Uploaded By</RbgTableCell>
                                <RbgTableCell>Type</RbgTableCell>
                                <RbgTableCell>Started</RbgTableCell>
                                <RbgTableCell>Completed</RbgTableCell>
                                <RbgTableCell>Mapping</RbgTableCell>
                                <RbgTableCell>
                                    <RbgButton
                                        size="small"
                                        onClick={() => {
                                            DialogHelper.openDialog('Add new bulk action', <CreateBulkAction />);
                                        }}
                                    >
                                        <IconPlus /> Create new
                                    </RbgButton>
                                </RbgTableCell>
                            </RbgTableRow>
                        </RbgTableHead>
                        <RbgTableBody>
                            {data.data.map((row) => (
                                <BulkActionRow rowData={row} key={row.id} />
                            ))}
                        </RbgTableBody>
                    </RbgTable>
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default BulkActions;
