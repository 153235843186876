import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import HrConfigEditCategory from './HrConfigEditCategory';

function HrConfigDocumentCategories() {
    const { data, isFetching } = useQuery({
        queryKey: [{ url: '/staff/documents/categories' }]
    });
    if (isFetching) {
        return <CardLoader />;
    }

    const editCat = (cat) => {
        DialogHelper.openSmDialog(cat ? 'Edit Category' : 'Add Category', <HrConfigEditCategory currentCategory={cat} />);
    };

    const getCategoryName = (id) => {
        const cat = data.data.filter((c) => {
            return c.id === id;
        });
        if (cat) {
            return cat.name;
        }
        return 'N/A';
    };

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    stickyHeader
                    tableContainerSx={{ maxHeight: '700px' }}
                    keys={data.data.map((row) => row.id)}
                    headers={['ID', 'Name', 'Level', 'Parent', <RbgButton icon={<IconPlus />} onClick={() => editCat()} />]}
                    rows={data.data.map((row) => [
                        row.id,
                        row.name,
                        row.hierarchy_level,
                        getCategoryName(row.parent_category),
                        <RbgButton icon={<IconEdit />} onClick={() => editCat(row)} />
                    ])}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

export default HrConfigDocumentCategories;
