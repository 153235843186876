import ServerSidePagination from '../pagination/ServerSidePagination';
import PropTypes from 'prop-types';
import RbgGrid from '../mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../store/constant';
import RbgSimpleTable from '../mui-wrappers/Table/RbgSimpleTable';
import { useState } from 'react';

function ClientSidePageableTable({
    headers,
    data,
    rowDecorator,
    topFilterContent,
    loading,
    stickyHeader,
    tableContainerSx,
    keys = [],
    perPage = 50
}) {
    if (keys.length === 0) {
        keys = data.map((row) => row.id);
    }
    const [pagination, setPagination] = useState({
        count: data.length > perPage ? perPage : data.length,
        current_page: 1,
        per_page: perPage,
        total: data.length,
        total_pages: Math.ceil(data.length / perPage)
    });
    const handlePageChange = (page) => {
        setPagination((prev) => ({ ...prev, current_page: page }));
    };
    const offset = (pagination.current_page - 1) * pagination.per_page;
    const offsetEnd = offset + perPage > pagination.length ? pagination.length : offset + perPage;
    const rows = data.slice(offset, offsetEnd);
    return (
        <RbgGrid container spacing={gridSpacing} sx={{ pt: 1 }}>
            {topFilterContent && (
                <RbgGrid item xs={4}>
                    {topFilterContent}
                </RbgGrid>
            )}
            {pagination.total_pages > 1 && (
                <RbgGrid item xs={topFilterContent ? 8 : 12}>
                    <ServerSidePagination
                        pagination={pagination}
                        changePage={handlePageChange}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    />
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    loading={loading}
                    stickyHeader={stickyHeader}
                    tableContainerSx={tableContainerSx}
                    headers={headers}
                    keys={keys}
                    rows={rows.map((row) => {
                        if (rowDecorator) {
                            row = rowDecorator(row);
                        }
                        return row;
                    })}
                />
            </RbgGrid>
            {pagination.total_pages > 1 && (
                <RbgGrid item xs={12}>
                    <ServerSidePagination
                        pagination={pagination}
                        changePage={handlePageChange}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

ClientSidePageableTable.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    rowDecorator: PropTypes.func,
    topFilterContent: PropTypes.element,
    loading: PropTypes.bool,
    stickyHeader: PropTypes.bool,
    tableContainerSx: PropTypes.object,
    keys: PropTypes.array,
    perPage: PropTypes.number
};

export default ClientSidePageableTable;
