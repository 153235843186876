import menuItems from '../menu-items/menuItems';

const MenuLookup = {
    flat_items: {},
    regex_items: [],
    flattenMenu() {
        if (Object.keys(this.flat_items).length === 0) {
            menuItems.items.forEach((item) => {
                if (item.type === 'group') {
                    const parent = [];
                    parent.push(item.id);
                    item.children.forEach((child) => {
                        if (child.type === 'item' && child.url) {
                            this.flat_items[child.url] = [...parent, child.id];
                            if (child.regex) {
                                this.flat_items[child.url] = [...parent, child.id];
                            }
                        }
                        if (child.children) {
                            child.children.forEach((subchild) => {
                                if (subchild.type === 'item' && subchild.url) {
                                    this.flat_items[subchild.url] = [...parent, child.id, subchild.id];
                                    if (subchild.regex) {
                                        this.regex_items.push({
                                            regex: subchild.regex,
                                            items: [...parent, child.id, subchild.id]
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });
        }
        return this.flat_items;
    },
    getActiveItems(url) {
        const items = this.flattenMenu();
        if (items[url]) {
            return items[url];
        }
        const matches = this.regex_items.filter((item) => {
            return url.match(item.regex);
        });
        if (matches.length > 0) {
            return matches[0].items;
        }
        return null;
    },
    isActive(targetUri, menuItem) {
        const hasChild = (menuItem, string) => {
            let found = false;
            menuItem.forEach((item) => {
                if (item.url === targetUri) {
                    found = true;
                }
                if (item.url === targetUri) {
                    found = true;
                }
                if (item.regex && string.match(item.regex)) {
                    found = true;
                }
                if (item.children) {
                    found = hasChild(item.children, string);
                }
            });
            return found;
        };

        return hasChild(menuItems.items, targetUri);
    },
    getParentItem(item) {
        let foundItem = null;
        menuItems.items.forEach((menuItem) => {
            if (menuItem.id === item.id) {
                foundItem = menuItem;
            }
            if (menuItem.children) {
                menuItem.children.forEach((child) => {
                    if (child.id === item.id) {
                        foundItem = menuItem;
                    }
                });
            }
        });
        if (foundItem) {
            return foundItem;
        }
        return null;
    }
};

export default MenuLookup;
