import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgFilter from '../../../../ui_component/extended/Form/RbgFilter';
import MainCard from '../../../../ui_component/cards/MainCard';
import Debug from '../../../../ui_component/misc/Debug';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import PageableTable from '../../../../ui_component/pageable-table/PageableTable';
import RbgTooltip from '../../../../ui_component/tooltip/RbgTooltip';
import dayjs from 'dayjs';
import RbgLink from '../../../../ui_component/mui-wrappers/Link/RbgLink';

function ActionReporting() {
    const [filter, setFilter] = useState({
        page: 1,
        action_id: '',
        severity: '',
        category: '',
        location: '',
        include: 'staff_member,type,additional_data'
    });
    const {
        data: meta,
        isLoading: metaLoading,
        error: metaError
    } = useQuery({
        queryKey: [{ url: '/staff/actions/meta' }],
        initialData: {
            data: {
                categories: { data: [] },
                severities: { data: [] },
                types: { data: [] }
            }
        }
    });

    const { data, error } = useQuery({
        queryKey: [{ url: '/staff/actions', params: filter }],
        disabled: metaLoading === true || !metaError
    });

    if (metaError) {
        return (
            <MainCard title="Team Actions">
                <ErrorCard>{metaError}</ErrorCard>
            </MainCard>
        );
    }
    if (error) {
        return (
            <MainCard title="Team Actions">
                <ErrorCard>{error}</ErrorCard>
            </MainCard>
        );
    }

    const types = meta.data.types.data
        .filter((type) => {
            let valid = true;
            if (filter.severity) {
                valid = type.severity.data.id === filter.severity;
            }
            if (filter.category) {
                if (valid) {
                    valid = type.category.data.id === filter.category;
                }
            }

            return valid;
        })
        .sort((type1, type2) => type1.name.localeCompare(type2.name));

    const filters = [
        {
            name: 'severity',
            label: 'Severity',
            type: 'select',
            value: filter.severity,
            values: meta.data.severities.data
        },
        {
            name: 'category',
            label: 'Category',
            type: 'select',
            value: filter.category,
            values: meta.data.categories.data
        },
        {
            name: 'type',
            label: 'Action Type',
            type: 'select',
            autoComplete: true,
            value: filter.action_id,
            values: types
        },
        {
            name: 'location',
            label: 'Location',
            type: 'remote_select',
            endpoint: '/staff/locations',
            value: filter.location
        }
    ];
    return (
        <MainCard title="Team Actions">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgFilter filter={filter} onChange={(filter) => setFilter(filter)} filters={filters} />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <PageableTable
                        headers={['Name', 'Location', 'Action', 'Date Added', 'Last Check']}
                        pagination={data.meta.pagination}
                        data={data.data}
                        rowDecorator={(row) => {
                            const tooltip = row.rendered;
                            return [
                                <RbgLink href={`/staff/view/${row.staff_id}`}>{row.staff_member.data.display_name}</RbgLink>,
                                row.staff_member.data.location_name,
                                row.rendered,
                                dayjs(row.date_added).fromNow(),
                                dayjs(row.last_checked).fromNow()
                            ];
                        }}
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default ActionReporting;
