import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui imports
import { Button, Fade, Tooltip } from '@mui/material';

// 3rd party imports
import { IconTrash } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

// project imports
import { iconSize } from 'store/constant';

function DeleteConfirm({ title, onDelete, btnText = '', confirmText, disabled, itemId, size, variant, tooltipPlacement, fullWidth, sx }) {
    const [confirm, setConfirm] = useState(false);
    const [id, setId] = useState(null);
    const [label, setLabel] = useState(btnText);

    useEffect(() => {
        const id = uuidv4();
        setId(id);
    }, []);

    useEffect(() => {
        let timeout;
        if (confirm === true) {
            setLabel(confirmText);
            timeout = setTimeout(() => setConfirm(false), 4000);
        } else setLabel(btnText);

        return () => clearTimeout(timeout);
    }, [confirm, confirmText, btnText]);

    const handleClick = (e) => {
        e.preventDefault();
        if (confirm === false) {
            setConfirm(true);
        } else if (confirm === true) {
            if (itemId) {
                onDelete({
                    target: e.target,
                    itemId
                });
                return;
            }
            onDelete(e);
        }
    };

    const deleteButton = (
        <Button
            sx={{ minWidth: 'fit-content', color: '#fff', width: fullWidth ? '100%' : 'auto', ...sx }}
            id={id}
            onClick={handleClick}
            variant={variant}
            size={size}
            disabled={disabled}
            color="error"
        >
            <IconTrash size={iconSize} />
            {label}
        </Button>
    );

    return (
        <Tooltip title={title} placement={tooltipPlacement} TransitionComponent={Fade}>
            {disabled ? <span>{deleteButton}</span> : deleteButton}
        </Tooltip>
    );
}

DeleteConfirm.defaultProps = {
    confirmText: 'Sure?',
    size: 'small',
    variant: 'contained',
    tooltipPlacement: 'bottom',
    title: 'Delete'
};

DeleteConfirm.propTypes = {
    onDelete: PropTypes.func.isRequired,
    title: PropTypes.string,
    confirmText: PropTypes.string,
    disabled: PropTypes.bool,
    itemId: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    btnText: PropTypes.string,
    fullWidth: PropTypes.bool,
    sx: PropTypes.object
};

export default DeleteConfirm;
