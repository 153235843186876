import { useMutation, useQuery } from '@tanstack/react-query';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import { useState } from 'react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconCopy, IconEdit, IconHistory, IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EditNotification from './EditNotification';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import { ButtonGroup } from '@mui/material';
import NotificationHistory from './NotificationHistory';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgFilter from '../../../../../ui_component/extended/Form/RbgFilter';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import _ from 'lodash';
import Api from '../../../../../utils/api';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';

function HrConfigNotifications() {
    const [filter, setFilter] = useState({
        page: 1,
        action_ids: [],
        type_ids: [],
        name_search: '',
        endpoint_search: ''
    });
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: [{ url: '/staff/notifications', params: filter }, 'staff_notifications']
    });

    const handleEditNotification = (notification) => {
        DialogHelper.openLgDialog(
            notification ? 'Edit Notification' : 'Add Notification',
            <EditNotification notification={notification} />
        );
    };
    const handleViewHistory = (notification) => {
        DialogHelper.openMdDialog('Notification History', <NotificationHistory notification={notification} />);
    };
    const deleteMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('DELETE', `/staff/notification/${id}`);
        },
        onSuccess: () => {
            refetch();
        }
    });
    const handleDelete = (notification) => {
        deleteMutation.mutate(notification.id);
    };
    const handleClone = (notification) => {
        const clone = JSON.parse(JSON.stringify(notification));
        clone.id = null;

        clone.rules.data = clone.rules.data.map((r) => {
            r.id = _.uniqueId('new');
            r.notification_id = null;
            return r;
        });
        clone.endpoints.data = clone.endpoints.data.map((r) => {
            r.id = _.uniqueId('new');
            r.notification_id = null;
            return r;
        });
        clone.fields.data = clone.fields.data.map((r) => {
            r.id = _.uniqueId('new');
            return r;
        });

        DialogHelper.openLgDialog('Clone Notification', <EditNotification notification={clone} />);
    };

    return (
        <MainCard title="HR Notifications">
            <RbgGrid container spacing={gridSpacing}>
                {error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgTypography>
                            Here you can add and edit notifications to occur through the system when events happen. It can get quite
                            complicated, if you are unsure log a ticket with IT!
                        </RbgTypography>
                    </IntroCardPrimary>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgFilter
                        onChange={(filter) => setFilter(filter)}
                        filter={filter}
                        filters={[
                            {
                                type: 'text',
                                name: 'name_search',
                                label: 'Name Search',
                                value: filter.name_search,
                                useDebounce: true
                            },
                            {
                                type: 'text',
                                name: 'endpoint_search',
                                label: 'Endpoint Search',
                                value: filter.endpoint_search,
                                useDebounce: true
                            },
                            {
                                type: 'remote_select',
                                name: 'action_ids',
                                multiple: true,
                                useAutoComplete: true,
                                endpoint: '/staff/notifications/actions',
                                label: 'Trigger On'
                            },
                            {
                                type: 'remote_select',
                                name: 'type_ids',
                                multiple: true,
                                endpoint: '/staff/notifications/types',
                                label: 'Type',
                                useAutoComplete: true
                            }
                        ]}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <PageableTable
                        loading={isLoading}
                        pagination={data.meta.pagination}
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                        headers={[
                            'ID',
                            'Name',
                            'Type',
                            'Trigger On',
                            'Endpoints',
                            'Rules',
                            <RbgButton
                                onClick={() => {
                                    handleEditNotification();
                                }}
                            >
                                <IconPlus size={15} />
                            </RbgButton>
                        ]}
                        data={data.data}
                        rowDecorator={(row) => {
                            return [
                                row.id,
                                row.name,
                                row.type.data.name,
                                row.action.data.requires_action
                                    ? `${row.action.data.name}: ${row.staff_action.data.name}`
                                    : row.action.data.name,
                                <RbgStack spacing={1}>
                                    {row.endpoints.data
                                        .filter((e) => e.enabled === true)
                                        .map((row) => {
                                            return <div key={row.id}>{row.target}</div>;
                                        })}

                                    {row.endpoints.data
                                        .filter((e) => e.enabled === false)
                                        .map((row) => {
                                            return (
                                                <div key={row.id} style={{ color: 'grey', textDecoration: 'line-through' }}>
                                                    {row.target}
                                                </div>
                                            );
                                        })}
                                </RbgStack>,
                                <RbgStack spacing={1}>
                                    {row.rules.data.map((row, index) => {
                                        return <div key={index}>{row.display_name}</div>;
                                    })}
                                </RbgStack>,
                                <ButtonGroup>
                                    <RbgButton
                                        onClick={() => {
                                            handleEditNotification(row);
                                        }}
                                    >
                                        <IconEdit size={15} />
                                    </RbgButton>
                                    <RbgButton
                                        onClick={() => {
                                            handleViewHistory(row);
                                        }}
                                    >
                                        <IconHistory size={15} />
                                    </RbgButton>
                                    <RbgButton onClick={() => handleClone(row)}>
                                        <IconCopy size={15} />
                                    </RbgButton>
                                    <DeleteConfirm onDelete={() => handleDelete(row)} disabled={deleteMutation.isPending} />
                                </ButtonGroup>
                            ];
                        }}
                    />
                </RbgGrid>
                <RbgGrid item xs={12} />
            </RbgGrid>
        </MainCard>
    );
}

export default HrConfigNotifications;
