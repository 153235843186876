import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import { payrollPeriod } from './draftPayslipTypes';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function DeletePayslipSlips({ payrollPeriod }) {
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff/payroll/period/${payrollPeriod.id}/slips`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('draft_payslips') > -1;
                }
            });
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <WarningCard>Are you sure you want to delete the payslip data for this week?</WarningCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <DeleteConfirm
                    disabled={deleteMutation.isPending}
                    onDelete={() => deleteMutation.mutate()}
                    fullWidth
                    confirmText="Yes, i am sure i know what i'm doing"
                    btnText="Delete payslip data"
                />
            </RbgGrid>
        </RbgGrid>
    );
}

DeletePayslipSlips.propTypes = {
    payrollPeriod: PropTypes.shape(payrollPeriod).isRequired
};

export default DeletePayslipSlips;
