import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import dayjs from 'dayjs';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import EventDispatcher from '../../../../../utils/EventDispatcher';

function FourthImportRow({ rowData }) {
    const [row, setRow] = useState(rowData);
    useEffect(() => {
        const id = EventDispatcher.subscribe('pusher-message', (event) => {
            if (event.event === 'file_updated') {
                if (event.data.data.file_id === rowData.file_id) {
                    const data = event.data.data;
                    data.result = JSON.parse(data.result);
                    setRow(data);
                }
            }
        });
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [rowData.file_id]);
    return (
        <RbgTableRow key={row.id}>
            <RbgTableCell>{row.file_name}</RbgTableCell>
            <RbgTableCell>{dayjs(row.date_created).format('DD/MM/YYYY HH:mm')}</RbgTableCell>
            <RbgTableCell>{dayjs(row.date_updated).format('DD/MM/YYYY HH:mm')}</RbgTableCell>
            <RbgTableCell>{row.date_started ? dayjs(row.date_started).format('DD/MM/YYYY HH:mm') : 'N/A'}</RbgTableCell>
            <RbgTableCell>{row.date_completed ? dayjs(row.date_completed).format('DD/MM/YYYY HH:mm') : 'N/A'}</RbgTableCell>
            <RbgTableCell>
                <pre>{JSON.stringify(row.result, null, 2)}</pre>
            </RbgTableCell>
        </RbgTableRow>
    );
}

FourthImportRow.propTypes = {
    rowData: PropTypes.object.isRequired
};
export default FourthImportRow;
