import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const rotaReducer = createSlice({
    name: 'rota',
    initialState: {
        week: null,
        year: null,
        templateId: null,
        staff: [],
        days: [],
        rota: {},
        areas: [],
        allData: {},
        hasChanges: false
    },
    reducers: {
        setRota: (state, action) => {
            const data = action.payload.data;
            state.shifts = data.shifts.data;
            state.staff = action.payload.meta.staff;
            state.areas = action.payload.meta.areas.map((a) => {
                a.shifts = [];
                return a;
            });
            state.days = data.days.data;
            state.original_data = action.payload;
            state.rota = {
                cost_centre: data.cost_centre,
                date_created: data.date_created,
                id: data.id,
                start_date: data.start_date,
                end_date: data.end_date,
                location_name: data.location_name,
                template_id: data.template_id,
                week: data.week,
                year: data.year
            };
        },
        cancelChanges: (state, action) => {
            state.shifts = state.original_data.data.shifts.data;
            state.hasChanges = false;
        },
        updateShift: (state, action) => {
            const shift = action.payload;
            let found = false;
            if (shift.shift_start && shift.shift_end) {
                const start = dayjs(shift.shift_start);
                const end = dayjs(shift.shift_end);
                shift.length = end.diff(start, 'minutes');
                shift.length_hours = shift.length / 60;
                if (shift.pay_type === 1) {
                    shift.cost = shift.length_hours * shift.rate;
                }
            }
            const shifts = state.shifts.map((s) => {
                if (s.id === shift.id) {
                    s = shift;
                    found = true;
                }
                return s;
            });
            if (found === false) {
                shifts.push(shift);
            }

            //Check if the original shifts match the new shifts, if not the rota has changes
            state.hasChanges = JSON.stringify(shifts) !== JSON.stringify(state.original_data.data.shifts.data);

            state.shifts = shifts;
        }
    }
});

export const { setRota, updateShift, cancelChanges } = rotaReducer.actions;
