import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import Debug from '../../../../../ui_component/misc/Debug';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import { currencyGBP } from '../../../../../utils/currencyFormatter';

function PayrollPeriodReport({ id }) {
    const { data } = useQuery({
        queryKey: [{ url: `/staff/payslips/period/${id}/report` }]
    });

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Location', 'Total Payslips', 'Gross Pay', 'No of queries']}
                    rows={data.data.map((row) => [
                        `${row.name} (${row.cost_centre})`,
                        row.total,
                        currencyGBP().format(row.gross_pay),
                        row.queries
                    ])}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

PayrollPeriodReport.propTypes = {
    id: PropTypes.number.isRequired
};
export default PayrollPeriodReport;
