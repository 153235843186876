import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Box } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Box](https://mui.com/material-ui/react-box/)
 *
 * API:
 *
 * - [Box API](https://mui.com/material-ui/api/box/)
 */

const RbgBox = forwardRef(({ children, ...others }, ref) => (
    <Box ref={ref} {...others}>
        {children}
    </Box>
));

export default RbgBox;

RbgBox.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node])
};
