/* eslint-disable camelcase */
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import pusherBatchAuthorizer from 'pusher-js-auth';
import Config from '../config';
import EventDispatcher from './EventDispatcher';
import RcloudAuthContext from '../contexts/RcloudAuthContext';
import { menuType, presetColors } from 'store/reducers/customizationReducer';
import { snackbarOpen } from 'store/reducers/snackbarReducer';
import Api from './api';
import PropTypes from 'prop-types';

const PusherContext = createContext(null);

function usePusher() {
    return useContext(PusherContext);
}

function PusherWrapper({ children }) {
    const [pusher, setPusher] = useState(null);
    const { isLoggedIn, user } = useContext(RcloudAuthContext);
    const dispatch = useDispatch();
    const { bar } = useSelector((state) => state.bar);
    const processMessage = (event, data) => {
        if (event === 'preference-update') {
            EventDispatcher.dispatch('preference-update', data);
            if (data.preference === 'theme') {
                dispatch(menuType(data.value));
            }
            if (data.preference === 'presetColor') {
                dispatch(presetColors(data.value));
            }
        }
        if (event === 'online_booking_made') {
            dispatch(
                snackbarOpen({
                    open: true,
                    message: data.message,
                    variant: 'alert',
                    severity: 'success'
                })
            );
        }
        EventDispatcher.dispatch('pusher-message', {
            event,
            data
        });
    };
    useEffect(() => {
        Api.getCached('/rest/pusher/key', {}).then((data) => {
            const pusherModel = new Pusher(data.data.key, {
                authorizer: pusherBatchAuthorizer,
                authDelay: 200,
                wsHost: Config.pusherHost,
                enabledTransports: ['ws', 'wss'],
                cluster: 'eu',
                auth: {},
                authEndpoint: '/api/rest/pusher/admin-key-batch'
            });
            pusherModel.bind_global((event, data) => processMessage(event, data));
            if (isLoggedIn) {
                window.user_channel = pusherModel.subscribe(`private-pp_user-${user.id}`);
                // window.location_channel = pusherModel.subscribe(`private-bar-${bar.bar_id}`);
                window.presence_channel = pusherModel.subscribe('presence-pp');
            }
            window.pusher = pusherModel;
            setPusher(pusherModel);
        });
    }, [setPusher]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (pusher && bar) {
            window.location_channel = pusher.subscribe(`private-bar-${bar.id}`);
            window.location_cc_channel = pusher.subscribe(`private-location-${bar.cost_centre}`);
        }
    }, [bar, pusher]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (pusher) {
            const eventId = EventDispatcher.subscribe('pusher-subscribe', (channel) => {
                pusher.subscribe(channel);
            });
            return () => {
                EventDispatcher.unsubscribe(eventId);
            };
        }
    }, [pusher]);
    return <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>;
}

PusherWrapper.propTypes = {
    children: PropTypes.any
};

export default PusherWrapper;
export { usePusher };
