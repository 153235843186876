import { useQuery } from '@tanstack/react-query';
import Debug from '../../../../../ui_component/misc/Debug';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import MainCard from '../../../../../ui_component/cards/MainCard';
import { useState } from 'react';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import dayjs from 'dayjs';
import RelativeTime from '../../../../../ui_component/relative-time/RelativeTime';

function TalosIncoming() {
    const [filter, setFilter] = useState({
        page: 1,
        type_id: 11
    });
    const { data, loading, isLoading } = useQuery({
        queryKey: [{ url: '/integrations/webhooks', params: filter }]
    });
    if (loading || isLoading) {
        return <CardLoader />;
    }

    return (
        <MainCard title="Talos Incoming Data">
            <PageableTable
                headers={['Date', 'IP', 'Name', 'Location', 'Job', 'Data']}
                pagination={data.meta.pagination}
                data={data.data}
                handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                rowDecorator={(row) => {
                    const name = `${row.data_json?.Applicant?.FirstName} ${row.data_json?.Applicant?.Surname}`;
                    const job = row.data_json?.Job?.JobTitle;
                    const location = `${row.data_json?.Job?.Location} ${row.data_json?.Job?.PostCode}`;
                    return [
                        <RelativeTime time={row.date} />,
                        row.ip_address,
                        name,
                        location,
                        job || 'N/A',
                        <RbgButton
                            onClick={() => {
                                DialogHelper.openMdDialog(
                                    'View Talos Data',
                                    <div>
                                        <pre>{JSON.stringify(row.data_json, null, 2)}</pre>
                                    </div>
                                );
                            }}
                        >
                            View
                        </RbgButton>
                    ];
                }}
            />
        </MainCard>
    );
}

export default TalosIncoming;
