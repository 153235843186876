import PropTypes from 'prop-types';
import { staffNotificationRule } from '../../StaffDataTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import _ from 'lodash';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import NotificationRuleField from './NotificationRuleField';

function NotificationRules({ rules, handleChange }) {
    const addRule = (rule) => {
        const newRules = _.extend([], rules);
        newRules.push(rule);
        handleChange(newRules);
    };

    const editRule = (rule) => {
        const newRules = _.extend([], rules).map((curRule) => {
            if (curRule.id === rule.id) {
                return rule;
            }
            return curRule;
        });
        handleChange(newRules);
    };
    const deleteRule = (rule) => {
        const newRules = _.extend([], rules).filter((curRule) => {
            if (curRule.id === rule.id) {
                return false;
            }
            return true;
        });
        handleChange(newRules);
    };
    return (
        <RbgGrid container>
            {rules.length > 0 && (
                <RbgGrid item xs={12}>
                    <RbgTable>
                        <RbgTableBody>
                            {rules.map((rule) => {
                                return <NotificationRuleField rule={rule} handleChange={editRule} key={rule.id} deleteRule={deleteRule} />;
                            })}
                        </RbgTableBody>
                    </RbgTable>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgButton
                    onClick={() =>
                        addRule({
                            id: _.uniqueId('new'),
                            field: '',
                            operator: '=',
                            value: ''
                        })
                    }
                >
                    <IconPlus /> Add Rule
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

NotificationRules.propTypes = {
    rules: PropTypes.arrayOf(PropTypes.shape(staffNotificationRule)),
    handleChange: PropTypes.func
};

export default NotificationRules;
