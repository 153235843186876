import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// mui wrappers
import RbgTextInput from 'ui_component/extended/Form/RbgTextInput';
import RbgDatePicker from 'ui_component/date-pickers/RbgDatePicker';
import RbgRemoteSelect from 'ui_component/remote-select/RbgRemoteSelect';

// 3rd party
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../../../utils/api';
import RbgSelect from '../../../../../../../ui_component/extended/Form/RbgSelect';
import RbgGrid from '../../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';

function EditDetails({ staffDetails, getError }) {
    const [fieldErrors, setFieldErrors] = useState([]);

    const [details, setDetails] = useState({
        surname: staffDetails.surname,
        forename: staffDetails.forename,
        birth_date: staffDetails.birth_date,
        ethnicity: staffDetails.ethnicity,
        ethnicity_other: staffDetails.ethnicity,
        gender: staffDetails.gender,
        known_as: staffDetails.known_as,
        gender_other: staffDetails.gender_other,
        title: staffDetails.title,
        address_line1: staffDetails.address_line1,
        address_line2: staffDetails.address_line2,
        address_line3: staffDetails.address_line3,
        address_county: staffDetails.address_county,
        address_postal_code: staffDetails.address_postal_code,
        pronoun_id: staffDetails.pronoun_id
    });
    const filterGenderAndEthnicityData = (data) =>
        data.data.map((item) => ({
            id: item.id,
            label: item.name,
            value: item.id,
            allow_other: item.allow_other,
            object: item
        }));

    const handleFieldChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (e) => {
        setDetails((prev) => ({ ...prev, birth_date: dayjs(e).format('YYYY-MM-DD') }));
    };

    useEffect(() => {
        const handleSave = () => {
            setFieldErrors([]);
            Api.patch(`/staff/${staffDetails.staff_id}/personal-details`, details).then((response) => {
                if (response.ok) {
                    DialogHelper.closeDialog();
                    DialogHelper.successNotification('Staff member personal details updated');
                    return;
                }
                setFieldErrors(response.field_errors);
            });
        };
        DialogHelper.addSaveButton(() => handleSave(), 'Save');
    }, [staffDetails.staff_id, details]);

    return (
        <RbgGrid container>
            <RbgGrid item xs={12} md={2}>
                <RbgSelect
                    name="title"
                    value={details.title}
                    label="Title"
                    values={['Mx', 'Miss', 'Mrs', 'Ms', 'Mr'].map((f) => ({ value: f, label: f }))}
                    error={fieldErrors.length > 0 && getError('title', fieldErrors)}
                    handleChange={handleFieldChange}
                    size="small"
                />
            </RbgGrid>
            <RbgGrid item xs={12} md={5}>
                <RbgTextInput
                    size="small"
                    name="forename"
                    label="Forename"
                    value={details.forename}
                    error={fieldErrors.length > 0 && getError('forename', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={12} md={5}>
                <RbgTextInput
                    size="small"
                    name="surname"
                    label="Surname"
                    value={details.surname}
                    error={fieldErrors.length > 0 && getError('surname', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={12} md={6}>
                <RbgTextInput
                    size="small"
                    name="known_as"
                    label="Known As (Forename only)"
                    value={details.known_as}
                    onChange={handleFieldChange}
                    help="If this team member prefers to go by another forename than their legal name, enter it here"
                />
            </RbgGrid>
            <RbgGrid item xs={12} md={6}>
                <RbgRemoteSelect
                    filterData={(data) => {
                        return data.data.map((row) => ({
                            value: `${row.id}`,
                            label: row.display_name
                        }));
                    }}
                    endpoint="/staff/pronouns"
                    size="small"
                    name="pronoun_id"
                    label="Pronouns"
                    value={details.pronoun_id}
                    handleChange={handleFieldChange}
                    help="If this team member prefers to go by another forename than their legal name, enter it here"
                />
            </RbgGrid>
            <RbgGrid item xs={12} md={6}>
                <RbgDatePicker
                    label="Date Of Birth"
                    InputProps={{ size: 'small' }}
                    value={dayjs(details.birth_date)}
                    error={fieldErrors.length > 0 && getError('birth_date', fieldErrors)}
                    onChange={handleDateChange}
                    maxDate={dayjs().subtract(16, 'years')}
                />
            </RbgGrid>
            <RbgGrid item xs={12} md={6}>
                <RbgRemoteSelect
                    disableBlankOption
                    useCached
                    size="small"
                    name="ethnicity"
                    endpoint="staff/ethnicities"
                    label="Ethnicity"
                    value={details.ethnicity}
                    handleChange={handleFieldChange}
                    filterData={filterGenderAndEthnicityData}
                />
            </RbgGrid>

            {details.ethnicity === 8 && (
                <RbgGrid item xs={12} md={6}>
                    <RbgTextInput
                        size="small"
                        name="ethnicity_other"
                        label="Ethnicity (Other)"
                        value={details.ethnicity_other}
                        error={fieldErrors.length > 0 && getError('ethnicity_other', fieldErrors)}
                        onChange={handleFieldChange}
                    />
                </RbgGrid>
            )}
            <RbgGrid item xs={12} md={6}>
                <RbgRemoteSelect
                    disableBlankOption
                    useCached
                    size="small"
                    name="gender"
                    endpoint="staff/genders"
                    label="Gender"
                    value={details.gender}
                    handleChange={handleFieldChange}
                    filterData={filterGenderAndEthnicityData}
                />
            </RbgGrid>
            {staffDetails.gender === 4 && (
                <RbgGrid item xs={12} md={6}>
                    <RbgTextInput
                        size="small"
                        name="gender_other"
                        label="Gender (Other)"
                        value={details.gender_other}
                        error={fieldErrors.length > 0 && getError('gender_other', fieldErrors)}
                        onChange={handleFieldChange}
                    />
                </RbgGrid>
            )}
            <RbgGrid item xs={6}>
                <RbgTextInput
                    size="small"
                    name="address_line1"
                    label="Address Line 1"
                    value={details.address_line1}
                    error={fieldErrors.length > 0 && getError('address_line1', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgTextInput
                    size="small"
                    name="address_line2"
                    label="Address Line 2"
                    value={details.address_line2}
                    error={fieldErrors.length > 0 && getError('address_line2', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgTextInput
                    size="small"
                    name="address_line3"
                    label="Address Line 3"
                    value={details.address_line3}
                    error={fieldErrors.length > 0 && getError('address_line3', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgTextInput
                    size="small"
                    name="address_county"
                    label="County"
                    value={details.address_county}
                    error={fieldErrors.length > 0 && getError('address_county', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgTextInput
                    size="small"
                    name="address_postal_code"
                    label="Post Code"
                    value={details.address_postal_code}
                    error={fieldErrors.length > 0 && getError('address_postal_code', fieldErrors)}
                    onChange={handleFieldChange}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

export default EditDetails;

EditDetails.propTypes = {
    staffDetails: PropTypes.object,
    getError: PropTypes.func
};
