const body = document.querySelector('body');

const overflow = (state) => {
    if (state) {
        body.style.overflow = 'hidden';
    } else {
        body.style.overflow = 'auto';
    }
};

export default overflow;
