import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { useState } from 'react';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgFilter from '../../../../../ui_component/extended/Form/RbgFilter';
import ServerSidePagination from '../../../../../ui_component/pagination/ServerSidePagination';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import { currencyGBP } from '../../../../../utils/currencyFormatter';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ApproveAuthorisation from './ApproveAuthorisation';
import RejectAuthorisation from './RejectAuthorisation';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import RbgLink from '../../../../../ui_component/mui-wrappers/Link/RbgLink';

function TeamApprovals() {
    const [filter, setFilter] = useState({
        page: 1,
        search: '',
        include: 'staff_member',
        status: 1
    });
    const { data, error, isFetched } = useQuery({
        queryKey: [{ url: '/staff/pay-rate-approvals', params: filter }, 'pay_rate_approvals']
    });

    const filters = [
        {
            name: 'search',
            type: 'text',
            label: 'Search',
            useDebounce: true
        },
        {
            name: 'status',
            type: 'remote_select',
            label: 'Status',
            endpoint: '/staff/pay-rate-approvals/statuses'
        }
    ];
    return (
        <MainCard>
            <RbgGrid container>
                {error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgFilter filter={filter} onChange={(filter) => setFilter(filter)} filters={filters} />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination
                        pagination={data.meta.pagination}
                        changePage={(page) => setFilter((prev) => ({ ...prev, page }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={[
                            'Staff Member',
                            'Status',
                            'Location',
                            'Added By',
                            'Position',
                            'Current Rate',
                            'New Rate',
                            'New Contract',
                            'Effective From',
                            ''
                        ]}
                        keys={data.data.map((row) => row.id)}
                        rows={data.data.map((row) => {
                            let color = '';
                            let prefix = '';
                            if (row.rate > row.current_rate) {
                                prefix = '+';
                                color = 'green';
                            }
                            if (row.rate < row.current_rate) {
                                color = 'red';
                            }
                            let position = row.position_name;
                            if (row.staff_member.data.role.data.position.data.name !== row.position_name) {
                                position = `${row.staff_member.data.role.data.position.data.name} -> ${row.position_name}`;
                            }
                            if (row.position_name === null) {
                                position = `${row.staff_member.data.role.data.position.data.name}`;
                            }
                            return [
                                <RbgLink href={`/staff/view/${row.staff_id}`}>{row.staff_member_name}</RbgLink>,
                                row.status.data.name,
                                row.location_name,
                                row.created_by_name,
                                position,
                                currencyGBP().format(row.current_rate),
                                <RbgStack direction="row" spacing={1} align="center" justifyContent="center">
                                    <RbgTypography>{currencyGBP().format(row.rate)}</RbgTypography>
                                    <RbgTypography color={color}>
                                        ({prefix}
                                        {currencyGBP().format(row.rate - row.current_rate)})
                                    </RbgTypography>
                                </RbgStack>,
                                <RbgStack justifyContent="center" alignItems="center">
                                    <BooleanIcon value={row.contract_template_name !== null} />
                                    {row.contracted_hours.data.hours !== undefined ? row.contracted_hours.data.name : null}
                                </RbgStack>,
                                <RbgStack justifyContent="center" direction="row" spacing={1}>
                                    <RbgTypography>{dayjs(row.effective_from_date).format('DD/MM/YYYY')}</RbgTypography>
                                    <RbgTypography>({dayjs(row.effective_from_date).fromNow()})</RbgTypography>
                                </RbgStack>,
                                <RbgStack direction="row" spacing={2} justifyContent="center">
                                    <RbgButton
                                        icon={<IconCheck size={15} />}
                                        variant="contained"
                                        onClick={() => {
                                            DialogHelper.openSmDialog('Approve Team Member Change', <ApproveAuthorisation rate={row} />);
                                        }}
                                    />
                                    <RbgButton
                                        icon={<IconTrash size={15} />}
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            DialogHelper.openSmDialog('Reject Team Member Change', <RejectAuthorisation rate={row} />);
                                        }}
                                    />
                                </RbgStack>
                            ];
                        })}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination
                        pagination={data.meta.pagination}
                        changePage={(page) => setFilter((prev) => ({ ...prev, page }))}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default TeamApprovals;
