import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import RbgTextInput from '../../../../../../ui_component/extended/Form/RbgTextInput';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import dayjs from 'dayjs';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import Loader from '../../../../../../ui_component/loading/Loader';

function HourlyRateRateCustomField({ fields, handleChange, position, location, value }) {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }
    const [override, setOverride] = useState(false);
    const enabled = !!(fields.date_of_birth && position.id && location);
    const { data, error, isPending } = useQuery({
        initialData: {
            data: { rate: 0 }
        },
        queryKey: [
            {
                url: '/staff/onboarding/pay-rate',
                params: {
                    cost_centre: location,
                    date_of_birth: fields.date_of_birth,
                    position_id: position.id,
                    date: fields.start_date ? fields.start_date : dayjs().format('YYYY-MM-DD')
                }
            }
        ],
        enabled
    });
    useEffect(() => {
        if (data.data.rate && !value) {
            handleChange(data.data.rate);
        }
    }, [data.data.rate, handleChange, value]);
    useEffect(() => {
        if (data.data.rate !== value) {
            setOverride(true);
        } else {
            setOverride(false);
        }
    }, [data.data.rate, value]);
    if (!enabled) {
        let message = 'Please enter date of birth';
        if (!position.id) {
            message = 'Please enter job role';
        }
        if (!location) {
            message = 'Please enter location';
        }
        if (!fields.start_date) {
            message = 'Please enter start date';
        }
        return <RbgTextInput disabled error={message} label="Pay Rate" />;
    }
    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    if (isPending) {
        return <Loader />;
    }
    if (!override) {
        return (
            <RbgTextInput
                disabled
                value={value}
                label="Pay Rate"
                textPrimary="£"
                textSecondary={
                    <RbgButton color="warning" variant="outlined" onClick={() => setOverride(true)}>
                        Change
                    </RbgButton>
                }
            />
        );
    }
    if (override) {
        return (
            <RbgTextInput
                value={value}
                type="number"
                min={data?.data?.rate ? data.data.rate : 0}
                onChange={(e) => handleChange(e.target.value)}
                label="Pay Rate"
                warning={`Default rate is £${data?.data?.rate ? data.data.rate : 0}`}
                textPrimary="£"
                textSecondary={
                    <RbgButton
                        color="warning"
                        variant="outlined"
                        onClick={() => {
                            handleChange(data.data.rate);
                            setOverride(false);
                        }}
                    >
                        Revert
                    </RbgButton>
                }
            />
        );
    }
}

HourlyRateRateCustomField.propTypes = {
    fields: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    position: PropTypes.object.isRequired,
    location: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default HourlyRateRateCustomField;
