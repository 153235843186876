import { staffDetails, staffRole } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { gridSpacing } from '../../../../../store/constant';
import dayjs from 'dayjs';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import { useEffect, useState } from 'react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import Api from '../../../../../utils/api';
import RbgTextArea from '../../../../../ui_component/text-area/RbgTextArea';
import MDEditor from '@uiw/react-md-editor';

function SupportCentre({ role, details }) {
    const [newRole, setNewRole] = useState('');
    const [newDepartment, setNewDepartment] = useState('');
    const [newLineManager, setNewLineManager] = useState('');
    const [howCanIHelp, setHowCanIHelp] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const { data, isFetching, isPending } = useQuery({
        queryKey: [{ url: '/staff/sc-meta', method: 'GET' }],
        initialData: {
            data: {
                roles: {
                    data: []
                },
                departments: {
                    data: []
                },
                staff: {
                    data: []
                }
            }
        }
    });

    const getRoleName = (roleId) => {
        const role = data.data.roles.data.find((role) => {
            return role.id === roleId;
        });
        return role ? role.name : 'N/A';
    };
    const roleName = getRoleName(role.sc_role);

    const getDepartmentName = (departmentId) => {
        const department = data.data.departments.data.find((dep) => {
            return dep.id === departmentId;
        });
        return department ? department.name : 'N/A';
    };
    const departmentName = getDepartmentName(role.sc_department);
    const roles = data.data.roles.data;
    const departments = data.data.departments.data;
    const updateMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${details.staff_id}/support-centre-role`, {
                role_id: newRole,
                department_id: newDepartment,
                line_manager_id: newLineManager,
                how_help: howCanIHelp
            });
        }
    });
    useEffect(() => {
        setNewRole(role.sc_role);
        setNewDepartment(role.sc_department);
        setNewLineManager(role.sc_line_manager_id);
        setHowCanIHelp(role.sc_how_help);
    }, [role.sc_role, role.sc_department, role.sc_line_manager_id, role.sc_how_help]);
    useEffect(() => {
        let changes = false;
        if (role.sc_role !== newRole) {
            changes = true;
        }
        if (role.sc_line_manager_id !== newLineManager) {
            changes = true;
        }
        if (role.sc_department !== newDepartment) {
            changes = true;
        }
        if (role.sc_how_help !== howCanIHelp) {
            changes = true;
        }
        setHasChanges(changes);
    }, [role.sc_role, role.sc_department, role.sc_line_manager_id, role.sc_how_help, newRole, newDepartment, newLineManager, howCanIHelp]);
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    {details.display_name} is a <strong>{roleName}</strong> in the <strong>{departmentName}</strong> department. They have
                    been in this role since <strong>{dayjs(role.start_date).format('DD/MM/YYYY')}</strong>, their line manager is{' '}
                    <strong>{role.sc_manager_name}</strong>
                </IntroCardPrimary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgGrid container spacing={5}>
                    <RbgGrid item sm={12} lg={4}>
                        <RbgSelect
                            name="role"
                            label="Role"
                            value={newRole}
                            handleChange={(e) => setNewRole(e.target.value)}
                            values={roles.map((role) => ({ value: role.id, label: role.name }))}
                        />
                    </RbgGrid>
                    <RbgGrid item sm={12} lg={4}>
                        <RbgSelect
                            name="department"
                            label="Department"
                            value={newDepartment}
                            handleChange={(e) => setNewDepartment(e.target.value)}
                            values={departments.map((role) => ({ value: role.id, label: role.name }))}
                        />
                    </RbgGrid>
                    <RbgGrid item sm={12} lg={4}>
                        <RbgSelect
                            name="line_manager"
                            label="Line Manager"
                            value={newLineManager}
                            handleChange={(e) => setNewLineManager(e.target.value)}
                            values={data.data.staff.data}
                        />
                    </RbgGrid>
                </RbgGrid>
            </RbgGrid>
            <RbgGrid item xs={12} sx={{ m: 1 }}>
                <MDEditor value={howCanIHelp} onChange={(value) => setHowCanIHelp(value)} height={500} />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton disabled={!hasChanges} sx={{ width: '100%' }} onClick={() => updateMutation.mutate()}>
                    Save
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

SupportCentre.propTypes = {
    role: PropTypes.shape(staffRole),
    details: PropTypes.shape(staffDetails)
};
export default SupportCentre;
