import PropTypes from 'prop-types';
import StaffFileUpload from '../../../Component/StaffFileUpload';
import RbgTextInput from '../../../../../../ui_component/extended/Form/RbgTextInput';

function FileUploadCustomField({ field, value, handleChange }) {
    let data = null;
    if (typeof value === 'object') {
        data = value;
    } else if (value && JSON.parse(value)) {
        data = JSON.parse(value);
    }
    if (data !== null) {
        return <RbgTextInput disabled value={data.original_filename} />;
    }
    return (
        <StaffFileUpload
            onFileUploaded={(f) => handleChange(JSON.stringify(f))}
            staffId={0}
            content={field.label}
            bucket="rbg-staff-documents"
        />
    );
}

FileUploadCustomField.propTypes = {
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired
};
export default FileUploadCustomField;
