import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// ==============================|| PARTYPRO REDUCER ||============================== //

export const partyProReducer = createSlice({
    name: 'partyPro',
    initialState: {
        periods: [],
        calendarData: undefined,
        currentMonth: '',
        tabs: [],
        tabsHistory: ['calendar'],
        currentTab: 'calendar'
    },
    reducers: {
        addTabs: (state, action) => {
            const checkForTab = _.find(state.tabs, (tab) => tab.value === action.payload.value);
            if (!checkForTab) {
                state.tabs.push({
                    canClose: true,
                    date: action.payload.date,
                    label: action.payload.label,
                    value: action.payload.value,
                    content: action.payload.content,
                    edit: false,
                    error: false,
                    id: action.payload.id
                });
                state.currentTab = action.payload.value;
                state.tabsHistory.push(action.payload.value);
            } else {
                state.currentTab = action.payload.value;
                state.tabsHistory.push(action.payload.value);
            }
        },
        changeTabDay: (state, action) => {
            const checkForTab = _.find(state.tabs, (tab) => tab.id === action.payload.id);
            const newTabs = state.tabs.slice();
            const index = state.tabs.indexOf(checkForTab);
            if (checkForTab) {
                let targetTab = { ...checkForTab };
                targetTab = {
                    ...targetTab,
                    date: action.payload.date,
                    label: action.payload.newDayLabel
                };
                newTabs.splice(index, 1, targetTab);
                state.tabs = newTabs;
            }
        },
        updateTab: (state, action) => {
            state.tabs = state.tabs.map((tab) => {
                if (tab.id === action.payload.id) {
                    tab = _.extend(tab, action.payload);
                }
                return tab;
            });
        },
        setTabs: (state, action) => {
            state.tabs = action.payload;
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
            state.tabsHistory.push(action.payload);
        },
        removeHistoryTab: (state, action) => {
            state.tabsHistory = state.tabsHistory.filter((tab) => tab !== action.payload);
            state.currentTab = state.tabsHistory.at(-1);
        },
        setCalendarData: (state, action) => {
            state.calendarData = action.payload;
        },
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
        setMonth: (state, action) => {
            state.currentMonth = action.payload;
        }
    }
});

export const { setCalendarData, setPeriods, setMonth, addTabs, setTabs, changeTabDay, setCurrentTab, removeHistoryTab, updateTab } =
    partyProReducer.actions;
