import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Checkbox](https://mui.com/material-ui/react-checkbox/)
 * - [Transfer List](https://mui.com/material-ui/react-transfer-list/)
 *
 * API:
 *
 * - [Checkbox API](https://mui.com/material-ui/api/checkbox/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */

const RbgCheckbox = forwardRef(({ label, disabled, checked, onChange, ...others }, ref) => {
    if (label) {
        return (
            <FormGroup>
                <FormControlLabel
                    disabled={disabled}
                    control={<Checkbox ref={ref} checked={checked} onChange={onChange} {...others} />}
                    label={label}
                />
            </FormGroup>
        );
    }
    return <Checkbox ref={ref} disabled={disabled} checked={checked} onChange={onChange} {...others} />;
});

export default RbgCheckbox;

RbgCheckbox.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool
};
