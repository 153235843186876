import { useState, useEffect } from 'react';
// material-ui imports
import { Dialog, Button, Tooltip, Fade, useMediaQuery, Divider, useTheme } from '@mui/material';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
// 3rd party imports
import { IconX, IconDeviceFloppy } from '@tabler/icons-react';
// project imports
import EventDispatcher from 'utils/EventDispatcher';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';
import { useDispatch } from 'react-redux';
import { snackbarOpen } from '../../store/reducers/snackbarReducer';

const defaultState = {
    open: false,
    content: <span />,
    title: '',
    icon: {},
    saveButtonDisabled: false,
    hideClose: false,
    hideSave: true,
    saveText: 'Save',
    closeText: 'Close',
    saveAction: () => {},
    maxWidth: 'lg',
    secondary: null,
    fullScreen: false
};

function RbgDialog() {
    const theme = useTheme();
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState(defaultState);
    const handleClose = () => setState(defaultState);
    const dispatch = useDispatch();

    useEffect(() => {
        const openListener = EventDispatcher.subscribe('openDialog', (options) =>
            setState((prev) => ({
                ...prev,
                open: true,
                content: options.content,
                title: options.title,
                secondary: options.secondary,
                icon: options.icon
            }))
        );
        const closeListener = EventDispatcher.subscribe('closeDialog', () => setState(defaultState));
        const updateListener = EventDispatcher.subscribe('updateDialog', (options) => setState((prev) => ({ ...prev, ...options })));

        return () => {
            EventDispatcher.unsubscribe(openListener);
            EventDispatcher.unsubscribe(closeListener);
            EventDispatcher.unsubscribe(updateListener);
        };
    }, []);
    useEffect(() => {
        const listener = EventDispatcher.subscribe('show-notification', (options) => {
            dispatch(snackbarOpen({ ...options, open: true }));
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
        };
    }, [dispatch]);
    const {
        open,
        content,
        title,
        icon,
        saveAction,
        hideSave,
        hideClose,
        saveButtonDisabled,
        saveText,
        closeText,
        maxWidth,
        secondary,
        fullScreen
    } = state;
    if (open === false) {
        return <span />;
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth
            maxWidth={maxWidth}
            open={open}
            disableEscapeKeyDown
            TransitionProps={{
                timeout: 200
            }}
            TransitionComponent={Fade}
        >
            <RbgStack>
                <RbgStack direction="row" justifyContent="space-between" alignItems="center">
                    <RbgStack direction="row" spacing={2}>
                        {!matchesSmDown ? <RbgTypography color={theme.palette.secondary.main}>{icon}</RbgTypography> : null}
                        <RbgTypography variant="h3">{title}</RbgTypography>
                    </RbgStack>
                    {secondary || (
                        <Tooltip title="Close">
                            <Button onClick={handleClose}>
                                <IconX />
                            </Button>
                        </Tooltip>
                    )}
                </RbgStack>
                <Divider sx={{ width: '100%' }} orientation="horizontal" flexItem />
            </RbgStack>
            <DialogContent sx={{ p: 2 }}>{content}</DialogContent>
            <DialogActions sx={{ px: 2, py: 0, flexWrap: 'wrap' }}>
                <Divider sx={{ width: '100%', mb: 1.5 }} orientation="horizontal" flexItem />
                <RbgStack direction="row" spacing={2}>
                    {!hideClose && (
                        <Button variant="outlined" startIcon={<IconX />} onClick={handleClose}>
                            {closeText}
                        </Button>
                    )}
                    {!hideSave && (
                        <Button
                            sx={{
                                background: theme.palette.success.main,
                                '&:hover': { background: theme.palette.success.dark }
                            }}
                            variant="contained"
                            startIcon={<IconDeviceFloppy />}
                            disabled={saveButtonDisabled}
                            onClick={saveAction}
                        >
                            {saveText}
                        </Button>
                    )}
                </RbgStack>
            </DialogActions>
        </Dialog>
    );
}

export const DialogHelper = {
    openDialog(title, content, icon, secondary) {
        EventDispatcher.dispatch('openDialog', {
            title,
            content,
            icon,
            secondary
        });
    },
    showSaveConfirm(title, content) {
        this.openDialog(title, content);
    },
    openSmDialog(title, content, icon, secondary) {
        this.updateDialog({ maxWidth: 'sm' });
        this.openDialog(title, content, icon, secondary);
    },
    openMdDialog(title, content, icon, secondary) {
        this.updateDialog({ maxWidth: 'md' });
        this.openDialog(title, content, icon, secondary);
    },
    openLgDialog(title, content, icon, secondary) {
        this.updateDialog({ maxWidth: 'lg' });
        this.openDialog(title, content, icon, secondary);
    },
    openFullDialog(title, content, icon, secondary) {
        this.updateDialog({ fullScreen: true });
        this.openDialog(title, content, icon, secondary);
    },
    closeDialog() {
        EventDispatcher.dispatch('closeDialog');
    },
    updateDialog(options) {
        if (document.querySelectorAll('.MuiDialog-root').length === 0) {
            setTimeout(() => this.updateDialog(options), 50);
        }
        EventDispatcher.dispatch('updateDialog', options);
    },
    hideSave() {
        this.updateDialog({ hideSave: true });
    },
    showSave() {
        this.updateDialog({ hideSave: false });
    },
    hideClose() {
        this.updateDialog({ hideClose: true });
    },
    showClose() {
        this.updateDialog({ hideClose: false });
    },
    addSaveButton(callback, text, disabled) {
        if (!text) {
            text = 'Save';
        }
        if (!disabled) {
            disabled = false;
        }
        this.updateDialog({
            hideSave: false,
            saveAction: callback,
            saveText: text,
            saveButtonDisabled: disabled
        });
    },
    disableSaveButton() {
        this.updateDialog({
            saveButtonDisabled: true
        });
    },
    enableSaveButton() {
        this.updateDialog({
            saveButtonDisabled: false
        });
    },
    successNotification(message) {
        EventDispatcher.dispatch('show-notification', {
            message,
            variant: 'alert',
            severity: 'success'
        });
    },
    errorNotification(message) {
        EventDispatcher.dispatch('show-notification', {
            message,
            variant: 'alert',
            severity: 'error'
        });
    }
};

export default RbgDialog;
