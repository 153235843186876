import { SvgIcon } from '@mui/material';

export function IconPdfFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#f44336' }} {...props}>
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.66 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.35 0 64-28.66 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.562 8.666 3.844 12.12 7.297l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM202.7 289.1c10.06-31.68 10.6-57.04 10.6-62.05C213.3 207.7 197.4 192 177.8 192S142.2 207.7 142.2 227.1c0 .3086 .1738 29.61 14.6 64c-7.396 19.62-16.33 39.33-26.63 58.7c-22.79 10.41-41.61 22.85-55.97 37.03C67.54 393.4 64 402.4 64 412.9C64 432.3 79.93 448 99.5 448c11.33 0 22.05-5.375 28.68-14.38c6.338-8.582 18.69-26.16 32.31-50.2c17.05-6.625 36.41-12.18 57.67-16.54c12.07 7.961 41.88 26.05 63.51 26.05C303.2 392.9 320 376 320 354.4c0-21.26-17.54-38.55-39.11-38.55c-16.06 0-40.51 3.656-52.21 5.566C218.3 312.2 209.6 301.4 202.7 289.1zM105.3 416.8c-3.416 4.664-12.82 2.891-12.82-5.25c0-1.723 .6387-3.305 1.75-4.418c9.514-9.418 21.03-17.44 33.6-24.28C117.9 399.2 109.6 410.9 105.3 416.8zM177.8 220.4c3.918 0 7.111 2.969 7.111 6.637c0 15.61-4.361 35.78-6.084 43.17C170.9 247.1 170.7 228.9 170.7 227.1C170.7 223.4 173.9 220.4 177.8 220.4zM160.7 352.9c6.932-13.75 13.89-29 20.33-45.5c6.723 11.11 15.26 22.25 26.15 32.56C192.4 343.2 176.5 347.4 160.7 352.9zM280.9 344.3c5.875 0 10.67 4.527 10.67 10.86c0 5.141-4.43 9.336-9.889 9.336c-8.809 0-30.23-10.26-40.18-16.2C242.1 348.2 268 344.3 280.9 344.3z" />
        </SvgIcon>
    );
}

export function IconVideoFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#ff9800' }} {...props}>
            <path d="M379.1 146c-3.186-7.688-7.871-14.76-13.87-20.76l-106.5-106.5c-6-6.004-13.07-10.69-20.76-13.88C230.3 1.688 221.1 0 213.5 0H64C46.33 0 30.33 7.164 18.75 18.75S0 46.33 0 64l.0065 384c0 17.67 7.158 33.67 18.74 45.25S46.33 512 64.01 512H320c17.67 0 33.67-7.164 45.26-18.75S384 465.7 384 448V170.5C384 162 382.3 153.7 379.1 146zM224 34.08c2.238 .7813 4.404 1.742 6.443 2.938c2.039 1.199 3.949 2.633 5.676 4.359l106.5 106.5c1.727 1.727 3.16 3.637 4.355 5.676C348.2 155.6 349.1 157.8 349.9 160H240c-4.406 0-8.406-1.793-11.3-4.688C225.8 152.4 224 148.4 224 144V34.08zM352 448c0 8.82-3.588 16.82-9.383 22.62C336.8 476.4 328.8 480 320 480H64c-8.822 0-16.82-3.59-22.62-9.383C35.59 464.8 32 456.8 32 448V64c0-8.82 3.59-16.82 9.383-22.62C47.18 35.59 55.18 32 64 32h128v112c0 13.23 5.383 25.23 14.07 33.93S226.8 192 240 192H352V448zM230.6 265.4C224.8 259.6 216.8 256 208 256H96C87.16 256 79.16 259.6 73.37 265.4C67.58 271.2 64 279.2 64 288v96c0 8.836 3.582 16.84 9.373 22.62C79.16 412.4 87.16 416 96 416h112c8.836 0 16.84-3.582 22.63-9.375c5.023-5.023 7.902-11.87 8.705-19.32l59.61 19.87C300.6 407.7 302.3 408 304 408c3.312 0 6.594-1.031 9.359-3.016C317.5 401.1 320 397.1 320 392v-112c0-5.141-2.469-9.969-6.641-12.98C309.2 264 303.8 263.2 298.9 264.8L239.3 284.7C238.5 277.2 235.7 270.4 230.6 265.4zM208 384H96V288h112V384zM240 318.2L288 302.2v67.59l-48-15.99V318.2z" />
        </SvgIcon>
    );
}

export function IconWordFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#1e88e5' }} {...props}>
            <path d="M177.1 250.1l-44 110L111.7 252.9c-1.719-8.656-10.11-14.33-18.83-12.55C84.2 242 78.58 250.5 80.31 259.1l32 160c1.391 6.938 7.172 12.14 14.22 12.8c7.25 .6562 13.7-3.422 16.33-10L192 299.1l49.14 122.9C243.6 428.1 249.5 432 256 432c.4844 0 .9687-.0156 1.469-.0625c7.047-.6562 12.83-5.859 14.22-12.8l32-160c1.734-8.672-3.891-17.09-12.55-18.83c-8.781-1.812-17.11 3.875-18.83 12.55l-21.45 107.2l-44-110C201.1 237.9 182 237.9 177.1 250.1zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448z" />
        </SvgIcon>
    );
}

export function IconZipFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#7e57c2' }} {...props}>
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64v-277.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM144 128h-64C71.16 128 64 135.2 64 144S71.16 160 80 160h64C152.8 160 160 152.8 160 144S152.8 128 144 128zM144 64h-64C71.16 64 64 71.16 64 80S71.16 96 80 96h64C152.8 96 160 88.84 160 80S152.8 64 144 64zM144 192h-64C71.16 192 64 199.2 64 208S71.16 224 80 224h64C152.8 224 160 216.8 160 208S152.8 192 144 192zM144 256h-64C71.16 256 64 263.2 64 272V320c0 26.47 21.53 48 48 48S160 346.5 160 320V272C160 263.2 152.8 256 144 256zM128 320c0 8.828-7.188 16-16 16S96 328.8 96 320V288h32V320z" />
        </SvgIcon>
    );
}

export function IconImageFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#8d6e63' }} {...props}>
            <path d="M190.3 285.7l-26.36 40.67c-12-14.92-37.75-13.73-48.31 2.531l-46.69 72.02c-5.984 9.266-6.531 21.09-1.453 30.84C72.67 441.8 82.83 448 93.1 448h196c11.17 0 21.33-6.219 26.55-16.23c5.094-9.797 4.531-21.62-1.484-30.86l-74.66-115.2C229.3 268.5 201.4 268.5 190.3 285.7zM286.7 416L95.77 416l44.89-66.95l9.922 15.3c5.906 9.094 20.97 9.094 26.84 0l37.91-58.48L286.7 416zM96 280c13.25 0 24-10.75 24-24c0-13.26-10.75-24-24-24S72 242.7 72 256C72 269.3 82.75 280 96 280zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448z" />
        </SvgIcon>
    );
}

export function IconPowerPointFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#d84315' }} {...props}>
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM128 240C119.2 240 112 247.2 112 256v176C112 440.8 119.2 448 128 448s16-7.156 16-16V384h56c39.69 0 72-32.3 72-72S239.7 240 200 240H128zM240 312c0 22.06-17.94 40-40 40H144V272h56C222.1 272 240 289.9 240 312z" />
        </SvgIcon>
    );
}

export function IconExcelFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#00897b' }} {...props}>
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM192 308.1L149.1 246.8c-5.062-7.25-15.06-9-22.28-3.938c-7.25 5.062-9 15.05-3.938 22.28L172.5 336L122.9 406.8c-5.062 7.234-3.312 17.22 3.938 22.28C129.6 431.1 132.8 432 135.1 432c5.047 0 10.02-2.375 13.12-6.828L192 363.9l42.89 61.27C237.1 429.6 242.1 432 248 432c3.172 0 6.375-.9375 9.156-2.891c7.25-5.062 9-15.05 3.938-22.28L211.5 336l49.58-70.83c5.062-7.234 3.312-17.22-3.938-22.28s-17.22-3.312-22.28 3.938L192 308.1z" />
        </SvgIcon>
    );
}

export function IconContactBookFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" sx={{ fill: '#29b6f6' }} {...props}>
            <path d="M272 288h-64C163.8 288 128 323.8 128 368C128 376.8 135.2 384 144 384C152.8 384 160 376.8 160 368C160 341.5 181.5 320 208 320h64c26.51 0 48 21.49 48 48c0 8.836 7.164 16 16 16c8.837 0 16-7.164 16-16C352 323.8 316.2 288 272 288zM496 192H480v96h16C504.8 288 512 280.8 512 272v-64C512 199.2 504.8 192 496 192zM240 256c35.35 0 64-28.65 64-64s-28.65-64-64-64s-64 28.65-64 64S204.7 256 240 256zM240 160c17.64 0 32 14.36 32 32s-14.36 32-32 32s-32-14.36-32-32S222.4 160 240 160zM496 320H480v96h16c8.837 0 16-7.164 16-16v-64C512 327.2 504.8 320 496 320zM496 64H480v96h16C504.8 160 512 152.8 512 144v-64C512 71.16 504.8 64 496 64zM384 0H96C60.65 0 32 28.65 32 64v384c0 35.35 28.65 64 64 64h288c35.35 0 64-28.65 64-64V64C448 28.65 419.3 0 384 0zM416 448c0 17.64-14.36 32-32 32H96c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h288c17.64 0 32 14.36 32 32V448z" />
        </SvgIcon>
    );
}

export function IconOtherFile(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" sx={{ fill: '#607d8b' }} {...props}>
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64v-277.5C384 153.5 377.3 137.3 365.3 125.3zM342.6 147.9C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08c4.477 1.566 8.666 3.846 12.12 7.299L342.6 147.9zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448z" />
        </SvgIcon>
    );
}

export function IconFolder(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" sx={{ fill: '#fbc02d' }} {...props}>
            <path d="M464 96h-192l-64-64h-160C21.5 32 0 53.5 0 80V160h512V144C512 117.5 490.5 96 464 96zM0 432C0 458.5 21.5 480 48 480h416c26.5 0 48-21.5 48-48V192H0V432z" />{' '}
        </SvgIcon>
    );
}

export function IconFolderOpen(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" sx={{ fill: '#f9a825' }} {...props}>
            <path d="M572.6 270.3l-96 192C471.2 473.2 460.1 480 447.1 480H64c-35.35 0-64-28.66-64-64V96c0-35.34 28.65-64 64-64h117.5c16.97 0 33.25 6.742 45.26 18.75L275.9 96H416c35.35 0 64 28.66 64 64v32h-48V160c0-8.824-7.178-16-16-16H256L192.8 84.69C189.8 81.66 185.8 80 181.5 80H64C55.18 80 48 87.18 48 96v288l71.16-142.3C124.6 230.8 135.7 224 147.8 224h396.2C567.7 224 583.2 249 572.6 270.3z" />{' '}
        </SvgIcon>
    );
}
