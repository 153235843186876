import {
    IconClipboardCheck,
    IconClipboardData,
    IconCurrencyPound,
    IconFileDiff,
    IconHome,
    IconLicense,
    IconMapPin,
    IconMedicalCross,
    IconMenu,
    IconPlus,
    IconReport,
    IconSearch,
    IconSettings,
    IconStar,
    IconStarsFilled,
    IconTable,
    IconToolsKitchen2,
    IconUser,
    IconUserCheck,
    IconUsers,
    IconUsersGroup
} from '@tabler/icons-react';
import { Support } from '@mui/icons-material';
// constant
const icons = { IconHome };

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        {
            id: 'dashboard',
            title: ' ',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/rcloud',
                    icon: icons.IconHome,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'bookings-management',
            title: 'Bookings',
            type: 'group',
            permission: 'partypro',
            children: [
                // {
                //     id: 'partypro-new',
                //     title: 'Party Pro (New)',
                //     type: 'item',
                //     url: '/partypro-new',
                //     icon: IconStar,
                //     breadcrumbs: false,
                //     permissionCallback: (user) => user.id < 10
                // },
                {
                    id: 'partypro',
                    title: 'Party Pro',
                    type: 'item',
                    url: '/partypro',
                    icon: IconStar,
                    external: true,
                    breadcrumbs: false
                },
                {
                    id: 'centralpartypro',
                    title: 'Party Central',
                    type: 'item',
                    url: '/partycentral',
                    icon: IconStarsFilled,
                    external: true,
                    breadcrumbs: false,
                    permission: 'central_partypro'
                },
                {
                    id: 'pre-orders',
                    title: 'Pre-Orders',
                    type: 'item',
                    url: '/kitchen',
                    icon: IconToolsKitchen2,
                    external: true,
                    breadcrumbs: false
                },
                {
                    id: 'table-bookings',
                    title: 'Rhost Admin',
                    type: 'item',
                    url: '/table-bookings',
                    icon: IconTable,
                    breadcrumbs: false,
                    external: true,
                    permission: 'rhost'
                },
                {
                    id: 'reports',
                    title: 'Reports',
                    type: 'item',
                    url: '/partypro/tools/report',
                    icon: IconReport,
                    breadcrumbs: false,
                    permission: 'cms'
                }
            ]
        },
        {
            id: 'customer-management',
            title: 'Customers',
            type: 'group',
            children: [
                {
                    id: 'customer',
                    title: 'Customers',
                    type: 'item',
                    external: true,
                    url: '/customers',
                    icon: IconUsers,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'staff_group',
            title: 'R-People',
            type: 'group',
            permission: 'hr_staff_view',
            children: [
                {
                    id: 'people_dashboard',
                    title: 'People Team Dashboard',
                    type: 'item',
                    icon: IconUsersGroup,
                    url: '/staff/people-dashboard',
                    permission: 'hr_config',
                    breadcrumbs: true
                },
                {
                    id: 'area_dashboard',
                    title: 'Area Dashboard',
                    type: 'item',
                    icon: IconUsersGroup,
                    url: '/staff/area',
                    permission: 'hr_staff_auth',
                    breadcrumbs: true
                },
                {
                    id: 'staff',
                    title: 'Team Dashboard',
                    type: 'item',
                    url: '/staff',
                    icon: IconUser,
                    breadcrumbs: true,
                    children: [
                        {
                            id: 'staff_member',
                            title: 'Team Member',
                            type: 'item',
                            hidden: true,
                            regex: /\/staff\/view\/(\d+)/,
                            url: '/staff/view/:staff_id',
                            icon: IconUser,
                            breadcrumbs: true
                        },
                        {
                            id: 'staff_details',
                            title: 'Full Details',
                            type: 'item',
                            hidden: true,
                            url: '/staff/list/:staff_id/full-details',
                            icon: IconUser,
                            breadcrumbs: true
                        }
                    ]
                },
                {
                    id: 'staff_search',
                    title: 'Team Member Search',
                    type: 'item',
                    hidden: false,
                    url: '/staff/search',
                    icon: IconSearch,
                    permission: 'hr_staff_view',
                    breadcrumbs: true
                },
                {
                    id: 'company_search',
                    title: 'Company Directory',
                    type: 'item',
                    hidden: false,
                    url: '/staff/company-search',
                    icon: IconSearch,
                    permission: 'hr_staff_view',
                    breadcrumbs: true
                },
                {
                    id: 'team_approvals',
                    title: 'Team Member Approvals',
                    type: 'item',
                    url: '/staff/team-approvals',
                    icon: IconUserCheck,
                    permission: 'hr_staff_auth',
                    breadcrumbs: true
                },
                {
                    id: 'support_centre_family_tree',
                    title: 'Support Centre',
                    type: 'item',
                    url: '/staff/family-tree',
                    icon: Support,
                    permission: 'hr_staff_view',
                    breadcrumbs: true
                },
                {
                    id: 'draft_payslips',
                    title: 'Draft Payslips',
                    type: 'item',
                    url: '/staff/draft-payslips',
                    icon: IconClipboardData,
                    permission: 'hr_view_payslips',
                    breadcrumbs: true
                },
                {
                    id: 'staff_reporting',
                    title: 'Reporting',
                    type: 'collapse',
                    icon: IconReport,
                    breadcrumbs: true,
                    children: [
                        // {
                        //     id: 'di_reporting',
                        //     title: 'Diversity & Inclusion',
                        //     type: 'item',
                        //     url: '/staff/reporting/di',
                        //     icon: IconUserCheck,
                        //     permission: 'hr_config',
                        //     breadcrumbs: true
                        // },
                        {
                            id: 'action_reporting',
                            title: 'Team Actions',
                            type: 'item',
                            url: '/staff/reporting/actions',
                            icon: IconReport,
                            permission: 'hr_staff_view',
                            breadcrumbs: true
                        },
                        {
                            id: 'first_aid_reporting',
                            title: 'First Aid Reporting',
                            type: 'item',
                            url: '/staff/reporting/first-aid',
                            icon: IconMedicalCross,
                            permission: 'hr_staff_auth',
                            breadcrumbs: true
                        }
                    ]
                },
                {
                    id: 'staff_admin',
                    title: 'HR Admin',
                    type: 'collapse',
                    icon: IconUsersGroup,
                    breadcrumbs: true,
                    children: [
                        {
                            id: 'new_starter_manual',
                            title: 'Add New Starter',
                            type: 'item',
                            url: '/staff/admin/manual-new-starter',
                            icon: IconPlus,
                            permission: 'hr_config',
                            breadcrumbs: true
                        },
                        {
                            id: 'rtw_checks',
                            title: 'New Starters / RTW Checks',
                            type: 'item',
                            url: '/staff/admin/onboarding',
                            icon: IconClipboardCheck,
                            permission: 'hr_config',
                            breadcrumbs: true
                        },
                        {
                            id: 'bulkstaffadmin',
                            title: 'Bulk Actions',
                            type: 'item',
                            url: '/staff/bulk-actions',
                            icon: IconUsersGroup,
                            permission: 'hr_staff_auth',
                            breadcrumbs: true
                        },
                        {
                            id: 's4differences',
                            title: 'S4 Differences',
                            type: 'item',
                            url: '/staff/admin/s4-differences',
                            icon: IconFileDiff,
                            permission: 'hr_config',
                            breadcrumbs: true
                        },
                        {
                            id: 'draft_payslips_admin',
                            title: 'Draft Payslips Admin',
                            type: 'item',
                            url: '/staff/admin/draft-payslips',
                            icon: IconClipboardData,
                            permission: 'hr_config',
                            breadcrumbs: true
                        },
                        {
                            id: 'opera_import',
                            title: 'Opera Import',
                            type: 'item',
                            url: '/staff/admin/opera-import',
                            icon: IconCurrencyPound,
                            permission: 'payroll',
                            breadcrumbs: true
                        },
                        // {
                        //     id: 'staffdocumentationapprovals',
                        //     title: 'RTW Approvals',
                        //     type: 'item',
                        //     url: '/staff/documentation-approvals',
                        //     icon: IconClipboardCheck,
                        //     permission: 'hr_staff_auth',
                        //     breadcrumbs: true
                        // },
                        {
                            id: 'qualityoflife',
                            title: 'QOL Admin',
                            type: 'item',
                            url: '/payroll#quality_of_life',
                            icon: IconClipboardCheck,
                            external: true,
                            newWindow: true,
                            permission: 'hr_staff_auth',
                            breadcrumbs: true
                        },
                        {
                            id: 'hradmin',
                            title: 'HR System Configuration',
                            type: 'item',
                            url: '/staff/configuration',
                            icon: IconSettings,
                            permission: 'hr_config',
                            breadcrumbs: true
                        }
                    ]
                },
                {
                    id: 'payroll',
                    title: 'Payroll Admin',
                    type: 'item',
                    url: '/payroll',
                    icon: IconCurrencyPound,
                    breadcrumbs: false,
                    permission: 'payroll'
                }
            ]
        },
        {
            id: 'application',
            title: 'Other Apps',
            type: 'group',
            permission: 'audit',
            children: [
                {
                    id: 'audit-app',
                    title: 'Audit App',
                    type: 'item',
                    external: true,
                    url: 'https://auditapp.revolutionbarsgroup.com',
                    icon: icons.IconClipboardCheck,
                    breadcrumbs: true,
                    newWindow: true,
                    permission: 'audit'
                }
            ]
        },
        {
            id: 'admin',
            title: 'Administration',
            type: 'group',
            children: [
                {
                    id: 'admin_area',
                    title: 'Admin Area',
                    type: 'collapse',
                    permission: 'cms',
                    children: [
                        {
                            id: 'admin',
                            title: 'R-Cloud Admin',
                            type: 'item',
                            url: '/partypro/admin',
                            icon: IconSettings,
                            permission: 'cms',
                            breadcrumbs: false
                        },
                        {
                            id: 'menu-admin',
                            title: 'Menu Admin',
                            type: 'item',
                            url: '/partypro/admin/menus',
                            icon: IconMenu,
                            permission: 'cms',
                            breadcrumbs: false
                        },
                        {
                            id: 'permissions',
                            title: 'Permissions',
                            type: 'item',
                            url: '/admin/permissions',
                            icon: IconLicense,
                            permission: 'cms',
                            breadcrumbs: true
                        },
                        {
                            id: 'location-attributes',
                            title: 'Location Attributes',
                            type: 'item',
                            url: '/admin/location-attributes',
                            icon: IconMapPin,
                            permission: 'central_location_admin',
                            breadcrumbs: true
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
