import { lazy } from 'react';
// project imports
import config from '../config';
import Loadable from 'ui_component/loading/Loadable';
// application routing
const Error = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    name: 'not-found',
    basename: config.basename,
    path: '*',
    element: <Error />
};

export default MainRoutes;
