import {
    IconAccessible,
    IconAffiliate,
    IconAlarm,
    IconAlarmOff,
    IconAlertTriangle,
    IconArrowDown,
    IconArrowLeft,
    IconArrowRight,
    IconArrowUp,
    IconBeach,
    IconBeerFilled,
    IconBell,
    IconBook,
    IconBriefcase,
    IconCake,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconClipboard,
    IconClipboardText,
    IconClock,
    IconCopy,
    IconCreditCard,
    IconDoorEnter,
    IconDoorExit,
    IconDownload,
    IconEye,
    IconEyeOff,
    IconFirstAidKit,
    IconHome,
    IconInfoCircle,
    IconLinkOff,
    IconLogin,
    IconLogout,
    IconMail,
    IconMapPin,
    IconMessage,
    IconPhone,
    IconPlus,
    IconRecycle,
    IconRefresh,
    IconSearch,
    IconSettings,
    IconStar,
    IconTrash,
    IconUnlink,
    IconUser,
    IconUserCheck,
    IconUserExclamation,
    IconUserPause,
    IconUsers,
    IconUserX
} from '@tabler/icons-react';
import { Celebration, Error, Face, Info, LocalBar, ReportProblemOutlined, Warning } from '@mui/icons-material';
import MhfaIcon from './CustomIcons/MhfaIcon';

export const icons = [
    { label: 'First aid', value: 'first-aid', icon: IconFirstAidKit, className: 'green-colour' },
    { label: 'Accessible', value: 'accessible', icon: IconAccessible },
    { label: 'Affiliate', value: 'affiliate', icon: IconAffiliate },
    { label: 'Alarm', value: 'alarm', icon: IconAlarm },
    { label: 'Alarm off', value: 'alarm-off', icon: IconAlarmOff },
    { label: 'Alert', value: 'alert', icon: ReportProblemOutlined },
    { label: 'Beer', value: 'beer', icon: IconBeerFilled, className: 'beer-colour' },
    { label: 'Cake', value: 'cake', icon: IconCake },
    { label: 'Face', value: 'face', icon: Face },
    { label: 'Unlink', value: 'unlink', icon: IconUnlink },
    { label: 'Unassigned', value: 'unassigned', icon: IconLinkOff },
    { label: 'Chevron down', value: 'chevron-down', icon: IconChevronDown },
    { label: 'Chevron up', value: 'chevron-up', icon: IconChevronUp },
    { label: 'Chevron left', value: 'chevron-left', icon: IconChevronLeft },
    { label: 'Chevron right', value: 'chevron-right', icon: IconChevronRight },
    { label: 'Arrow up', value: 'arrow-up', icon: IconArrowUp },
    { label: 'Arrow down', value: 'arrow-down', icon: IconArrowDown },
    { label: 'Arrow left', value: 'arrow-left', icon: IconArrowLeft },
    { label: 'Arrow right', value: 'arrow-right', icon: IconArrowRight },
    { label: 'Plus', value: 'plus', icon: IconPlus },
    { label: 'Map pin', value: 'map-pin', icon: IconMapPin },
    { label: 'Bell', value: 'bell', icon: IconBell },
    { label: 'Settings', value: 'settings', icon: IconSettings },
    { label: 'Users', value: 'users', icon: IconUsers },
    { label: 'User', value: 'user', icon: IconUser },
    { label: 'Star', value: 'star', icon: IconStar },
    { label: 'Trash', value: 'trash', icon: IconTrash },
    { label: 'Refresh', value: 'refresh', icon: IconRefresh },
    { label: 'Download', value: 'download', icon: IconDownload },
    { label: 'Login', value: 'login', icon: IconLogin },
    { label: 'Logout', value: 'logout', icon: IconLogout },
    { label: 'Credit card', value: 'credit-card', icon: IconCreditCard },
    { label: 'Book', value: 'book', icon: IconBook },
    { label: 'Triangle', value: 'triangle', icon: IconAlertTriangle },
    { label: 'Info', value: 'info', icon: IconInfoCircle },
    { label: 'Eye', value: 'eye', icon: IconEye },
    { label: 'Eye off', value: 'eye-off', icon: IconEyeOff },
    { label: 'Search', value: 'search', icon: IconSearch },
    { label: 'Mail', value: 'mail', icon: IconMail },
    { label: 'Phone', value: 'phone', icon: IconPhone },
    { label: 'Message', value: 'message', icon: IconMessage },
    { label: 'Clipboard', value: 'clipboard', icon: IconClipboard },
    { label: 'Clock', value: 'clock', icon: IconClock },
    { label: 'BriefCase', value: 'briefcase', icon: IconBriefcase },
    { label: 'Contract', value: 'contract', icon: IconClipboardText },
    { label: 'Beach', value: 'beach', icon: IconBeach },
    { label: 'Door Exit', value: 'door-exit', icon: IconDoorExit },
    { label: 'Door Enter', value: 'door-enter', icon: IconDoorEnter },
    { label: 'Party', value: 'party', icon: Celebration },
    { label: 'User Exclamation', value: 'user-exclamation', icon: IconUserExclamation },
    { label: 'User Pause', value: 'user-pause', icon: IconUserPause },
    { label: 'User Check', value: 'user-check', icon: IconUserCheck },
    { label: 'Accessible', value: 'accessible', icon: IconAccessible },
    { label: 'User X', value: 'user-x', icon: IconUserX },
    { label: 'Copy', value: 'copy', icon: IconCopy },
    { label: 'MHFA', value: 'mhfa', icon: MhfaIcon, className: 'mhfa' },
    { label: 'Error', value: 'error', icon: Error, className: 'error' },
    { label: 'Warning', value: 'warning', icon: Warning, className: 'warning' },
    { label: 'Info', value: 'info', icon: Info, className: 'info' },
    { label: 'Recycle', value: 'recycle', icon: IconRecycle, className: 'green-colour' },
    { label: 'Cocktail', value: 'cocktail', icon: LocalBar },
    { label: 'Home', value: 'home', icon: IconHome }
];
