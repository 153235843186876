export const currencyGBP = (minDigits = 2, maxDigits = 2) =>
    new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits
    });

function CurrencyFormatter({ value, minDigits, maxDigits }) {
    return currencyGBP(minDigits, maxDigits).format(value);
}

export default CurrencyFormatter;
