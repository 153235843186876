import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import { staffPayRate } from '../../StaffDataTypes';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import { currencyGBP } from '../../../../../utils/currencyFormatter';

function PreviousRates({ rates }) {
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Start Date', 'End Date', 'Type', 'Rate']}
                    keys={rates.map((rate) => rate.id)}
                    rows={rates.map((rate) => {
                        return [
                            dayjs(rate.start_date).format('DD/MM/YYYY'),
                            rate.end_date ? dayjs(rate.end_date).format('DD/MM/YYYY') : '',
                            rate.type.data.name,
                            `${currencyGBP().format(rate.rate)} ${rate.type.data.display_type}`
                        ];
                    })}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

PreviousRates.propTypes = {
    rates: PropTypes.arrayOf(PropTypes.shape(staffPayRate))
};

export default PreviousRates;
