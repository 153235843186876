import PropTypes from 'prop-types';
import { locationWithConfig } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function HrConfigEditLocation({ location }) {
    const [newLocation, setNewLocation] = useState(
        location.staff_config.data || {
            location: location.cost_centre,
            payroll_ref_prefix: '',
            s4_site_id: '',
            initial_training_config: { data: {} }
        }
    );
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('PATCH', `/staff/location-config/${location.cost_centre}`, newLocation);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/locations', params: { include: 'staff_config' } }]
            });
            DialogHelper.closeDialog();
        }
    });
    const isNew = !location.staff_config.data.location;
    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', JSON.stringify(location) === JSON.stringify(newLocation));
    }, [location, mutation, newLocation]);
    return (
        <RbgGrid container>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={6}>
                <RbgTextInput name="name" value={location.name} disabled label="Location Name" />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgTextInput name="cost_centre" value={location.cost_centre} disabled label="Cost Centre" />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="payroll_ref_prefix"
                    label="Payroll Ref Prefix"
                    disabled={isNew === false}
                    value={newLocation.payroll_ref_prefix}
                    help={
                        isNew
                            ? 'The prefix which will be assigned to all employees payroll references'
                            : 'To change the payroll ref prefix please contact IT Support'
                    }
                    onChange={(e) => setNewLocation((prev) => ({ ...prev, payroll_ref_prefix: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="s4_site_id"
                    type="number"
                    disabled={isNew === false && newLocation.s4_site_id !== ''}
                    label="S4 Site ID"
                    help="S$ Site ID. If you do not know this, please check in with IT Support first"
                    value={newLocation.s4_site_id}
                    onChange={(e) => setNewLocation((prev) => ({ ...prev, s4_site_id: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    label="Initial Training Config"
                    endpoint="/staff/initial-training-configs"
                    name="initial_training_config"
                    value={newLocation.initial_training_config ? newLocation.initial_training_config.data.id : ''}
                    handleObjectChange={(e) =>
                        setNewLocation((prev) => ({
                            ...prev,
                            initial_training_config: { data: e.target.object }
                        }))
                    }
                />
            </RbgGrid>
        </RbgGrid>
    );
}

HrConfigEditLocation.propTypes = {
    location: PropTypes.shape(locationWithConfig)
};
export default HrConfigEditLocation;
