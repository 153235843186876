import { staffContract } from '../../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PageableTable from '../../../../../../ui_component/pageable-table/PageableTable';

function ContractHistory({ contract }) {
    const [page, setPage] = useState(1);
    const { data } = useQuery({
        queryKey: [
            {
                url: `/staff/staff-member/${contract.staff_id}/contract/${contract.id}/history`,
                params: { page }
            }
        ]
    });
    return (
        <PageableTable
            headers={['Date', 'User', 'Entry']}
            pagination={data.meta.pagination}
            data={data.data}
            rowDecorator={(row) => [row.date, row.user_name, row.entry]}
            handlePageChange={(page) => setPage(page)}
        />
    );
}

ContractHistory.propTypes = {
    contract: PropTypes.shape(staffContract).isRequired
};
export default ContractHistory;
