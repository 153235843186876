import PropTypes from 'prop-types';
// mui wrappers
import RbgButton from 'ui_component/mui-wrappers/Button/RbgButton';
import RbgList from 'ui_component/mui-wrappers/List/RbgList';
import RbgListItem from 'ui_component/mui-wrappers/ListItem/RbgListItem';
import RbgListItemText from 'ui_component/mui-wrappers/ListItemText/RbgListItemText';
import RbgDivider from 'ui_component/mui-wrappers/Divider/RbgDivider';
import MainCard from 'ui_component/cards/MainCard';
import { DialogHelper } from 'ui_component/modals/RbgDialog';
import EventDispatcher from 'utils/EventDispatcher';
import EditContact from './EditGroups/EditContact';
import { staffDetails } from '../../../StaffDataTypes';

function ContactGroup({ staffDetails, getError, hasEditPermissions }) {
    const handleSave = () => {
        DialogHelper.closeDialog();
        EventDispatcher.dispatch('setTabLoading', true);
        setTimeout(() => {
            EventDispatcher.dispatch('setTabLoading', false);
        }, 1500);
    };

    const openEdit = () => {
        DialogHelper.updateDialog({ maxWidth: 'sm', saveAction: handleSave, height: 'fit-content' });
        DialogHelper.openDialog('Edit Contact & Next of kin', <EditContact staffDetails={staffDetails} getError={getError} />);
    };

    return (
        <MainCard
            sx={{ height: '100%' }}
            title="Contact & NOK"
            secondary={
                <RbgButton size="small" variant="contained" color="primary" onClick={openEdit} disabled={hasEditPermissions === false}>
                    Edit
                </RbgButton>
            }
        >
            <RbgList>
                <RbgListItem>
                    <RbgListItemText primary="Email Address" secondary={staffDetails.email_address} />
                </RbgListItem>
                <RbgDivider />
                {staffDetails.work_email_address && (
                    <>
                        <RbgListItem>
                            <RbgListItemText primary="Work Email Address" secondary={staffDetails.work_email_address} />
                        </RbgListItem>
                        <RbgDivider />
                    </>
                )}
                <RbgListItem>
                    <RbgListItemText primary="Mobile" secondary={staffDetails.mobile} />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="Next of Kin Name" secondary={staffDetails.nok_name} />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="Next of Kin Phone" secondary={staffDetails.nok_phone} />
                </RbgListItem>
            </RbgList>
        </MainCard>
    );
}

export default ContactGroup;

ContactGroup.propTypes = {
    staffDetails: PropTypes.shape(staffDetails),
    getError: PropTypes.func,
    hasEditPermissions: PropTypes.bool
};
