import PropTypes from 'prop-types';
import { contractTemplate } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import Debug from '../../../../../ui_component/misc/Debug';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import PreviewContractTemplate from './PreviewContractTemplate';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import EditContractTemplateTab from './EditContractTemplateTab';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function EditContractTemplate({ template }) {
    const [tab, setTab] = useState('edit');
    const [newTemplate, setNewTemplate] = useState(
        template || {
            id: null,
            name: '',
            min_contracted_hours: 0,
            max_contracted_hours: 0,
            allow_manual_upload: false,
            min_hierarchy_level: 5,
            locked: false,
            locations: [],
            sections: { data: [] },
            pay_type: { data: {} }
        }
    );

    const { data: sections } = useQuery({
        queryKey: [{ url: '/staff/staff-contract-admin/sections', params: { page: 1, per_page: 9999 } }, 'contract_sections']
    });
    const tabs = [
        {
            value: 'edit',
            label: 'Edit',
            content: <EditContractTemplateTab template={newTemplate} handleTemplateChange={setNewTemplate} />
        },
        {
            value: 'preview',
            label: 'Preview',
            content: <PreviewContractTemplate template={newTemplate} />
        },
        {
            value: 'debug',
            label: 'Debug',
            content: <Debug source={newTemplate} />
        }
    ];

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            const data = JSON.parse(JSON.stringify(newTemplate));
            data.pay_type_id = data.pay_type.data.id;
            if (newTemplate.id) {
                return Api.reactQueryMutation('PUT', `/staff/staff-contract-admin/template/${newTemplate.id}`, data);
            }
            return Api.reactQueryMutation('POST', '/staff/staff-contract-admin/template', data);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('contract_templates') > -1;
                }
            });
        }
    });

    useEffect(() => {
        if (JSON.stringify(template) !== JSON.stringify(newTemplate)) {
            DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', false);
            return;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', true);
    }, [template, newTemplate, mutation]);
    return (
        <>
            {mutation.error && <ErrorCard>{mutation.error}</ErrorCard>}
            <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} />
        </>
    );
}

EditContractTemplate.propTypes = {
    template: PropTypes.shape(contractTemplate)
};

export default EditContractTemplate;
