import RbgTableRow from '../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import BooleanIcon from '../../../../ui_component/icons/boolean-icon';
import RbgListItem from '../../../../ui_component/mui-wrappers/ListItem/RbgListItem';
import RbgList from '../../../../ui_component/mui-wrappers/List/RbgList';
import { DialogHelper } from '../../../../ui_component/modals/RbgDialog';
import ActionMapping from './ActionMapping';
import RbgButtonDropdown from '../../../../ui_component/mui-wrappers/Button/RbgButtonDropdown';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../utils/api';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../ui_component/error/InfoCard';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useCallback, useEffect, useState } from 'react';
import EventDispatcher from '../../../../utils/EventDispatcher';
import BulkActionHistory from './BulkActionHistory';

function BulkActionRow({ rowData }) {
    const [row, setRow] = useState(rowData);
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('DELETE', `/staff/bulk-actions/${id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/bulk-actions' }]
            });
        }
    });
    const runMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', `/staff/bulk-actions/${id}/run`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/bulk-actions' }]
            });
        }
    });
    const runAction = (action) => {
        DialogHelper.openSmDialog(
            'Sure?',
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <InfoCard>
                        Are you sure you want to run this bulk action? This action is not reversible once started so make sure everything is
                        right!
                    </InfoCard>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgButton onClick={() => runMutation.mutate(action.id)} fullWidth color="error">
                        Yes
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    };
    const deleteAction = (action) => {
        DialogHelper.openSmDialog(
            'Sure?',
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <InfoCard>Are you sure you want to archive this bulk action?</InfoCard>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgButton onClick={() => deleteMutation.mutate(action.id)} fullWidth color="error">
                        Yes
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        );
    };
    const handleMessage = useCallback(
        (event) => {
            if (event.event === 'action_updated') {
                if (event.data.data.id === rowData.id) {
                    setRow(event.data.data);
                }
            }
        },
        [rowData]
    );
    useEffect(() => {
        EventDispatcher.dispatch('pusher-subscribe', `private-staff_bulk_action-${row.id}`);
        const id = EventDispatcher.subscribe('pusher-message', (data) => {
            handleMessage(data);
        });
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [row.id, handleMessage]);
    useEffect(() => {
        setRow(rowData);
    }, [rowData]);
    const actions = [
        {
            label: 'Mapping',
            onClick: () => DialogHelper.openLgDialog('Action Mapping', <ActionMapping action={row} />)
        },
        {
            label: 'View History',
            onClick: () => DialogHelper.openLgDialog('Action History', <BulkActionHistory action={row} />)
        },
        {
            label: 'Archive',
            onClick: () => deleteAction(row)
        }
    ];
    if (row.started === false) {
        actions.push({
            label: 'Run Action',
            onClick: () => runAction(row)
        });
    }
    return (
        <RbgTableRow>
            <RbgTableCell>{row.id}</RbgTableCell>
            <RbgTableCell>{row.file.data.original_filename}</RbgTableCell>
            <RbgTableCell>{row.user_name}</RbgTableCell>
            <RbgTableCell>{row.type.data.name}</RbgTableCell>
            <RbgTableCell>
                {row.started ? dayjs(row.date_started).format('DD/MM/YYYY HH:mm:ss') : <BooleanIcon value={false} />}
            </RbgTableCell>
            <RbgTableCell>
                <BooleanIcon value={row.completed} />
            </RbgTableCell>
            <RbgTableCell>
                <RbgList>
                    {row.fields.data.map((f) => (
                        <RbgListItem key={f.field_id} sx={{ textAlign: 'center' }}>
                            {f.field?.data?.label} - {f.column_name}
                        </RbgListItem>
                    ))}
                </RbgList>
            </RbgTableCell>
            <RbgTableCell>
                <RbgButtonDropdown label="Options" items={actions} />
            </RbgTableCell>
        </RbgTableRow>
    );
}

BulkActionRow.propTypes = {
    rowData: PropTypes.object.isRequired
};

export default BulkActionRow;
