import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import { staffDocument } from '../../StaffDataTypes';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgLink from '../../../../../ui_component/mui-wrappers/Link/RbgLink';

function DownloadDocument({ document }) {
    const { data, isFetched, error } = useQuery({
        queryKey: [{ url: `/staff/document/${document.id}/download` }]
    });
    if (isFetched === false) {
        return <CardLoader />;
    }
    if (error) {
        return (
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <RbgLink href={data.data.url} target="_blank" rel="noreferrer">
                    Download {document.file.data.filename}
                </RbgLink>
            </RbgGrid>
        </RbgGrid>
    );
}

DownloadDocument.propTypes = {
    document: PropTypes.shape(staffDocument).isRequired
};
export default DownloadDocument;
