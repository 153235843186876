import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import EditContractTemplateSection from './EditContractTemplateSection';
import PropTypes from 'prop-types';
import { contractTemplate } from '../../StaffDataTypes';
import EditContractTemplateNewSections from './EditContractTemplateNewSections';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import MultipleLocationSelect from '../../../../../ui_component/location-select/MultipleLocationSelect';

function EditContractTemplateTab({ template, handleTemplateChange }) {
    const handleFieldChange = (name, value) => {
        handleTemplateChange((prev) => ({ ...prev, [name]: value }));
    };
    const removeSection = (id) => {
        const sections = template.sections.data.filter((section) => {
            return section.section_id !== id;
        });
        handleTemplateChange((prev) => ({ ...prev, sections: { data: sections } }));
    };

    const editSection = (newSection) => {
        let updateDisplayOrder = {};
        let found = false;
        let sections = template.sections.data.map((section) => {
            if (section.section_id === newSection.section_id) {
                found = true;
                updateDisplayOrder = {
                    old: newSection.display_order,
                    new: section.display_order
                };
                return newSection;
            }
            return section;
        });
        if (found === false) {
            sections.push(newSection);
        }
        if (updateDisplayOrder.old) {
            sections = sections.map((sec) => {
                if (sec.display_order === updateDisplayOrder.old && sec.section_id !== newSection.section_id) {
                    sec.display_order = updateDisplayOrder.new;
                }
                return sec;
            });
        }
        handleTemplateChange((prev) => ({ ...prev, sections: { data: sections } }));
    };
    const sectionList = template.sections.data.sort((s1, s2) => (s1.display_order > s2.display_order ? 1 : -1));
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    <RbgTypography>
                        Once a contract has been assigned to a member of staff, it becomes locked and cannot be edited.
                    </RbgTypography>
                </IntroCardPrimary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="name"
                    label="Template Name"
                    value={template.name}
                    onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                />
            </RbgGrid>
            <RbgGrid item xs={2}>
                <RbgTextInput
                    label="Minimum contracted hours"
                    name="min_contracted_hours"
                    type="number"
                    value={template.min_contracted_hours}
                    onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                />
            </RbgGrid>
            <RbgGrid item xs={2}>
                <RbgTextInput
                    label="Maximum contracted hours"
                    name="max_contracted_hours"
                    type="number"
                    value={template.max_contracted_hours}
                    onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                />
            </RbgGrid>
            <RbgGrid item xs={3}>
                <RbgRemoteSelect
                    endpoint="/staff/pay-types"
                    name="pay_type"
                    label="Pay Type"
                    handleChange={(e) => {
                        if (!e.target.value) {
                            handleFieldChange('pay_type', { data: {} });
                        }
                    }}
                    handleObjectChange={(e) => {
                        handleFieldChange('pay_type', { data: e.target.object });
                    }}
                    value={template.pay_type.data ? template.pay_type.data.id : ''}
                />
            </RbgGrid>
            <RbgGrid item xs={3}>
                <RbgSelect
                    value={template.contract_type}
                    name="contract_type"
                    handleChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                    values={[
                        { value: 'permanent', label: 'Permanent' },
                        { value: 'temporary', label: 'Temporary' }
                    ]}
                    label="Contract Type"
                />
            </RbgGrid>
            <RbgGrid item xs={2}>
                <RbgRemoteSelect
                    endpoint="/staff/hierarchy-levels"
                    name="min_hierarchy_level"
                    filterData={(data) => {
                        return data.data.map((row) => ({ value: row.level, label: row.name }));
                    }}
                    handleChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                    value={template.min_hierarchy_level}
                    label="Hierachy Level"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <MultipleLocationSelect
                    help="What locations this template will be available at"
                    name="locations"
                    error="test"
                    label="Locations"
                    multiple
                    limitTags={5}
                    value={template.locations}
                    onChange={(e) => handleFieldChange('locations', e.target.value)}
                />
            </RbgGrid>
            <RbgGrid item xs={8}>
                <RbgGrid container>
                    {sectionList.map((section) => {
                        return (
                            <EditContractTemplateSection
                                sectionTemplate={section}
                                editSection={editSection}
                                key={section.section_id}
                                max={sectionList.length}
                                removeSection={removeSection}
                            />
                        );
                    })}
                </RbgGrid>
            </RbgGrid>
            <RbgGrid item xs={4}>
                <EditContractTemplateNewSections currentTemplate={template} editSection={editSection} />
            </RbgGrid>
        </RbgGrid>
    );
}

EditContractTemplateTab.propTypes = {
    template: PropTypes.shape(contractTemplate).isRequired,
    handleTemplateChange: PropTypes.func.isRequired
};

export default EditContractTemplateTab;
