import { createSlice } from '@reduxjs/toolkit';

// project imports
import config from '../../config';

// ==============================|| CUSTOMIZATION SLICE ||============================== //
export const customizationReducer = createSlice({
    name: 'customization',
    initialState: {
        isOpen: [], // for active default menu
        fontFamily: config.fontFamily,
        borderRadius: config.borderRadius,
        outlinedFilled: config.outlinedFilled,
        navType: config.theme,
        presetColor: config.presetColor,
        locale: config.i18n,
        opened: true,
        matchDownSm: false
    },
    reducers: {
        menuOpen: (state, action) => {
            state.isOpen.push(action.payload);
        },
        menuType: (state, action) => {
            state.navType = action.payload;
        },
        presetColors: (state, action) => {
            state.presetColor = action.payload;
        },
        themeLocale: (state, action) => {
            state.locale = action.payload;
        },

        setMenu: (state, action) => {
            state.opened = action.payload;
        },
        setBorderRadius: (state, action) => {
            state.borderRadius = action.payload;
        },
        setMatchDownSm: (state, action) => {
            state.matchDownSm = action.payload;
        }
    }
});

export const { menuOpen, menuType, presetColors, setMenu, setMatchDownSm } = customizationReducer.actions;
