import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import { useEffect, useState } from 'react';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import { useMutation, useQuery } from '@tanstack/react-query';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import StaffFileUpload from '../../Component/StaffFileUpload';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function AddManualContract({ staffMember }) {
    const [contract, setContract] = useState({
        start_date: staffMember.employment.data.start_date,
        end_date: '',
        duration_id: '',
        contracted_hours: '',
        pdf_url: '',
        cost_centre: '',
        file_id: '',
        rate: staffMember.pay_rate.data.rate,
        template_id: ''
    });
    const [file, setFile] = useState({});
    const handleFieldChange = (e) => {
        setContract((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const { data } = useQuery({
        queryKey: [{ url: `/staff/staff-contract-admin/templates?location=${staffMember.role.data.cost_centre}&page=1` }]
    });

    const templates = data.data.filter((template) => {
        return template.allow_manual_upload === true;
    });

    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/contracts/add-manual`, contract);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        let disabled = false;
        if (!contract.start_date) {
            disabled = true;
        }
        if (!contract.file_id) {
            disabled = true;
        }
        if (!contract.template_id) {
            disabled = true;
        }
        if (saveMutation.isPending) {
            disabled = true;
        }
        DialogHelper.addSaveButton(() => saveMutation.mutate(), 'Save', disabled);
    }, [contract, saveMutation]);
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>
                    This should only be used to import a contract which has been signed &#x0022;offline&#x0022;, or imported from a legacy
                    system. This will not ask the team member to sign a new contract, it will immediately be assigned to their profile as
                    the active contract.
                </InfoCard>
            </RbgGrid>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={4}>
                <RbgDatePicker
                    name="start_date"
                    value={contract.start_date}
                    onChange={(date) =>
                        handleFieldChange({
                            target: {
                                name: 'start_date',
                                value: date
                            }
                        })
                    }
                    label="Contract Start Date"
                />
            </RbgGrid>
            <RbgGrid item xs={4}>
                <RbgSelect
                    values={templates.map((t) => ({ value: t.id, label: t.name }))}
                    value={contract.template_id}
                    name="template_id"
                    label="Contract Type"
                    handleChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={4}>
                <RbgRemoteSelect
                    endpoint="/staff/contracts/contracted-hours"
                    name="contracted_hours"
                    value={contract.contracted_hours}
                    label="Contracted Hours"
                    handleChange={handleFieldChange}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                {!file.id ? (
                    <StaffFileUpload
                        onFileUploaded={(file) => {
                            setFile(file);
                            setContract((prev) => ({ ...prev, file_id: file.id, pdf_url: file.uri }));
                        }}
                        staffId={staffMember.id}
                        bucket="rbg-staff-documents"
                        content="Upload contract file here"
                    />
                ) : (
                    <RbgTextInput
                        name="contract_filename"
                        value={file.original_filename}
                        disabled
                        textSecondary={
                            <RbgButton
                                color="error"
                                onClick={() => {
                                    setFile({});
                                    setContract((prev) => ({ ...prev, file_id: '', pdf_url: '' }));
                                }}
                            >
                                Remove
                            </RbgButton>
                        }
                    />
                )}
            </RbgGrid>
        </RbgGrid>
    );
}

AddManualContract.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default AddManualContract;
