import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import MainCard from '../../../../ui_component/cards/MainCard';
import ScDepartmentTab from './Tree/ScDepartmentTab';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';

function ScFamilyTree() {
    const { data, isFetched } = useQuery({
        queryKey: [{ url: '/staff/support-centre/family-tree' }]
    });
    const [currentTab, setCurrentTab] = useState(null);

    const formattedData = data.data.reduce((acc, department) => {
        if (!department.parent_department) {
            // Create a new top-level department entry
            acc.push({ id: department.id, name: department.name, data: [department] });
        } else {
            // Find the parent department
            const parent = acc.find((topDep) => topDep.id === department.parent_department);
            if (parent) {
                parent.data.push(department);
            }
        }
        return acc;
    }, []);

    const tabs = isFetched
        ? formattedData.map((dep) => ({ value: dep.name, label: dep.name, content: <ScDepartmentTab department={dep} /> }))
        : [];

    useEffect(() => {
        if (isFetched && data.data[0].name) {
            setCurrentTab(data.data[0].name);
        }
    }, [isFetched, data.data]);

    if (!isFetched) {
        return <CardLoader />;
    }

    return (
        <MainCard title="Support Centre Family Tree">
            {tabs.length > 0 && currentTab && <RbgTabs setCurrentTab={setCurrentTab} currentTab={currentTab} tabs={tabs} scrollButtons />}
        </MainCard>
    );
}

export default ScFamilyTree;
