import dayjs from 'dayjs';

function getStaffListComparator(orderBy) {
    let comparator = (a, b) => {};
    console.log(orderBy);
    switch (orderBy) {
        case 'display_name':
            comparator = (a, b) => {
                const aName = a.display_name;
                const bName = b.display_name;
                if (aName === bName) {
                    return a.id > b.id ? 1 : -1;
                }
                return aName.localeCompare(bName);
            };
            break;
        case 'payroll_ref':
            comparator = (a, b) => {
                const aPayrollRef = a.payroll_ref;
                const bPayrollRef = b.payroll_ref;
                if (aPayrollRef === bPayrollRef) {
                    return a.id > b.id ? 1 : -1;
                }
                return aPayrollRef.localeCompare(bPayrollRef);
            };
            break;
        case 'role_name':
            comparator = (a, b) => {
                const aRoleName = a.support_centre_role ? a.support_centre_role : a.role_name;
                const bRoleName = b.support_centre_role ? b.support_centre_role : b.role_name;
                if (aRoleName === bRoleName) {
                    return a.id > b.id ? 1 : -1;
                }
                return aRoleName.localeCompare(bRoleName);
            };
            break;
        case 'dept':
            comparator = (a, b) => {
                const aScRoleName = a.support_centre_department;
                const bScRoleName = b.support_centre_department;
                if (aScRoleName === bScRoleName) {
                    return b.display_name.localeCompare(a.display_name);
                }
                return aScRoleName.localeCompare(bScRoleName);
            };
            break;
        case 'contract_type':
            comparator = (a, b) => {
                const aContractType = a.contract_type;
                const bContractType = b.contract_type;
                if (aContractType === bContractType) {
                    return a.id > b.id ? 1 : -1;
                }
                return aContractType.localeCompare(bContractType);
            };
            break;
        case 'start_date':
            comparator = (a, b) => {
                const aDate = a.start_date;
                const bDate = b.start_date;
                if (aDate === bDate) {
                    return a.id > b.id ? 1 : -1;
                }
                return dayjs(aDate).isAfter(dayjs(bDate)) ? 1 : -1;
            };
            break;
        case 'contracted_hours':
            comparator = (a, b) => {
                const aContractHours = a.contracted_hours;
                const bContractHours = b.contracted_hours;
                if (aContractHours === bContractHours) {
                    return a.id > b.id ? 1 : -1;
                }
                return aContractHours > bContractHours ? 1 : -1;
            };
            break;
        case 'active':
            comparator = (a, b) => {
                const aContractStatus = a.active;
                const bContractStatus = b.active;
                if ((aContractStatus && bContractStatus) || (!aContractStatus && !bContractStatus)) {
                    return a.id > b.id ? 1 : -1;
                }
                if (aContractStatus && !bContractStatus) {
                    return 1;
                }
                if (!aContractStatus && bContractStatus) {
                    return -1;
                }
                return 0;
            };
            break;
        // no default
    }
    return comparator;
}

export default getStaffListComparator;
