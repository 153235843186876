import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List } from '@mui/material';
// project imports
import NavItem from '../NavItem/NavItem';
import NavCollapse from '../NavCollapse/NavCollapse';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

function NavGroup({ item }) {
    const theme = useTheme();
    const { user } = useContext(RcloudAuthContext);

    // menu list collapse & items
    const items = item.children
        ?.filter((menu) => {
            if (!user || !user.permissions) {
                return false;
            }
            if (menu.permissionCallback) {
                return menu.permissionCallback(user);
            }
            if (menu.hidden === true) {
                return false;
            }
            if (menu.type === 'collapse') {
                const activeChildren = menu.children.filter((item) => {
                    if (item.permission && user.permissions.indexOf(item.permission) === -1) {
                        return false;
                    }
                    return true;
                });
                if (activeChildren.length === 0) {
                    return false;
                }
            }
            return !(menu.permission && user.permissions.indexOf(menu.permission) === -1);
        })
        .map((menu) => {
            if (menu.hidden === true) {
                return <span />;
            }
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} />;
                default:
                    return (
                        <RbgTypography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </RbgTypography>
                    );
            }
        });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <RbgTypography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <RbgTypography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </RbgTypography>
                            )}
                        </RbgTypography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
}

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
