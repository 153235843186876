import PropTypes from 'prop-types';
// mui
import RbgTextInput from './RbgTextInput';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import BarSelect from '../../bar-select/BarSelect';
import RbgSelect from './RbgSelect';
import RbgRemoteSelect from '../../remote-select/RbgRemoteSelect';
import RbgCheckbox from '../../mui-wrappers/RbgCheckbox';
import RbgDatePicker from '../../date-pickers/RbgDatePicker';
import RbgStack from '../../mui-wrappers/Stack/RbgStack';
// 3rd party
import _ from 'lodash';
import dayjs from 'dayjs';

export default function RbgFilter({
    filters,
    onChange,
    disabled,
    direction = 'row',
    spacing = 2,
    justifyContent,
    alignItems,
    alignContent,
    filter
}) {
    const [values, setValues] = useState(filter);
    const [debounce, setDebounce] = useState(false);

    const triggerUpdate = useCallback(() => {
        onChange(values);
    }, [onChange, values]);

    const debouncedTriggerUpdate = useMemo(() => _.debounce(triggerUpdate, 1000), [triggerUpdate]);

    const handleFieldChange = (e, useDebounce = false) => {
        const { name, type, value } = e.target;

        switch (type) {
            case 'number':
                setValues((prevState) => ({ ...prevState, [name]: +value }));
                break;
            case 'checkbox':
                setValues((prevState) => ({ ...prevState, [name]: e.target.checked }));
                break;
            case 'date':
                setValues((prevState) => ({ ...prevState, [name]: dayjs(value).format('YYYY-MM-DD') }));
                break;
            default:
                setValues((prevState) => ({ ...prevState, [name]: value }));
        }

        if (useDebounce === true) {
            setDebounce(true);
        } else {
            setDebounce(false);
        }
    };

    const handleDateChange = (e) => {
        handleFieldChange({ target: { type: 'date', name: e.target.name, value: e.target.value } });
    };

    useEffect(() => {
        if (Object.keys(values).length > 0) {
            if (debounce) {
                debouncedTriggerUpdate();
            } else {
                triggerUpdate();
            }
        }

        return () => debouncedTriggerUpdate.cancel();
        // eslint-disable-next-line
    }, [values]);

    return (
        <RbgStack
            direction={direction}
            spacing={spacing}
            justifyContent={justifyContent}
            alignItems={alignItems}
            alignContent={alignContent}
        >
            {filters.map((input, idx) => {
                let content;
                switch (input.type) {
                    case 'text':
                        content = (
                            <RbgTextInput
                                label={input.label}
                                name={input.name}
                                value={values[input.name]}
                                type={input.type}
                                disabled={disabled}
                                error={input.error}
                                required={input.required}
                                help={input.help}
                                warning={input.warning}
                                onChange={(e) => handleFieldChange(e, input.useDebounce)}
                                formStyles={input.formStyles}
                            />
                        );
                        break;
                    case 'number':
                        content = (
                            <RbgTextInput
                                label={input.label}
                                name={input.name}
                                value={values[input.name]}
                                type={input.type}
                                min={input.min}
                                max={input.max}
                                disabled={disabled}
                                error={input.error}
                                required={input.required}
                                help={input.help}
                                warning={input.warning}
                                onChange={(e) => handleFieldChange(e, input.useDebounce)}
                                formStyles={input.formStyles}
                            />
                        );
                        break;
                    case 'bar_select':
                        content = (
                            <BarSelect
                                label={input.label}
                                bar={values[input.name]}
                                disabled={disabled}
                                error={input.error}
                                required={input.required}
                                help={input.help}
                                warning={input.warning}
                                setBar={(e) => handleFieldChange({ target: { name: input.name, value: e, type: null } })}
                                formStyles={input.formStyles}
                            />
                        );
                        break;
                    case 'select':
                        content = (
                            <RbgSelect
                                label={input.label}
                                name={input.name}
                                value={values[input.name]}
                                values={input.values}
                                disabled={disabled}
                                error={input.error}
                                multiple={input.multiple}
                                help={input.help}
                                useAutoComplete
                                required={input.required}
                                disableBlankOption={input.disableBlankOption}
                                handleChange={(e) => handleFieldChange(e)}
                                formStyles={input.formStyles}
                            />
                        );
                        break;
                    case 'remote_select':
                        content = (
                            <RbgRemoteSelect
                                label={input.label}
                                name={input.name}
                                value={values[input.name]}
                                disabled={disabled}
                                required={input.required}
                                error={input.error}
                                help={input.help}
                                multiple={input.multiple}
                                warning={input.warning}
                                endpoint={input.endpoint}
                                useCached={input.useCached}
                                filterData={
                                    input.filterData
                                        ? input.filterData
                                        : (data) => {
                                              if (data.data) {
                                                  return data.data.map((item) => ({
                                                      value: item.id,
                                                      label: item.name
                                                  }));
                                              }
                                              return [];
                                          }
                                }
                                useAutoComplete={input.useAutoComplete}
                                disableBlankOption={input.disableBlankOption}
                                handleChange={(e) => handleFieldChange(e)}
                            />
                        );
                        break;
                    case 'checkbox':
                        content = (
                            <RbgCheckbox
                                label={input.label}
                                name={input.name}
                                checked={values[input.name]}
                                disabled={disabled}
                                required={input.required}
                                error={input.error}
                                help={input.help}
                                warning={input.warning}
                                onChange={(e) => handleFieldChange(e)}
                            />
                        );
                        break;
                    case 'date':
                        content = (
                            <RbgDatePicker
                                label={input.label}
                                name={input.name}
                                value={values[input.name]}
                                disabled={disabled}
                                required={input.required}
                                orientation={input.orientation}
                                onChange={(e) => handleDateChange({ target: { name: input.name, value: e } })}
                            />
                        );
                        break;
                    default:
                        content = null;
                }
                return <Fragment key={idx}>{content}</Fragment>;
            })}
        </RbgStack>
    );
}

RbgFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(dayjs), PropTypes.object]),
            checked: PropTypes.bool,
            values: PropTypes.array,
            min: PropTypes.number,
            max: PropTypes.number,
            required: PropTypes.bool,
            help: PropTypes.string,
            endpoint: PropTypes.string,
            useCached: PropTypes.bool,
            error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            filterData: PropTypes.func,
            endpointParameters: PropTypes.object,
            disableClearable: PropTypes.bool,
            useAutoComplete: PropTypes.bool,
            disableBlankOption: PropTypes.bool,
            blankOptionText: PropTypes.string,
            size: PropTypes.oneOf(['small', 'medium']),
            orientation: PropTypes.oneOf(['portrait', 'landscape']),
            useDebounce: PropTypes.bool
        })
    ).isRequired,
    disabled: PropTypes.bool,
    direction: PropTypes.oneOf(['row', 'column']),
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    alignContent: PropTypes.string,
    spacing: PropTypes.number,
    filter: PropTypes.object.isRequired
};
