import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
// mui wrappers
import RbgTableHead from './RbgTableHead';
import RbgTableCell from '../TableCell/RbgTableCell';
import RbgTableRow from '../TableRow/RbgTableRow';

function RbgTableHeadFilter({ headCells, order, orderBy, handleSort }) {
    const createSortHandler = (property) => (event) => {
        handleSort(event, property);
    };

    return (
        <RbgTableHead>
            <RbgTableRow>
                {headCells.map(
                    (headCell) =>
                        !headCell.hideCell && (
                            <RbgTableCell
                                key={headCell.id}
                                align={headCell?.direction || 'center'}
                                padding={headCell?.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {headCell.sort ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </RbgTableCell>
                        )
                )}
            </RbgTableRow>
        </RbgTableHead>
    );
}

export default RbgTableHeadFilter;

RbgTableHeadFilter.propTypes = {
    headCells: PropTypes.array.isRequired,
    handleSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};
