import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddEditEmail from './AddEditEmail';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import dayjs from 'dayjs';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconCopy, IconEdit, IconPlus } from '@tabler/icons-react';
import RbgButtonDropdown from '../../../../../ui_component/mui-wrappers/Button/RbgButtonDropdown';
import StaffAdminEmailTemplateLog from './StaffAdminEmailTemplateLog';

function StaffAdminEmailTemplates() {
    const [filter, setFilter] = useState({
        page: 1,
        search: ''
    });
    const { status, isPending, error, data, isFetching, refetch } = useQuery({
        queryKey: ['staff_admin_email_templates', filter],
        initialData: {
            data: [],
            meta: {
                pagination: {}
            }
        },
        queryFn: () => Api.get('/staff/staff-admin/email-templates', filter).then((res) => res.data)
    });
    const addEditEmail = (email = null) => {
        DialogHelper.openLgDialog(
            email ? 'Edit Email Template' : 'Add Email Template',
            <AddEditEmail email={email} onSave={() => refetch()} />
        );
    };
    const viewLog = (email = null) => {
        DialogHelper.openLgDialog('View Template Log', <StaffAdminEmailTemplateLog template={email} />);
    };
    const cloneEmail = (email = null) => {
        const newEmail = JSON.parse(JSON.stringify(email));
        newEmail.id = null;
        newEmail.name = `Copy of ${newEmail.name}`;
        DialogHelper.openLgDialog('Clone E-mail Template', <AddEditEmail email={newEmail} onSave={() => refetch()} />);
    };
    if (isPending || isFetching) {
        return <CardLoader />;
    }
    return (
        <PageableTable
            headers={[
                '#',
                'Name',
                'Date Created',
                'Subject',
                'Tagline',
                <RbgButton onClick={() => addEditEmail()} startIcon={<IconPlus />}>
                    New
                </RbgButton>
            ]}
            pagination={data.meta.pagination}
            data={data.data}
            keys={data.data.map((row) => row.id)}
            rowDecorator={(row) => {
                return [
                    row.id,
                    row.name,
                    dayjs(row.date_created).format('DD/MM/YYYY'),
                    row.subject,
                    row.tagline,
                    <RbgStack direction="row" spacing={0}>
                        <RbgButtonDropdown
                            size="sm"
                            label="Options"
                            items={[
                                {
                                    label: 'Edit',
                                    onClick: () => addEditEmail(row)
                                },
                                {
                                    label: 'Clone',
                                    onClick: () => cloneEmail(row)
                                },
                                {
                                    label: 'View Log',
                                    onClick: () => viewLog(row)
                                }
                            ]}
                        />
                    </RbgStack>
                ];
            }}
            handlePageChange={(page) => setFilter({ ...filter, page })}
        />
    );
}

export default StaffAdminEmailTemplates;
