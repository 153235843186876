import PropTypes from 'prop-types';
// mui imports
import { Alert } from '@mui/material';

function ErrorCard({ variant = 'filled', styles, children, ...others }) {
    if (children instanceof Error) {
        if (Array.isArray(children.fields) && children.fields.length > 0) {
            let message = `${children.toString()} - `;
            message += children.fields
                .map((field) => {
                    return field.error;
                })
                .join(', ');
            children = message;
        } else {
            children = children.toString();
        }
    }
    return (
        <Alert severity="error" variant={variant} {...others}>
            {children}
        </Alert>
    );
}

export default ErrorCard;

ErrorCard.propTypes = {
    variant: PropTypes.oneOf(['filled', 'outlined']),
    styles: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string, PropTypes.node, PropTypes.object])
};
