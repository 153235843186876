import { createSlice } from '@reduxjs/toolkit';

// ==============================|| BREADCRUMBS REDUCER ||============================== //

export const breadcrumbsReducer = createSlice({
    name: 'breadcrumbs',
    initialState: {
        variables: {}
    },
    reducers: {
        setVariable: (state, action) => {
            state.variables = action.payload;
        }
    }
});

export const { setVariable } = breadcrumbsReducer.actions;
