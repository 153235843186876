// project imports
import NavGroup from './NavGroup/NavGroup';
import menuItem from 'menu-items/menuItems';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../contexts/RcloudAuthContext';

// ==============================|| SIDEBAR MENU LIST ||============================== //

function MenuList() {
    const { user } = useContext(RcloudAuthContext);

    const navItems = menuItem.items
        .filter((i) => {
            if (!user || !user.permissions) {
                return false;
            }
            if (i.permissionCallback) {
                return i.permissionCallback(user);
            }
            if (!i.permission) {
                return true;
            }
            if (i.permission && user.permissions.indexOf(i.permission) === -1) {
                return false;
            }
            return true;
        })
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <RbgTypography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </RbgTypography>
                    );
            }
        });

    return navItems;
}

export default MenuList;
