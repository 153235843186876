import { useMutation, useQuery } from '@tanstack/react-query';
import Api from '../../../../utils/api';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainCard from '../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../ui_component/error/InfoCard';
import RbgTextInput from '../../../../ui_component/extended/Form/RbgTextInput';
import RbgDatePicker from '../../../../ui_component/date-pickers/RbgDatePicker';
import dayjs from 'dayjs';
import StaffFileUpload from '../Component/StaffFileUpload';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgLocationSelect from '../../../../ui_component/location-select/RbgLocationSelect';
import RbgSelect from '../../../../ui_component/extended/Form/RbgSelect';

function ManualAddNewStarter() {
    const [data, setData] = useState({
        forename: '',
        surname: '',
        email: '',
        location: '',
        date_of_birth: '',
        position: '',
        rtw_check_id: ''
    });
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/manual-add-new-starter', data);
        },
        onSuccess: (data) => {
            navigate(`/staff/onboarding/${data.data.id}`);
        }
    });

    const { data: positions } = useQuery({
        queryKey: [{ url: `/staff/onboarding/positions?cost_centre=${data.location}` }]
    });

    let disabled = false;
    if (!data.location) {
        disabled = true;
    }
    if (!data.position) {
        disabled = true;
    }
    if (!data.forename) {
        disabled = true;
    }
    if (!data.surname) {
        disabled = true;
    }
    if (!data.email) {
        disabled = true;
    }
    if (!data.rtw_check_id) {
        disabled = true;
    }
    if (mutation.isPending) {
        disabled = true;
    }
    return (
        <MainCard title="Manual Add New Starter">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <InfoCard>
                        Manually enter the new starter information below. This should only be used in the rare occurrence that we will not
                        get the information passed over from the ATS
                    </InfoCard>
                </RbgGrid>
                <RbgGrid item xs={12} md={6}>
                    <RbgLocationSelect
                        name="location"
                        label="Location"
                        value={data.location}
                        onChange={(e) => setData((prev) => ({ ...prev, location: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12} md={6}>
                    {!data.location ? (
                        <RbgSelect values={[]} label="Position" disabled help="Select location to see avaialble positions" />
                    ) : (
                        <RbgSelect
                            values={positions.data
                                .sort((pos1, pos2) => pos1.pay_type.data.name.localeCompare(pos2.pay_type.data.name))
                                .map((pos) => {
                                    return {
                                        value: pos.id,
                                        label: `${pos.pay_type.data.name} - ${pos.name}`,
                                        object: pos
                                    };
                                })}
                            label="Position"
                            useAutoComplete
                            value={data.position}
                            name="position"
                            groupBy={(opt) => {
                                return opt.object.pay_type.data.name;
                            }}
                            handleChange={(e) => setData((prev) => ({ ...prev, position: e.target.value }))}
                            help="Chose the role the team member will be onboarded to"
                        />
                    )}
                </RbgGrid>
                <RbgGrid item xs={12} md={6}>
                    <RbgTextInput
                        name="forename"
                        label="Forename"
                        required
                        value={data.forename}
                        onChange={(e) => setData((prev) => ({ ...prev, forename: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12} md={6}>
                    <RbgTextInput
                        name="surname"
                        required
                        label="Surname"
                        value={data.surname}
                        onChange={(e) => setData((prev) => ({ ...prev, surname: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12} md={6}>
                    <RbgTextInput
                        name="email"
                        required
                        type="email"
                        label="E-mail address"
                        value={data.email}
                        onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
                    />
                </RbgGrid>
                <RbgGrid item xs={12} md={6}>
                    <RbgDatePicker
                        name="date_of_birth"
                        label="Date of birth"
                        disableFuture
                        defaultCalendarMonth={dayjs().add(-16, 'year')}
                        maxDate={dayjs().add(-16, 'year').toDate()}
                        minDate={dayjs().add(-100, 'year').toDate()}
                        value={data.date_of_birth ? data.date_of_birth : ''}
                        onChange={(date) => {
                            setData((prev) => ({
                                ...prev,
                                date_of_birth: date ? date.format('YYYY-MM-DD') : ''
                            }));
                        }}
                    />
                </RbgGrid>
                {!data.rtw_check_id && (
                    <RbgGrid item xs={12}>
                        <StaffFileUpload
                            onFileUploaded={(data) => {
                                setData((prev) => ({ ...prev, rtw_check_id: data.id }));
                                setFile(data);
                            }}
                            staffId={1}
                            bucket="rbg-staff-documents"
                            content="Upload Trust ID Check"
                        />
                    </RbgGrid>
                )}
                {file && (
                    <RbgGrid item xs={12}>
                        <RbgTextInput
                            value={file.original_filename}
                            disabled
                            label="Trust ID Check"
                            textSecondary={
                                <RbgButton
                                    color="warning"
                                    onClick={() => {
                                        setFile(null);
                                        setData((prev) => ({ ...prev, rtw_check_id: '' }));
                                    }}
                                >
                                    Remove
                                </RbgButton>
                            }
                        />
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgButton color="primary" sx={{ width: '100%' }} disabled={disabled} onClick={() => mutation.mutate()}>
                        Add New Starter
                    </RbgButton>
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default ManualAddNewStarter;
