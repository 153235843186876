import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useEffect, useState } from 'react';
import RbgRemoteSelect from '../../../../ui_component/remote-select/RbgRemoteSelect';
import Debug from '../../../../ui_component/misc/Debug';
import StaffFileUpload from '../Component/StaffFileUpload';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../utils/api';
import { DialogHelper } from '../../../../ui_component/modals/RbgDialog';
import ActionMapping from './ActionMapping';

function CreateBulkAction() {
    const [typeData, setTypeData] = useState({
        type: { data: {} },
        file: { data: {} }
    });
    const queryClient = useQueryClient();
    const previewMutation = useMutation({
        enabled: false,
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/bulk-actions/create-from-file', {
                type_id: typeData.type.data.id,
                file_id: typeData.file.data.id
            });
        },
        onSuccess(data) {
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/bulk-actions' }]
            });
            DialogHelper.closeDialog();
            setTimeout(() => {
                DialogHelper.openLgDialog('Action Mapping', <ActionMapping action={data.data} />);
            }, 200);
        }
    });

    return (
        <RbgGrid container>
            <RbgGrid item xs={6}>
                <RbgRemoteSelect
                    name="type"
                    label="Action Type"
                    value={typeData.type.data.id}
                    endpoint="/staff/bulk-actions/types"
                    autoSelectSingleOption
                    handleChange={(e) => {
                        if (!e.target.value) {
                            setTypeData((prev) => ({ ...prev, type: { data: {} } }));
                        }
                    }}
                    handleObjectChange={(e) => {
                        setTypeData((prev) => ({ ...prev, type: { data: e.target.object } }));
                    }}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <StaffFileUpload
                    onFileUploaded={(file) => setTypeData((prev) => ({ ...prev, file: { data: file } }))}
                    staffId={1}
                    bucket="rbg-staff-files"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton fullWidth disabled={!typeData.type.data.id || !typeData.file.data.id} onClick={() => previewMutation.mutate()}>
                    Continue
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

export default CreateBulkAction;
