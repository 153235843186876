import { lazy } from 'react';
// project imports
import config from '../config';
import Loadable from 'ui_component/loading/Loadable';
import MinimalLayout from 'layout/MinimalLayout/MinimalLayout';
import NavigationScroll from 'layout/NavigationScroll';

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));
const AuthCodeVerification = Loadable(lazy(() => import('views/pages/authentication/authentication/CodeVerification')));
// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));
const ErrorBoundary = Loadable(lazy(() => import('ui_component/error/ErrorBoundary/ErrorBoundary')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    basename: config.basename,
    path: '/',
    element: (
        <ErrorBoundary hideHomeButton>
            <NavigationScroll>
                <MinimalLayout />
            </NavigationScroll>
        </ErrorBoundary>
    ),
    children: [
        {
            path: '/pages/login/login3',
            element: <AuthLogin />
        },
        {
            path: '/pages/forgot-password/forgot-password3',
            element: <AuthForgotPassword />
        },
        {
            path: '/pages/reset-password/reset-password3',
            element: <AuthResetPassword />
        },
        {
            path: '/pages/code-verification/code-verification3',
            element: <AuthCodeVerification />
        },

        {
            path: '/pages/error',
            element: <MaintenanceError />
        },
        {
            path: '/pages/under-construction',
            element: <MaintenanceUnderConstruction />
        }
    ]
};

export default AuthenticationRoutes;
