import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Box, styled } from '@mui/material';
import Api from '../../../../utils/api';
import _ from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';

const DropZoneBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.grey50 : theme.colors?.darkTextPrimary,
    borderStyle: 'dashed',
    background: theme.paper,
    color: theme.heading,
    outline: 'none',
    transition: 'border .2s ease-in-out',
    '&:hover': {
        borderColor: theme?.palette?.primary.main
    }
}));

function StaffFileUpload({
    staffId,
    onFileUploaded,
    content = 'Drag files here, or click to upload',
    bucket = 'rcloud-fileshare',
    publicDownload = false,
    endpoint
}) {
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const createUploadingFile = useCallback(
        (data) => {
            const files = uploadingFiles;
            files.push(data);
            setUploadingFiles(files);
        },
        [uploadingFiles]
    );

    const updateUploadingFile = useCallback(
        (id, data) => {
            const files = uploadingFiles.map((file) => {
                if (file.id === id) {
                    file = _.merge(file, data);
                    //{ ...file, ...data };
                }
                return file;
            });
            setUploadingFiles(files);
        },
        [uploadingFiles]
    );

    const uploadFile = useCallback(
        (id) => {
            const file = uploadingFiles.find((file) => file.id === id);
            if (!file) {
                return;
            }
            updateUploadingFile(file.id, { uploading: true });
            Api.post(
                endpoint || '/staff/upload-file',
                { filename: file.name, file_data: file.data, bucket, private: publicDownload ? '1' : '0' },
                {
                    onUploadProgress: (r) => {
                        updateUploadingFile(file.id, { complete_percentage: r.progress * 100 });
                    }
                }
            ).then((r) => {
                if (r.ok) {
                    onFileUploaded(r.data.data);
                    updateUploadingFile(file.id, { uploading: false });
                } else {
                    updateUploadingFile(file.id, { error: 'Error in uploading file, please try again' });
                }
            });
        },
        [uploadingFiles, updateUploadingFile, onFileUploaded, bucket, endpoint, publicDownload]
    );

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const id = _.uniqueId('file');
                createUploadingFile({
                    id,
                    name: file.name,
                    last_modified: file.last_modified,
                    uploading: false,
                    complete_percentage: 0,
                    error: null,
                    data: null
                });
                const reader = new FileReader();

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {
                    updateUploadingFile(id, {
                        data: reader.result
                    });
                    uploadFile(id);
                };
                reader.readAsDataURL(file);
            });
        },
        [createUploadingFile, uploadFile, updateUploadingFile]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <DropZoneBox {...getRootProps()}>
            <input {...getInputProps()} />
            {content}
            {uploadingFiles
                .filter((file) => {
                    return file.uploading === true;
                })
                .map((file) => {
                    return (
                        <div key={file.id} style={{ maxWidth: '200px', maxHeight: '200px', overflow: 'hidden' }}>
                            <LinearProgress variant="determinate" value={file.complete_percentage ? file.complete_percentage : 0} />{' '}
                            {file.error ? file.error : file.name}
                        </div>
                    );
                })}
        </DropZoneBox>
    );
}

StaffFileUpload.propTypes = {
    staffId: PropTypes.number.isRequired,
    onFileUploaded: PropTypes.func.isRequired,
    content: PropTypes.string,
    bucket: PropTypes.string,
    publicDownload: PropTypes.bool,
    endpoint: PropTypes.string
};

export default StaffFileUpload;
