import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PdfViewer from '../../../../../ui_component/pdf-viewer/PdfViewer';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RelativeTime from '../../../../../ui_component/relative-time/RelativeTime';
import dayjs from 'dayjs';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { useContext, useState } from 'react';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import StaffFileUpload from '../../Component/StaffFileUpload';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function NewStarterRightToWorkCheck({ data, newStarterId, setTab }) {
    const { user } = useContext(RcloudAuthContext);

    const [checked, setChecked] = useState(data.data.right_to_work_checked);
    const queryClient = useQueryClient();
    const checkMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${newStarterId}/rtw-check`, { checked });
        },
        onSuccess: () => {
            setTab('confirmation');
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${newStarterId}` }]
            });
        }
    });

    const newRtwFileMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${newStarterId}/rtw-file`, { file_id: id });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${newStarterId}` }]
            });
        }
    });

    const approveMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', `/staff/onboarding-admin/rtw-check/${id}/approve`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${newStarterId}` }]
            });
        }
    });

    const canApprove = user.permissions.indexOf('hr_document_auth') > -1;
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgStack gap={2}>
                    {data.data.rtw_check.data.length === 0 && (
                        <>
                            <WarningCard>
                                There are no right to work checks uploaded for this team member. Please ask the OST to upload assist in
                                creating a check.
                            </WarningCard>
                            {user.permissions.indexOf('hr_document_auth') > -1 && (
                                <StaffFileUpload
                                    onFileUploaded={(file) => newRtwFileMutation.mutate(file.id)}
                                    staffId={0}
                                    bucket="rbg-staff-documents"
                                />
                            )}
                        </>
                    )}
                    {data.data.rtw_check.data.length === 1 && (
                        <InfoCard>Check the information contained matches the right to work check.</InfoCard>
                    )}
                    {data.data.rtw_check.data.length > 1 && (
                        <InfoCard>There are multiple checks for this employee, please check all documents.</InfoCard>
                    )}
                </RbgStack>
            </RbgGrid>
            {data.data.rtw_check.data.map((check) => {
                const checkData = check.check_data;
                return (
                    <RbgGrid item xs={12} key={check.id}>
                        <RbgStack gap={2}>
                            {checkData.admin_check_required && (
                                <ErrorCard>
                                    Your HRBP will need to check this submission, and will approve or contact you if there are any issues in
                                    the next 24 hours: <strong>{checkData.admin_check_reason}</strong>
                                </ErrorCard>
                            )}
                            {checkData.admin_check_required && canApprove && (
                                <RbgButton
                                    color="success"
                                    onClick={() => approveMutation.mutate(check.id)}
                                    disabled={approveMutation.isPending}
                                >
                                    Approve RTW Check
                                </RbgButton>
                            )}
                            <RbgSimpleTable
                                headers={[
                                    'When the check was completed',
                                    'Is there a limit on how long the check is valid for?',
                                    'Are there any restrictions on what type of work they can undertake?',
                                    'Is there a maximum amount of hours they can work each week?'
                                ]}
                                rows={[
                                    [
                                        <RelativeTime time={checkData.date_of_check} />,
                                        checkData.check_expires
                                            ? `This team member will need to have another check completed before ${dayjs(
                                                  checkData.check_expires
                                              ).format('DD/MM/YYYY')}`
                                            : 'No, they can work continuously',
                                        checkData.restrictions ? checkData.restrictions : 'No restrictions',
                                        checkData.hours ? `They are only permitted to work ${checkData.hours} per week` : 'No'
                                    ]
                                ]}
                            />
                            <PdfViewer file={`/api/staff/onboarding/team-member/${newStarterId}/pdf-proxy/${check.file.data.id}`} />
                        </RbgStack>
                    </RbgGrid>
                );
            })}
            {data.data.rtw_check.data.length >= 1 && (
                <>
                    <RbgGrid item xs={12}>
                        <RbgCheckbox
                            label="I confirm that i have checked over the right to work document and it matches the person we have hired"
                            checked={checked}
                            onChange={(e) => {
                                setChecked(e.target.checked);
                            }}
                        />
                    </RbgGrid>
                    {checkMutation.error && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{checkMutation.error}</ErrorCard>
                        </RbgGrid>
                    )}
                    <RbgGrid item xs={12}>
                        <RbgButton
                            onClick={() => {
                                checkMutation.mutate();
                            }}
                            sx={{ width: '100%' }}
                            disabled={checked === false}
                        >
                            Next
                        </RbgButton>
                    </RbgGrid>
                </>
            )}
        </RbgGrid>
    );
}

NewStarterRightToWorkCheck.propTypes = {
    data: PropTypes.object.isRequired,
    newStarterId: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired
};
export default NewStarterRightToWorkCheck;
