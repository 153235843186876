import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// mui
import RbgPaper from '../mui-wrappers/Paper/RbgPaper';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';
import { Divider, useTheme } from '@mui/material';
import RbgStack from '../mui-wrappers/Stack/RbgStack';
import RbgSelect from '../extended/Form/RbgSelect';
// 3rd party
import dayjs from 'dayjs';

export default function DatePartPicker({
    label = 'DOB',
    required,
    size = 'small',
    name,
    daySelectProps,
    monthSelectProps,
    yearSelectProps,
    onChange,
    error
}) {
    const theme = useTheme();
    const formStyles = {
        '.MuiFormLabel-root': { background: theme.palette.background.paper, [error && 'color']: `${theme.palette.error.main}` },
        fieldset: {
            border: 'none',
            borderRadius: 0
        }
    };
    const [date, setDate] = useState({
        day: '',
        month: '',
        year: ''
    });
    const isMounting = useRef(false);

    const getOptions = (start, count) => {
        const result = [];
        for (let i = start; i <= count; i += 1) {
            result.push({
                value: i.toString(),
                label: i.toString()
            });
        }
        return result;
    };

    const days = getOptions(1, 31);
    const months = Array.from({ length: 12 }, (_, i) => dayjs().month(i).format('MMMM')).map((month, idx) => ({
        value: (idx + 1).toString(),
        label: month
    }));
    const years = getOptions(dayjs().year() - 100, dayjs().year());

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setDate((prevState) => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        if (isMounting.current) {
            onChange({ target: { name, value: date } });
        } else {
            isMounting.current = true;
        }
        // eslint-disable-next-line
    }, [date]);

    return (
        <RbgStack>
            <RbgPaper
                sx={{
                    border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.main}`,
                    [error && 'borderColor']: `${theme.palette.error.main}`,
                    width: '100%'
                }}
                component={RbgStack}
                direction="row"
                alignItems="center"
            >
                <RbgTypography sx={{ px: 1 }}>
                    {label}
                    {required && (
                        <RbgTypography color="red" variant="span">
                            *
                        </RbgTypography>
                    )}
                </RbgTypography>

                <Divider orientation="vertical" flexItem />
                <RbgSelect
                    textFieldStyles={formStyles}
                    label="Day"
                    name="day"
                    size={size}
                    value={date.day}
                    handleChange={handleFieldChange}
                    values={days}
                    {...daySelectProps}
                />

                <Divider orientation="vertical" flexItem />
                <RbgSelect
                    textFieldStyles={formStyles}
                    label="Month"
                    name="month"
                    size={size}
                    value={date.month}
                    handleChange={handleFieldChange}
                    values={months}
                    {...monthSelectProps}
                />

                <Divider orientation="vertical" flexItem />
                <RbgSelect
                    textFieldStyles={formStyles}
                    size={size}
                    label="Year"
                    name="year"
                    value={date.year}
                    handleChange={handleFieldChange}
                    values={years}
                    {...yearSelectProps}
                />
            </RbgPaper>
            {error && (
                <RbgTypography sx={{ margin: '4px 14px 0' }} color={theme.palette.error.main}>
                    {error}
                </RbgTypography>
            )}
        </RbgStack>
    );
}

DatePartPicker.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    daySelectProps: PropTypes.object,
    monthSelectProps: PropTypes.object,
    yearSelectProps: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
