import PropTypes from 'prop-types';
import { StaffEmail } from '../../StaffDataTypes';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import RbgEditor from '../../../../../ui_component/wysiwyg/RbgEditor';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import StaffAdminEmailPreview from './StaffAdminEmailPreview';

function AddEditEmail({ email, onSave }) {
    const [emailEdit, setEmailEdit] = useState(
        email || {
            id: null,
            name: '',
            date_created: '',
            outer_template_id: '',
            date_updated: '',
            subject: '',
            tagline: '',
            content: '',
            text_content: ''
        }
    );
    const [tab, setTab] = useState('content');
    const [errors, setErrors] = useState([]);
    const { data: types } = useQuery({
        queryKey: ['staff_email_types'],
        initialData: {
            data: []
        },
        queryFn: () => Api.get('/staff/staff-admin/email-outer-templates').then((res) => res.data)
    });

    const handleSave = useCallback(() => {
        DialogHelper.disableSaveButton();
        setErrors([]);
        const handleResponse = (response) => {
            if (response.ok) {
                if (onSave) {
                    onSave(response.data.data);
                }
                DialogHelper.successNotification('E-mail updated');
                DialogHelper.closeDialog();
                return;
            }
            setErrors(response.data.errors.fields);
            DialogHelper.enableSaveButton();
            DialogHelper.errorNotification(response.error_message);
        };
        if (emailEdit.id) {
            Api.patch(`/staff/staff-admin/email-template/${emailEdit.id}`, emailEdit).then(handleResponse);
        } else {
            Api.post('/staff/staff-admin/email-template', emailEdit).then(handleResponse);
        }
    }, [emailEdit, onSave]);

    const getError = useCallback(
        (field) => {
            const fieldErrors = errors.filter((error) => {
                return error.field === field;
            });
            if (fieldErrors.length > 0) {
                return fieldErrors
                    .map((error) => {
                        return error.error;
                    })
                    .join(', ');
            }
            return null;
        },
        [errors]
    );
    const tabs = [
        {
            value: 'content',
            label: 'Content',
            content: (
                <RbgGrid container spacing={gridSpacing} sx={{ pt: 1 }}>
                    <RbgGrid item xs={12}>
                        <RbgSelect
                            error={getError('outer_template_id')}
                            value={emailEdit.outer_template_id}
                            help="The main look and feel of the e-mail template"
                            values={types.data.map((type) => {
                                return {
                                    value: type.id,
                                    label: type.name
                                };
                            })}
                            required
                            label="Outer Template"
                            handleChange={(e) => setEmailEdit({ ...emailEdit, outer_template_id: e.target.value })}
                        />
                    </RbgGrid>
                    <RbgGrid item xs={12} lg={4}>
                        <RbgTextInput
                            label="Template Name"
                            name="name"
                            required
                            error={getError('name')}
                            help="Name of the template, ensure this is unique and representative of the purpose"
                            value={emailEdit.name}
                            onChange={(e) => setEmailEdit({ ...emailEdit, name: e.target.value })}
                        />
                    </RbgGrid>
                    <RbgGrid item xs={12} lg={4}>
                        <RbgTextInput
                            label="Subject"
                            required
                            name="subject"
                            error={getError('subject')}
                            help="Subject line of the e-mail"
                            value={emailEdit.subject}
                            onChange={(e) => setEmailEdit({ ...emailEdit, subject: e.target.value })}
                        />
                    </RbgGrid>
                    <RbgGrid item xs={12} lg={4}>
                        <RbgTextInput
                            label="Tagline"
                            name="tagline"
                            error={getError('tagline')}
                            help="This displays in the header of the e-mail, most e-mail clients use this as the first line in the preview"
                            value={emailEdit.tagline}
                            onChange={(e) => setEmailEdit({ ...emailEdit, tagline: e.target.value })}
                        />
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <RbgEditor
                            label="Content"
                            name="content"
                            error={getError('content')}
                            placeholder="Main Email Content"
                            value={emailEdit.content}
                            variables={[
                                {
                                    variable: "{{ cta(employment.getOnboardUrl(), 'Your button copy here') }}",
                                    description: 'Onboard URL Link'
                                },
                                {
                                    variable: '{{ details.getForename() }}',
                                    description: 'Forename'
                                },
                                {
                                    variable: '{{ details.getSurname() }}',
                                    description: 'Surname'
                                },
                                {
                                    variable: '{{ details.getEmail() }}',
                                    description: 'E-mail address'
                                }
                            ]}
                            onChange={(e) => setEmailEdit((prev) => ({ ...prev, content: e.target.value }))}
                        />
                    </RbgGrid>
                </RbgGrid>
            )
        },
        {
            value: 'preview',
            label: 'Preview',
            content: <StaffAdminEmailPreview email={emailEdit} />
        }
    ];

    useEffect(() => {
        DialogHelper.addSaveButton(() => handleSave(), 'Save');
    }, [handleSave]);
    return <RbgTabs currentTab={tab} setCurrentTab={(t) => setTab(t)} tabs={tabs} />;
}

AddEditEmail.propTypes = {
    email: PropTypes.shape(StaffEmail),
    onSave: PropTypes.func
};
export default AddEditEmail;
