import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Menu } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [App Bar](https://mui.com/material-ui/react-app-bar/)
 * - [Menu](https://mui.com/material-ui/react-menu/)
 *
 * API:
 *
 * - [Menu API](https://mui.com/material-ui/api/menu/)
 * - inherits [Popover API](https://mui.com/material-ui/api/popover/)
 */

const RbgMenu = forwardRef(({ children, ...others }, ref) => (
    <Menu ref={ref} {...others}>
        {children}
    </Menu>
));

export default RbgMenu;

RbgMenu.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
