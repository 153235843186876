import PropTypes from 'prop-types';

// material-ui imports
import { Paper } from '@mui/material';

function Debug({ source }) {
    return (
        <Paper>
            <pre>{JSON.stringify(source, null, 2)}</pre>
        </Paper>
    );
}

Debug.propTypes = {
    source: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default Debug;
