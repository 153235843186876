import PropTypes from 'prop-types';
import Debug from '../../../../../ui_component/misc/Debug';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import BarSelect from '../../../../../ui_component/bar-select/BarSelect';
import { useState } from 'react';
import RbgLocationSelect from '../../../../../ui_component/location-select/RbgLocationSelect';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function MoveLocation({ data }) {
    const [bar, setBar] = useState(data.data.location);
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${data.data.id}/move`, {
                target: bar
            });
        },
        onSuccess() {
            window.location.href = '/staff';
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>
                    Where do you want to move this new starter to? Note: You should only be using this if the location has been ascertained
                    incorrectly from Talos import data
                </InfoCard>
            </RbgGrid>
            {saveMutation.isError && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgLocationSelect label="Location" value={bar} name="location" onChange={(b) => setBar(b.target.value)} />
            </RbgGrid>

            <RbgGrid item xs={12}>
                <RbgButton fullWidth onClick={() => saveMutation.mutate()}>
                    Move
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

MoveLocation.propTypes = {
    data: PropTypes.object.isRequired
};
export default MoveLocation;
