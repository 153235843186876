import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// mui imports
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
// 3rd party
import dayjs from 'dayjs';
// project imports
import { updateShift } from 'store/reducers/rotaReducer';
import EditShift from './EditShift';

function ShiftCell({ shifts = [] }) {
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    if (edit === true) {
        return (
            <RbgGrid container direction="column">
                {shifts.map((ss) => (
                    <RbgGrid item key={ss.id}>
                        <EditShift
                            shift={ss}
                            onChange={(value) => {
                                let start = '';
                                let finish = '';
                                [start, finish] = value.split('-');
                                start = `${dayjs(ss.shift_start).format('YYYY-MM-DD')} ${start}`;
                                finish = `${dayjs(ss.shift_end).format('YYYY-MM-DD')} ${finish}`;
                                const shift = { ...ss, shift_start: start, shift_end: finish };
                                dispatch(updateShift(shift));
                                setEdit(false);
                            }}
                        />
                    </RbgGrid>
                ))}
            </RbgGrid>
        );
    }
    return (
        <div
            className="shift-cell"
            onDoubleClick={() => {
                setEdit(true);
            }}
        >
            {shifts.map((ss) => (
                <div className="shift" key={ss.id}>
                    {dayjs(ss.shift_start).format('HH:mm')} - {dayjs(ss.shift_end).format('HH:mm')}
                </div>
            ))}
        </div>
    );
}

export default ShiftCell;

ShiftCell.propTypes = {
    shifts: PropTypes.array
};
