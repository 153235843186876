import PropTypes from 'prop-types';
// material ui imports
import { CircularProgress, Paper } from '@mui/material';

export default function CircularLoading({ sx, size = 24 }) {
    return (
        <Paper sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }}>
            <CircularProgress disableShrink size={size} />
        </Paper>
    );
}
CircularLoading.propTypes = {
    sx: PropTypes.object,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
