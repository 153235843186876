import PropTypes from 'prop-types';
// mui imports
import { TableRow, useTheme } from '@mui/material';
import CircularLoading from '../../loading/CircularLoading';
import RbgTableCell from '../TableCell/RbgTableCell';

/**
 * Will automatically set dynamic row height
 * based on the material table element parent (head, body, etc).
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TableRow API](https://mui.com/material-ui/api/table-row/)
 */

function RbgLoadingRow({ sx = {}, striped, colSpan, ...others }) {
    const theme = useTheme();
    const stripedRowStyles = {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.mode === 'light' ? '#0001' : '#fff1'
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.paper
        }
    };
    return (
        <TableRow sx={striped ? { ...stripedRowStyles, ...sx } : sx} {...others}>
            <RbgTableCell colSpan={colSpan}>
                <CircularLoading />
            </RbgTableCell>
        </TableRow>
    );
}

export default RbgLoadingRow;

RbgLoadingRow.propTypes = {
    sx: PropTypes.object,
    striped: PropTypes.bool,
    colSpan: PropTypes.number
};
