import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import PropTypes from 'prop-types';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { useQuery } from '@tanstack/react-query';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';

function StaffAdminEmailsEditMappingRow({ mapping, handleMappingChange, handleDelete }) {
    const { data: locations } = useQuery({
        queryKey: [{ url: '/staff/locations' }]
    });
    const locs = locations.data.map((loc) => ({ value: loc.cost_centre, label: loc.name }));
    const handleFieldChange = (e) => {
        const newMapping = JSON.parse(JSON.stringify(mapping));
        newMapping[e.target.name] = e.target.value;
        handleMappingChange(newMapping);
    };
    return (
        <RbgTableRow>
            <RbgTableCell>
                <RbgSelect
                    values={locs}
                    label="Location"
                    handleChange={handleFieldChange}
                    value={mapping.cost_centre}
                    disableClearable
                    name="cost_centre"
                />
            </RbgTableCell>
            <RbgTableCell>
                <RbgRemoteSelect
                    endpoint="/staff/staff-admin/email-templates?page=1"
                    name="template_id"
                    label="Template"
                    value={mapping.template_id}
                    handleChange={handleFieldChange}
                />
            </RbgTableCell>
            <RbgTableCell>
                <DeleteConfirm onDelete={() => handleDelete(mapping)} />
            </RbgTableCell>
        </RbgTableRow>
    );
}

StaffAdminEmailsEditMappingRow.propTypes = {
    mapping: PropTypes.object.isRequired,
    handleMappingChange: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
};

export default StaffAdminEmailsEditMappingRow;
