import PropTypes from 'prop-types';

export const payrollPeriod = {
    id: PropTypes.number,
    pay_period: PropTypes.number,
    financial_year: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    draft: PropTypes.bool,
    completed: PropTypes.bool,
    weeks: PropTypes.array,
    type: PropTypes.shape({
        data: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        })
    })
};
