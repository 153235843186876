import PropTypes from 'prop-types';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import RbgPaper from 'ui_component/mui-wrappers/Paper/RbgPaper';

function PayslipSection({ children, title, minHeight }) {
    return (
        <RbgStack sx={{ width: '100%' }} spacing={0.5} alignItems="center">
            <RbgTypography sx={{ textTransform: 'uppercase', color: '#fff' }} variant="h5">
                <strong>{title}</strong>
            </RbgTypography>
            <RbgPaper
                component={RbgStack}
                variant="outlined"
                sx={{ minHeight, height: '100%', width: '100%', p: 1 }}
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                {children}
            </RbgPaper>
        </RbgStack>
    );
}
export default PayslipSection;

PayslipSection.propTypes = {
    minHeight: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
