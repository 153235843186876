import Loader from './Loader';
import CircularLoading from './CircularLoading';
import MainCard from '../cards/MainCard';

function CardLoader() {
    return (
        <MainCard border={false}>
            <Loader />
            <CircularLoading />
        </MainCard>
    );
}

export default CardLoader;
