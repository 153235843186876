import { useCallback, useEffect, useState } from 'react';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../utils/api';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';

function EditRole({ role, onSave }) {
    const [newRole, setNewRole] = useState(
        role || {
            id: null,
            name: '',
            department_id: '',
            inmoment_key: ''
        }
    );

    const handleSave = useCallback(() => {
        const handleResponse = (response) => {
            if (response.ok === false) {
                if (response.error_with_fields) {
                    DialogHelper.errorNotification(response.error_with_fields);
                } else {
                    DialogHelper.errorNotification(response.error_message);
                }
                return;
            }
            if (onSave) {
                onSave(response);
            }
            DialogHelper.closeDialog();
        };

        if (role) {
            Api.patch(`/staff/sc-role/${role.id}`, newRole).then((response) => handleResponse(response));
        } else {
            Api.post('/staff/sc-role', newRole).then((response) => handleResponse(response));
        }
    }, [newRole, role, onSave]);
    useEffect(() => {
        DialogHelper.addSaveButton(() => handleSave(), 'Save');
    }, [handleSave]);

    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="role_name"
                    label="Role Name"
                    value={newRole.name}
                    onChange={(e) => setNewRole((prevRole) => ({ ...prevRole, name: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    label="Department"
                    filterData={(data) => {
                        return data.data
                            .sort((dep1, dep2) => dep1.name.localeCompare(dep2.name))
                            .map((dep) => {
                                return {
                                    value: dep.id,
                                    label: dep.name
                                };
                            });
                    }}
                    endpoint="/staff/sc-departments"
                    name="department_id"
                    value={newRole.department_id}
                    handleChange={(e) => setNewRole((prevRole) => ({ ...prevRole, department_id: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    type="number"
                    label="Inmoment Key"
                    help="The key that inmoment use for roles. If you are unsure contact inmoment"
                    value={newRole.inmoment_key}
                    name="inmoment_key"
                    onChange={(e) => setNewRole((prevRole) => ({ ...prevRole, inmoment_key: e.target.value }))}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

EditRole.propTypes = {
    role: PropTypes.object,
    onSave: PropTypes.func
};
export default EditRole;
