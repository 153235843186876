import PropTypes from 'prop-types';
import { useState, useRef, useEffect, forwardRef, createContext, useContext } from 'react';
// mui imports
import { TextField, MenuItem, ClickAwayListener, Autocomplete } from '@mui/material';
// 3rd party
import { FixedSizeList as List } from 'react-window';
import _ from 'lodash';
import { pt } from 'date-fns/locale';
// project

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return (
        <div ref={ref} {...props} {...outerProps}>
            {props.children}
        </div>
    );
});

const ListBoxComponent = forwardRef((props, ref) => {
    const { options, listHeight = 300, handleSelect, selected, ...other } = props;
    const listRef = useRef(null);

    useEffect(() => {
        if (!listRef.current) return;
        const foundSelected = _.find(options, (item) => item.value === selected);
        listRef.current.scrollToItem(options.indexOf(foundSelected), 'center');
    }, [selected, options]);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <MenuItem disabled value="">
                    - Select -
                </MenuItem>
                <List
                    style={{ height: 'fit-content' }}
                    ref={listRef}
                    height={listHeight}
                    itemCount={options.length}
                    itemData={options}
                    itemSize={30}
                    width="100%"
                    outerElementType={OuterElementType}
                >
                    {({ data, index, style }) => {
                        const item = data[index];
                        return (
                            <MenuItem
                                style={style}
                                selected={item.value === selected}
                                value={item.key}
                                onClick={(event) => {
                                    handleSelect(event, item, 'selectOption');
                                }}
                            >
                                {item.value}
                            </MenuItem>
                        );
                    }}
                </List>
            </OuterElementContext.Provider>
        </div>
    );
});

function VirtualizedSelect(props) {
    const { size, data, label, placeholder, value = '', listHeight = 300, onSelect } = props;
    const [options, setOptions] = useState(data);
    const [selected, setSelected] = useState(value);
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const handleSelect = (event, item, reason) => {
        if (reason === 'selectOption') {
            setSelected(item.value);
            onSelect(item);
            setOpen(false);
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setOptions(_.filter(data, (data) => data.value.toLowerCase().includes(value.toLowerCase())));
    };
    const handleClose = () => setOpen(false);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Autocomplete
                id={`virtualized-select-${_.uniqueId()}`}
                ref={ref}
                open={open}
                size={size}
                placeholder={placeholder}
                fullWidth
                openOnFocus
                autoHighlight
                disableClearable
                onChange={handleSelect}
                ListboxComponent={ListBoxComponent}
                ListboxProps={{ selected, handleSelect, listHeight, ref, options, ...props }}
                options={options}
                value={selected}
                renderInput={(params) => (
                    <TextField {...params} autoComplete="off" label={label} onClick={() => setOpen(true)} onChange={handleInputChange} />
                )}
                getOptionLabel={(option) => {
                    if (!option.value && value) return value;
                    if (!option.value || !value) return '';
                    return option.value;
                }}
                renderOption={(props, option, state) => [props, option, state.index]}
                isOptionEqualToValue={(option, value) => option.value === value}
            />
        </ClickAwayListener>
    );
}

export default VirtualizedSelect;

VirtualizedSelect.defaultProps = {
    size: 'small'
};

VirtualizedSelect.propTypes = {
    size: PropTypes.oneOf(['small', 'medium']),
    data: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    listHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

OuterElementType.propTypes = {
    children: PropTypes.node
};
ListBoxComponent.propTypes = {
    options: PropTypes.array.isRequired,
    listHeight: PropTypes.number,
    handleSelect: PropTypes.func,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
