import PropTypes from 'prop-types';
import { staffEmployment } from '../../StaffDataTypes';
import Debug from '../../../../../ui_component/misc/Debug';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';

function PreviousEmployment({ employment }) {
    return (
        <RbgSimpleTable
            headers={['Start Date', 'End Date', 'Probation End Date', 'Leave Reason']}
            rows={employment.map((row) => [
                dayjs(row.start_date).format('DD/MM/YYYY'),
                row.leave_date ? dayjs(row.leave_date).format('DD/MM/YYYY') : 'N/A',
                row.probation_end_date ? dayjs(row.probation_end_date).format('DD/MM/YYYY') : 'N/A',
                row.leave_reason
            ])}
        />
    );
}

PreviousEmployment.propTypes = {
    employment: PropTypes.arrayOf(PropTypes.shape(staffEmployment))
};

export default PreviousEmployment;
