import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import dayjs from 'dayjs';

function RoleHistory({ staffMember }) {
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Role', 'Start Date', 'End Date', 'Location', 'Payroll Ref']}
                    keys={staffMember.all_roles.data.map((role) => role.id)}
                    rows={staffMember.all_roles.data.map((role) => {
                        return [
                            role.position.data.name,
                            dayjs(role.start_date).format('DD/MM/YYYY'),
                            role.end_date ? dayjs(role.end_date).format('DD/MM/YYYY') : '',
                            role.location_name,
                            role.payroll_ref
                        ];
                    })}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

RoleHistory.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};

export default RoleHistory;
