import { useQuery } from '@tanstack/react-query';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import HrConfigRoleEdit from './HrConfigRoleEdit';
import { IconCopy, IconEdit } from '@tabler/icons-react';
import { useState } from 'react';
import RbgFilter from '../../../../../ui_component/extended/Form/RbgFilter';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';

function HrConfigSiteRoles() {
    const [filter, setFilter] = useState({
        search: '',
        include_archived: false,
        min_hierarchy_level: 1
    });
    const { data, isFetching } = useQuery({
        queryKey: [{ url: '/staff/positions' }]
    });

    const addRole = (role) => {
        DialogHelper.openMdDialog(role ? 'Edit Role' : 'Add Role', <HrConfigRoleEdit position={role} />);
    };

    if (isFetching) {
        return <CardLoader />;
    }

    let roles = data.data;
    if (filter.search) {
        roles = roles.filter((role) => {
            return role.name.toLowerCase().indexOf(filter.search.toLowerCase()) > -1;
        });
    }

    if (filter.include_archived === false) {
        roles = roles.filter((role) => {
            return role.archived === false;
        });
    }

    return (
        <MainCard title="Site Positions/Roles" secondary={<RbgButton onClick={() => addRole()}>Add Role</RbgButton>}>
            <RbgGrid container spacing={3}>
                <RbgGrid item xs={12}>
                    <RbgFilter
                        onChange={(filter) => setFilter(filter)}
                        filter={filter}
                        filters={[
                            {
                                name: 'search',
                                label: 'Search',
                                type: 'text'
                            },
                            {
                                name: 'include_archived',
                                label: 'Include Archived',
                                type: 'checkbox'
                            }
                        ]}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={['#', 'Name', 'Type', 'Pay Type', 'Archived', 'Hierarchy Level', 'Locations', 'Onboard Fieldsets', '']}
                        keys={roles.map((role) => role.id)}
                        rows={roles.map((role) => {
                            return [
                                role.id,
                                role.name,
                                role.type.data.name,
                                role.pay_type.data.name,
                                <BooleanIcon value={role.archived} />,
                                role.hierarchy_level,
                                role.locations.length,
                                <RbgStack gap={1}>
                                    <RbgTypography variant="a">{role.onboard_manager_fieldset_name}</RbgTypography>
                                    <RbgTypography variant="a">{role.onboard_team_member_fieldset_name}</RbgTypography>
                                </RbgStack>,
                                <RbgStack direction="row" gap={1}>
                                    <RbgButton onClick={() => addRole(role)} icon={<IconEdit size={20} />} variant="contained" />
                                    <RbgButton
                                        onClick={() => {
                                            const newRole = { ...role, id: null };
                                            addRole(newRole);
                                        }}
                                        icon={<IconCopy size={20} />}
                                        variant="contained"
                                    />
                                </RbgStack>
                            ];
                        })}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default HrConfigSiteRoles;
