import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import InfoCard from '../../../../../ui_component/error/InfoCard';

function StaffSelfie({ staffMember }) {
    const deleteMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff/staff-member/${staffMember.id}/image`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgStack justifyContent="center" alignItems="center" gap={2}>
            <img
                src={staffMember.image.data.uri}
                alt={staffMember.details.display_name}
                style={{ maxWidth: '400px', maxHeight: '400px' }}
            />
            <InfoCard>
                If a team member wants to use a new selfie click remove below. Note that they will not be able to use team discount without
                a selfie image
            </InfoCard>
            <DeleteConfirm
                fullWidth
                confirmText="Are you sure you want to delete this selfie?"
                btnText="Remove staff selfie"
                onDelete={() => {
                    deleteMutation.mutate();
                }}
            />
        </RbgStack>
    );
}

StaffSelfie.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default StaffSelfie;
