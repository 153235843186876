import PropTypes from 'prop-types';
import { payRateAuth } from '../../StaffDataTypes';
import { currencyGBP } from '../../../../../utils/currencyFormatter';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';

function ChangeTable({ change }) {
    const changes = [];
    if (change.current_rate !== change.rate) {
        changes.push({
            name: 'Pay Rate',
            old: currencyGBP().format(change.current_rate),
            new: currencyGBP().format(change.rate)
        });
    }
    if (change.position_name !== change.staff_member.data.role.data.position.data.name) {
        changes.push({
            name: 'Role',
            old: change.staff_member.data.role.data.position.data.name,
            new: change.position_name
        });
    }
    if (change.contracted_hours.data.hours) {
        if (change.contracted_hours.data.hours !== change.staff_member.data.contract.data.contracted_hours) {
            changes.push({
                name: 'Contracted Hours',
                old: change.staff_member.data.contract.data.contracted_hours,
                new: change.contracted_hours.data.hours
            });
        }
    }
    return (
        <RbgTable>
            <RbgTableHead>
                <RbgTableRow>
                    <RbgTableCell />
                    <RbgTableCell>Previous</RbgTableCell>
                    <RbgTableCell>New</RbgTableCell>
                </RbgTableRow>
            </RbgTableHead>
            <RbgTableBody>
                {changes.map((change) => {
                    return (
                        <RbgTableRow key={change.name}>
                            <RbgTableCell>{change.name}</RbgTableCell>
                            <RbgTableCell>{change.old}</RbgTableCell>
                            <RbgTableCell>{change.new}</RbgTableCell>
                        </RbgTableRow>
                    );
                })}
                {change.contract_template_name && (
                    <RbgTableRow>
                        <RbgTableCell colSpan={3}>
                            {change.staff_member.data.details.data.display_name} will be sent a new{' '}
                            <strong>{change.contract_template_name}</strong> contract for signing when approved
                        </RbgTableCell>
                    </RbgTableRow>
                )}
            </RbgTableBody>
        </RbgTable>
    );
}

ChangeTable.propTypes = {
    change: PropTypes.shape(payRateAuth).isRequired
};
export default ChangeTable;
