import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgTooltip from '../../../../ui_component/tooltip/RbgTooltip';
import { Help } from '@mui/icons-material';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useNavigate } from 'react-router-dom';
import EventDispatcher from '../../../../utils/EventDispatcher';
import { staffMember } from '../StaffDataTypes';

function NewStarterList({ search, initialTraining }) {
    const [filter, setFilter] = useState({
        page: 1,
        location: ''
    });
    const { data, isPending, error, refetch } = useQuery({
        queryKey: [{ url: '/staff/onboarding', params: filter }],
        enabled: filter.location !== ''
    });
    const navigate = useNavigate();
    const { bar } = useSelector((state) => state.bar);
    useEffect(() => {
        setFilter((prev) => ({ ...prev, location: bar.cost_centre }));
    }, [bar]);
    useEffect(() => {
        const id = EventDispatcher.subscribe('pusher-message', (data) => {
            if (data.event === 'reload-new-starters') {
                refetch();
            }
        });
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [refetch]);
    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    if (isPending) {
        return <span />;
    }
    if (data.data.length === 0 && initialTraining.length === 0) {
        return <span />;
    }

    let list = data.data;
    if (search) {
        list = list.filter((item) => {
            const compareString = `${item.data.forename} ${item.data.surname} ${item.data.email}`;
            return compareString.toLowerCase().indexOf(search.toLowerCase()) > -1;
        });
    }

    const listItems = [];
    const ids = [];
    list.forEach((row) => {
        ids.push(row.id);
        listItems.push([
            `${row.data.forename} ${row.data.surname}`,
            row.data.email,
            row.position.data.name ? row.position.data.name : 'N/A',
            row.status.data.name,
            row.rtw_check.data.length > 0 && row.rtw_check.data[0].id ? row.rtw_check.data[0].status.data.name : 'N/A',
            <RbgButton
                size="sm"
                onClick={() => {
                    navigate(`/staff/onboarding/${row.id}`);
                }}
            >
                Continue
            </RbgButton>
        ]);
    });
    initialTraining.forEach((row) => {
        ids.push(row.id);
        listItems.push([
            row.display_name,
            row.email_address,
            row.role_name,
            'Initial Training',
            row.rtw_status,
            <RbgButton
                size="sm"
                onClick={() => {
                    navigate(`/staff/view/${row.id}`);
                }}
            >
                Continue
            </RbgButton>
        ]);
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgStack direction="row" gap={2} sx={{ mb: 2 }}>
                    <RbgTypography variant="h2">New Starters </RbgTypography>
                    <RbgTooltip title="Any new team members which have been onboarded from Talos">
                        <Help />
                    </RbgTooltip>
                </RbgStack>
                <RbgSimpleTable
                    headers={['Name', 'Email', 'Position', 'Status', 'Right to work', '']}
                    keys={ids}
                    rows={listItems.map((row) => row)}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgStack direction="row" gap={2} sx={{ mb: 2 }}>
                    <RbgTypography variant="h2">Current Team </RbgTypography>
                </RbgStack>
            </RbgGrid>
        </RbgGrid>
    );
}

NewStarterList.propTypes = {
    search: PropTypes.string,
    initialTraining: PropTypes.arrayOf(PropTypes.shape(staffMember))
};
export default NewStarterList;
