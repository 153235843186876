import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import PropTypes from 'prop-types';
import EditOnboardFieldset from './EditOnboardFieldset';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function OnboardFieldsets({ addTab }) {
    const { data, isPending, isLoading } = useQuery({
        queryKey: [{ url: '/staff/admin/onboard-fieldsets', params: { include: 'fields' } }, 'onboard_fieldsets']
    });

    if (isPending || isLoading) {
        return <CardLoader />;
    }

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Name', 'Fields']}
                    keys={data.data.map((row) => row.id)}
                    rows={data.data.map((row) => [
                        row.name,
                        <RbgStack>
                            {row.fields.data.map((field) => {
                                let label = field.field_name;
                                const variant = null;
                                let sx = {};
                                if (field.type === 'title') {
                                    label = field.label;
                                    sx = { fontWeight: '700' };
                                }
                                if (field.type === 'content') {
                                    label = field.label;
                                    sx = { fontStyle: 'italic', textDecoration: 'underline' };
                                }
                                if (field.label) {
                                    label = field.label;
                                }
                                return (
                                    <RbgTypography key={field.id} sx={sx} variant={variant}>
                                        {label}
                                    </RbgTypography>
                                );
                            })}
                        </RbgStack>,
                        <RbgButton
                            onClick={() => {
                                DialogHelper.openMdDialog(`Edit Group ${row.name}`, <EditOnboardFieldset fieldset={row} />);
                            }}
                        >
                            Edit
                        </RbgButton>
                    ])}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

OnboardFieldsets.propTypes = {
    addTab: PropTypes.func.isRequired
};
export default OnboardFieldsets;
