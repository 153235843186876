import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';

function NewStarterHistory({ id }) {
    const { data, isFetched, error } = useQuery({
        queryKey: [{ url: `/staff/onboarding/team-member/${id}/history` }]
    });
    if (error) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Date', 'User', 'Entry']}
                    rows={data.data.map((entry) => [entry.date, entry.user_name, entry.entry])}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

NewStarterHistory.propTypes = {
    id: PropTypes.number.isRequired
};
export default NewStarterHistory;
