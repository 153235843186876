import { useState } from 'react';
import TwoFactorAuthInitiateMethods from './TwoFactorAuthInitiateMethods';
import InfoCard from '../error/InfoCard';
import RbgTextInput from '../extended/Form/RbgTextInput';
import RbgStack from '../mui-wrappers/Stack/RbgStack';
import RbgButton from '../mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../utils/api';
import EventDispatcher from '../../utils/EventDispatcher';
import ErrorCard from '../error/ErrorCard';

function TwoFactorAuth() {
    const [attempt, setAttempt] = useState(null);
    const [code, setCode] = useState('');
    const verifyMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `bouncer/tfa-logged-in/${attempt.code}/verify`, { code });
        },
        onSuccess: () => {
            EventDispatcher.dispatch('hide_two_factor_auth');
            EventDispatcher.dispatch('two_factor_auth_success');
        }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        verifyMutation.mutate();
    };
    const method = attempt?.method.data;
    const type = method?.type.data;
    if (attempt === null) {
        return <TwoFactorAuthInitiateMethods onAttemptCreate={(method) => setAttempt(method)} />;
    }
    let text = 'Enter the code e-mailed to you below';
    let label = 'Code';
    if (type.id === 2) {
        text = 'Enter the code sent to your phone below';
    }
    if (type.id === 3) {
        label = 'Authenticator code';
        text = 'Enter the code on your authenticator device below';
    }
    return (
        <form onSubmit={handleSubmit}>
            <RbgStack gap={2}>
                <InfoCard>{text}</InfoCard>
                {verifyMutation.error && <ErrorCard>{verifyMutation.error}</ErrorCard>}
                <RbgTextInput name="code" value={code} onChange={(e) => setCode(e.target.value)} label={label} autoComplete="on" />
                <RbgButton type="submit">Verify</RbgButton>
            </RbgStack>
        </form>
    );
}

export default TwoFactorAuth;
