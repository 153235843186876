import { createSlice } from '@reduxjs/toolkit';

// ==============================|| ROUTES REDUCER ||============================== //

export const routesReducer = createSlice({
    name: 'routes',
    initialState: {
        routes: []
    },
    reducers: {
        setRoutes: (state, action) => {
            state.routes = action.payload;
        }
    }
});

export const { setRoutes } = routesReducer.actions;
