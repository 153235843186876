import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useState } from 'react';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import dayjs from 'dayjs';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function ChangeEmploymentStartDate({ staffMember }) {
    const [date, setDate] = useState(staffMember.employment.data.start_date);
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/start-date`, { date });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgGrid container>
            {saveMutation.error && (
                <RbgGrid>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgDatePicker value={date} onChange={(d) => setDate(d.format('YYYY-MM-DD'))} />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton fullWidth disabled={date === staffMember.employment.data.start_date} onClick={() => saveMutation.mutate()}>
                    Save
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

ChangeEmploymentStartDate.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};

export default ChangeEmploymentStartDate;
