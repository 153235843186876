import dayjs from 'dayjs';

const Cache = {
    get(key) {
        if (!window.localStorage) {
            return null;
        }
        const data = window.localStorage.getItem(key);
        if (data) {
            const decoded = JSON.parse(data);
            const expired = dayjs(decoded.expires).isBefore(dayjs());
            if (expired) {
                this.remove(key);
                return null;
            }
            return decoded.data;
        }
        return null;
    },
    remove(key) {
        if (!window.localStorage) {
            return null;
        }
        window.localStorage.removeItem(key);
        return true;
    },
    save(key, data, update, ttl = 30) {
        if (!window.localStorage) {
            return null;
        }
        let storeData;
        if (update) {
            storeData = data;
        } else {
            storeData = {
                data,
                expires: dayjs().add(ttl, 'minute').format('YYYY-M-D H:m:s')
            };
        }
        return window.localStorage.setItem(key, JSON.stringify(storeData));
    },
    getContent(url, params) {
        if (!window.localStorage) {
            return null;
        }
        const key = this.generateKey(url, params);
        const item = this.load(key);
        if (item) {
            return item;
        }
        return false;
    },
    updateContent(url, params, data) {
        if (!window.localStorage) {
            return null;
        }
        const key = this.generateKey(url, params);
        const item = this.load(key);

        item.data.data.data = data;
        return this.save(key, item, true);
    },
    generateKey(url, params) {
        return JSON.stringify({ url, params });
    },
    load(key) {
        const value = window.localStorage.getItem(key);
        return JSON.parse(value);
    },
    getSize() {
        let data = '';
        if (!window.localStorage) {
            return 0;
        }
        Object.keys(window.localStorage).map((key) => {
            // eslint-disable-next-line no-prototype-builtins
            if (window.localStorage.hasOwnProperty(key)) {
                data += window.localStorage[key];
            }
            return key;
        });
        return data.length;
    }
};

export default Cache;
