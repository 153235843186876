import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import { gridSpacing } from '../../../../../store/constant';
import dayjs from 'dayjs';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../utils/api';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgTextArea from '../../../../../ui_component/text-area/RbgTextArea';
import { useMutation } from '@tanstack/react-query';
import IntroCardSecondary from '../../../../../ui_component/cards/IntroCardSecondary';
import WarningCard from '../../../../../ui_component/error/WarningCard';

function MarkAsLeaver({ staffMember }) {
    const [leaver, setLeaver] = useState({
        leave_date: dayjs().format('YYYY-MM-DD'),
        leave_reason: '',
        would_reemploy: true,
        leaver_notes: ''
    });

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `staff/staff-member/${staffMember.id}/leaver`, leaver);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        if (leaver.leave_date && leaver.leave_reason) {
            DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', false);
        } else {
            DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', true);
        }
    }, [leaver, mutation]);
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    This will mark a team member as a leaver. If you select today&apos;s date or before they will be marked as a leaver
                    immediately.
                </IntroCardPrimary>
            </RbgGrid>
            {staffMember.pay_rate.data.type.data.salary === true && (
                <RbgGrid item xs={12}>
                    <WarningCard>This will trigger a password reset request to Astro on their leave date.</WarningCard>
                </RbgGrid>
            )}
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item md={6} xs={12}>
                <RbgDatePicker
                    name="leave_date"
                    error=""
                    minDate={dayjs().add(-2, 'month')}
                    value={leaver.leave_date}
                    onChange={(date) => setLeaver({ ...leaver, leave_date: date ? date.format('YYYY-MM-DD') : '' })}
                    label="Leave Date"
                />
            </RbgGrid>
            <RbgGrid item md={6} xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/leave-reasons"
                    name="leave_reason"
                    label="Leave Reason"
                    value={leaver.leave_reason}
                    useAutoComplete
                    handleChange={(e) => setLeaver({ ...leaver, leave_reason: e.target.value })}
                    filterData={(data) => {
                        return data.data.map((row) => {
                            return { value: row.id, label: row.name };
                        });
                    }}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextArea
                    name="leave_notes"
                    value={leaver.leaver_notes}
                    label="Leave Notes"
                    onChange={(e) => setLeaver({ ...leaver, leaver_notes: e.target.value })}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgCheckbox
                    label="I would re-employ this person"
                    onChange={(e) => setLeaver({ ...leaver, would_reemploy: e.target.checked })}
                    checked={leaver.would_reemploy}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

MarkAsLeaver.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default MarkAsLeaver;
