import PropTypes from 'prop-types';
import { staffMember } from '../../../StaffDataTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RbgDatePicker from '../../../../../../ui_component/date-pickers/RbgDatePicker';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../../utils/api';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';
import RbgLocationSelect from '../../../../../../ui_component/location-select/RbgLocationSelect';
import RbgTextInput from '../../../../../../ui_component/extended/Form/RbgTextInput';
import RbgSelect from '../../../../../../ui_component/extended/Form/RbgSelect';

function AddEditAgreement({ staffMember, agreement = null, agreementType = '' }) {
    const [newAgreement, setNewAgreement] = useState(
        agreement || {
            id: null,
            staff_id: staffMember.id,
            type_id: agreementType,
            cost_centre: staffMember.role.data.cost_centre,
            date_created: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            created_by: 0,
            key: '',
            date_signed: null,
            start_date: dayjs().format('YYYY-MM-DD'),
            end_date: '',
            pdf: { data: {} },
            signature: { data: {} },
            data: {}
        }
    );

    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/agreement`, newAgreement);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    const { data: types } = useQuery({
        queryKey: [{ url: `/staff/agreement-types`, params: { location: newAgreement.cost_centre } }]
    });

    const handleDataChange = (e) => {
        const data = JSON.parse(JSON.stringify(newAgreement.data));
        data[e.target.name] = e.target.value;
        setNewAgreement((prev) => ({ ...prev, data }));
    };
    const type = types.data.find((t) => {
        return t.id === newAgreement.type_id;
    });
    const fields = type ? type.fields.data : [];
    useEffect(() => {
        DialogHelper.addSaveButton(() => saveMutation.mutate(), 'Save');
    }, [newAgreement, saveMutation]);

    useEffect(() => {
        if (type !== undefined) {
            const fields = {};
            type.fields.data.forEach((field) => {
                fields[field.field_name] = field.default_value;
            });
            setNewAgreement((prev) => ({ ...prev, data: fields }));
        }
    }, [type]);
    return (
        <RbgGrid container>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgLocationSelect
                    name="cost_centre"
                    label="Location"
                    value={newAgreement.cost_centre}
                    onChange={(e) =>
                        setNewAgreement((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value ? e.target.value : ''
                        }))
                    }
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSelect
                    values={types.data.map((t) => ({ value: t.id, label: t.name }))}
                    name="type_id"
                    required
                    disabled={agreementType !== ''}
                    label="Agreement Type"
                    value={newAgreement.type_id}
                    handleChange={(e) => setNewAgreement((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    required
                    name="start_date"
                    label="Agreement Start Date"
                    value={newAgreement.start_date}
                    onChange={(date) => setNewAgreement((prev) => ({ ...prev, start_date: date }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    name="end_date"
                    value={newAgreement.end_date}
                    label="Agreement End Date"
                    onChange={(date) => setNewAgreement((prev) => ({ ...prev, end_date: date }))}
                />
            </RbgGrid>
            {fields.map((field) => {
                let fieldContent = <p>Field not implement: {field.type}</p>;
                if (field.type === 'select') {
                    fieldContent = (
                        <RbgSelect
                            values={field.computed_allowed_values}
                            label={field.label}
                            name={field.field_name}
                            value={newAgreement.data[field.field_name]}
                            handleChange={(e) => handleDataChange(e)}
                        />
                    );
                }
                if (field.type === 'text') {
                    fieldContent = (
                        <RbgTextInput
                            name={field.field_name}
                            type="text"
                            value={newAgreement.data[field.field_name] ?? ''}
                            label={field.label}
                            onChange={(e) => handleDataChange(e)}
                        />
                    );
                }
                if (field.type === 'number') {
                    fieldContent = (
                        <RbgTextInput
                            name={field.field_name}
                            type="number"
                            min={1}
                            value={newAgreement.data[field.field_name] ?? ''}
                            label={field.label}
                            onChange={(e) => handleDataChange(e)}
                        />
                    );
                }
                return (
                    <RbgGrid item xs={12} key={field.field_name}>
                        {fieldContent}
                    </RbgGrid>
                );
            })}
        </RbgGrid>
    );
}

AddEditAgreement.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired,
    agreement: PropTypes.object,
    agreementType: PropTypes.number
};
export default AddEditAgreement;
