import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
// mui wrappers
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgTextInput from 'ui_component/extended/Form/RbgTextInput';
import { DialogHelper } from '../../../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../../../utils/api';

function EditContact({ staffDetails, getError }) {
    const [fieldErrors, setFieldErrors] = useState([]);
    const [error, setError] = useState(null);
    const [newStaffDetails, setNewStaffDetails] = useState({
        email_address: staffDetails.email_address,
        work_email_address: staffDetails.work_email_address,
        mobile: staffDetails.mobile,
        nok_name: staffDetails.nok_name,
        nok_phone: staffDetails.nok_phone
    });

    const handleFieldChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setNewStaffDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = useCallback(() => {
        setError(null);
        Api.patch(`/staff/${staffDetails.staff_id}/contact-nok`, newStaffDetails).then((response) => {
            if (response.ok) {
                DialogHelper.closeDialog();
            }
            if (response.field_errors && response.field_errors.length > 0) {
                setError(
                    response.field_errors
                        .map((error) => {
                            return error.error;
                        })
                        .join(', ')
                );
                setFieldErrors(response.field_errors);
            }
        });
    }, [newStaffDetails, staffDetails.staff_id]);

    useEffect(() => {
        DialogHelper.addSaveButton(() => handleSave(), 'Save');
    }, [handleSave]);

    return (
        <RbgStack sx={{ py: 2 }} spacing={2}>
            <RbgTextInput
                size="small"
                name="email_address"
                label="Email"
                error={fieldErrors.length > 0 && getError('email_address', fieldErrors)}
                value={newStaffDetails.email_address}
                onChange={(e) => handleFieldChange(e, 'details')}
            />
            <RbgTextInput
                size="small"
                name="work_email_address"
                label="Work Email"
                error={fieldErrors.length > 0 && getError('work_email_address', fieldErrors)}
                value={newStaffDetails.work_email_address}
                onChange={(e) => handleFieldChange(e, 'details')}
            />
            <RbgTextInput
                size="small"
                name="mobile"
                label="Mobile"
                value={newStaffDetails.mobile}
                error={fieldErrors.length > 0 && getError('mobile', fieldErrors)}
                onChange={(e) => handleFieldChange(e, 'details')}
            />
            <RbgTextInput
                size="small"
                name="nok_name"
                label="Next Of Kin Name"
                value={newStaffDetails.nok_name}
                error={fieldErrors.length > 0 && getError('nok_name', fieldErrors)}
                onChange={(e) => handleFieldChange(e, 'details')}
            />
            <RbgTextInput
                size="small"
                name="nok_phone"
                label="Next Of Kin Phone"
                value={newStaffDetails.nok_phone}
                error={fieldErrors.length > 0 && getError('nok_phone', fieldErrors)}
                onChange={(e) => handleFieldChange(e, 'details')}
            />
        </RbgStack>
    );
}

export default EditContact;

EditContact.propTypes = {
    staffDetails: PropTypes.object,
    getError: PropTypes.func
};
