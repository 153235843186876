import { useState } from 'react';
import PropTypes from 'prop-types';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import ViewDisciplinary from './ViewDisciplinary';
import { IconClipboard } from '@tabler/icons-react';
import MainCard from '../../../../../ui_component/cards/MainCard';
import AddDisciplinary from './AddDisciplinary';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';

function Disciplinaries({ staffMember }) {
    const [filter, setFilter] = useState({
        page: 1
    });

    const { data, isLoading, isPending } = useQuery({
        queryKey: [{ url: `/staff/${staffMember.id}/disciplinaries`, params: filter }]
    });

    if (isLoading || isPending) {
        return <CardLoader />;
    }
    const list = data.data;
    return (
        <MainCard
            title="Disciplinaries"
            secondary={
                <RbgButton
                    onClick={() => {
                        DialogHelper.openMdDialog('Add Disciplinary', <AddDisciplinary staffMember={staffMember} />);
                    }}
                >
                    <IconClipboard size="20px" /> &nbsp;Add Disciplinary
                </RbgButton>
            }
        >
            <RbgSimpleTable
                headers={['Date', 'Type', 'Reason', 'Logged By', 'Location', 'Expiry Date', '']}
                keys={list.map((entry) => entry.id)}
                rows={list.map((entry) => {
                    return [
                        dayjs(entry.date).format('DD/MM/YYYY'),
                        entry.type.data.name,
                        entry.reason.data.name ? entry.reason.data.name : 'N/A',
                        entry.logged_by_name,
                        `${entry.location_name} (${entry.location})`,
                        `${dayjs(entry.expiry_date).format('DD/MM/YYYY')} (${dayjs(entry.expiry_date).fromNow()})`,
                        <RbgButton
                            onClick={() => {
                                DialogHelper.openMdDialog('View Disciplinary', <ViewDisciplinary disciplinary={entry} />);
                            }}
                        >
                            View
                        </RbgButton>
                    ];
                })}
            />
        </MainCard>
    );
}

Disciplinaries.propTypes = {
    staffMember: PropTypes.object.isRequired
};

export default Disciplinaries;
