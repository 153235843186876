import axios from './axios';
import Cache from './cache';
import EventDispatcher from './EventDispatcher';

class ApiError extends Error {
    constructor(message, fields = []) {
        super(message);
        this.fields = fields;
    }
}

const Api = {
    async get(url, params) {
        try {
            const e = await axios.get(url, { params });
            return this.processResponse(e);
        } catch (err) {
            return this.processError(err);
        }
    },
    async getCached(url, params = {}, ttl = 30) {
        const key = JSON.stringify({ url, params });
        const object = Cache.get(key);
        if (!object) {
            const data = await this.get(url, params);
            if (data.status === 200) {
                Cache.save(key, data, false, ttl);
            }
            return new Promise((resolve) => {
                resolve(data);
            });
        }
        return object;
    },
    async post(url, params, options) {
        try {
            const e = await axios.post(url, params, options);
            return this.processResponse(e);
        } catch (err) {
            return this.processError(err);
        }
    },
    async patch(url, params) {
        try {
            const e = await axios.patch(url, params);
            return this.processResponse(e);
        } catch (err) {
            return this.processError(err);
        }
    },
    async delete(url) {
        try {
            const e = await axios.delete(url);
            return this.processResponse(e);
        } catch (err) {
            return this.processError(err);
        }
    },
    async put(url, params) {
        try {
            const e = await axios.put(url, params);
            return this.processResponse(e);
        } catch (err) {
            return this.processError(err);
        }
    },
    processResponse(response) {
        return {
            status: response.status,
            statusText: response.statusText,
            data: response.data,
            error_message: null,
            ok: true
        };
    },
    processError(error) {
        let message = error.response.statusText;
        let fieldErrors = [];
        let errorWithFields = null;
        if (error.response.status === 401) {
            EventDispatcher.dispatch('logout');
        }
        if (error.response.data && error.response.data.errors && error.response.data.errors.message) {
            message = error.response.data.errors.message;
        } else if (error.response.data && error.response.data.error && error.response.data.error.message) {
            message = error.response.data.error.message;
        }

        if (error.response.data && error.response.data.errors && error.response.data.errors.fields) {
            fieldErrors = error.response.data.errors.fields;
        }
        if (fieldErrors.length) {
            errorWithFields = message;
            errorWithFields += `: ${fieldErrors
                .map((error) => {
                    return error.error;
                })
                .join(', ')}`;
        }
        return {
            status: error.response.status,
            statusText: error.response.statusText,
            data: error.response.data,
            ok: false,
            error_message: message,
            field_errors: fieldErrors,
            error_with_fields: errorWithFields
        };
    },
    async reactQueryPost(url, params) {
        const data = { queryKey: [{ url, params, method: 'POST' }] };
        return this.reactQueryFn(data);
    },
    async reactQueryPatch(url, params) {
        const data = { queryKey: [{ url, params, method: 'PATCH' }] };
        return this.reactQueryFn(data);
    },
    async reactQueryMutation(method, url, params) {
        const data = { queryKey: [{ url, params, method }] };
        return this.reactQueryFn(data);
    },
    async reactQuery(method, url, params) {
        let e = {};
        try {
            switch (method) {
                case 'GET':
                    e = await axios.get(url, { params });
                    break;
                default:
                    return Promise.reject(new Error('Invalid query type'));
            }
        } catch (e) {
            if (e.response.status === 401) {
                EventDispatcher.dispatch('logout');
            }
            if (e?.response?.data?.error?.message) {
                console.error(e.response.data.error.message);
                return Promise.reject(new Error(e.response.data.error.message));
            }
            if (e?.response?.data?.errors?.message) {
                console.error(e.response.data.errors.message);
                return Promise.reject(new ApiError(e.response.data.errors.message, e.response.data.errors.fields));
            }
            if (e?.response?.data?.error) {
                console.error(e.response.data.error);
                return Promise.reject(new Error(e.response.data.error));
            }
            console.error('Invalid Query', { method, url, params }, e);
            return Promise.reject(new Error('Query error'));
        }
        return e.data;
    },
    async reactQueryFn({ queryKey }) {
        const queryOpts = queryKey[0];
        if (typeof queryOpts !== 'object') {
            return Promise.reject(new Error('First element in query key must be an object'));
        }
        if (!queryOpts.url) {
            return Promise.reject(new Error('No URL set in query key'));
        }
        const query = {
            method: queryOpts.method ? queryOpts.method.toUpperCase() : 'GET',
            url: queryOpts.url,
            params: queryOpts.params ? queryOpts.params : {}
        };
        let e = {};
        const params = query.params;
        try {
            switch (query.method) {
                case 'GET':
                    e = await axios.get(query.url, { params });
                    break;
                case 'POST':
                    e = await axios.post(query.url, params);
                    break;
                case 'PATCH':
                    e = await axios.patch(query.url, params);
                    break;
                case 'DELETE':
                    e = await axios.delete(query.url, params);
                    break;
                case 'PUT':
                    e = await axios.put(query.url, params);
                    break;
                default:
                    return Promise.reject(new Error('Invalid query type'));
            }
        } catch (e) {
            if (e.response.status === 401) {
                EventDispatcher.dispatch('logout');
            }
            if (e.response.status === 402) {
                EventDispatcher.dispatch('tfa_factor_auth');
            }
            if (e?.response?.data?.error?.message) {
                console.error(e.response.data.error.message);
                return Promise.reject(new Error(e.response.data.error.message));
            }
            if (e?.response?.data?.errors?.message) {
                console.error(e.response.data.errors.message);
                return Promise.reject(new ApiError(e.response.data.errors.message, e.response.data.errors.fields));
            }
            if (e?.response?.data?.error) {
                console.error(e.response.data.error);
                return Promise.reject(new Error(e.response.data.error));
            }
            console.error('Invalid Query', query, e);
            return Promise.reject(new Error('Query error'));
        }
        return e.data;
    }
};

export default Api;
