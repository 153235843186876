import { staffAgreement } from '../../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../../utils/api';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import Debug from '../../../../../../ui_component/misc/Debug';
import RbgTable from '../../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableBody from '../../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import RbgTableRow from '../../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import dayjs from 'dayjs';

function ViewAgreement({ agreement }) {
    const download = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('GET', `/staff/staff-member/${agreement.staff_id}/agreement/${agreement.id}/download`);
        },
        onSuccess: (data) => {
            window.open(data.data.uri);
        }
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgTable>
                    <RbgTableBody>
                        <RbgTableRow>
                            <RbgTableCell>Date Created</RbgTableCell>
                            <RbgTableCell>{dayjs(agreement.date_created).format('DD/MM/YYYY HH:mm:ss')}</RbgTableCell>
                        </RbgTableRow>
                        <RbgTableRow>
                            <RbgTableCell>Date Signed</RbgTableCell>
                            <RbgTableCell>{dayjs(agreement.date_signed).format('DD/MM/YYYY HH:mm:ss')}</RbgTableCell>
                        </RbgTableRow>
                        <RbgTableRow>
                            <RbgTableCell>Start Date</RbgTableCell>
                            <RbgTableCell>{dayjs(agreement.start_date).format('DD/MM/YYYY')}</RbgTableCell>
                        </RbgTableRow>
                        <RbgTableRow>
                            <RbgTableCell>End Date</RbgTableCell>
                            <RbgTableCell>{agreement.end_date ? dayjs(agreement.start_date).format('DD/MM/YYYY') : 'N/A'}</RbgTableCell>
                        </RbgTableRow>
                        {agreement.signature.data.id && (
                            <RbgTableRow>
                                <RbgTableCell>Signature</RbgTableCell>
                                <RbgTableCell>
                                    <img src={agreement.signature.data.uri} alt="Signature" />
                                </RbgTableCell>
                            </RbgTableRow>
                        )}
                        <RbgTableRow>
                            <RbgTableCell colSpan={2}>
                                <RbgButton onClick={() => download.mutate()}>Download</RbgButton>
                            </RbgTableCell>
                        </RbgTableRow>
                    </RbgTableBody>
                </RbgTable>
            </RbgGrid>
        </RbgGrid>
    );
}

ViewAgreement.propTypes = {
    agreement: PropTypes.shape(staffAgreement).isRequired
};

export default ViewAgreement;
