import { useEffect, useState } from 'react';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import OnboardFields from './OnboardFields';
import OnboardFieldsets from './OnboardFieldsets';

function HrConfigOnboardingFields() {
    const [tab, setTab] = useState('groups');
    const [tabs, setTabs] = useState([]);
    const addTab = (id, title, content, canClose, openTo = true) => {
        setTabs((prev) => [
            ...prev,
            {
                value: id,
                label: title,
                content,
                canClose
            }
        ]);
        if (openTo) {
            setTab(id);
        }
    };
    useEffect(() => {
        addTab('groups', 'Groups', <OnboardFieldsets addTab={addTab} />, false, false);
        addTab('fields', 'Fields', <OnboardFields />, false, false);
    }, []);

    const handleTabClose = (event, value) => {
        if (value === tab) {
            setTab(tabs[1].value);
        }
        setTabs((prev) =>
            prev.filter((t) => {
                return t.value !== value;
            })
        );
    };
    return <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} handleTabClose={handleTabClose} />;
}

export default HrConfigOnboardingFields;
