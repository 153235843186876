import PropTypes from 'prop-types';
import { useState } from 'react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddAbsence from './AddAbsence';
import dayjs from 'dayjs';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import MainCard from '../../../../../ui_component/cards/MainCard';
import { IconCalendarPlus, IconEdit } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

function Absences({ staffMember }) {
    const [filter, setFilter] = useState({
        page: 1
    });
    const { data } = useQuery({
        queryKey: [{ url: `/staff/${staffMember.id}/absences`, params: filter }]
    });

    const pagination = data.meta.pagination;
    return (
        <MainCard title="Absences">
            <PageableTable
                handlePageChange={(page) => setFilter({ ...filter, page })}
                pagination={pagination}
                data={data.data}
                headers={[
                    'Date(s)',
                    'Type',
                    'Location',
                    'Comments',
                    <RbgButton
                        onClick={() => {
                            DialogHelper.openSmDialog(
                                `Add Absence for ${staffMember.details.data.display_name}`,
                                <AddAbsence staffMember={staffMember} location={staffMember.details.data.location} />
                            );
                        }}
                    >
                        <IconCalendarPlus size="15px" /> &nbsp;Add
                    </RbgButton>
                ]}
                rowDecorator={(absence) => {
                    return [
                        absence.end_date && absence.end_date !== absence.start_date
                            ? `${dayjs(absence.start_date).format('DD/MM/YYYY')} - ${dayjs(absence.end_date).format('DD/MM/YYYY')}`
                            : dayjs(absence.start_date).format('DD/MM/YYYY'),
                        absence.type.data.name,
                        `${absence.location_name} (${absence.location_id})`,
                        absence.reason,
                        <RbgButton
                            onClick={() => {
                                DialogHelper.openSmDialog(
                                    `Add Absence for ${staffMember.details.data.display_name}`,
                                    <AddAbsence
                                        staffMember={staffMember}
                                        location={staffMember.details.data.location}
                                        absenceData={absence}
                                    />
                                );
                            }}
                        >
                            <IconEdit size="20" />
                        </RbgButton>
                    ];
                }}
            />
        </MainCard>
    );
}

Absences.propTypes = {
    staffMember: PropTypes.object.isRequired
};
export default Absences;
