import { object } from 'prop-types';
import ScNode from './ScNode';
import ScAvatar from './ScAvatar';
import './Tree.scss';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgDivider from '../../../../../ui_component/mui-wrappers/Divider/RbgDivider';
import RbgChip from '../../../../../ui_component/mui-wrappers/Chip/RbgChip';
import { useTheme } from '@mui/material';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';

function ScTree({ department }) {
    const theme = useTheme();
    return (
        <RbgStack alignItems="center" sx={{ minWidth: '100%' }}>
            <RbgChip
                label={<RbgTypography variant="h5">{department.name}</RbgTypography>}
                variant="outlined"
                sx={{ borderColor: `${theme.palette.primary.main}70` }}
            />
            <RbgDivider orientation="vertical" sx={{ height: '25px', alignSelf: 'center', borderColor: '#ccc' }} flexItem />
            <ScAvatar
                image={department.selfie_image}
                name={department.manager}
                position={department.role}
                forename={department.manager_forename}
                surname={department.manager_surname}
                id={department.manager_id}
            />

            {department.staff.length > 0 && (
                <RbgDivider orientation="vertical" sx={{ height: '25px', alignSelf: 'center', borderColor: '#ccc' }} flexItem />
            )}

            <div className="tree">
                <ul>
                    <ScNode member={department} />
                </ul>
            </div>
        </RbgStack>
    );
}

export default ScTree;

ScTree.propTypes = {
    department: object.isRequired
};
