/**
 * axios setup to use mock service
 */

import axios from 'axios';
import config from '../config';

const axiosServices = axios.create({
    baseURL: config.api.url,
    timeout: config.api.timeout
});

export default axiosServices;
