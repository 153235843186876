import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// project imports
import LogoSection from '../LogoSection/LogoSection';
import ProfileSection from './ProfileSection/ProfileSection';
import HeaderBarSelect from './HeaderBarSelect/HeaderBarSelect';
import Targets from './Targets/Targets';
import AvatarIconButton from 'ui_component/buttons/AvatarIconButton';

// assets
import { IconMenu2 } from '@tabler/icons-react';
import { useSelector } from 'react-redux';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

function Header({ handleLeftDrawerToggle }) {
    const { showTargets } = useSelector((state) => state.bar);
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <AvatarIconButton handleClick={handleLeftDrawerToggle} icon={IconMenu2} />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {showTargets && <Targets />}
            <HeaderBarSelect />
            <ProfileSection />
        </>
    );
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
