import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ClientSidePageableTable from '../../../../ui_component/pageable-table/ClientSidePageableTable';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import dayjs from 'dayjs';

function PayslipHistory({ id }) {
    const [filter, setFilter] = useState({
        page: 1
    });

    const { data, isFetched } = useQuery({
        queryKey: [{ url: `/staff/payslip/${id}/history`, params: filter }]
    });
    if (!isFetched) {
        return <CardLoader />;
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <ClientSidePageableTable
                    perPage={10}
                    headers={['Date', 'User', 'Entry']}
                    data={data.data.map((row) => [dayjs(row.date).format('DD/MM/YYYY HH:mm'), row.user_name, row.entry])}
                    keys={data.data.map((row) => row.id)}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

PayslipHistory.propTypes = {
    id: PropTypes.number.isRequired
};
export default PayslipHistory;
