import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../../../../../config';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// project imports
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import menuItems from '../../../../../menu-items/menuItems';
import menuLookup from '../../../../../utils/menuLookup';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const CustomListItem = forwardRef((props, ref) => <Link ref={ref} {...props} to={props.item.url} target={props.itemTarget} />);

function NavItem({ item, level }) {
    const theme = useTheme();
    const route = useLocation();

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: route.pathname === item.url ? 8 : 6,
                height: route.pathname === item.url ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    // let active = false;
    // if (route.pathname === item.url) {
    //     active = true;
    // }

    const active = menuLookup.getActiveItems(route.pathname);

    return (
        <ListItemButton
            component="a"
            target={item.newWindow ? '_blank' : '_self'}
            disabled={item.disabled}
            to={item.url}
            href={item.url}
            sx={{
                borderRadius: `${config.borderRadius}px`,
                mb: 0,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 0.75 : 1,
                pl: `${level * 24}px`
            }}
            selected={active && active.indexOf(item.id) > -1}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <RbgTypography variant={route.pathname === item.url ? 'h5' : 'body1'} color="inherit">
                        {item.title}
                    </RbgTypography>
                }
                secondary={
                    item.caption && (
                        <RbgTypography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </RbgTypography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

CustomListItem.propTypes = {
    item: PropTypes.object,
    itemTarget: PropTypes.string
};

export default NavItem;
