import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import { staffMember, staffRole } from '../../../StaffDataTypes';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import RbgSelect from '../../../../../../ui_component/extended/Form/RbgSelect';
import CardLoader from '../../../../../../ui_component/loading/CardLoader';
import InfoCard from '../../../../../../ui_component/error/InfoCard';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../../utils/api';
import ErrorCard from '../../../../../../ui_component/error/ErrorCard';

function ManageAdditionalRoles({ staffMember, role }) {
    const [positions, setPositions] = useState(
        role.additional_positions.data.map((pos) => {
            return pos.id;
        })
    );

    const {
        data: positionList,
        isLoading,
        isFetched
    } = useQuery({
        queryKey: [{ url: '/staff/positions', params: { cost_centre: role.cost_centre } }]
    });

    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/role/${role.id}`, { positions });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        DialogHelper.addSaveButton(() => saveMutation.mutate(), 'Save');
    }, [saveMutation]);
    if (isFetched === false || isLoading === true) {
        return <CardLoader />;
    }
    const values = positionList.data
        .filter((pos) => pos.locations.indexOf(role.cost_centre) > -1)
        .map((pos) => ({
            value: pos.id,
            label: `${pos.name} (${pos.pay_type.data.name})`
        }));
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>
                    Here you can add additional roles for this team member. This will not change the pay rate, or have any other effect
                    apart from the role being available to use in scheduling
                </InfoCard>
            </RbgGrid>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgSelect
                    multiple
                    value={positions}
                    useAutoComplete
                    values={values}
                    label="Positions"
                    handleChange={(e) => setPositions(e.target.value)}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

ManageAdditionalRoles.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired,
    role: PropTypes.shape(staffRole).isRequired
};

export default ManageAdditionalRoles;
