import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import { useState } from 'react';
import FirstAidCountsPerSite from './FirstAidCountsPerSite';

function FirstAidReporting() {
    const [tab, setTab] = useState('counts');
    const tabs = [
        {
            value: 'counts',
            label: 'First Aid Counts by Site',
            content: <FirstAidCountsPerSite />
        }
    ];
    return (
        <MainCard title="First Aid Reporting">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default FirstAidReporting;
