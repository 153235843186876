import { staffDisciplinary } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RelativeTime from '../../../../../ui_component/relative-time/RelativeTime';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableHead from '../../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { useMutation, useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconDownload } from '@tabler/icons-react';
import Api from '../../../../../utils/api';

function ViewDisciplinary({ disciplinary }) {
    const { data, isPending, isFetched, isLoading } = useQuery({
        queryKey: [{ url: `/staff/${disciplinary.staff_id}/disciplinaries/${disciplinary.id}` }]
    });

    const downloadMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('GET', `/staff/${disciplinary.staff_id}/disciplinaries/${disciplinary.id}/file/${id}`);
        },
        onSuccess: (data) => {
            window.open(data.data.uri);
        }
    });

    if (isFetched === false) {
        return <CardLoader />;
    }
    return (
        <RbgGrid container>
            {data.data.expiry_date && dayjs(data.data.expiry_date).isBefore(dayjs()) && (
                <RbgGrid item xs={12}>
                    <ErrorCard>This disciplinary {dayjs(data.data.expiry_date).fromNow()}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTable>
                    <RbgTableHead>
                        <RbgTableRow>
                            <RbgTableCell>{dayjs(data.data.date).format('DD/MM/YYYY')}</RbgTableCell>
                            <RbgTableCell>{data.data.type.data.name}</RbgTableCell>
                            <RbgTableCell>{data.data.reason.data.name}</RbgTableCell>
                            <RbgTableCell>
                                Expires: <RelativeTime time={data.data.expiry_date} />
                            </RbgTableCell>
                        </RbgTableRow>
                        <RbgTableRow>
                            <RbgTableCell colSpan={4}>Logged By: {data.data.logged_by_name}</RbgTableCell>
                        </RbgTableRow>
                        {data.data.attendees.data.length > 0 && (
                            <RbgTableRow>
                                <RbgTableCell colSpan={4}>
                                    <RbgStack direction="row" gap={2} justifyContent="center">
                                        {data.data.attendees.data.map((att) => {
                                            return (
                                                <RbgTypography key={att.name}>
                                                    {att.name} ({att.role})
                                                </RbgTypography>
                                            );
                                        })}
                                    </RbgStack>
                                </RbgTableCell>
                            </RbgTableRow>
                        )}
                    </RbgTableHead>
                    <RbgTableBody>
                        <RbgTableRow>
                            <RbgTableCell colSpan={4}>{data.data.details}</RbgTableCell>
                        </RbgTableRow>
                        {data.data.files.data.length > 0 && (
                            <RbgTableRow>
                                <RbgTableCell colspan={4}>
                                    <RbgStack direction="row" alignItems="center" gap={2} justifyContent="center">
                                        {data.data.files.data.map((file) => {
                                            return (
                                                <RbgButton
                                                    key={file.id}
                                                    onClick={() => downloadMutation.mutate(file.file.data.id)}
                                                    disabled={downloadMutation.isPending}
                                                >
                                                    {file.file.data.original_filename}
                                                    <IconDownload />
                                                </RbgButton>
                                            );
                                        })}
                                    </RbgStack>
                                </RbgTableCell>
                            </RbgTableRow>
                        )}
                    </RbgTableBody>
                </RbgTable>
            </RbgGrid>
        </RbgGrid>
    );
}

ViewDisciplinary.propTypes = {
    disciplinary: PropTypes.shape(staffDisciplinary).isRequired
};

export default ViewDisciplinary;
