import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function CancelTransfer({ staffMember }) {
    const futureRoles = staffMember.all_roles.data.filter((role) => dayjs(role.start_date).isAfter(dayjs()));
    const transferRoles = futureRoles.filter((role) => role.cost_centre !== staffMember.role.data.cost_centre);
    const cancelMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `staff/staff-member/${staffMember.id}/transfer/${transferRoles[0].id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgGrid container>
            {cancelMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{cancelMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    <RbgTypography>Are you sure you want to cancel this transfer? This cannot be un-done</RbgTypography>
                </IntroCardPrimary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton fullWidth onClick={() => cancelMutation.mutate()} disabled={cancelMutation.isPending}>
                    Yes
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

CancelTransfer.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default CancelTransfer;
