import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EventDispatcher from '../../../../../utils/EventDispatcher';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function UpdateExternalIdentifier({ staffMember, type }) {
    const currentIdentifier = staffMember.external_identifiers.data.find((t) => {
        return t.type.data.id === type.id;
    })?.identifier;
    const [value, setValue] = useState(currentIdentifier);

    const isValid = (value) => {
        if (type.validation_regex) {
            const flags = type.validation_regex.replace(/.*\/([gimy]*)$/, '$1');
            const pattern = type.validation_regex.replace(new RegExp(`^/(.*?)/${flags}$`), '$1');
            const regex = new RegExp(pattern, flags);

            return regex.test(value);
        }

        return true;
    };

    const canSave = value === currentIdentifier || !isValid(value);

    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/external-identifier/${type.id}`, { value });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            EventDispatcher.dispatch('reload-staff-member', staffMember.id);
        }
    });
    return (
        <RbgGrid container>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    label={`${type.name} Identifier`}
                    error={isValid(value) ? '' : type.validation_help}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton fullWidth disabled={canSave} onClick={() => saveMutation.mutate()}>
                    Save
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

UpdateExternalIdentifier.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired,
    type: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired])
};

export default UpdateExternalIdentifier;
