import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// project imports
import NavItem from '../NavItem/NavItem';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import menuLookup from '../../../../../utils/menuLookup';
import { useLocation } from 'react-router-dom';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

function NavCollapse({ menu, level }) {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const { user } = useContext(RcloudAuthContext);
    const route = useLocation();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    // const [group, setGroup] = useState('');
    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // useEffect(() => {
    //     if (menu.children) {
    //         const entry = menu.children.find((c) => c.url === window.location.pathname);
    //         if (entry) {
    //             setOpen(true);
    //             setSelected(true);
    //         }
    //     }
    // }, [menu]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        if (item.hidden === true) {
            return null;
        }
        if (item.permission && user.permissions.indexOf(item.permission) === -1) return null;
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <RbgTypography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </RbgTypography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const active = menuLookup.getActiveItems(route.pathname);

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 0.75 : 1,
                    pl: `${level * 24}px`
                }}
                selected={active && active.indexOf(menu.id) > -1}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <RbgTypography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {menu.title}
                        </RbgTypography>
                    }
                    secondary={
                        menu.caption && (
                            <RbgTypography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </RbgTypography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open || (active && active.indexOf(menu.id) > -1)} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
}

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
