import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import { staffPayRate } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function EditRate({ rate }) {
    const [newRate, setNewRate] = useState(rate.rate);
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${rate.staff_id}/pay-rate/${rate.id}`, { new_rate: newRate });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        const disabled = newRate === rate.rate;
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', disabled);
    }, [newRate, mutation, rate.rate]);
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <WarningCard>
                    This will edit the current pay rate of the employee. This should only be completed if a mistake has been made, pay rises
                    should be done through the &quot;Schedule Pay Rate Change&quot; function
                </WarningCard>
            </RbgGrid>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput type="number" name="rate" value={newRate} onChange={(e) => setNewRate(e.target.value)} label="New Pay Rate" />
            </RbgGrid>
        </RbgGrid>
    );
}

EditRate.propTypes = {
    rate: PropTypes.shape(staffPayRate)
};

export default EditRate;
