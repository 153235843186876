import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material-ui imports
import { Autocomplete, TextField } from '@mui/material';
// 3rd party imports
import _ from 'lodash';
import Api from 'utils/api';
import RcloudAuthContext from '../../contexts/RcloudAuthContext';

function BarSelect({ bar, setBar, label, formSize, formStyles, getBarObject, includeCentralBars, onlyAssignedBars, ...others }) {
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const { user } = useContext(RcloudAuthContext);

    const handleChange = (event, selected, reason) => {
        if (reason === 'selectOption') {
            setValue(selected);
        }
    };

    useEffect(() => {
        Api.getCached('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json', {}, 30)
            .then(({ data }) => {
                const barsData = data.map((bar) => ({
                    id: bar.id.toString(),
                    label: bar.alias,
                    value: bar.alias,
                    open: bar.open,
                    alias: bar.alias,
                    brand: bar.brand,
                    brandId: bar.brand_id,
                    areaManager: bar.area_manager ? bar.area_manager : '',
                    areaManagerId: bar.area_manager_id ? bar.area_manager_id : null,
                    centralBar: bar.central_bar,
                    costCentre: bar.cost_centre
                }));
                const filteredLocations = barsData.filter((bar) => {
                    let valid = bar.open;
                    if (!includeCentralBars) {
                        valid = !bar.centralBar;
                    }
                    if (onlyAssignedBars === true) {
                        let found = false;
                        user.bars.data.forEach((b) => {
                            if (b.bar_id === parseInt(bar.id, 10)) {
                                found = true;
                            }
                        });
                        if (found === false) {
                            valid = false;
                        }
                    }
                    return valid;
                });
                setOptions(filteredLocations);
                const defaultValue = _.find(filteredLocations, (location) => location.id === bar);
                handleChange(null, defaultValue, 'selectOption');
            })
            .catch((err) => console.error(err));
        return () => {
            setValue('');
            setOptions([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!value) return;
        if (getBarObject) {
            getBarObject(value);
        }
        setBar(value.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Autocomplete
            fullWidth
            autoComplete
            autoHighlight
            onChange={handleChange}
            options={options}
            value={value}
            disableClearable
            isOptionEqualToValue={(option, value) => option?.label === value?.label}
            renderInput={(params) => <TextField autoComplete="off" {...params} label={label} />}
            {...others}
        />
    );
}

BarSelect.propTypes = {
    bar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setBar: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    formSize: PropTypes.string,
    getBarObject: PropTypes.func,
    formStyles: PropTypes.object,
    includeCentralBars: PropTypes.bool,
    onlyAssignedBars: PropTypes.bool
};

export default BarSelect;
