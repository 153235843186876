import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// 3rd party imports
import _ from 'lodash';
import dayjs from 'dayjs';
// mui imports
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
// project imports
import Api from 'utils/api';
import { snackbarOpen } from 'store/reducers/snackbarReducer';
import CircularLoading from '../loading/CircularLoading';
import RbgDatePicker from './RbgDatePicker';
import VirtualizedSelect from '../extended/Form/VirtualizedSelect';

function DateRangeSelect({
    allowSingleDate = true,
    start,
    end,
    onChange,
    defaultType = 'single-date',
    showPrepend = true,
    size = 'small'
}) {
    // redux
    const dispatch = useDispatch();
    // state
    const [loading, setLoading] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [type, setType] = useState(defaultType);
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedWeek, setSelectedWeek] = useState('');

    const handleSingleDateChange = (e) => {
        onChange({
            start: dayjs(e).format('YYYY-MM-DD'),
            end: null
        });
    };
    const handlePeriodChange = (e) => {
        setSelectedPeriod(e.value);
        const period = periods.find((period) => period.key === e.key);
        onChange({ start: period.start, end: period.end, period: period.period, year: period.year });
    };
    const handleWeekChange = (e) => {
        const week = weeks.find((week) => week.key === e.key);
        onChange({ start: week.start_date, end: week.end_date });
    };
    const handleTypeChange = (e) => {
        const value = e.target.value;
        setType(value);
        if (value === 'period') {
            const current = periods.find((period) => period.current === true);
            if (current) {
                setSelectedPeriod(current.key);
                onChange({ start: current.start, end: current.end });
            }
        }
        if (value === 'week') {
            const current = weeks.find((week) => week.current === true);
            if (current) {
                setSelectedWeek(current.value);
                onChange({ start: current.start_date, end: current.end_date });
            }
        }
    };

    useEffect(() => {
        Api.getCached('rest/periods').then((res) => {
            const now = dayjs();
            const periodsData = {};
            const periodIds = [];
            const weeksData = [];
            let periodsArr = [];
            if (res.ok) {
                res.data.map((wk) => {
                    const start = dayjs(wk.bus_start);
                    const startTs = start.unix();
                    const end = dayjs(wk.bus_end);
                    const endTs = end.unix();
                    weeksData.push({
                        key: wk.id,
                        value: `W${wk.week} P${wk.period} FY${wk.financial_year}`,
                        start: wk.bus_start,
                        end: wk.bus_end,
                        startDate: start.format('YYYY-MM-DD'),
                        endDate: end.format('YYYY-MM-DD'),
                        startTs,
                        endTs,
                        current: now.unix() > startTs && now.unix() < endTs
                    });
                    const periodId = `${wk.period} ${wk.financial_year}`;
                    if (periodIds.indexOf(periodId) === -1) {
                        periodIds.push(periodId);
                        periodsData[periodId] = {
                            key: `${wk.period}-${wk.financial_year}`,
                            value: `P${wk.period} FY${wk.financial_year}`,
                            start: null,
                            end: null,
                            period: wk.period,
                            year: wk.financial_year,
                            weeks: []
                        };
                    }
                    return periodsData[periodId].weeks.push(wk);
                });
                Object.keys(periodsData).map((p) => periodsArr.push(periodsData[p]));
                periodsArr = periodsArr
                    .map((period) => {
                        let start = null;
                        let end = null;
                        period.weeks.forEach((week) => {
                            if (start === null || dayjs(week.start_time) < start) {
                                start = dayjs(week.start_time);
                            }
                            if (end === null || dayjs(week.end_time) > end) {
                                end = dayjs(week.end_time);
                            }
                            const m = dayjs(start);
                            const em = dayjs(end);
                            period.start = m.format('YYYY-MM-DD');
                            period.start_ts = m.unix();
                            period.end = em.format('YYYY-MM-DD');
                            period.end_ts = em.unix();
                        });
                        period.current = now.unix() > period.start_ts && now.unix() < period.end_ts;
                        return period;
                    })
                    .sort((p1, p2) => (p1.start_ts > p2.start_ts ? 1 : -1));
                setPeriods(periodsArr);
                setWeeks(weeksData);
                const currentPeriod = _.find(periodsArr, (p) => p.current);
                setSelectedPeriod(currentPeriod.value);
                setLoading(false);
            } else {
                dispatch(snackbarOpen({ open: true, severity: 'error', message: res.error_message }));
            }
        });

        return () => setLoading(true);
    }, [dispatch]);

    const prepend = (
        <Box sx={{ minWidth: 120 }}>
            <FormControl sx={{ width: '100%' }} size="small">
                <InputLabel id="date-type">Date Type</InputLabel>
                <Select labelId="date-type" label="Date Type" value={type} onChange={handleTypeChange}>
                    <MenuItem value="single-date">Single Date</MenuItem>
                    <MenuItem value="period">Period</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
    if (loading) return <CircularLoading />;
    if (type === 'single-date') {
        return (
            <RbgStack direction="row" spacing={2}>
                {showPrepend && prepend}
                <RbgDatePicker
                    label="Date"
                    value={start}
                    onChange={handleSingleDateChange}
                    InputProps={{ size: 'small' }}
                    disableMaskedInput
                />
            </RbgStack>
        );
    }
    if (type === 'period') {
        return (
            <RbgStack direction="row" spacing={2}>
                {showPrepend && prepend}
                <Box sx={{ width: '100%' }}>
                    <VirtualizedSelect label="Period" size={size} data={periods} value={selectedPeriod} onSelect={handlePeriodChange} />
                </Box>
            </RbgStack>
        );
    }

    if (type === 'week') {
        return (
            <RbgStack direction="row" spacing={2}>
                {showPrepend && prepend}
                <Box sx={{ minWidth: 120 }}>
                    <VirtualizedSelect label="Week" size={size} value={selectedWeek} data={weeks} onSelect={handleWeekChange} />
                </Box>
            </RbgStack>
        );
    }
}
export default DateRangeSelect;

DateRangeSelect.propTypes = {
    allowSingleDate: PropTypes.bool,
    start: PropTypes.string,
    end: PropTypes.string,
    onChange: PropTypes.func,
    defaultType: PropTypes.oneOf(['single-date', 'week', 'period']),
    showPrepend: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
};
