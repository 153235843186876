import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// reducers
import { customizationReducer } from './reducers/customizationReducer';
import { snackbarReducer } from './reducers/snackbarReducer';
import { barReducer } from './reducers/barReducer';
import { partyProReducer } from './reducers/partyProReducer';
import { breadcrumbsReducer } from './reducers/breadcrumbsReducer';
import { routesReducer } from './reducers/routesReducer';
import { rotaReducer } from './reducers/rotaReducer';
import { actionsReducer } from './reducers/actionsReducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['breadcrumbs', 'bar']
};

const rootReducer = combineReducers({
    bar: barReducer.reducer,
    customization: customizationReducer.reducer,
    snackbar: snackbarReducer.reducer,
    partyPro: partyProReducer.reducer,
    breadcrumbs: breadcrumbsReducer.reducer,
    routes: routesReducer.reducer,
    rota: rotaReducer.reducer,
    actions: actionsReducer.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(store);
