import { useMutation, useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useState } from 'react';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RelativeTime from '../../../../../ui_component/relative-time/RelativeTime';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconCheck } from '@tabler/icons-react';
import Api from '../../../../../utils/api';

function OnboardAdminList() {
    const [filter, setFilter] = useState({
        page: 1,
        complete: 0
    });
    const { data, refetch } = useQuery({
        queryKey: [{ url: '/staff/onboarding-admin', params: filter }]
    });
    const approveMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('POST', `/staff/onboarding-admin/rtw-check/${id}/approve`);
        },
        onSuccess: () => {
            refetch();
        }
    });

    const removeMutation = useMutation({
        mutationFn: (id) => {
            return Api.reactQueryMutation('DELETE', `/staff/onboarding-admin/rtw-check/${id}`);
        },
        onSuccess: () => {
            refetch();
        }
    });

    return (
        <MainCard title="New Starters / RTW Checks">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <PageableTable
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                        data={data.data}
                        headers={['ID', 'Team Member', 'Status', 'RTW Checks']}
                        pagination={data.meta.pagination}
                        rowDecorator={(row) => {
                            return [
                                row.id,
                                <RbgStack gap={2}>
                                    <RbgTypography>
                                        <a href={`/staff/onboarding/${row.id}`}>
                                            {row.data.forename} {row.data.surname}
                                        </a>
                                    </RbgTypography>
                                    <RbgTypography>{row.location_name}</RbgTypography>
                                    <RbgTypography>{row.position.data.name}</RbgTypography>
                                </RbgStack>,
                                row.status.data.name,
                                <RbgSimpleTable
                                    headers={['Date Completed', 'Date Expires', 'Status', 'Rejected', 'Restrictions', 'Admin Check']}
                                    rows={row.rtw_check.data.map((check) => [
                                        <RelativeTime time={check.check_data.date_of_check} />,
                                        check.check_data.check_expires ? <RelativeTime time={check.check_data.check_expires} /> : 'N/A',
                                        check.check_data.right_to_work_status,
                                        check.check_data.reject_reason ? (
                                            <RbgStack gap={2}>
                                                <ErrorCard>{check.check_data.reject_reason}</ErrorCard>
                                                {check.check_data.reject_reason !== 'None' && (
                                                    <RbgButton
                                                        color="error"
                                                        disabled={removeMutation.isPending}
                                                        onClick={() => removeMutation.mutate(check.id)}
                                                    >
                                                        Remove Failed Check
                                                    </RbgButton>
                                                )}
                                            </RbgStack>
                                        ) : (
                                            'No'
                                        ),
                                        check.check_data.restrictions ? check.check_data.restrictions : 'N/A',
                                        check.check_data.admin_check_required ? (
                                            <WarningCard>
                                                {check.check_data.admin_check_reason}
                                                <RbgButton
                                                    color="success"
                                                    onClick={() => approveMutation.mutate(check.id)}
                                                    disabled={approveMutation.isPending}
                                                >
                                                    Approve <IconCheck />
                                                </RbgButton>
                                            </WarningCard>
                                        ) : (
                                            'N/A'
                                        )
                                    ])}
                                />
                            ];
                        }}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default OnboardAdminList;
