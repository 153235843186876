import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import dayjs from 'dayjs';
import PageableTable from '../../../../ui_component/pageable-table/PageableTable';
import RbgTextInput from '../../../../ui_component/extended/Form/RbgTextInput';
import { useDebounce } from '@uidotdev/usehooks';

function BulkActionHistory({ action }) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const searchTerm = useDebounce(search, 500);
    const { data } = useQuery({
        queryKey: [{ url: `/staff/bulk-actions/${action.id}/log`, params: { page, search: searchTerm } }]
    });

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgTextInput name="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <PageableTable
                    pagination={data.meta.pagination}
                    data={data.data}
                    handlePageChange={(page) => setPage(page)}
                    headers={['ID', 'Date', 'Team Member Name', 'Entry']}
                    rowDecorator={(row) => [row.id, dayjs(row.date).format('DD/MM/YYYY HH:mm:ss'), row.staff_name, row.entry]}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

BulkActionHistory.propTypes = {
    action: PropTypes.object.isRequired
};

export default BulkActionHistory;
