import PropTypes from 'prop-types';
// mui imports
import { Table } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [Table API](https://mui.com/material-ui/api/table/)
 */

function RbgTable({ children, ...others }) {
    return (
        <Table size="small" {...others}>
            {children}
        </Table>
    );
}

export default RbgTable;

RbgTable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
