import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
// routing
import { RouterProvider } from 'react-router-dom';
import ThemeRoutes from 'routes/ThemeRoutes';
// defaultTheme
import themes from 'themes/themes';
// project imports
import { setRoutes } from 'store/reducers/routesReducer';
import Snackbar from 'ui_component/extended/Snackbar';
import RbgDialog from 'ui_component/modals/RbgDialog';
// auth provider
import { RcloudProvider } from 'contexts/RcloudAuthContext';
import PusherWrapper from 'utils/pusher-wrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Api from './utils/api';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TwoFactorDialog from './ui_component/two-factor/TwoFactorDialog';

// ==============================|| APP ||============================== //

function App() {
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 0,
                refetchOnWindowFocus: false,
                initialData: {
                    data: [],
                    meta: { pagination: {} }
                },
                queryFn: Api.reactQueryFn
            }
        }
    });
    useEffect(() => {
        dispatch(setRoutes(ThemeRoutes));
    }, [dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <QueryClientProvider client={queryClient}>
                    <RcloudProvider>
                        <PusherWrapper>
                            <RouterProvider router={ThemeRoutes} />
                            <TwoFactorDialog />
                            <RbgDialog />
                            <Snackbar />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </PusherWrapper>
                    </RcloudProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
