import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import RbgDivider from '../../../../../ui_component/mui-wrappers/Divider/RbgDivider';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';
import { filesize } from 'filesize';
import { staffDocument } from '../../StaffDataTypes';

function EditDocument({ document }) {
    const [type, setType] = useState(document.type.data);

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('PATCH', `/staff/staff-member/${document.staff_id}/document/${document.id}`, {
                type: { data: type }
            });
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    const deleteMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff//staff-member/${document.staff_id}/document/${document.id}`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        if (type && type.id !== document.type.data.id) {
            DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', false);
            return;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', true);
    }, [type, document.type, mutation]);
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <strong>{document.file.data.filename}</strong> ({filesize(document.file.data.size)})
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    value={type?.id}
                    endpoint="/staff/documents/types"
                    name="type"
                    label="Document Type"
                    useAutoComplete
                    handleObjectChange={(e) => setType(() => e.target.object)}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDivider />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <DeleteConfirm onDelete={() => deleteMutation.mutate()} btnText="Delete Document" confirmText="Sure?" />
            </RbgGrid>
        </RbgGrid>
    );
}

EditDocument.propTypes = {
    document: PropTypes.shape(staffDocument).isRequired
};
export default EditDocument;
