import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import { useState } from 'react';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import ClientSidePageableTable from '../../../../ui_component/pageable-table/ClientSidePageableTable';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import InfoCard from '../../../../ui_component/error/InfoCard';
import WarningCard from '../../../../ui_component/error/WarningCard';

function PreviewBulkAction({ action, fields }) {
    const [tab, setTab] = useState('updates');
    const { data, isFetched, error } = useQuery({
        queryKey: [{ method: 'POST', url: `/staff/bulk-actions/${action.id}/preview`, params: { fields } }]
    });
    if (isFetched === false) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <CardLoader />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (error) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }

    const tabs = [
        {
            value: 'updates',
            label: 'Updates',
            notifications: data.data.results.length,
            content: (
                <RbgGrid container>
                    <RbgGrid item xs={12}>
                        <InfoCard>These are the team members that will be updated</InfoCard>
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <RbgSimpleTable headers={data.data.headers} rows={data.data.results.map((row) => row.preview)} />
                    </RbgGrid>
                </RbgGrid>
            )
        },
        {
            value: 'errors',
            label: 'Errors',
            notifications: data.data.errors.length,
            content: (
                <RbgGrid container>
                    <RbgGrid item xs={12}>
                        <WarningCard>These are team members that will not be updated</WarningCard>
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <ClientSidePageableTable
                            headers={['Row Number', 'Error']}
                            data={data.data.errors.map((row) => [row.row_number, row.error])}
                        />
                    </RbgGrid>
                </RbgGrid>
            ),
            content_old: (
                <RbgSimpleTable headers={['Row Number', 'Error']} rows={data.data.errors.map((row) => [row.row_number, row.error])} />
            )
        }
    ];
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgTabs currentTab={tab} setCurrentTab={(tab) => setTab(tab)} tabs={tabs} />
            </RbgGrid>
        </RbgGrid>
    );
}

PreviewBulkAction.propTypes = {
    action: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired
};

export default PreviewBulkAction;
