import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import RbgBox from 'ui_component/mui-wrappers/Box/RbgBox';
import RbgPopper from 'ui_component/popper/RbgPopper';
import RbgPaper from 'ui_component/mui-wrappers/Paper/RbgPaper';
// assets
import { IconMapPin } from '@tabler/icons-react';
// project imports
import { setBar } from 'store/reducers/barReducer';
import AvatarButton from 'ui_component/buttons/AvatarButton';
import RbgLocationSelect from '../../../../ui_component/location-select/RbgLocationSelect';
import Api from '../../../../utils/api';

function HeaderBarSelect() {
    const theme = useTheme();
    const matchDown980 = useMediaQuery(theme.breakpoints.down(980));
    // redux
    const dispatch = useDispatch();
    const { bar } = useSelector((state) => state.bar);
    // local state
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    if (!matchDown980) {
        return (
            <RbgBox
                sx={{
                    ml: 3,
                    mr: 3
                }}
            >
                <RbgLocationSelect
                    name="location"
                    label="Location"
                    onlyAssignedLocations
                    disableClearable
                    value={bar.cost_centre}
                    sx={{ minWidth: '200px' }}
                    onObjectChange={(e) => {
                        Api.post(`rest/preference/selected_location`, { value: e.target.object.cost_centre });
                        dispatch(setBar(e.target.object));
                    }}
                />
            </RbgBox>
        );
    }

    return (
        <>
            <AvatarButton ref={anchorRef} buttonType="secondary" disableRipple Icon={IconMapPin} onClick={handleToggle} />
            <RbgPopper
                ref={anchorRef}
                open={open}
                setOpen={setOpen}
                popperPlacement="bottom"
                transitionPosition="top"
                disablePortal
                outlined={false}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [-20, 20]
                            }
                        }
                    ]
                }}
            >
                <RbgPaper sx={{ p: 1 }} elevation={16}>
                    <RbgLocationSelect
                        name="location"
                        label="Location"
                        onlyAssignedLocations
                        disableClearable
                        value={bar.cost_centre}
                        sx={{ minWidth: '200px' }}
                        onObjectChange={(e) => {
                            dispatch(setBar(e.target.object));
                        }}
                    />
                </RbgPaper>
            </RbgPopper>
        </>
    );
}

export default HeaderBarSelect;
