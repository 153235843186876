import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import MainCard from '../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import StaticBreadcrumb from '../../../../ui_component/extended/StaticBreadcrumb';
import SubCard from '../../../../ui_component/cards/SubCard';
import RbgIcon from '../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import dayjs from 'dayjs';
import ClientSidePageableTable from '../../../../ui_component/pageable-table/ClientSidePageableTable';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { currencyGBP } from '../../../../utils/currencyFormatter';

function PeopleDashboard() {
    const { data, isPending, isFetched } = useQuery({
        queryKey: [{ url: '/staff/reporting/people-dashboard' }]
    });
    if (isPending || !isFetched) {
        return <CardLoader />;
    }
    return (
        <>
            <StaticBreadcrumb items={[{ label: 'Team' }, { label: 'People Dashboard' }]} />
            <MainCard>
                <RbgGrid container>
                    <RbgGrid item xs={6}>
                        <SubCard title="Recent Promotions / Pay Increases">
                            <ClientSidePageableTable
                                perPage={5}
                                headers={['Name', 'Location', 'Date', 'From', 'To', '']}
                                data={data.data.promotions.map((row) => {
                                    let icon = 'arrow-right';
                                    let colour = 'grey';
                                    if (row.new_hierarchy_level > row.previous_hierarchy_level) {
                                        icon = 'arrow-up';
                                        colour = 'green';
                                    }
                                    if (row.previous_hierarchy_level > row.new_hierarchy_level) {
                                        icon = 'arrow-down';
                                        colour = 'red';
                                    }
                                    if (row.type === 'role') {
                                        return [
                                            <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                            row.location_name,
                                            dayjs(row.start_date).format('DD/MM/YYYY'),
                                            row.previous_role,
                                            <RbgTypography>{row.new_role}</RbgTypography>,
                                            <RbgIcon name={icon} color={colour} />
                                        ];
                                    }
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        dayjs(row.start_date).format('DD/MM/YYYY'),
                                        currencyGBP().format(row.previous_role),
                                        currencyGBP().format(row.new_role),
                                        <RbgIcon name={icon} color={colour} />
                                    ];
                                })}
                                keys={data.data.promotions.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Salaried New Starters">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Date', 'Role']}
                                data={data.data.salaried_starters.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        dayjs(row.start_date).format('DD/MM/YYYY'),
                                        row.role_name
                                    ];
                                })}
                                keys={data.data.salaried_starters.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Salaried Leavers">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Leave Date', 'Role']}
                                data={data.data.salaried_leavers.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        dayjs(row.leave_date).format('DD/MM/YYYY'),
                                        row.role_name
                                    ];
                                })}
                                keys={data.data.salaried_leavers.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Recent Disciplinaries">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Date', 'Type']}
                                data={data.data.disciplinaries.map((row) => {
                                    let type = row.type;
                                    if (row.reason) {
                                        type = `${row.type} - ${row.reason}`;
                                    }
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        dayjs(row.date).format('DD/MM/YYYY'),
                                        type
                                    ];
                                })}
                                keys={data.data.disciplinaries.map((row) => `${row.id}-${row.date}`)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Active Suspensions">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Date', 'Created By', 'Notes']}
                                data={data.data.suspensions.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        dayjs(row.date).format('DD/MM/YYYY'),
                                        row.created_by,
                                        row.notes
                                    ];
                                })}
                                keys={data.data.suspensions.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Salaried Absence Triggers">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Instances', 'Total Days']}
                                data={data.data.absence_warnings.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        row.absences,
                                        row.total_days
                                    ];
                                })}
                                keys={data.data.absence_warnings.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Salaried Probations">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Role', 'Start Date', 'Probation End']}
                                data={data.data.probation_list.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        row.role_name,
                                        dayjs(row.start_date).format('DD/MM/YYYY'),
                                        dayjs(row.probation_end_date).format('DD/MM/YYYY')
                                    ];
                                })}
                                keys={data.data.probation_list.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Upcoming RTW Expirations">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Role', 'Check Expires']}
                                data={data.data.rtw_expires.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.id}`}>{row.display_name}</a>,
                                        row.location_name,
                                        row.role_name,
                                        <RbgStack>
                                            <RbgTypography>{dayjs(row.check_expires).format('DD/MM/YYYY')}</RbgTypography>
                                            <RbgTypography>{dayjs(row.check_expires).fromNow()}</RbgTypography>
                                        </RbgStack>
                                    ];
                                })}
                                keys={data.data.rtw_expires.map((row) => row.id)}
                            />
                        </SubCard>
                    </RbgGrid>
                    <RbgGrid item xs={6}>
                        <SubCard title="Upcoming Salaried Birthdays">
                            <ClientSidePageableTable
                                perPage={10}
                                headers={['Name', 'Location', 'Role', 'Birthday', 'Age']}
                                data={data.data.salaried_birthdays.map((row) => {
                                    return [
                                        <a href={`/staff/view/${row.staff_id}`}>{row.name}</a>,
                                        row.location_name,
                                        row.role_name,
                                        dayjs(row.next_birthday).format('DD/MM/YYYY'),
                                        row.age
                                    ];
                                })}
                                keys={data.data.salaried_birthdays.map((row) => row.staff_id)}
                            />
                        </SubCard>
                    </RbgGrid>
                </RbgGrid>
            </MainCard>
        </>
    );
}

export default PeopleDashboard;
