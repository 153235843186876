import { Avatar } from '@mui/material';
import { number, string } from 'prop-types';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ScTeamMember from './ScTeamMember';

const avatarStyles = { width: '80px', height: '80px', margin: 'auto' };

function ScAvatar({ image, name, position, forename, surname, id }) {
    return (
        <RbgButton
            sx={{ display: 'inline-block', maxWidth: '150px' }}
            onClick={() => {
                DialogHelper.openSmDialog(name, <ScTeamMember id={id} />);
            }}
        >
            {image ? (
                <Avatar src={image} alt={image} sx={avatarStyles} />
            ) : (
                <Avatar sx={avatarStyles}>{`${forename?.at(0) || ''}${surname?.at(0) || ''}`}</Avatar>
            )}
            <RbgTypography variant="h5">{name}</RbgTypography>
            {position && <RbgTypography variant="h6">{position}</RbgTypography>}
        </RbgButton>
    );
}

export default ScAvatar;

ScAvatar.propTypes = {
    image: string,
    forename: string,
    surname: string,
    name: string.isRequired,
    position: string,
    id: number.isRequired
};
