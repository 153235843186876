import PropTypes from 'prop-types';

function PdfViewer({ file }) {
    return (
        <div className="pdf-viewer">
            <iframe title="PDF" src={`/pdfjs-4.1.392-dist/web/viewer.html?file=${file}`} width="100%" height="500px" />
        </div>
    );
}

PdfViewer.propTypes = {
    file: PropTypes.string.isRequired
};
export default PdfViewer;
