import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
import SubCard from 'ui_component/cards/SubCard';
import { Avatar } from '@mui/material';
import RbgList from 'ui_component/mui-wrappers/List/RbgList';
import RbgDivider from 'ui_component/mui-wrappers/Divider/RbgDivider';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import { IconHash, IconId, IconMail, IconMapPin, IconPhone } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';
import ActionButtons from 'ui_component/buttons/ActionButtons';
import RbgTooltip from 'ui_component/tooltip/RbgTooltip';
import ListItem from './ListItem';
import { snackbarSuccess } from '../../../../../store/reducers/snackbarReducer';
import { useDispatch } from 'react-redux';
import Markdown from 'react-markdown';
import { staffMember } from '../../StaffDataTypes';
import RbgIcon from '../../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddAbsence from '../Absences/AddAbsence';
import MarkAsLeaver from '../StaffEmployment/MarkAsLeaver';
import dayjs from 'dayjs';
import CancelLeaver from '../StaffEmployment/CancelLeaver';
import ReInstate from '../StaffEmployment/ReInstate';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import ViewContract from '../StaffEmployment/ViewContract';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import StocklinkAccess from '../Stocklink/StocklinkAccess';
import StaffSelfie from '../StaffSelfie/StaffSelfie';
import NewRoleWizard from '../StaffEmployment/NewRole/NewRoleWizard';
import RegeneratePayrollReference from '../StaffEmployment/Roles/RegeneratePayrollReference';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function StaffOverview({ staffMember }) {
    const staffDetails = staffMember.details.data;
    const staffRole = staffMember.role.data;
    const { user } = useContext(RcloudAuthContext);

    const actionButtons = [];
    const syncMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/trigger-events`);
        }
    });
    if (user.permissions.indexOf('hr_staff_edit') > -1) {
        if (staffMember.leave_date) {
            if (staffMember.can_reinstate) {
                actionButtons.push({
                    color: 'primary',
                    variant: 'outlined',
                    content: `Re-Instate ${staffDetails.forename}`,
                    onClick: () => {
                        DialogHelper.openLgDialog(
                            `Reinstate ${staffMember.details.data.display_name}`,
                            <ReInstate staffMember={staffMember} />
                        );
                    }
                });
            } else if (staffMember.can_cancel_leaver) {
                actionButtons.push({
                    variant: 'outlined',
                    color: 'secondary',
                    content: `Cancel Leaver Status`,
                    onClick: () => {
                        DialogHelper.openLgDialog(
                            `Cancel leaver status for ${staffMember.details.data.display_name}`,
                            <CancelLeaver staffMember={staffMember} />
                        );
                    }
                });
            } else {
                actionButtons.push({
                    color: 'primary',
                    variant: 'outlined',
                    content: `Re-Instate ${staffDetails.forename}`,
                    onClick: () => {
                        DialogHelper.openLgDialog(
                            `Reinstate ${staffMember.details.data.display_name}`,
                            <ReInstate staffMember={staffMember} />
                        );
                    }
                });
            }
        } else {
            if (staffMember.has_future_role === false) {
                actionButtons.push({
                    color: 'primary',
                    variant: 'outlined',
                    content: 'Transfer to Location',
                    onClick: () => {
                        DialogHelper.openMdDialog(
                            'Transfer Team Member',
                            <NewRoleWizard staffMember={staffMember} initialData={{ same_location: false }} />
                        );
                    }
                });
            }
            if (!staffMember.new_starter) {
                actionButtons.push({
                    color: 'secondary',
                    variant: 'contained',
                    content: 'Add Absence',
                    onClick: () => {
                        DialogHelper.openSmDialog(
                            `Add Absence for ${staffMember.details.data.display_name}`,
                            <AddAbsence staffMember={staffMember} location={staffMember.details.data.location} />
                        );
                    }
                });
            }
            if (staffMember.employment.data.leave_date === null) {
                actionButtons.push({
                    color: 'error',
                    variant: 'contained',
                    content: 'Mark as Leaver',
                    onClick: () => {
                        DialogHelper.openSmDialog(
                            `Mark ${staffMember.details.data.display_name} as leaver`,
                            <MarkAsLeaver staffMember={staffMember} />
                        );
                    }
                });
            } else if (dayjs(staffMember.employment.data.leave_date).isAfter(dayjs())) {
                actionButtons.push({
                    color: 'error',
                    variant: 'contained',
                    content: 'Cancel Leaver',
                    onClick: () => {
                        DialogHelper.openSmDialog(
                            `Cancel ${staffMember.details.data.display_name}'s leaver status`,
                            <CancelLeaver staffMember={staffMember} />
                        );
                    }
                });
            }
            const approveContract = staffMember.all_contracts.data.find((c) => {
                return c.status.data.auth === true;
            });
            if (approveContract && user.permissions.indexOf('hr_staff_auth') > -1) {
                actionButtons.push({
                    color: 'info',
                    variant: 'contained',
                    content: 'Authorise Pending Contract',
                    onClick: () => {
                        DialogHelper.openMdDialog(`Authorise pending contract`, <ViewContract contract={approveContract} />);
                    }
                });
            }
        }
        actionButtons.push({
            color: 'warning',
            variant: 'contained',
            content: 'Sync Team Member',
            onClick: () => {
                syncMutation.mutate();
            }
        });
        if (user.permissions.indexOf('hr_config') > -1) {
            actionButtons.push({
                color: 'secondary',
                variant: 'contained',
                content: 'Create new payroll reference',
                onClick: () => {
                    DialogHelper.openMdDialog('Regenerate Payroll Reference', <RegeneratePayrollReference staffMember={staffMember} />);
                }
            });
        }
        const brand = staffMember?.location?.data?.brand?.data;
        if (brand.id !== 5) {
            actionButtons.push({
                color: 'info',
                variant: 'contained',
                content: 'Stocklink Access',
                onClick: () => {
                    DialogHelper.openSmDialog('Stocklink status', <StocklinkAccess staffMember={staffMember} />);
                }
            });
        }
    }
    const dispatch = useDispatch();
    const flags = staffMember.actions.data.filter((action) => action.severity_id === 4);
    const visibleActions = staffMember.actions.data.filter((action) => action.severity_id !== 4);
    return (
        <RbgGrid container spacing={gridSpacing}>
            {actionButtons.length > 0 && (
                <RbgGrid item xs={12}>
                    <SubCard>
                        <ActionButtons buttons={actionButtons} />
                    </SubCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12} lg={5}>
                <SubCard
                    title={
                        <RbgGrid container spacing={2} alignItems="center">
                            <RbgGrid item>
                                {staffMember.image?.data?.uri ? (
                                    <a
                                        href="#"
                                        onClick={() => {
                                            DialogHelper.openMdDialog('Team Member Image', <StaffSelfie staffMember={staffMember} />);
                                        }}
                                    >
                                        <Avatar alt={staffDetails.display_name} src={staffMember.image.data.uri} />
                                    </a>
                                ) : (
                                    <Avatar>{`${staffDetails.forename?.at(0) || ''}${staffDetails.surname?.at(0) || ''}`}</Avatar>
                                )}
                            </RbgGrid>
                            <RbgGrid item xs zeroMinWidth>
                                <RbgTypography align="left" variant="subtitle1">
                                    {staffDetails.display_name}
                                </RbgTypography>
                                <RbgTypography align="left" variant="subtitle2">
                                    {staffRole.position_name}
                                </RbgTypography>
                            </RbgGrid>
                            <RbgGrid item>
                                <RbgStack direction="row" gap={1}>
                                    {flags.map((flag) => {
                                        return (
                                            <RbgTooltip title={flag.description_rendered} key={flag.id}>
                                                <RbgIcon name={flag.icon} />
                                            </RbgTooltip>
                                        );
                                    })}
                                </RbgStack>
                            </RbgGrid>
                        </RbgGrid>
                    }
                >
                    <RbgList component="nav" sx={{ padding: 0 }}>
                        <ListItem
                            onClick={() => {
                                document.location.href = `mailto:${
                                    staffDetails.work_email_address ? staffDetails.work_email_address : staffDetails.email_address
                                }`;
                            }}
                            Icon={IconMail}
                            textPrimary="Email"
                            textSecondary={staffDetails.work_email_address ? staffDetails.work_email_address : staffDetails.email_address}
                        />
                        <RbgDivider />
                        <ListItem Icon={IconPhone} textPrimary="Phone" textSecondary={staffDetails.mobile} />
                        <RbgDivider />
                        <ListItem Icon={IconMapPin} textPrimary="Home Location" textSecondary={staffDetails.location_name} />
                        <RbgDivider />
                        <ListItem
                            Icon={IconHash}
                            textPrimary="Payroll Ref"
                            textSecondary={staffRole.payroll_ref}
                            onClick={() => {
                                dispatch(snackbarSuccess('Payroll Ref Copied'));
                                window.navigator.clipboard.writeText(staffRole.payroll_ref);
                            }}
                        />
                        <RbgDivider />
                        <ListItem
                            Icon={IconId}
                            textPrimary="Unique ID"
                            textSecondary={`${staffDetails.staff_id}`}
                            onClick={() => {
                                dispatch(snackbarSuccess('Unique ID Copied'));
                                window.navigator.clipboard.writeText(staffDetails.staff_id);
                            }}
                        />
                    </RbgList>
                </SubCard>
            </RbgGrid>
            <RbgGrid item xs={12} lg={7}>
                <RbgStack gap={2} sx={{ height: '100%' }}>
                    {visibleActions.length > 0 && (
                        <SubCard title="Action Centre">
                            <RbgStack spacing={2}>
                                {staffMember.actions.data.map((action) => {
                                    if (action.severity_id === 2) {
                                        return <WarningCard key={action.id}>{action.description_rendered}</WarningCard>;
                                    }
                                    if (action.severity_id === 1) {
                                        return <InfoCard key={action.id}>{action.description_rendered}</InfoCard>;
                                    }
                                    if (action.severity_id === 3) {
                                        return <ErrorCard key={action.id}>{action.description_rendered}</ErrorCard>;
                                    }
                                    return null;
                                })}
                            </RbgStack>
                        </SubCard>
                    )}
                    <SubCard title="Team Member Information">
                        <Markdown>{staffMember.status}</Markdown>
                    </SubCard>
                </RbgStack>
            </RbgGrid>
        </RbgGrid>
    );
}

StaffOverview.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default StaffOverview;
