import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { ListItem } from '@mui/material';

/**
 * Uses an additional container component if `ListItemSecondaryAction` is the last child.
 *
 * Demos:
 *
 * - [Lists](https://mui.com/material-ui/react-list/)
 * - [Transfer List](https://mui.com/material-ui/react-transfer-list/)
 *
 * API:
 *
 * - [ListItem API](https://mui.com/material-ui/api/list-item/)
 */

const RbgListItem = forwardRef(({ children, ...others }, ref) => (
    <ListItem ref={ref} {...others}>
        {children}
    </ListItem>
));

export default RbgListItem;

RbgListItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
