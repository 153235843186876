import { useQuery } from '@tanstack/react-query';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EditDepartment from './EditDepartment';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconEdit } from '@tabler/icons-react';
import MainCard from '../../../../../ui_component/cards/MainCard';

function SupportCentreDepartments() {
    const { error, data, isFetching, refetch } = useQuery({
        queryKey: [{ method: 'GET', url: '/staff/sc-departments' }]
    });

    return (
        <MainCard title="Support Centre Departments">
            <RbgSimpleTable
                loading={isFetching}
                error={error}
                headers={[
                    'ID',
                    'Name',
                    'Manager',
                    'Reporting Manager',
                    <RbgButton
                        onClick={() => {
                            DialogHelper.openSmDialog('Add Department', <EditDepartment onSave={() => refetch()} />);
                        }}
                    >
                        Add Department
                    </RbgButton>
                ]}
                keys={data.data.map((row) => row.id)}
                rows={data.data.map((department) => {
                    return [
                        department.id,
                        department.name,
                        department.manager_name,
                        department.reporting_manager_name,
                        <RbgButton
                            onClick={() => {
                                DialogHelper.openSmDialog(
                                    'Edit Department',
                                    <EditDepartment department={department} onSave={() => refetch()} />
                                );
                            }}
                        >
                            <IconEdit />
                        </RbgButton>
                    ];
                })}
            />
        </MainCard>
    );
}

export default SupportCentreDepartments;
