import { createSlice } from '@reduxjs/toolkit';

// ==============================|| SNACKBAR SLICE ||============================== //

export const snackbarReducer = createSlice({
    name: 'snackbar',
    initialState: {
        action: false,
        open: false,
        message: '',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        variant: 'alert',
        severity: 'success',
        transition: 'Fade',
        close: true,
        actionButton: false
    },
    reducers: {
        snackbarOpen: (state, action) => {
            state.action = !state.action;
            state.open = action.payload.open;
            state.message = action.payload.message;
            state.anchorOrigin = action.payload.anchorOrigin;
            state.severity = action.payload.severity;
            state.transition = action.payload.transition;
            state.close = action.payload.close;
            state.actionButton = action.payload.actionButton;
        },
        snackbarError: (state, action) => {
            state.action = !state.action;
            state.open = true;
            state.severity = 'error';
            state.message = action.payload;
        },
        snackbarSuccess: (state, action) => {
            state.action = !state.action;
            state.open = true;
            state.severity = 'success';
            state.message = action.payload;
        }
    }
});

export const { snackbarOpen, snackbarError, snackbarSuccess } = snackbarReducer.actions;
