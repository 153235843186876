import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { useDispatch } from 'react-redux';
import { snackbarSuccess } from '../../../../../store/reducers/snackbarReducer';

function StocklinkAccess({ staffMember }) {
    const { data, loading, refetch, error } = useQuery({
        queryKey: [{ url: `/staff/staff-member/${staffMember.id}/stocklink` }]
    });
    const dispatch = useDispatch();
    const createMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/stocklink/create`, {});
        },
        onSuccess: (data) => {
            dispatch(snackbarSuccess('User account has been created'));
            refetch();
        }
    });
    const resetMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/stocklink/create`, {});
        },
        onSuccess: (data) => {
            dispatch(snackbarSuccess('User account has been reset'));
            refetch();
        }
    });
    const reactivateMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/stocklink/reactivate`, {});
        },
        onSuccess: (data) => {
            dispatch(snackbarSuccess('User account has been reactivated'));
            refetch();
        }
    });
    const deactivateMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/stocklink/deactivate`, {});
        },
        onSuccess: (data) => {
            dispatch(snackbarSuccess('User account has been deactivated'));
            refetch();
        }
    });
    if (loading) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <CardLoader />
                </RbgGrid>
            </RbgGrid>
        );
    }
    if (error) {
        return (
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            </RbgGrid>
        );
    }
    const disabled = reactivateMutation.isPending || createMutation.isPending || deactivateMutation.isPending || resetMutation.isPending;
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    Current Status: <strong>{data.data.description}</strong>
                </IntroCardPrimary>
            </RbgGrid>
            {createMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{createMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            {reactivateMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{reactivateMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            {deactivateMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{deactivateMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgStack gap={2}>
                    {data.data.can_create && (
                        <RbgButton color="success" disabled={disabled} onClick={() => createMutation.mutate()}>
                            Create stocklink user
                        </RbgButton>
                    )}
                    {data.data.can_reset && (
                        <RbgButton color="success" disabled={disabled} onClick={() => resetMutation.mutate()}>
                            Reset stocklink password
                        </RbgButton>
                    )}
                    {data.data.can_reactivate && (
                        <RbgButton color="warning" disabled={disabled} onClick={() => reactivateMutation.mutate()}>
                            Reactivate user
                        </RbgButton>
                    )}
                    {data.data.can_deactivate && (
                        <RbgButton color="error" disabled={disabled} onClick={() => deactivateMutation.mutate()}>
                            Deactivate user
                        </RbgButton>
                    )}
                </RbgStack>
            </RbgGrid>
        </RbgGrid>
    );
}

StocklinkAccess.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};

export default StocklinkAccess;
