import { staffDocumentType } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';

function HrConfigEditDocumentType({ documentType }) {
    const [type, setType] = useState(
        documentType || {
            min_hierarchy_level: 1,
            name: '',
            category: { data: {} }
        }
    );

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation(
                documentType ? 'PATCH' : 'POST',
                documentType ? `/staff/documents/type/${documentType.id}` : '/staff/documents/type',
                type
            );
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/documents/types' }]
            });
        }
    });

    const deleteMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('DELETE', `/staff/documents/type/${documentType.id}`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/documents/types' }]
            });
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        let disabled = false;
        if (!type.category.data.id) {
            disabled = true;
        }
        if (!type.min_hierarchy_level) {
            disabled = true;
        }
        if (!type.name) {
            disabled = true;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', disabled);
    }, [type, mutation]);

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="name"
                    label="Name"
                    value={type.name}
                    onChange={(e) => setType((prev) => ({ ...prev, name: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/documents/categories"
                    name="category"
                    value={type.category.data.id}
                    label="Document Category"
                    useAutoComplete
                    handleObjectChange={(e) =>
                        setType((prev) => ({
                            ...prev,
                            category: { data: e.target.object ? e.target.object : {} }
                        }))
                    }
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/hierarchy-levels"
                    name="min_hierarchy_level"
                    filterData={(data) => {
                        return data.data.map((row) => ({ value: row.level, label: row.name }));
                    }}
                    handleChange={(e) =>
                        setType((prev) => ({
                            ...prev,
                            min_hierarchy_level: parseInt(e.target.value, 36)
                        }))
                    }
                    value={type.min_hierarchy_level}
                    label="Hierachy Level"
                />
            </RbgGrid>

            <RbgGrid item xs={12}>
                <DeleteConfirm
                    onDelete={() => deleteMutation.mutate()}
                    disabled={deleteMutation.isPending}
                    fullWidth
                    btnText="Archive Document Type"
                    confirmText="Are you sure?"
                />
            </RbgGrid>
        </RbgGrid>
    );
}

HrConfigEditDocumentType.propTypes = {
    documentType: PropTypes.shape(staffDocumentType)
};

export default HrConfigEditDocumentType;
