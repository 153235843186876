import PropTypes from 'prop-types';
import { initialTrainingConfig } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function HrConfigEditInitialTrainingSet({ config = null }) {
    const [newConfig, setNewConfig] = useState(
        config || {
            name: '',
            external_name: '',
            pay_hours: 0,
            courses: { data: [] }
        }
    );
    const [add, setAdd] = useState(false);

    const removeCourse = (id) => {
        setNewConfig((prev) => ({
            ...prev,
            courses: { data: prev.courses.data.filter((course) => course.id !== id) }
        }));
    };
    const addCourse = (course) => {
        setNewConfig((prev) => ({
            ...prev,
            courses: { data: [...prev.courses.data, course] }
        }));
        setAdd(false);
    };
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation(
                config ? 'PATCH' : 'PUT',
                config ? `/staff/initial-training-config/${config.id}` : '/staff/initial-training-config',
                newConfig
            );
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/initial-training-configs' }]
            });
        }
    });
    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', JSON.stringify(config) === JSON.stringify(newConfig));
    }, [newConfig, mutation, config]);
    return (
        <RbgGrid container>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="name"
                    label="Config Set Name"
                    value={newConfig.name}
                    onChange={(e) => setNewConfig((prev) => ({ ...prev, name: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="external_name"
                    label="External Name"
                    help="The name which will be sent to Mapal One/Flow"
                    value={newConfig.external_name}
                    onChange={(e) => setNewConfig((prev) => ({ ...prev, external_name: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput
                    type="number"
                    label="Pay Hours"
                    value={newConfig.pay_hours}
                    onChange={(e) => setNewConfig((prev) => ({ ...prev, pay_hours: e.target.value }))}
                    help="The amount of hours the team member will get paid for completing the training set"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Course', 'Options']}
                    keys={newConfig.courses.data.map((course) => course.id)}
                    rows={newConfig.courses.data.map((course) => {
                        return [course.title, <RbgButton icon={<IconTrash />} onClick={() => removeCourse(course.id)} />];
                    })}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                {add ? (
                    <RbgRemoteSelect
                        endpoint="/staff/training-courses"
                        name="training-course"
                        useAutoComplete
                        handleObjectChange={(e) => {
                            if (e.target.object) {
                                addCourse(e.target.object);
                            }
                        }}
                    />
                ) : (
                    <RbgButton color="secondary" onClick={() => setAdd(true)}>
                        <IconPlus size={20} /> Add Course
                    </RbgButton>
                )}
            </RbgGrid>
        </RbgGrid>
    );
}

HrConfigEditInitialTrainingSet.propTypes = {
    config: PropTypes.shape(initialTrainingConfig)
};
export default HrConfigEditInitialTrainingSet;
