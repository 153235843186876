import PropTypes from 'prop-types';
import RbgGrid from '../../mui-wrappers/Grid/RbgGrid';
import RbgTextInput from './RbgTextInput';
import RbgCheckbox from '../../mui-wrappers/RbgCheckbox';
import RbgSelect from './RbgSelect';
import { gridSpacing } from '../../../store/constant';
import Debug from '../../misc/Debug';
import ErrorCard from '../../error/ErrorCard';
import RbgRemoteSelect from '../../remote-select/RbgRemoteSelect';
import RbgDatePicker from '../../date-pickers/RbgDatePicker';
import getError from '../../../utils/getError';

function ObjectForm({ elements, handleChange, stateSetter, debug, error }) {
    return (
        <RbgGrid container spacing={gridSpacing}>
            {error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{error}</ErrorCard>
                </RbgGrid>
            )}
            {elements.map((element) => {
                let input = <ErrorCard>Invalid Element Type {element.type}</ErrorCard>;
                if (element.type === 'text') {
                    input = (
                        <RbgTextInput
                            name={element.name}
                            value={element.value}
                            label={element.label}
                            error={error && getError(error.fields, element.name)}
                            onChange={(e) => {
                                if (handleChange) {
                                    handleChange(e.target.name, e.target.value);
                                }
                                if (stateSetter) {
                                    stateSetter((prev) => ({ ...prev, [element.name]: e.target.value }));
                                }
                            }}
                        />
                    );
                }
                if (element.type === 'select') {
                    input = (
                        <RbgSelect
                            name={element.name}
                            values={element.values}
                            error={error && getError(error.fields, element.name)}
                            label={element.label}
                            handleChange={(e) => {
                                if (handleChange) {
                                    handleChange(e.target.name, e.target.value);
                                }
                                if (stateSetter) {
                                    stateSetter((prev) => ({ ...prev, [element.name]: e.target.value }));
                                }
                            }}
                        />
                    );
                }
                if (element.type === 'datepicker') {
                    input = (
                        <RbgDatePicker
                            value={element.value}
                            onChange={(e) => {
                                if (handleChange) {
                                    handleChange(e.target.name, e.target.value);
                                }
                                if (stateSetter) {
                                    stateSetter((prev) => ({ ...prev, [element.name]: e.target.value }));
                                }
                            }}
                            label={element.label}
                            error={error && getError(error.fields, element.name)}
                        />
                    );
                }
                if (element.type === 'checkbox') {
                    input = (
                        <RbgCheckbox
                            name={element.name}
                            checked={element.value}
                            onChange={(e) => {
                                if (handleChange) {
                                    handleChange(e.target.name, e.target.checked);
                                }
                                if (stateSetter) {
                                    stateSetter((prev) => ({ ...prev, [element.name]: e.target.checked }));
                                }
                            }}
                            label={element.label}
                            error={error && getError(error.fields, element.name)}
                        />
                    );
                }
                if (element.type === 'remote_select') {
                    input = (
                        <RbgRemoteSelect
                            error={error && getError(error.fields, element.name)}
                            filterData={
                                element.filterData
                                    ? element.filterData
                                    : (data) => {
                                          return data.data.map((row) => {
                                              return {
                                                  label: row.name,
                                                  value: row.id
                                              };
                                          });
                                      }
                            }
                            endpointParameters={element.endpointParameters}
                            value={element.value}
                            handleChange={(e) => {
                                if (handleChange) {
                                    handleChange(e.target.name, e.target.value);
                                }
                                if (stateSetter) {
                                    stateSetter((prev) => ({ ...prev, [element.name]: e.target.value }));
                                }
                            }}
                            endpoint={element.endpoint}
                            name={element.name}
                            label={element.label}
                            {...element}
                        />
                    );
                }
                return (
                    <RbgGrid item xs={element.xs ? element.xs : 12} md={element.md} lg={element.lg} key={element.name}>
                        {input}
                    </RbgGrid>
                );
            })}
            {debug === true && (
                <RbgGrid xs={12} item>
                    <Debug source={elements} />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

const ObjectFormElement = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    stateSetter: PropTypes.func,
    xs: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    type: PropTypes.oneOf(['text', 'select', 'location_select', 'checkbox', 'remote_select'])
};
ObjectForm.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.shape(ObjectFormElement)).isRequired,
    handleChange: PropTypes.func,
    debug: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    stateSetter: PropTypes.func
};
export default ObjectForm;
