import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import dayjs from 'dayjs';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { Mail, Phone } from '@mui/icons-material';
import nl2br from 'react-nl2br';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';

function ScTeamMember({ id }) {
    const { data, isPending } = useQuery({
        queryKey: [{ url: `/staff/support-centre/family-tree/${id}` }]
    });

    if (isPending) {
        return <CardLoader />;
    }

    const capitilize = (string) => {
        if (!string) {
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const pronoun = data.data.pronoun ? data.data.pronoun : 'They';
    const verb = data.data.verb ? data.data.verb : 'have';
    return (
        <RbgGrid container>
            {data.data.image && (
                <RbgGrid item xs={4}>
                    <img src={data.data.image} alt={data.data.surname} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </RbgGrid>
            )}
            <RbgGrid item xs={data.data.image ? 8 : 12}>
                <IntroCardPrimary>
                    <RbgStack gap={2}>
                        <RbgTypography>
                            {data.data.forename} currently works as <strong>{data.data.role}</strong> in the{' '}
                            <strong>{data.data.department}</strong> department.
                        </RbgTypography>

                        <RbgTypography>
                            {capitilize(pronoun)} {verb} been with The Revel Collective for{' '}
                            <strong>{dayjs(data.data.start_date).fromNow(true)}</strong>
                        </RbgTypography>
                        <RbgTypography>
                            {capitilize(data.data.forename)}&apos;s birthday is in {data.data.birth_month}
                        </RbgTypography>
                        {data.data.profile && <RbgTypography>{data.data.profile}</RbgTypography>}
                        {data.data.email && (
                            <RbgTypography>
                                <Mail /> {data.data.email}
                            </RbgTypography>
                        )}
                        {data.data.phone && (
                            <RbgTypography>
                                <Phone /> {data.data.phone}
                            </RbgTypography>
                        )}
                        {data.data.how_help && (
                            <>
                                <RbgTypography variant="h5">How can i help?</RbgTypography>
                                <RbgTypography sx={{ px: 2 }}>
                                    <Markdown remarkPlugins={[remarkGfm]}>{data.data.how_help}</Markdown>
                                </RbgTypography>
                            </>
                        )}
                    </RbgStack>
                </IntroCardPrimary>
            </RbgGrid>
        </RbgGrid>
    );
}

ScTeamMember.propTypes = {
    id: PropTypes.number.isRequired
};
export default ScTeamMember;
