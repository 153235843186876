import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import DropdownMenu from '../buttons/DropdownMenu';
import RbgStack from '../mui-wrappers/Stack/RbgStack';

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentStyles = {},
            secondary,
            shadow,
            sx = {},
            title,
            icon,
            headerStyles,
            headerVariant = 'h3',
            actionButtons,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    height: '100%',
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                              (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                <CardHeader
                    sx={{
                        padding: title || secondary || icon ? 2 : 0,
                        ...headerStyles
                    }}
                    title={
                        icon ? (
                            <RbgGrid container justifyContent="flex-start" alignContent="center">
                                <RbgGrid item>
                                    <RbgTypography sx={{ mr: 2, color: theme.palette.secondary.dark }} variant="span">
                                        {icon}
                                    </RbgTypography>
                                </RbgGrid>
                                <RbgGrid item>
                                    <RbgTypography variant={headerVariant}>{title}</RbgTypography>
                                </RbgGrid>
                            </RbgGrid>
                        ) : (
                            <RbgTypography variant={headerVariant}>{title}</RbgTypography>
                        )
                    }
                    action={
                        (secondary || actionButtons) && (
                            <RbgStack direction="row" spacing={2}>
                                {secondary}
                                {actionButtons && actionButtons.items.length > 0 && (
                                    <DropdownMenu menuLabel={actionButtons.label ?? 'Options'} menuItems={actionButtons.items} />
                                )}
                            </RbgStack>
                        )
                    }
                />

                {/* content & header divider */}
                {(title || secondary || actionButtons || icon) && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent
                        sx={{
                            height: '100%',
                            padding: 2,
                            overflowX: 'auto',
                            ...contentStyles
                        }}
                        className={contentClass}
                    >
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentStyles: PropTypes.object,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    icon: PropTypes.object,
    headerStyles: PropTypes.object,
    headerVariant: PropTypes.string,
    actionButtons: PropTypes.shape({
        label: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
                onClick: PropTypes.func
            })
        ).isRequired
    })
};

export default MainCard;
