import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import StaffMemberAutocomplete from '../../Utility/StaffMemberAutocomplete';

function StaffAdminEmailPreview({ email }) {
    const [staffId, setStaffId] = useState('');
    const { data, isFetched } = useQuery({
        queryKey: [
            {
                method: 'POST',
                url: `/staff/admin/email/preview`,
                params: {
                    outer_template_id: email.outer_template.data.id,
                    subject: email.subject,
                    tagline: email.tagline,
                    content: email.content,
                    staff_id: staffId
                }
            }
        ],
        enable: staffId !== ''
    });
    return (
        <RbgGrid container sx={{ pt: 1 }}>
            <RbgGrid item xs={12}>
                <StaffMemberAutocomplete onChange={(e) => setStaffId(e.target.value)} label="Staff Member" />
            </RbgGrid>
            {isFetched && (
                <RbgGrid item xs={12}>
                    <iframe srcDoc={data.data.template} style={{ width: '100%', height: '400px', border: 'none' }} title="Preview" />
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

StaffAdminEmailPreview.propTypes = {
    email: PropTypes.object.isRequired
};
export default StaffAdminEmailPreview;
