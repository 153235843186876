import { staffDocument } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';

function DocumentHistory({ document }) {
    const [page, setPage] = useState(1);

    const { data } = useQuery({
        queryKey: [{ url: `/staff/staff-member/${document.staff_id}/document/${document.id}/history`, params: { page } }]
    });

    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <PageableTable
                    headers={['Date', 'Name', 'Entry']}
                    pagination={data.meta.pagination}
                    data={data.data}
                    rowDecorator={(row) => [row.date, row.user_name, row.entry]}
                    handlePageChange={(page) => setPage(page)}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

DocumentHistory.propTypes = {
    document: PropTypes.shape(staffDocument).isRequired
};
export default DocumentHistory;
