import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function NewStarterApprovalReject({ newStarterId, refetch }) {
    const [reason, setReason] = useState('');

    const rejectMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${newStarterId}/reject`, { reason });
        },
        onSuccess: () => {
            refetch();
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        DialogHelper.addSaveButton(() => rejectMutation.mutate(), 'Reject', rejectMutation.isPending);
    }, [rejectMutation]);
    return (
        <RbgGrid container>
            {rejectMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{rejectMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <InfoCard>Enter the reason for the rejection below</InfoCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput name="reason" value={reason} onChange={(e) => setReason(e.target.value)} label="Reason for the rejection" />
            </RbgGrid>
        </RbgGrid>
    );
}

NewStarterApprovalReject.propTypes = {
    newStarterId: PropTypes.number.isRequired,
    refetch: PropTypes.func.isRequired
};
export default NewStarterApprovalReject;
