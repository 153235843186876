import PropTypes from 'prop-types';
import { staffDocumentType } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgIconsAutocomplete from '../../../../../ui_component/mui-wrappers/Icon/RbgIconsAutocomplete';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';

function HrConfigEditCategory({ currentCategory }) {
    const [category, setCategory] = useState(
        currentCategory || {
            hierarchy_level: 1,
            icon: '',
            name: '',
            parent_category: ''
        }
    );

    const { data: parents } = useQuery({
        queryKey: [{ url: '/staff/documents/categories' }]
    });

    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation(
                currentCategory ? 'PATCH' : 'POST',
                currentCategory ? `/staff/documents/category/${currentCategory.id}` : '/staff/documents/category',
                category
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/documents/categories' }]
            });
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        let disabled = mutation.isPending;
        if (!category.icon) {
            disabled = true;
        }
        if (!category.name) {
            disabled = true;
        }
        if (!category.hierarchy_level) {
            disabled = true;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', disabled);
    }, [category, mutation]);
    return (
        <RbgGrid container>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput
                    label="Name"
                    name="name"
                    value={category.name}
                    onChange={(e) => setCategory((prev) => ({ ...prev, name: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgIconsAutocomplete
                    onChange={(e) => setCategory((prev) => ({ ...prev, icon: e.target.value }))}
                    value={category.icon}
                    label="Icon"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    endpoint="/staff/hierarchy-levels"
                    name="min_hierarchy_level"
                    filterData={(data) => {
                        return data.data.map((row) => ({ value: row.level, label: row.name }));
                    }}
                    handleChange={(e) => setCategory((prev) => ({ ...prev, hierarchy_level: e.target.value }))}
                    value={category.hierarchy_level}
                    label="Hierachy Level"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSelect
                    name="parent_category"
                    label="Parent Category"
                    value={category.parent_category}
                    values={parents.data
                        .filter((cat) => cat.id !== category.id)
                        .map((cat) => ({
                            value: cat.id,
                            label: cat.name
                        }))}
                    handleChange={(e) => setCategory((prev) => ({ ...prev, parent_category: e.target.value }))}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

HrConfigEditCategory.propTypes = {
    currentCategory: PropTypes.shape(staffDocumentType)
};
export default HrConfigEditCategory;
