import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import HrConfigEditDocumentType from './HrConfigEditDocumentType';

function HrConfigDocumentTypes() {
    const { data, isFetching } = useQuery({
        queryKey: [{ url: '/staff/documents/types' }]
    });
    const editDoc = (doc) => {
        DialogHelper.openSmDialog(doc ? 'Edit Document Type' : 'Create Document Type', <HrConfigEditDocumentType documentType={doc} />);
    };
    if (isFetching) {
        return <CardLoader />;
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    stickyHeader
                    tableContainerSx={{ maxHeight: '700px' }}
                    headers={['#', 'Name', 'Category', <RbgButton onClick={() => editDoc()} icon={<IconPlus size={20} />} />]}
                    keys={data.data.map((row) => row.id)}
                    rows={data.data.map((row) => [
                        row.id,
                        row.name,
                        row.category.data.name,
                        <RbgButton onClick={() => editDoc(row)} icon={<IconEdit size={20} />} />
                    ])}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

export default HrConfigDocumentTypes;
