import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import MainCard from '../../../../../ui_component/cards/MainCard';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';

function S4Differences() {
    const [filter, setFilter] = useState({
        page: 1,
        type_id: null
    });
    const { data, error } = useQuery({
        initialData: {
            data: [],
            meta: { pagination: {}, types: [] }
        },
        queryKey: [{ url: '/staff/s4/differences', params: filter }]
    });
    return (
        <MainCard title="S4 Differences">
            <RbgGrid container>
                {error && (
                    <RbgGrid item xs={12}>
                        <ErrorCard>{error}</ErrorCard>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgSelect
                        values={data.meta.types}
                        label="Change Type"
                        name="type_id"
                        value={filter.type_id ?? ''}
                        handleChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <PageableTable
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                        data={data.data}
                        pagination={data.meta.pagination}
                        headers={['ID', 'Type', 'Team Member', 'S4 Employee', 'Location', 'Previous Value', 'New Value', 'Additional Data']}
                        rowDecorator={(row) => {
                            let previousValue = row.previous_value;
                            let newValue = row.new_value;
                            if (row.type.data.id === 1) {
                                previousValue = currencyFormatter({ value: row.previous_value });
                                newValue = currencyFormatter({ value: row.new_value });
                            }
                            const keys = Object.keys(row.additional_data);
                            return [
                                row.id,
                                row.type.data.name,
                                <a href={`/staff/view/${row.staff_id}`}>
                                    {row.staff_name} ({row.staff_id})
                                </a>,
                                row.employee_name,
                                row.location_name,
                                previousValue,
                                newValue,
                                <RbgStack>
                                    {keys.map((key) => {
                                        return (
                                            <RbgTypography key={key}>
                                                <strong>{key}:</strong> {row.additional_data[key]}
                                            </RbgTypography>
                                        );
                                    })}
                                </RbgStack>
                            ];
                        }}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default S4Differences;
