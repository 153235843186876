import PropTypes from 'prop-types';
// mui imports
import RbgButton from '../mui-wrappers/Button/RbgButton';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
// project imports
import { iconSize } from 'store/constant';

function ActionButtons({ buttons, direction = 'row', flexWrap = 'wrap', justifyContent, alignItems, spacing, gap = 2 }) {
    return (
        <RbgStack
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
            spacing={spacing}
            gap={gap}
            flexWrap={flexWrap}
        >
            {buttons.map(({ sx, color, variant, Icon, onClick, content, tooltip, disabled }, idx) => {
                if (Icon) {
                    return (
                        <RbgButton
                            key={idx}
                            color={color}
                            variant={variant}
                            sx={sx}
                            onClick={onClick}
                            icon={<Icon size={iconSize} />}
                            tooltip={tooltip}
                            disabled={disabled}
                        />
                    );
                }
                return (
                    <RbgButton key={idx} color={color} variant={variant} sx={sx} onClick={onClick} tooltip={tooltip} disabled={disabled}>
                        {content}
                    </RbgButton>
                );
            })}
        </RbgStack>
    );
}

export default ActionButtons;

ActionButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    direction: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    spacing: PropTypes.number,
    flexWrap: PropTypes.string,
    gap: PropTypes.number
};
