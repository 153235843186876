import PropTypes from 'prop-types';
// mui imports
import { Alert } from '@mui/material';

function WarningCard({ variant = 'outlined', styles, children, ...others }) {
    if (children instanceof Error) {
        if (Array.isArray(children.fields)) {
            let message = `${children.toString()} - `;
            message += children.fields
                .map((field) => {
                    return field.error;
                })
                .join(', ');
            children = message;
        } else {
            children = children.toString();
        }
    }
    return (
        <Alert severity="warning" variant={variant} {...others}>
            {children}
        </Alert>
    );
}

export default WarningCard;

WarningCard.propTypes = {
    variant: PropTypes.string,
    styles: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string])
};
