import PropTypes from 'prop-types';
// mui imports
import { TableRow, useTheme } from '@mui/material';
// 3rd party

/**
 * Will automatically set dynamic row height
 * based on the material table element parent (head, body, etc).
 *
 * Demos:
 *
 * - [Table](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TableRow API](https://mui.com/material-ui/api/table-row/)
 */

function RbgTableRow({ sx = {}, striped, children, ...others }) {
    const theme = useTheme();
    const stripedRowStyles = {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.mode === 'light' ? '#0001' : '#fff1'
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.paper
        }
    };
    return (
        <TableRow sx={striped ? { ...stripedRowStyles, ...sx } : sx} {...others}>
            {children}
        </TableRow>
    );
}

export default RbgTableRow;

RbgTableRow.propTypes = {
    sx: PropTypes.object,
    striped: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
