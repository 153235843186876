import PropTypes from 'prop-types';
import { useRef, useEffect, forwardRef } from 'react';
// material-ui imports
import { Popper, ClickAwayListener, useTheme, Paper } from '@mui/material';
// third party imports
import PerfectScrollbar from 'react-perfect-scrollbar';
// project imports
import Transitions from 'ui_component/extended/Transitions';
import overflow from 'utils/overflow';

function PopperContent({ handleClose, perfectScrollbar, scrollbarStyles, appBarStyles, outlined, children }) {
    const theme = useTheme();
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Paper
                variant={outlined ? 'outlined' : 'elevation'}
                sx={{
                    [theme.breakpoints.down('md')]: {
                        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
                    },
                    width: '100%',
                    ...appBarStyles
                }}
            >
                {perfectScrollbar ? <PerfectScrollbar style={scrollbarStyles}>{children}</PerfectScrollbar> : children}
            </Paper>
        </ClickAwayListener>
    );
}

const RbgPopper = forwardRef(
    (
        {
            open,
            setOpen,
            popperStyles,
            popperOptions,
            popperPlacement = 'top-start',
            transitionType = 'zoom',
            transitionPosition = 'top-right',
            transitionDirection = 'right',
            perfectScrollbar = false,
            scrollbarStyles,
            children,
            bodyOverflowHidden,
            appBarStyles,
            outlined = true,
            disablePortal = true,
            ...others
        },
        ref
    ) => {
        const prevOpen = useRef(open);
        const handleClose = (event) => {
            if (event.target.localName === 'body') {
                return;
            }

            if (ref?.current && ref.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        };

        useEffect(() => {
            if (prevOpen.current === true && open === false) {
                ref?.current.focus();
            }

            prevOpen.current = open;
            if (bodyOverflowHidden) overflow(open);
        }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

        // cleanup
        useEffect(() => () => setOpen(false), [setOpen]);

        return (
            <Popper
                placement={popperPlacement}
                open={open}
                anchorEl={ref?.current}
                role={undefined}
                transition
                disablePortal={disablePortal}
                style={popperStyles}
                popperOptions={popperOptions}
                {...others}
            >
                {/* throws error if Tabs are wrapped in Transitions */}
                {transitionType === 'none' ? (
                    <PopperContent
                        handleClose={handleClose}
                        perfectScrollbar={perfectScrollbar}
                        scrollbarStyles={scrollbarStyles}
                        appBarStyles={appBarStyles}
                        outlined={outlined}
                    >
                        {children}
                    </PopperContent>
                ) : (
                    ({ TransitionProps }) => (
                        <Transitions
                            in={open}
                            type={transitionType}
                            position={transitionPosition}
                            direction={transitionDirection}
                            {...TransitionProps}
                        >
                            <PopperContent
                                handleClose={handleClose}
                                perfectScrollbar={perfectScrollbar}
                                scrollbarStyles={scrollbarStyles}
                                appBarStyles={appBarStyles}
                                outlined={outlined}
                            >
                                {children}
                            </PopperContent>
                        </Transitions>
                    )
                )}
            </Popper>
        );
    }
);

export default RbgPopper;

RbgPopper.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    popperStyles: PropTypes.object,
    popperOptions: PropTypes.object,
    perfectScrollbar: PropTypes.bool,
    scrollbarStyles: PropTypes.object,
    children: PropTypes.node,
    bodyOverflowHidden: PropTypes.bool,
    appBarStyles: PropTypes.object,
    outlined: PropTypes.bool,
    disablePortal: PropTypes.bool,
    transitionType: PropTypes.oneOf(['grow', 'fade', 'collapse', 'slide', 'zoom', 'none']),
    transitionDirection: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    transitionPosition: PropTypes.oneOf(['top-right', 'top', 'bottom-left', 'bottom-right', 'bottom', 'top-left']),
    popperPlacement: PropTypes.oneOf([
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
        'left-start',
        'left',
        'left-end'
    ])
};

PopperContent.propTypes = {
    handleClose: PropTypes.func,
    perfectScrollbar: PropTypes.bool,
    scrollbarStyles: PropTypes.object,
    children: PropTypes.node,
    appBarStyles: PropTypes.object,
    outlined: PropTypes.bool
};

// popper options
// {
//     modifiers: [
//         {
//             name: 'offset',
//             options: {
//                 offset: [0, 20]
//             }
//         }
//     ]
// }
