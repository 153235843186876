import { staffDetails } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import { useQuery } from '@tanstack/react-query';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import MainCard from '../../../../../ui_component/cards/MainCard';
import { IconPlus } from '@tabler/icons-react';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EditSuspension from './EditSuspension';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';

function Suspensions({ staffDetails }) {
    const { data, isFetching } = useQuery({
        queryKey: [{ url: `/staff/staff-member/${staffDetails.staff_id}/suspensions` }, 'staff_suspensions']
    });
    return (
        <MainCard
            title="Suspensions"
            secondary={
                <RbgButton
                    onClick={() => {
                        DialogHelper.openMdDialog('Add Suspension', <EditSuspension staffDetails={staffDetails} />);
                    }}
                >
                    <IconPlus /> Add Suspension
                </RbgButton>
            }
        >
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={['Type', 'Reason', 'Date', 'Created By', 'Active']}
                        keys={data.data.map((row) => row.id)}
                        rows={data.data.map((row) => {
                            return [
                                <Link
                                    to="#"
                                    onClick={() => {
                                        DialogHelper.openMdDialog(
                                            'Edit Suspension',
                                            <EditSuspension staffDetails={staffDetails} suspension={row} />
                                        );
                                    }}
                                >
                                    {row.type.data.name}
                                </Link>,
                                row.reason.data.name,
                                dayjs(row.date_created).format('DD/MM/YYYY'),
                                row.created_by_name,
                                <BooleanIcon value={row.active} />
                            ];
                        })}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

Suspensions.propTypes = {
    staffDetails: PropTypes.shape(staffDetails).isRequired
};
export default Suspensions;
