import { staffMember } from '../../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../../store/constant';
import IntroCardPrimary from '../../../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import DateFormatter from '../../../../../../utils/dateFormatter';
import dayjs from 'dayjs';
import ActionButtons from '../../../../../../ui_component/buttons/ActionButtons';
import MainCard from '../../../../../../ui_component/cards/MainCard';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';
import MarkAsLeaver from '../MarkAsLeaver';
import ViewRightToWorkInformation from '../ViewRightToWorkInformation';
import ExtendProbation from '../ExtendProbation';
import CancelLeaver from '../CancelLeaver';
import ExtendLeaver from '../ExtendLeaver';
import ReInstate from '../ReInstate';
import PreviousEmployment from '../PreviousEmployment';
import RbgStack from '../../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import CancelTransfer from '../CancelTransfer';
import AddTempTransfer from '../AddTempTransfer';
import IntroCardSecondary from '../../../../../../ui_component/cards/IntroCardSecondary';
import StaffMemberRightToWork from '../RightToWork/StaffMemberRightToWork';
import NewRoleWizard from '../NewRole/NewRoleWizard';
import ChangeEmploymentStartDate from '../ChangeEmploymentStartDate';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../../contexts/RcloudAuthContext';

function EmploymentWidget({ staffMember }) {
    const details = staffMember.details.data;
    const { user } = useContext(RcloudAuthContext);

    const hasPreviousEmployment = staffMember.all_employment.data.length > 1;
    const transferStaffMember = () => {
        DialogHelper.openMdDialog(
            'Transfer staff member',
            <NewRoleWizard staffMember={staffMember} initialData={{ same_location: false }} />
        );
    };
    const markAsLeaver = () => {
        DialogHelper.openMdDialog('Mark as leaver', <MarkAsLeaver staffMember={staffMember} />);
    };

    const cancelTransfer = () => {
        DialogHelper.openMdDialog('Cancel transfer', <CancelTransfer staffMember={staffMember} />);
    };

    const viewRightToWorkInfo = () => {
        DialogHelper.openMdDialog(
            'View Right to work information',
            <ViewRightToWorkInformation rightToWorkInfo={staffMember.employment.data.right_to_work.data} />
        );
    };
    const extendsProbation = () => {
        DialogHelper.openMdDialog(
            staffMember.employment.data.probation_end_date ? 'Extend Probation' : 'Add Probation End Date',
            <ExtendProbation staffEmployment={staffMember.employment.data} />
        );
    };

    const employmentButtons = [];
    const actionButtons = [];

    const probationEnd = staffMember.employment.data.probation_end_date ? dayjs(staffMember.employment.data.probation_end_date) : null;
    const futureRoles = staffMember.all_roles.data.filter((role) => dayjs(role.start_date).isAfter(dayjs()));
    const transferRoles = futureRoles.filter((role) => role.cost_centre !== staffMember.role.data.cost_centre);
    if (user.permissions.indexOf('hr_staff_edit') > -1) {
        if (!staffMember.leave_date) {
            if (futureRoles.length === 0) {
                actionButtons.push({ content: 'Transfer to site', onClick: () => transferStaffMember() });
            }
            if (transferRoles.length > 0) {
                actionButtons.push({ content: 'Cancel transfer', onClick: () => cancelTransfer() });
            }
            actionButtons.push({ content: 'Mark as leaver', onClick: () => markAsLeaver() });
            if (probationEnd && probationEnd.isAfter(dayjs())) {
                actionButtons.push({ content: 'Extend Probation', onClick: () => extendsProbation() });
            }
            if (!probationEnd) {
                actionButtons.push({ content: 'Add Probation End Date', onClick: () => extendsProbation() });
            }
        } else if (dayjs(staffMember.leave_date).isAfter(dayjs())) {
            actionButtons.push({
                content: 'Cancel Leaver Status',
                onClick: () => {
                    DialogHelper.openSmDialog(
                        `Cancel leaver status for ${staffMember.details.data.display_name}`,
                        <CancelLeaver staffMember={staffMember} />
                    );
                }
            });
            actionButtons.push({
                content: 'Extend Leave Date',
                onClick: () => {
                    DialogHelper.openSmDialog(
                        `Extend Leave Date for ${staffMember.details.data.display_name}`,
                        <ExtendLeaver staffMember={staffMember} />
                    );
                }
            });
        } else {
            actionButtons.push({
                content: `Reinstate ${staffMember.details.data.display_name}`,
                onClick: () => {
                    DialogHelper.openLgDialog(
                        `Reinstate ${staffMember.details.data.display_name}`,
                        <ReInstate staffMember={staffMember} />
                    );
                }
            });
        }
        actionButtons.push({
            content: 'Add Temp Transfer',
            onClick: () => {
                DialogHelper.openSmDialog(
                    `Temp Transfer ${staffMember.details.data.display_name}`,
                    <AddTempTransfer staffMember={staffMember} />
                );
            }
        });
    }
    if (hasPreviousEmployment) {
        actionButtons.push({
            content: 'View Employment History',
            onClick: () => {
                DialogHelper.openLgDialog(
                    `Employment History for ${staffMember.details.data.display_name}`,
                    <PreviousEmployment employment={staffMember.all_employment.data} />
                );
            }
        });
    }
    if (user.permissions.indexOf('hr_config') > -1) {
        actionButtons.push({
            content: 'Change Start Date',
            onClick: () => {
                DialogHelper.openSmDialog('Change Employment Start Date', <ChangeEmploymentStartDate staffMember={staffMember} />);
            }
        });
    }
    employmentButtons.push({
        variant: 'outlined',
        color: 'info',
        content: 'View Right To Work',
        onClick: () => {
            DialogHelper.openMdDialog('View Right to work documentation', <StaffMemberRightToWork staffMember={staffMember} />);
        }
    });

    return (
        <MainCard
            title="Employment"
            actionButtons={{
                label: 'Actions',
                items: actionButtons
            }}
        >
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <IntroCardPrimary>
                        <RbgStack gap={2}>
                            <RbgTypography>
                                {staffMember.display_forename}&apos;s current employment started on{' '}
                                <strong>
                                    <DateFormatter date={staffMember.employment.data.start_date} />.
                                </strong>
                            </RbgTypography>

                            {!probationEnd && <RbgTypography>The current role does not have probation end date</RbgTypography>}
                            {probationEnd && (
                                <RbgTypography>
                                    {probationEnd.isBefore(dayjs()) && (
                                        <>
                                            Their probation period ended on <strong>{probationEnd.format('DD/MM/YYYY')}</strong>
                                        </>
                                    )}
                                    {probationEnd.isAfter(dayjs()) && (
                                        <>
                                            Their probation period ends on{' '}
                                            <strong>
                                                {probationEnd.format('DD/MM/YYYY')} ({probationEnd.fromNow()})
                                            </strong>
                                        </>
                                    )}
                                </RbgTypography>
                            )}
                            {transferRoles.length > 0 && (
                                <RbgTypography>
                                    {staffMember.display_forename} is due to be transferred to{' '}
                                    <strong>{futureRoles[0].location_name}</strong> on{' '}
                                    <strong>{dayjs(futureRoles[0].start_date).format('DD/MM/YYYY')}</strong>
                                </RbgTypography>
                            )}
                        </RbgStack>
                    </IntroCardPrimary>
                </RbgGrid>
                {staffMember.temp_transfers.data.length > 0 && (
                    <RbgGrid item xs={12}>
                        <IntroCardSecondary>
                            {staffMember.temp_transfers.data.map((transfer) => {
                                if (dayjs(transfer.start_date).isAfter(dayjs())) {
                                    return (
                                        <RbgTypography key={transfer.id}>
                                            {staffMember.display_forename} will be temp transferred to {transfer.to_name} from{' '}
                                            <strong>{dayjs(transfer.start_date).format('DD/MM/YYYY')}</strong> till{' '}
                                            <strong>{dayjs(transfer.end_date).format('DD/MM/YYYY')}</strong>
                                        </RbgTypography>
                                    );
                                }
                                return (
                                    <RbgTypography key={transfer.id}>
                                        {staffMember.display_forename} is currently temp transferred to <strong>{transfer.to_name}</strong>{' '}
                                        until {dayjs(transfer.end_date).format('DD/MM/YYYY')}
                                    </RbgTypography>
                                );
                            })}
                        </IntroCardSecondary>
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <ActionButtons buttons={employmentButtons} gap={1} spacing={0} />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

EmploymentWidget.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default EmploymentWidget;
