import { styled, useMediaQuery } from '@mui/material';
import { TabList } from '@mui/lab';

const TabListStyled = styled(TabList)(({ theme }) => ({
    '.MuiTabs-flexContainer': {
        borderBottom: useMediaQuery(theme.breakpoints.down('sm')) ? '1px solid #eee' : 'none',
        maxHeight: '500px'
    },
    '.MuiTabs-scrollButtons.Mui-disabled': {
        opacity: 0.2
    }
}));

export default TabListStyled;
