import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// project imports
import CircularLoading from 'ui_component/loading/CircularLoading';
import StaffOverview from './StaffOverview/StaffOverview';
import StaffDetails from './StaffDetails/StaffDetails';
import StaffTraining from './StaffTraining/StaffTraining';
import StaffHistory from './StaffHistory/StaffHistory';
import StaffEmployment from './StaffEmployment/StaffEmployment';
import EventDispatcher from '../../../../utils/EventDispatcher';
import MainCard from '../../../../ui_component/cards/MainCard';
import Absences from './Absences/Absences';
import Disciplinaries from './Disciplinary/Disciplinaries';
import { useMediaQuery, useTheme } from '@mui/material';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import StaticBreadcrumb from '../../../../ui_component/extended/StaticBreadcrumb';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../store/constant';
import dayjs from 'dayjs';
import WarningCard from '../../../../ui_component/error/WarningCard';
import SupportCentre from './SupportCentre/SupportCentre';
import Suspensions from './Suspensions/Suspensions';
import StaffDocuments from './Documents/StaffDocuments';
import { useQuery } from '@tanstack/react-query';
import ExternalIdentifiers from './ExternalIdentifiers/ExternalIdentifiers';
import StaffOnboarding from './Onboarding/StaffOnboarding';
import StaffSystemLog from './SystemLog/StaffSystemLog';
import RcloudAuthContext from '../../../../contexts/RcloudAuthContext';

function StaffMember() {
    // redux
    const { user } = useContext(RcloudAuthContext);

    // local state
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(window.location.hash ? window.location.hash.substring(1) : 'overview');

    const theme = useTheme();
    const matchDown400 = useMediaQuery(theme.breakpoints.down(400));

    const {
        data,
        isFetched,
        refetch,
        error,
        isFetching,
        isFetchedAfterMount: initialLoad
    } = useQuery({
        queryKey: [
            {
                url: `/staff/get/${params.staff_id}`,
                params: {
                    include:
                        'employment.new_starter_record,all_employment.new_starter_record,employment.documentation,employment.right_to_work,employment.right_to_work.history'
                }
            },
            `staff_member_${params.staff_id}`
        ]
    });
    const staffMember = data.data;

    useEffect(() => {
        const id = EventDispatcher.subscribe('reload-staff-member', (id) => {
            if (id === staffMember.id) {
                refetch();
            }
        });
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [staffMember, refetch]);

    useEffect(() => {
        if (staffMember.id) {
            EventDispatcher.dispatch('pusher-subscribe', `private-staffmember-${staffMember.id}`);
        }
    }, [staffMember]);

    useEffect(() => {
        const listener = EventDispatcher.subscribe('pusher-message', (event) => {
            if (event.event === 'staff_member_updated') {
                if (event.data.staff_id === staffMember.id) {
                    refetch();
                }
            }
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
        };
    }, [staffMember, refetch]);

    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    if (isFetched === false || isFetching === true || initialLoad === false) {
        return <CircularLoading />;
    }
    const tabs = [];
    tabs.push({
        loading,
        label: 'Overview',
        value: 'overview',
        content: <StaffOverview staffMember={staffMember} />
    });
    if (user.permissions.indexOf('hr_staff_personal_details') > -1) {
        tabs.push({
            loading,
            label: 'Personal Details',
            value: 'details',
            content: <StaffDetails staffDetails={staffMember.details.data} />
        });
    }
    tabs.push({
        loading,
        label: 'Employment Details',
        value: 'employment',
        content: <StaffEmployment staffMember={staffMember} />
    });
    if (user.permissions.indexOf('hr_staff_training_view') > -1) {
        tabs.push({
            loading,
            label: 'Training & Certifications',
            value: 'training',
            content: <StaffTraining staffMember={staffMember} />
        });
    }
    if (user.permissions.indexOf('hr_staff_document_view') > -1) {
        tabs.push({
            loading,
            label: 'Documents',
            value: 'documents',
            content: <StaffDocuments staffMember={staffMember} />
        });
    }
    if (user.permissions.indexOf('hr_staff_absence_view') > -1 && !staffMember.new_starter) {
        tabs.push({
            loading,
            label: 'Absences',
            value: 'absences',
            content: <Absences staffMember={staffMember} />
        });
    }
    if (user.permissions.indexOf('hr_disciplinary_view') > -1 && !staffMember.new_starter) {
        tabs.push({
            loading,
            label: 'Disciplinaries',
            value: 'disciplinary',
            content: <Disciplinaries staffMember={staffMember} />
        });
    }
    if (user.permissions.indexOf('hr_staff_suspend') > -1 && !staffMember.new_starter) {
        tabs.push({
            loading,
            label: 'Suspensions',
            value: 'suspension',
            content: <Suspensions staffDetails={staffMember.details.data} />
        });
    }
    if (staffMember.role.data.central === true) {
        tabs.push({
            loading,
            label: 'Support Centre',
            value: 'support_centre',
            content: <SupportCentre details={staffMember.details.data} role={staffMember.role.data} />
        });
    }
    tabs.push({
        loading,
        label: 'Accounts',
        value: 'external_ids',
        content: <ExternalIdentifiers staffMember={staffMember} />
    });
    tabs.push({
        loading,
        label: 'History',
        value: 'history',
        content: <StaffHistory staffMember={staffMember} />
    });
    if (user.permissions.indexOf('hr_config') > -1) {
        tabs.push({
            loading,
            label: 'Onboarding',
            value: 'onboarding',
            content: <StaffOnboarding staffMember={staffMember} />
        });
        tabs.push({
            loading,
            label: 'System Log',
            value: 'system_log',
            content: <StaffSystemLog id={staffMember.id} />
        });
    }

    const allEmployment = staffMember.all_employment.data;
    const lastEmployment = staffMember.employment.data.id ? staffMember.employment.data : allEmployment[allEmployment.length - 1];
    const isLeaver = lastEmployment && lastEmployment.leave_date && dayjs(lastEmployment.leave_date).isBefore(dayjs());
    const futureLeaver = lastEmployment && staffMember.employment.data && dayjs(lastEmployment.leave_date).isAfter(dayjs());
    return (
        <>
            <StaticBreadcrumb
                items={[
                    { label: 'Team' },
                    { link: `/staff`, label: staffMember.details.data.location_name },
                    { link: ``, label: staffMember.details.data.display_name }
                ]}
            />
            <MainCard
                sx={{ width: '100%', height: '100%' }}
                contentStyles={{
                    height: '100%'
                }}
            >
                <RbgGrid container spacing={gridSpacing}>
                    {staffMember.actions.data
                        .filter((action) => action.severity_id === 3)
                        .map((action) => {
                            return (
                                <RbgGrid item xs={12} key={action.id}>
                                    <ErrorCard>{action.description_rendered}</ErrorCard>
                                </RbgGrid>
                            );
                        })}
                    {isLeaver && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>
                                {staffMember.details.data.display_name} left RBG on {dayjs(lastEmployment.leave_date).format('DD/MM/YYYY')}
                            </ErrorCard>
                        </RbgGrid>
                    )}
                    {staffMember.suspended === true && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{staffMember.details.data.display_name} is currently suspended</ErrorCard>
                        </RbgGrid>
                    )}
                    {futureLeaver && (
                        <RbgGrid item xs={12}>
                            <WarningCard>
                                {staffMember.details.data.display_name} is due to leave RBG on{' '}
                                {dayjs(lastEmployment.leave_date).format('DD/MM/YYYY')}
                            </WarningCard>
                        </RbgGrid>
                    )}
                    {staffMember.employment.data.onboard_complete === false && (
                        <RbgGrid item xs={12}>
                            <ErrorCard>{staffMember.details.data.display_name} has not yet completed their onboarding process</ErrorCard>
                        </RbgGrid>
                    )}
                    <RbgGrid item xs={12}>
                        <RbgTabs
                            tabStyles={{ maxHeight: '900px' }}
                            tabs={tabs}
                            orientation="vertical"
                            currentTab={tab}
                            setCurrentTab={(tab) => {
                                setTab(tab);
                            }}
                            popperStyles={{
                                maxWidth: matchDown400 ? '175px' : '250px',
                                position: 'absolute',
                                left: '58px'
                            }}
                        />
                    </RbgGrid>
                </RbgGrid>
            </MainCard>
        </>
    );
}

export default StaffMember;
