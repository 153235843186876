import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddEditField from './AddEditField';
import MainCard from '../../../../../ui_component/cards/MainCard';

function OnboardFields() {
    const [filter, setFilter] = useState({
        page: 1,
        search: '',
        type: ''
    });
    const { data, isPending } = useQuery({
        queryKey: [{ url: '/staff/admin/onboard-fields', params: filter }, 'onboard_fields']
    });
    if (isPending) {
        return <CardLoader />;
    }
    const editField = (field) => {
        DialogHelper.openMdDialog(field ? 'Edit Field' : 'Add Field', <AddEditField field={field} />);
    };
    return (
        <MainCard title="Onboarding Fields">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgSimpleTable
                        headers={[
                            'ID',
                            'Field Name',
                            'Type',
                            'New Starter Field',
                            <RbgButton onClick={() => editField()} icon={<IconPlus />} />
                        ]}
                        keys={data.data.map((row) => row.id)}
                        rows={data.data.map((row) => [
                            row.id,
                            row.name,
                            row.type,
                            row.new_starter_field,
                            <RbgButton onClick={() => editField(row)}>Edit</RbgButton>
                        ])}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}
export default OnboardFields;
