import { lazy } from 'react';
// project imports
import config from '../config';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui_component/loading/Loadable';
import NavigationScroll from 'layout/NavigationScroll';
// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
const ErrorBoundary = Loadable(lazy(() => import('ui_component/error/ErrorBoundary/ErrorBoundary')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    basename: config.basename,
    path: '/',
    element: (
        <ErrorBoundary hideHomeButton>
            <NavMotion>
                <GuestGuard>
                    <NavigationScroll>
                        <MinimalLayout />
                    </NavigationScroll>
                </GuestGuard>
            </NavMotion>
        </ErrorBoundary>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        }
    ]
};

export default LoginRoutes;
