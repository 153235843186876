import { SvgIcon } from '@mui/material';

function MhfaIcon(props) {
    const styles = {
        st0: { fill: '#64AC30' },
        st1: { fill: '#287431' },
        st2: { fill: '#1D1E1C' }
    };
    return (
        <SvgIcon {...props} viewBox="0 0 500 330">
            <path
                style={styles.st0}
                d="m257.4,303.5c1.4,-0.9 2.6,-2.2 3.6,-3.6c4.1,-6.2 2.4,-14.5 -3.8,-18.6c-2.5,-1.7 -5,-3.5 -7.4,-5.3c34.3,-26.2 54.6,-66.8 54.6,-110.3c0,-41.3 -18.2,-78.5 -46.9,-103.9c-2.5,-2.2 -5,-4.3 -7.6,-6.3c7.5,-5.8 15.7,-10.8 24.3,-14.9c2.9,2.5 5.8,5.2 8.5,7.9c30.1,30 48.7,71.5 48.7,117.2c-0.2,55.4 -27.8,107 -74,137.8m-12.8,1.3c-0.9,-0.4 -1.7,-0.8 -2.6,-1.4c0.1,0.1 0.2,0.1 0.3,0.2c0.7,0.5 1.5,0.9 2.3,1.2m5.2,1l0,0c2,0 4,-0.5 5.8,-1.3c-1.9,0.9 -3.9,1.3 -5.8,1.3m-236.3,25.4c-7.4,0 -13.5,-6 -13.5,-13.5l0,-152.1c0,-91.3 74.3,-165.6 165.6,-165.6c25.7,0 50,5.9 71.7,16.3c4.2,2.1 8.4,4.3 12.4,6.6c-8.6,5.1 -16.7,11 -24.3,17.5c-4.1,-2 -8.3,-3.7 -12.6,-5.3c-14.8,-5.4 -30.7,-8.3 -47.3,-8.3c-76.3,0.1 -138.6,62.3 -138.6,138.8l0,152.1c0,7.5 -6,13.5 -13.4,13.5"
                id="svg_2"
            />
            <path
                style={styles.st0}
                d="m242,303.4c-46.2,-30.8 -73.7,-82.3 -73.7,-137.8c0,-49.9 22.2,-94.7 57.2,-125.1c8.6,4.1 16.7,9.1 24.3,14.9c-33.2,25.4 -54.6,65.3 -54.6,110.2c0,43.5 20.3,84.1 54.6,110.3c-2.4,1.8 -4.9,3.6 -7.4,5.3c-6.2,4.1 -7.9,12.5 -3.8,18.6c0.9,1.5 2.1,2.7 3.4,3.6m13.6,1.1c0.6,-0.3 1.1,-0.6 1.6,-0.9c0.1,0 0.1,-0.1 0.2,-0.1c-0.6,0.4 -1.2,0.7 -1.8,1m-5.9,1.3c-1.7,0 -3.5,-0.3 -5.2,-1c1.7,0.7 3.5,1 5.2,1l0,0m236.3,25.4c-7.4,0 -13.4,-6 -13.4,-13.5l0,-152.1c0,-76.5 -62.2,-138.7 -138.7,-138.7c-21.4,0 -41.7,4.9 -59.9,13.6c-7.5,-6.5 -15.6,-12.4 -24.3,-17.5c24.7,-14.6 53.4,-23 84.1,-23c91.3,0 165.6,74.3 165.6,165.6l0,152.1c0.1,7.5 -5.9,13.5 -13.4,13.5"
                id="svg_3"
            />
            <path
                style={styles.st1}
                d="m249.8,55.4c-7.5,-5.8 -15.6,-10.8 -24.3,-14.9c7.5,-6.5 15.6,-12.4 24.3,-17.5c8.6,5.1 16.7,11 24.3,17.5c-8.7,4.1 -16.8,9.1 -24.3,14.9m0,250.4c-1.8,0 -3.6,-0.4 -5.2,-1c-0.8,-0.3 -1.5,-0.7 -2.2,-1.2c-0.1,-0.1 -0.2,-0.1 -0.3,-0.2c-1.3,-0.9 -2.5,-2.1 -3.4,-3.6c-4.1,-6.2 -2.4,-14.5 3.7,-18.6c2.6,-1.7 5,-3.5 7.4,-5.3c2.4,1.8 4.9,3.6 7.4,5.3c6.2,4.1 7.9,12.5 3.7,18.6c-1,1.5 -2.2,2.7 -3.6,3.6c-0.1,0 -0.1,0.1 -0.2,0.1c-0.5,0.3 -1.1,0.7 -1.6,0.9c-1.7,1 -3.7,1.4 -5.7,1.4"
                id="svg_4"
            />
        </SvgIcon>
    );
}

export default MhfaIcon;
