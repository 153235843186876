import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Avatar, useTheme } from '@mui/material';
import RbgButton from '../mui-wrappers/Button/RbgButton';
// project
import { iconSize } from '../../store/constant';

const AvatarButton = forwardRef(({ Icon, onClick, buttonType, disableRipple, avatarStyles }, ref) => {
    const theme = useTheme();
    return (
        <RbgButton type={buttonType} variant="text" disableRipple={disableRipple}>
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    ...avatarStyles
                }}
                ref={ref}
                onClick={onClick}
            >
                <Icon stroke={1.5} size={iconSize} />
            </Avatar>
        </RbgButton>
    );
});

export default AvatarButton;

AvatarButton.propTypes = {
    Icon: PropTypes.object,
    onClick: PropTypes.func,
    buttonType: PropTypes.string,
    disableRipple: PropTypes.bool,
    avatarStyles: PropTypes.object
};
