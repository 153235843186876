import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { TreeItem, TreeView } from '@mui/lab';
import RbgIcon from '../../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import { filesize } from 'filesize';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconDownload, IconEdit, IconInfoCircle, IconPlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import RbgTooltip from '../../../../../ui_component/tooltip/RbgTooltip';
import { IconExcelFile, IconImageFile, IconPdfFile, IconWordFile } from '../../../../../assets/images/icons/IconsCustom';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import DownloadDocument from './DownloadDocument';
import AddStaffDocument from './AddStaffDocument';
import EditDocument from './EditDocument';
import DocumentInfo from './DocumentInfo';
import InfoCard from '../../../../../ui_component/error/InfoCard';

function StaffDocuments({ staffMember }) {
    const { data: categories, isFetched } = useQuery({
        queryKey: [{ url: '/staff/documents/categories' }]
    });

    const documents = useMemo(() => {
        const showCategories = [];
        staffMember.documents.data.forEach((doc) => {
            doc.type.data.category.data.category_breadcrumb.forEach((cat) => {
                if (showCategories.indexOf(cat) > -1) {
                    showCategories.push(cat);
                }
            });
        });
        const newCategories = categories.data.filter((cat) => {
            return showCategories.indexOf(cat.id);
        });
        const getDocumentsInCat = (id) => {
            return staffMember.documents.data.filter((doc) => {
                return doc.type.data.category.data.id === id;
            });
        };
        const getChildren = (cid) => {
            const cats = categories.data
                .filter((cat) => {
                    return cat.parent_category === cid;
                })
                .map((cat) => {
                    cat.documents = getDocumentsInCat(cat.id);
                    cat.children = getChildren(cat.id);
                    return cat;
                });
            return cats;
        };
        return newCategories
            .filter((cat) => {
                return cat.parent_category === null;
            })
            .map((cat) => {
                cat.children = getChildren(cat.id);
                cat.documents = getDocumentsInCat(cat.id);
                return cat;
            });
    }, [staffMember.documents.data, categories]);

    const downloadFile = (doc) => {
        DialogHelper.openMdDialog('Download', <DownloadDocument document={doc} />);
    };
    const editFile = (doc) => {
        DialogHelper.openMdDialog(`Edit Document #${doc.id}`, <EditDocument document={doc} />);
    };
    const docInfo = (doc) => {
        DialogHelper.openMdDialog(`Document #${doc.id}`, <DocumentInfo document={doc} />);
    };

    const renderCategories = (cats) => {
        return cats.map((cat) => {
            const children = renderCategories(cat.children);
            if (cat.children.length === 0 && cat.documents.length === 0) {
                return null;
            }
            const documents = cat.documents.map((doc) => {
                const file = doc.file.data;
                return (
                    <TreeItem
                        nodeId={`doc-${doc.id}`}
                        sx={{ '.MuiTreeItem-label': { cursor: 'default !important' } }}
                        label={
                            <RbgStack direction="row" alignItems="center" gap={1}>
                                {file.extension === 'pdf' && <IconPdfFile size={20} />}
                                {(file.extension === 'xls' || file.extension === 'xlsx') && <IconExcelFile size={20} />}
                                {(file.extension === 'doc' || file.extension === 'docx') && <IconWordFile size={20} />}
                                {(file.extension === 'jpeg' || file.extension === 'png' || file.extension === 'jpg') && (
                                    <IconImageFile size={20} />
                                )}
                                <RbgStack direction="row" gap={2}>
                                    <RbgTooltip title={dayjs(doc.date_uploaded).format('DD/MM/YYYY HH:MM')}>
                                        {dayjs(doc.date_uploaded).format('DD/MM/YYYY')}
                                    </RbgTooltip>
                                    <RbgTypography>{doc.type.data.name}</RbgTypography>
                                    <RbgTypography>({filesize(doc.file.data.size)})</RbgTypography>
                                </RbgStack>
                                <RbgStack direction="row" sx={{ marginRight: '0', marginLeft: 'auto' }} gap={1}>
                                    <RbgButton icon={<IconInfoCircle />} onClick={() => docInfo(doc)} />
                                    <RbgButton icon={<IconEdit />} onClick={() => editFile(doc)} />
                                    <RbgButton icon={<IconDownload />} onClick={() => downloadFile(doc)} />
                                </RbgStack>
                            </RbgStack>
                        }
                        key={doc.id}
                    />
                );
            });
            const childrenFiltered = children.filter((child) => child !== null);
            return (
                <TreeItem
                    nodeId={`cat-${cat.id}`}
                    label={
                        <RbgStack direction="row" alignItems="center" gap={2}>
                            <RbgIcon name={cat.icon} size={24} /> <strong>{cat.name}</strong>
                        </RbgStack>
                    }
                    key={cat.id}
                >
                    {childrenFiltered.length === 0 && documents.length === 0 && <InfoCard>Nothing here</InfoCard>}
                    {childrenFiltered}
                    {documents}
                </TreeItem>
            );
        });
    };

    const addDocument = () => {
        DialogHelper.openSmDialog('Upload New File', <AddStaffDocument staffMember={staffMember} />);
    };

    if (isFetched === false) {
        return <CardLoader />;
    }
    const expanded = categories.data.map((cat) => {
        return `cat-${cat.id}`;
    });
    return (
        <MainCard
            title="Team Documents"
            secondary={
                <RbgButton onClick={() => addDocument()}>
                    <IconPlus /> Add Document
                </RbgButton>
            }
        >
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    {documents.length > 0 && <TreeView defaultExpanded={expanded}>{renderCategories(documents)}</TreeView>}
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

StaffDocuments.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default StaffDocuments;
