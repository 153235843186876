import PropTypes from 'prop-types';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RbgStack from '../mui-wrappers/Stack/RbgStack';
import { ListItem } from '@mui/material';
import { DragHandle } from '@mui/icons-material';

function RbgDraggableList({ items, onChange, itemDecorator, droppableId = null }) {
    if (droppableId === null) {
        droppableId = `${_.uniqueId()}`;
    }
    const handleMoveItem = (id, target, source) => {
        const newItems = [...items];
        const [removed] = newItems.splice(source, 1);
        newItems.splice(target, 0, removed);
        if (typeof onChange === 'function') {
            onChange(newItems);
        }
    };

    return (
        <DragDropContext
            onDragEnd={(e) => {
                handleMoveItem(e.draggableId, e.destination.index, e.source.index);
            }}
        >
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => {
                                if (typeof item !== 'object') {
                                    console.error('Item is not an object', item);
                                    return null;
                                }
                                if (!('id' in item)) {
                                    console.error('Item does not have id attribute', item);
                                    return null;
                                }
                                return (
                                    <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                        {(provided, snapshot) => {
                                            return (
                                                <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ width: '100%' }}>
                                                    <RbgStack direction="row" gap={2} sx={{ width: '100%' }}>
                                                        <div {...provided.dragHandleProps}>
                                                            <DragHandle />
                                                        </div>
                                                        {itemDecorator(item)}
                                                    </RbgStack>
                                                </ListItem>
                                            );
                                        }}
                                    </Draggable>
                                );
                            })}
                        </div>
                        <span>{provided.placeholder}</span>
                    </>
                )}
            </Droppable>
        </DragDropContext>
    );
}

RbgDraggableList.propTypes = {
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    droppableId: PropTypes.string,
    itemDecorator: PropTypes.func.isRequired
};
export default RbgDraggableList;
