import PropTypes from 'prop-types';
import { useEffect, useId, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// mui imports
import { Paper, useTheme } from '@mui/material';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
// 3rd party
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, SubTitle, Title, Tooltip } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { IconDownload } from '@tabler/icons-react';
// project imports
import { IconExcelFile } from 'assets/images/icons/IconsCustom';
import { snackbarOpen } from 'store/reducers/snackbarReducer';
import RbgTooltip from '../tooltip/RbgTooltip';
import RbgButton from '../mui-wrappers/Button/RbgButton';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, SubTitle);
function UtilityChart({ config, height, width, exportChart = true }) {
    // global state
    const theme = useTheme();
    const dispatch = useDispatch();
    const { navType, presetColor } = useSelector((state) => state.customization);
    // local state
    const [download, setDownload] = useState(false);
    const [chartConfig, setChartConfig] = useState(config);
    const id = useId();
    const title = config.options.plugins.title.text || 'Chart';
    const chartRef = useRef(null);
    // chart colours
    const secondary = theme.palette.secondary.dark;
    const primary = theme.palette.primary.dark;
    const success = theme.palette.success.dark;
    const error = theme.palette.error.dark;
    const warning = theme.palette.warning.dark;
    const orange = theme.palette.orange.dark;

    useEffect(() => {
        const el = document.getElementById(id);
        if (el && exportChart) {
            setDownload(true);
        }
    }, [exportChart, id]);

    useEffect(() => {
        setChartConfig((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                datasets: config.data.datasets.map((dataset) => ({
                    ...dataset,
                    backgroundColor: dataset.backgroundColor ?? [
                        `${secondary}95`,
                        `${primary}95`,
                        `${success}95`,
                        `${error}95`,
                        `${warning}95`,
                        `${orange}95`
                    ],
                    borderColor: dataset.borderColor ?? [secondary, primary, success, error, warning, orange],
                    borderWidth: dataset.borderWidth ?? 1
                }))
            }
        }));
    }, [config.data.datasets, error, navType, orange, presetColor, primary, secondary, success, warning]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.download = `${title}.png`;
        link.href = chartRef.current?.toBase64Image('image/png', 1);
        link.click();
    };

    const handleCopyToExcel = () => {
        const data = [];
        const row = [''];
        config.data.datasets.map((dataSet) => row.push(dataSet.label));
        data.push(row);
        config.data.labels.map((label, idx) => {
            const row = [label];
            config.data.datasets.map((dataSet) => row.push(dataSet.data[idx]));
            return data.push(row);
        });
        const string = data.map((row) => `${row.join('\t')}\n`);
        const clipboardData = [new window.ClipboardItem({ 'text/plain': new Blob([string], { type: 'text/plain' }) })];
        navigator.clipboard.write(clipboardData).then(
            () => {
                dispatch(
                    snackbarOpen({
                        open: true,
                        message: 'Excel data copied to clipboard',
                        variant: 'alert',
                        severity: 'success'
                    })
                );
            },
            () => {
                dispatch(
                    snackbarOpen({
                        open: true,
                        message: 'Error in copying data to clipboard',
                        variant: 'alert',
                        severity: 'error'
                    })
                );
            }
        );
    };

    return (
        <Paper>
            <div style={{ height, width }}>
                <Chart ref={chartRef} id={id} type={chartConfig.type} options={chartConfig.options} data={chartConfig.data} />
            </div>
            {download && (
                <RbgStack direction="row" spacing={2}>
                    <RbgTooltip title="Download Chart Image" placement="top" arrow>
                        <RbgButton type="primary" onClick={handleDownload} icon={<IconDownload />} />
                    </RbgTooltip>
                    <RbgTooltip title="Copy to Excel" placement="top" arrow>
                        <RbgTypography sx={{ cursor: 'pointer' }} component="a" onClick={handleCopyToExcel}>
                            <IconExcelFile />
                        </RbgTypography>
                    </RbgTooltip>
                </RbgStack>
            )}
        </Paper>
    );
}

export default UtilityChart;

UtilityChart.propTypes = {
    config: PropTypes.object,
    height: PropTypes.string,
    width: PropTypes.string,
    exportChart: PropTypes.bool
};
