// material-ui imports
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
// third party
// project imports
import { gridSpacing, iconSize } from 'store/constant';
import MainCard from 'ui_component/cards/MainCard';
import { useSelector } from 'react-redux';
import StaffList from '../StaffList/StaffList';
import StaticBreadcrumb from 'ui_component/extended/StaticBreadcrumb';
import { Dangerous, Warning } from '@mui/icons-material';
import ActionList from '../Actions/ActionList';
import { Alert, ListItemButton, useMediaQuery, useTheme } from '@mui/material';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../ui_component/modals/RbgDialog';
import AddAbsence from '../StaffMember/Absences/AddAbsence';
import { useQuery } from '@tanstack/react-query';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgIcon from '../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import RbgListItem from '../../../../ui_component/mui-wrappers/ListItem/RbgListItem';
import RbgList from '../../../../ui_component/mui-wrappers/List/RbgList';

function Dashboard() {
    // breakpoints
    const { bar } = useSelector((state) => state.bar);
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.up('lg'));
    const hasLocation = !!bar.cost_centre;
    const {
        data: actions,
        isPending,
        isLoading,
        error
    } = useQuery({
        queryKey: [
            {
                url: '/staff/actions',
                params: {
                    page: 1,
                    include: 'staff_member,type',
                    type_id: null,
                    location: bar.cost_centre,
                    per_page: 9999,
                    category_id: null
                }
            }
        ],
        enabled: hasLocation,
        initialData: {
            data: [],
            meta: {
                pagination: {},
                severity_totals: []
            }
        }
    });
    const immediateActions = actions.data.filter((action) => action.type.data.severity.data.id === 3);
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={12}>
                <StaticBreadcrumb items={[{ label: 'Team' }, { label: bar.name }]} />
            </RbgGrid>
            <RbgGrid item xs={12} lg={8}>
                <RbgStack gap={2}>
                    {immediateActions.length > 0 && (
                        <MainCard title="Immediate Actions" icon={<Dangerous size={iconSize} color="error" />}>
                            <RbgList sx={{ m: 0, p: 0 }}>
                                {immediateActions.map((item) => (
                                    <RbgListItem key={item.id} sx={{ width: '100%', m: 0, p: 0, mb: 1 }}>
                                        <ListItemButton
                                            variant="contained"
                                            sx={{
                                                background: 'none',
                                                padding: 0,
                                                margin: 0,
                                                ':hover': { background: 'none' },
                                                width: '100%'
                                            }}
                                            onClick={() => {
                                                document.location.href = `/staff/view/${item.staff_id}#employment`;
                                            }}
                                        >
                                            <Alert
                                                severity={item.type.data.severity.data.status_class}
                                                sx={{ width: '100%' }}
                                                icon={<RbgIcon name={item.type.data.icon} />}
                                            >
                                                {item.rendered}
                                            </Alert>
                                        </ListItemButton>
                                    </RbgListItem>
                                ))}
                            </RbgList>
                        </MainCard>
                    )}
                    <StaffList />
                </RbgStack>
            </RbgGrid>
            <RbgGrid item xs={12} lg={4}>
                <RbgGrid item container justifyContent="space-between" xs={12} lg={12} spacing={gridSpacing}>
                    <RbgGrid item xs={12} md={6} lg={12}>
                        <MainCard
                            icon={<Warning size={iconSize} />}
                            title="Action Centre"
                            border
                            secondary={
                                <RbgButton
                                    color="warning"
                                    onClick={() => {
                                        DialogHelper.openSmDialog('Log Absence', <AddAbsence location={bar.cost_centre} />);
                                    }}
                                >
                                    Log Absence
                                </RbgButton>
                            }
                            contentStyles={{ overflowX: 'hidden', minWidth: '320px' }}
                        >
                            <ActionList data={actions} />
                        </MainCard>
                    </RbgGrid>
                </RbgGrid>
            </RbgGrid>
        </RbgGrid>
    );
}

export default Dashboard;
