import PropTypes from 'prop-types';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project imports
import MainCard from 'ui_component/cards/MainCard';
import IntroCardSkeleton from 'ui_component/cards/Skeleton/IntroCardSkeleton';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.10)' : 'rgba(0, 0, 0, 0.20)',
    overflow: 'hidden',
    position: 'relative',
    paddingRight: useMediaQuery(theme.breakpoints.up('lg')) ? 20 : 0,

    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.secondary.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.secondary.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

function IntroCardSecondary({ isLoading, children }) {
    if (isLoading) {
        return <IntroCardSkeleton />;
    }
    return (
        <CardWrapper border={false} content={false}>
            <Box sx={{ p: 2 }}>{children}</Box>
        </CardWrapper>
    );
}

IntroCardSecondary.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.node
};

export default IntroCardSecondary;
