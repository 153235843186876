import { lazy } from 'react';
// project imports
import config from '../config';
import MainLayout from 'layout/MainLayout/MainLayout';
import Loadable from 'ui_component/loading/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NavigationScroll from 'layout/NavigationScroll';
// dashboard routing
const ErrorBoundary = Loadable(lazy(() => import('ui_component/error/ErrorBoundary/ErrorBoundary')));
const Dashboard = Loadable(lazy(() => import('views/welcome/Dashboard/Dashboard')));
const AdminPermissions = Loadable(lazy(() => import('views/admin/permissions/AdminPermissions')));
const LocationAttributes = Loadable(lazy(() => import('views/admin/locations/LocationAttributes')));

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = {
    name: 'admin-routes',
    basename: config.basename,
    path: '/admin',
    element: (
        <ErrorBoundary>
            <AuthGuard>
                <NavigationScroll>
                    <MainLayout />
                </NavigationScroll>
            </AuthGuard>
        </ErrorBoundary>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'permissions',
            element: <AdminPermissions />
        },
        {
            path: 'location-attributes',
            element: <LocationAttributes />
        }
    ]
};

export default AdminRoutes;
