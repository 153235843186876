import PropTypes from 'prop-types';
import { staffMember } from '../../StaffDataTypes';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { gridSpacing } from '../../../../../store/constant';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import IntroCardSecondary from '../../../../../ui_component/cards/IntroCardSecondary';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation } from '@tanstack/react-query';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';

function CancelLeaver({ staffMember }) {
    const [cancelReason, setCancelReason] = useState('');
    const mutation = useMutation({
        mutationFn: (data) => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/cancel-leaver`, { reason: cancelReason });
        },
        onSuccess: (data) => {
            DialogHelper.closeDialog();
        }
    });
    if (staffMember.employment.data.leave_date === null) {
        return <ErrorCard>{staffMember.details.data.display_name} is not marked as a leaver</ErrorCard>;
    }

    return (
        <RbgGrid container spacing={gridSpacing}>
            {mutation.failureReason && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.failureReason}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <IntroCardSecondary>
                    Are you sure you want to cancel the leaver status of {staffMember.details.data.display_name}? They are currently due to
                    leave RBG in {dayjs(staffMember.employment.data.leave_date).fromNow()}.
                </IntroCardSecondary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgSelect
                    values={['Marked in error', 'Employee changed mind'].map((reason) => ({ value: reason, label: reason }))}
                    name="reason"
                    value={cancelReason}
                    handleChange={(e) => setCancelReason(e.target.value)}
                    label="Reason for cancel"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton
                    sx={{ width: '100%' }}
                    type="button"
                    variant="contained"
                    onClick={() => mutation.mutate()}
                    disabled={mutation.isPending}
                >
                    Yes I&apos;m sure
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

CancelLeaver.propTypes = {
    staffMember: PropTypes.shape(staffMember)
};
export default CancelLeaver;
