import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../../ui_component/error/InfoCard';
import RbgTableContainer from '../../../../../ui_component/mui-wrappers/TableContainer/RbgTableContainer';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTable from '../../../../../ui_component/mui-wrappers/Table/RbgTable';
import dayjs from 'dayjs';
import CurrencyFormatter from '../../../../../utils/currencyFormatter';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import WarningCard from '../../../../../ui_component/error/WarningCard';
import RbgTableBody from '../../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import PropTypes from 'prop-types';
import RbgTooltip from '../../../../../ui_component/tooltip/RbgTooltip';
import { IconExclamationCircle } from '@tabler/icons-react';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function NewStartConfirmation({ data }) {
    const fieldSet = data.data.manager_fieldset.data?.fields?.data;
    const { user } = useContext(RcloudAuthContext);
    const queryClient = useQueryClient();
    const completeMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${data.data.id}/complete`, {});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: `/staff/onboarding/team-member/${data.data.id}` }]
            });
        }
    });
    const needsAuth = data.data.needs_auth;
    const authFields = data.data.auth_fields;
    const hasAuthPermission = user.permissions.indexOf('hr_staff_auth') > -1;
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>Please check all of the information below and then submit.</InfoCard>
            </RbgGrid>
            {completeMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{completeMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTableContainer>
                    <RbgTable>
                        <RbgTableBody>
                            {fieldSet.map((field) => {
                                if (field.type === 'title' || field.type === 'content') {
                                    return null;
                                }
                                let value = data.data.fields[field.name];
                                if (field.type === 'file') {
                                    if (typeof value === 'object') {
                                        value = value.original_filename;
                                    } else {
                                        value = 'N/A';
                                    }
                                }
                                if (field.type === 'select' || field.type === 'contract') {
                                    const intValue = parseInt(value, 10);
                                    const selectValue = field.allowed_values.find((val) => val.key === `${value}` || val.key === intValue);
                                    value = selectValue ? selectValue.value : value;
                                    if (!value) {
                                        value = 'N/A';
                                    }
                                }
                                if (field.type === 'date') {
                                    value = `${dayjs(value).format('DD/MM/YYYY')} (${dayjs(value).fromNow()})`;
                                }
                                if (field.type === 'hourly_rate') {
                                    value = <CurrencyFormatter value={parseFloat(value)} />;
                                }
                                if (field.type === 'salary') {
                                    value = <CurrencyFormatter value={parseFloat(value)} />;
                                }
                                const fieldsNeedsAuth = authFields.indexOf(field.name) > -1;
                                return (
                                    <RbgTableRow key={field.id}>
                                        <RbgTableCell>{field.label}</RbgTableCell>
                                        <RbgTableCell>
                                            <RbgStack direction="row" justifyContent="center" gap={1}>
                                                <RbgTypography>{value}</RbgTypography>
                                                {fieldsNeedsAuth && (
                                                    <RbgTooltip title="This field requires authorisation">
                                                        <IconExclamationCircle size={20} color="red" />
                                                    </RbgTooltip>
                                                )}
                                            </RbgStack>
                                        </RbgTableCell>
                                    </RbgTableRow>
                                );
                            })}
                        </RbgTableBody>
                    </RbgTable>
                </RbgTableContainer>
            </RbgGrid>
            {needsAuth && hasAuthPermission && (
                <RbgGrid item xs={12}>
                    <WarningCard>
                        You have the team member authorisation permission. By clicking continue this will bypass the authorisation process
                        and start the staff onboarding process
                    </WarningCard>
                </RbgGrid>
            )}
            {needsAuth && !hasAuthPermission && (
                <RbgGrid item xs={12}>
                    <WarningCard>
                        This team member will require authorisation from the authoriser for this location (
                        <strong>{data.data.auth_name}</strong>) before the new starter will get their welcome e-mail.
                    </WarningCard>
                </RbgGrid>
            )}
            {!needsAuth && (
                <RbgGrid item xs={12}>
                    <InfoCard>
                        This new starter does not needs authorisation. As soon as you click complete they will get an e-mail asking them to
                        continue their onboarding journey.
                    </InfoCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgButton
                    sx={{ width: '100%' }}
                    disabled={completeMutation.isPending}
                    onClick={() => {
                        completeMutation.mutate();
                    }}
                >
                    Complete
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

NewStartConfirmation.propTypes = {
    data: PropTypes.object.isRequired
};
export default NewStartConfirmation;
