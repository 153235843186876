import StaticBreadcrumb from '../../../../ui_component/extended/StaticBreadcrumb';
import MainCard from '../../../../ui_component/cards/MainCard';
import { useQuery } from '@tanstack/react-query';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import RelativeTime from '../../../../ui_component/relative-time/RelativeTime';
import CurrencyFormatter from '../../../../utils/currencyFormatter';
import SubCard from '../../../../ui_component/cards/SubCard';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgRemoteSelect from '../../../../ui_component/remote-select/RbgRemoteSelect';
import { useEffect, useState } from 'react';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import ClientSidePageableTable from '../../../../ui_component/pageable-table/ClientSidePageableTable';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconExternalLink } from '@tabler/icons-react';
import RbgTooltip from '../../../../ui_component/tooltip/RbgTooltip';
import RbgLink from '../../../../ui_component/mui-wrappers/Link/RbgLink';

function StaffAreaManagerDashboard() {
    const [areaManager, setAreaManager] = useState(null);
    const { data, isFetched, error } = useQuery({
        queryKey: [{ url: '/staff/area-dashboard', params: { area_manager_id: areaManager } }],
        enabled: areaManager !== null
    });
    const { data: areaManagers } = useQuery({
        queryKey: [{ url: '/staff/area-managers' }]
    });
    useEffect(() => {
        if (!areaManager && areaManagers.data.length > 0) {
            setAreaManager(areaManagers.data[0].id);
        }
    }, [areaManagers.data, areaManager]);
    if (areaManager === null) {
        return (
            <RbgStack gap={1}>
                <StaticBreadcrumb items={[{ label: 'Team' }, { label: 'Area Manager Dashboard' }]} />
                <SubCard>
                    <RbgRemoteSelect
                        endpoint="/staff/area-managers"
                        name="area_manager"
                        label="Area Manager"
                        useAutoComplete
                        value={areaManager}
                        handleChange={(e) => setAreaManager(e.target.value)}
                    />
                </SubCard>
            </RbgStack>
        );
    }
    return (
        <RbgStack gap={1}>
            <StaticBreadcrumb items={[{ label: 'Team' }, { label: 'Area Manager Dashboard' }]} />
            <SubCard>
                <RbgRemoteSelect
                    endpoint="/staff/area-managers"
                    name="area_manager"
                    label="Area Manager / POD Director"
                    useAutoComplete
                    value={areaManager}
                    handleChange={(e) => setAreaManager(e.target.value)}
                />
            </SubCard>
            {isFetched === false ? (
                <MainCard>
                    <CardLoader />
                </MainCard>
            ) : (
                <MainCard>
                    {error ? (
                        <ErrorCard>{error}</ErrorCard>
                    ) : (
                        <RbgGrid container>
                            <RbgGrid item xs={12}>
                                <SubCard title="Locations">{data.meta.locations.join(', ')}</SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={3}>
                                <SubCard title="Salaried Team Member">
                                    <RbgTypography variant="h2">{data.data.stats.salaried}</RbgTypography>
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={3}>
                                <SubCard title="Hourly Team Members">
                                    <RbgTypography variant="h2">{data.data.stats.hourly}</RbgTypography>
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard
                                    title="Pending Rate Changes"
                                    secondary={
                                        <RbgButton size="small" component="a" href="/staff/team-approvals">
                                            <IconExternalLink size={15} />
                                            Authorisations
                                        </RbgButton>
                                    }
                                >
                                    <RbgSimpleTable
                                        headers={['Team Member', 'Location', 'Date', 'Rate', 'Role']}
                                        keys={data.data.rate_changes.map((row) => row.id)}
                                        rows={data.data.rate_changes.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.effective_from_date} />,
                                            row.current_rate !== row.rate ? (
                                                <RbgTooltip title={`Current Rate: ${row.current_rate}`}>
                                                    <CurrencyFormatter value={row.rate} />
                                                    <br />(<CurrencyFormatter value={row.rate - row.current_rate} />)
                                                </RbgTooltip>
                                            ) : (
                                                <RbgTypography>No Change</RbgTypography>
                                            ),
                                            row.role_name !== row.new_position_name
                                                ? `${row.role_name} -> ${row.new_position_name}`
                                                : 'No Change'
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard
                                    title="Pending Contract Approvals"
                                    secondary={
                                        <RbgButton size="small" component="a" href="/staff/team-approvals#contracts">
                                            <IconExternalLink size={15} />
                                            Authorisations
                                        </RbgButton>
                                    }
                                >
                                    <RbgSimpleTable
                                        headers={['Team Member', 'Location', 'Effective Date', 'Current Hours', 'New Hours']}
                                        keys={data.data.contracts.map((row) => row.id)}
                                        rows={data.data.contracts.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.effected_date} />,
                                            row.current_hours,
                                            row.new_hours
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard title="Right to work expiries">
                                    <RbgSimpleTable
                                        headers={['Team Member', 'Location', 'Expiry']}
                                        keys={data.data.right_to_work_expiry.map((row) => row.id)}
                                        rows={data.data.right_to_work_expiry.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.rtw_expires} />
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard title="Recent Disciplinaries">
                                    <RbgSimpleTable
                                        headers={['Team Member', 'Location', 'Date', 'Type']}
                                        keys={data.data.recent_disciplinaries.map((row) => row.id)}
                                        rows={data.data.recent_disciplinaries.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.date} />,
                                            row.type
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard title="New Starters within Probation">
                                    <ClientSidePageableTable
                                        perPage={10}
                                        headers={['Team Member', 'Location', 'Start Date', 'Probation End']}
                                        data={data.data.new_starters.map((row) => [
                                            <RbgLink href={`/staff/view/${row.id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.start_date} />,
                                            <RelativeTime time={row.probation_end_date} />
                                        ])}
                                        keys={data.data.new_starters.map((row) => row.id)}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard title="Salary Probation Upcoming">
                                    <RbgSimpleTable
                                        keys={data.data.salary_probation_upcoming.map((row) => row.id)}
                                        headers={['Team Member', 'Location', 'Role', 'Probation End']}
                                        rows={data.data.salary_probation_upcoming.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            row.role_name,
                                            <RelativeTime time={row.probation_end_date} />
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard title="Upcoming Salaried Birthdays">
                                    <RbgSimpleTable
                                        headers={['Team Member', 'Location', 'Birthday', 'Age']}
                                        keys={data.data.salaried_birthdays.map((row) => row.id)}
                                        rows={data.data.salaried_birthdays.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.next_birthday} />,
                                            row.age
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                            <RbgGrid item xs={12} lg={6}>
                                <SubCard title="Upcoming Length of service">
                                    <RbgSimpleTable
                                        headers={['Team Member', 'Location', 'Anniversary', 'Tenure']}
                                        rows={data.data.length_of_service.map((row) => [
                                            <RbgLink href={`/staff/view/${row.staff_id}`}>
                                                {row.name} ({row.role_name})
                                            </RbgLink>,
                                            row.location_name,
                                            <RelativeTime time={row.next_work_anniversary} />,
                                            row.tenure_years + 1
                                        ])}
                                    />
                                </SubCard>
                            </RbgGrid>
                        </RbgGrid>
                    )}
                </MainCard>
            )}
        </RbgStack>
    );
}

export default StaffAreaManagerDashboard;
