import PropTypes from 'prop-types';
import { contractTemplate } from '../../StaffDataTypes';
import { useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import StaffMemberAutocomplete from '../../Utility/StaffMemberAutocomplete';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import { useQuery } from '@tanstack/react-query';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import { currencyGBP } from '../../../../../utils/currencyFormatter';
import { Paper } from '@mui/material';

function PreviewContractTemplate({ template }) {
    const [staffMember, setStaffMember] = useState(null);
    const [role, setRole] = useState(null);
    const [rate, setRate] = useState(null);

    const { data: staffData } = useQuery({
        initialData: {
            data: {
                all_roles: { data: [] },
                pay_rates: { data: [] }
            }
        },
        queryKey: [{ url: `/staff/get/${staffMember?.id}` }],
        enabled: staffMember !== null
    });

    const { data: previewData } = useQuery({
        queryKey: [
            {
                method: 'POST',
                url: '/staff/staff-contract-admin/preview',
                params: { template, role_id: role?.id, pay_rate_id: rate?.id, staff_id: staffMember?.id }
            }
        ],
        enabled: role !== null && rate !== null
    });
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    <RbgTypography>Find a current team member to preview this contract with</RbgTypography>
                </IntroCardPrimary>
            </RbgGrid>
            <RbgGrid item xs={4}>
                <StaffMemberAutocomplete
                    value={staffMember ? `${staffMember.id}` : null}
                    onChange={(e) => setStaffMember(e.target.value ? e.target.object : null)}
                    label="Team Member"
                    name="staff_member"
                />
            </RbgGrid>
            <RbgGrid item xs={4}>
                <RbgSelect
                    values={staffData.data.all_roles.data.map((role) => ({
                        value: role.id,
                        label: role.position_name,
                        object: role
                    }))}
                    label="Role"
                    value={role ? role.id : ''}
                    handleObjectChange={(e) => setRole(e.target.object)}
                    handleChange={(e) => {
                        if (!e.target.value) {
                            setRole(null);
                        }
                    }}
                    disabled={staffMember === null}
                />
            </RbgGrid>
            <RbgGrid item xs={4}>
                <RbgSelect
                    values={staffData.data.pay_rates.data.map((rate) => ({
                        value: rate.id,
                        label: `${currencyGBP().format(rate.rate)} ${rate.type.data.display_type}`,
                        object: rate
                    }))}
                    handleObjectChange={(e) => setRate(e.target.object)}
                    handleChange={(e) => {
                        if (!e.target.value) {
                            setRate(null);
                        }
                    }}
                    value={rate ? rate.id : ''}
                    label="Pay Rate"
                    disabled={staffMember === null}
                />
            </RbgGrid>
            {previewData.data.html && (
                <RbgGrid item xs={12}>
                    <Paper>
                        {/*eslint-disable-next-line*/}
                        <div dangerouslySetInnerHTML={{ __html: previewData.data.html }} />
                    </Paper>
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

PreviewContractTemplate.propTypes = {
    template: PropTypes.shape(contractTemplate)
};

export default PreviewContractTemplate;
