import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// material-ui imports
import { Tab, useMediaQuery, useTheme } from '@mui/material';
import { TabContext } from '@mui/lab';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import RbgGrid from 'ui_component/mui-wrappers/Grid/RbgGrid';
// 3rd party imports
import { IconPencil, IconX } from '@tabler/icons-react';
// project imports
import { gridSpacing, iconSize } from 'store/constant';
import CircularLoading from 'ui_component/loading/CircularLoading';
import TabPanel from './TabPanel';
import TabListStyled from './TabListStyled';
import RbgTooltip from '../tooltip/RbgTooltip';

function RbgTabs({
    currentTab,
    setCurrentTab,
    handleTabClose,
    tabs,
    scrollButtons = false,
    gridContainerStyles,
    tabGridStyles,
    tabPanelGridStyles,
    tabStyles,
    tabListStyles,
    orientation = 'horizontal'
}) {
    const theme = useTheme();
    const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchBetweenSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
    const { borderRadius } = useSelector((state) => state.customization);

    let responsiveOrientation;
    switch (orientation) {
        case 'vertical':
            if (matchDownLg) {
                responsiveOrientation = 'horizontal';
            } else {
                responsiveOrientation = 'vertical';
            }
            break;
        case 'horizontal':
            responsiveOrientation = 'horizontal';
            break;
        default:
            responsiveOrientation = 'horizontal';
    }
    // styles
    const tabGridContainerSX = !matchDownLg ? { position: 'relative' } : {};
    const tabGridSX =
        orientation === 'vertical' && !matchDownLg
            ? {
                  width: '100%',
                  maxWidth: '250px'
              }
            : { width: '100%' };
    const tabPanelGridSX =
        orientation === 'vertical' && !matchDownLg
            ? {
                  width: 'calc(100% - 250px)',
                  height: '100%',
                  overflow: 'auto',
                  px: 0,
                  pb: 12.5,
                  pt: '20px !important',
                  '& .MuiTabPanel-root': {
                      px: 0,
                      pt: (matchBetweenSmLg && '48px') || 0
                  }
              }
            : { paddingTop: '14px !important', width: '100%' };
    const tabSX =
        orientation === 'vertical' && !matchDownLg
            ? {
                  borderBottom: '1px solid',
                  borderLeft: '0',
                  borderRight: '0',
                  width: '100%',
                  borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                  '&:hover': {
                      background: theme.palette.mode === 'dark' ? `${theme.palette.secondary.main}30` : theme.palette.secondary.light
                  },
                  '&.Mui-selected': {
                      color: theme.palette.secondary.main
                  },

                  '& .label': {
                      width: '150px',
                      textAlign: 'left'
                  }
              }
            : {
                  py: '5px',
                  minHeight: '24px'
              };
    const tabListSX =
        orientation === 'vertical' && !matchDownLg
            ? {
                  height: '100%',
                  maxHeight: '500px',
                  width: '100%',
                  border: '1.5px solid',
                  borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                  borderRadius: `${borderRadius}px`,
                  '.MuiTabs-flexContainer': {
                      position: 'relative',
                      maxHeight: '600px',
                      alignItems: 'center'
                  },

                  '.MuiTabScrollButton-vertical': {
                      height: '20px'
                  }
              }
            : {
                  pb: 0.5,
                  minHeight: '24px',
                  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light}`
              };

    // local state
    const [clickedTabs, setClickedTabs] = useState([currentTab]);

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };

    const handleClose = (event, value, edit) => {
        event.stopPropagation();
        if (edit) {
            // eslint-disable-next-line
            if (window.confirm('You will lose any unsaved changes, are you sure?')) {
                handleTabClose(event, value);
            }
        } else {
            handleTabClose(event, value);
        }
    };
    const tabList = tabs.map((tab, idx) => {
        const notifications = tab.notifications?.toString().replaceAll(',', '/');
        return (
            <Tab
                onClick={() => {
                    if (clickedTabs.indexOf(tab.value) === -1) {
                        setClickedTabs((prev) => [...prev, tab.value]);
                    }
                }}
                disabled={tab.disabled}
                sx={{
                    ...tabSX,
                    '&[disabled]': { textDecoration: 'line-through' },
                    '&.main': {
                        background: theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'light' ? `${theme.palette.primary.dark}` : `${theme.palette.primary.main}50`
                        },
                        '&.Mui-selected': {
                            background: theme.palette.mode === 'light' ? `${theme.palette.primary.dark}` : `${theme.palette.primary.main}50`
                        },
                        color: 'white',
                        zIndex: 3,
                        '&.main':
                            orientation === 'vertical' && matchDownLg
                                ? {
                                      margin: '0 2px',
                                      borderRadius: '4px'
                                  }
                                : {}
                    },
                    ...tabStyles
                }}
                className={tab.className}
                key={idx}
                label={
                    <RbgStack sx={{ position: 'relative' }} direction="row" alignItems="center" justifyContent="space-between">
                        {tab.notifications ? (
                            <>
                                <span>{tab.label}</span>
                                <span className="rbg-tab-badge" style={{ backgroundColor: theme.palette.error.main }}>
                                    {notifications}
                                </span>
                            </>
                        ) : (
                            <span>{tab.label}</span>
                        )}
                        {tab.edit && (
                            <RbgTooltip title="Editing">
                                <IconPencil size={iconSize} color={theme.palette.info.main} />
                            </RbgTooltip>
                        )}
                        {tab.canClose && (
                            <IconX
                                style={{
                                    marginLeft: '8px'
                                }}
                                size={iconSize}
                                onClick={(event) => handleClose(event, tab.value, tab.edit)}
                            />
                        )}
                    </RbgStack>
                }
                value={tab.value}
            />
        );
    });

    useEffect(() => {
        if (clickedTabs.indexOf(currentTab) === -1) {
            setClickedTabs((prev) => [...prev, currentTab]);
        }
    }, [currentTab, clickedTabs]);

    return (
        <TabContext value={currentTab}>
            <RbgGrid sx={{ ...tabGridContainerSX, ...gridContainerStyles }} container direction="row" spacing={gridSpacing}>
                <RbgGrid sx={{ ...tabGridSX, ...tabGridStyles }} item>
                    <TabListStyled
                        sx={{
                            ...tabListSX,
                            '.MuiTabs-indicator': {
                                zIndex: 5,
                                width: '4px',
                                height: '4px',
                                background: theme.palette.warning.main
                            },
                            ...tabListStyles
                        }}
                        onChange={handleTabChange}
                        variant="scrollable"
                        orientation={responsiveOrientation}
                        scrollButtons={scrollButtons}
                    >
                        {tabList}
                    </TabListStyled>
                </RbgGrid>
                <RbgGrid sx={{ ...tabPanelGridSX, ...tabPanelGridStyles }} item>
                    {tabs.map((tab, idx) => (
                        <TabPanel key={idx} value={tab.value}>
                            {clickedTabs.indexOf(tab.value) > -1 ? tab.content : <CircularLoading />}
                        </TabPanel>
                    ))}
                </RbgGrid>
            </RbgGrid>
        </TabContext>
    );
}

RbgTabs.propTypes = {
    /**
     * Current visible tab
     */
    currentTab: PropTypes.string.isRequired,

    /**
     * Set current visible tab
     */
    setCurrentTab: PropTypes.func.isRequired,

    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            content: PropTypes.node.isRequired,
            className: PropTypes.string,
            canClose: PropTypes.bool,
            notifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            edit: PropTypes.bool
        })
    ),

    /**
     * Display scroll indicators
     * @default false
     */
    scrollButtons: PropTypes.bool,

    /**
     * Removes a tab from the array
     */
    handleTabClose: PropTypes.func,

    /**
     * Grid container styles - parent of RbgVerticalTabs
     */
    gridContainerStyles: PropTypes.object,

    /**
     * Grid item styles - parent of tab list
     */
    tabGridStyles: PropTypes.object,

    /**
     * List styles - parent of tabs
     */
    tabListStyles: PropTypes.object,

    /**
     * Tab styles
     */
    tabStyles: PropTypes.object,

    /**
     * Grid item styles - parent of tab panels
     */
    tabPanelGridStyles: PropTypes.object,
    orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

TabPanel.propTypes = {
    children: PropTypes.object,
    value: PropTypes.string,
    disabled: PropTypes.bool
};
export default RbgTabs;
