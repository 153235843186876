import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import Debug from '../../../../ui_component/misc/Debug';
import PropTypes from 'prop-types';
import RbgSelect from '../../../../ui_component/extended/Form/RbgSelect';
import { useState } from 'react';
import InfoCard from '../../../../ui_component/error/InfoCard';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../utils/api';
import WarningCard from '../../../../ui_component/error/WarningCard';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import PreviewBulkAction from './PreviewBulkAction';

function ActionMapping({ action }) {
    const [fields, setFields] = useState(action.fields.data);
    const [tab, setTab] = useState('mapping');
    const mappingFields = action.columns.map((col) => {
        return {
            label: col,
            value: col
        };
    });

    const getFieldValue = (field) => {
        const value = fields.find((f) => {
            return f.field_id === field;
        });
        return value ? value.column_name : '';
    };

    const handleFieldChange = (fieldId, e) => {
        const field = fields.find((f) => f.field_id === fieldId);
        const newField = { field_id: fieldId, bulk_action_id: action.id, column_name: e.target.value };
        if (!field) {
            setFields((prev) => [...prev, newField]);
        } else {
            setFields((prev) => {
                return prev.map((f) => {
                    if (f.field_id === fieldId) {
                        return newField;
                    }
                    return f;
                });
            });
        }
    };

    const queryClient = useQueryClient();
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/bulk-actions/${action.id}/mapping`, { mapping: fields });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{ url: '/staff/bulk-actions' }]
            });
        }
    });
    let canEdit = true;
    let complete = false;
    if (saveMutation.isPending) {
        canEdit = false;
    }
    if (action.completed === true) {
        canEdit = false;
        complete = true;
    }
    if (action.date_started) {
        canEdit = false;
        complete = true;
    }
    let canPreview = true;
    if (!complete) {
        action.type.data.fields.data.forEach((f) => {
            if (!getFieldValue(f.id) && f.required === true) {
                canPreview = false;
            }
        });
    }
    console.log(canPreview);
    const tabs = [
        {
            value: 'mapping',
            label: 'Mapping',
            content: (
                <RbgGrid container>
                    {complete ? (
                        <RbgGrid item xs={12}>
                            <WarningCard>This action has already been started or completed, mapping cannot be updated</WarningCard>
                        </RbgGrid>
                    ) : (
                        <RbgGrid item xs={12}>
                            <InfoCard>Enter the columns which need to be mapped below</InfoCard>
                        </RbgGrid>
                    )}
                    <RbgGrid item xs={12}>
                        <RbgStack direction="row" spacing={2}>
                            {action.type.data.fields.data.map((field) => {
                                return (
                                    // <RbgGrid item xs={4} key={field.id}>
                                    <RbgSelect
                                        key={field.id}
                                        label={field.label}
                                        values={mappingFields}
                                        disabled={!canEdit}
                                        required={field.required}
                                        value={getFieldValue(field.id)}
                                        handleChange={(e) => handleFieldChange(field.id, e)}
                                    />
                                    // </RbgGrid>
                                );
                            })}
                        </RbgStack>
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <RbgButton fullWidth disabled={!canEdit} onClick={() => saveMutation.mutate()}>
                            Save Mapping
                        </RbgButton>
                    </RbgGrid>
                </RbgGrid>
            )
        },
        {
            value: 'preview',
            label: 'Preview',
            disabled: !canPreview,
            content: <PreviewBulkAction action={action} fields={fields} />
        }
    ];
    return <RbgTabs tabs={tabs} currentTab={tab} setCurrentTab={(tab) => setTab(tab)} />;
}

ActionMapping.propTypes = {
    action: PropTypes.object.isRequired
};
export default ActionMapping;
