import RbgTextInput from '../../../../../../ui_component/extended/Form/RbgTextInput';
import PropTypes from 'prop-types';
import { useDebounce } from '@uidotdev/usehooks';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import currencyFormatter, { currencyGBP } from '../../../../../../utils/currencyFormatter';

function SalaryCustomField({ fields, field, value, onChange, location, position }) {
    const { data, queryError, isPending } = useQuery({
        initialData: {
            data: { rate: 0, max_rate: 0 }
        },
        queryKey: [
            {
                url: '/staff/onboarding/pay-rate',
                params: {
                    cost_centre: location,
                    date_of_birth: fields.date_of_birth,
                    position_id: position.id,
                    date: fields.start_date ? fields.start_date : dayjs().format('YYYY-MM-DD')
                }
            }
        ],
        enabled: !!(position.id && fields.date_of_birth && location)
    });
    let salaryMinimum = 0;
    if (fields.contracted_hours) {
        salaryMinimum = data.data.rate * fields.contracted_hours * 52;
    }
    let error = queryError;
    if (value < salaryMinimum) {
        error = `Salary should be less than NMW of ${currencyGBP().format(salaryMinimum)}`;
    }
    return (
        <RbgTextInput
            type="number"
            min={field.allowed_values.min}
            max={field.allowed_values.max}
            error={error}
            help={salaryMinimum && `Minimum rate of ${salaryMinimum}`}
            required={field.required}
            name={field.name}
            value={value}
            label={field.label}
            onChange={(e) => onChange(e)}
        />
    );
}

SalaryCustomField.propTypes = {
    fields: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
    position: PropTypes.object.isRequired
};
export default SalaryCustomField;
