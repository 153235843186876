import { useEffect, useState } from 'react';
import EventDispatcher from '../../utils/EventDispatcher';
import { Button, Dialog, Divider, Fade, Tooltip } from '@mui/material';
import RbgStack from '../mui-wrappers/Stack/RbgStack';
import RbgTypography from '../mui-wrappers/Typography/RbgTypography';
import { IconX } from '@tabler/icons-react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TwoFactorAuth from './TwoFactorAuth';

function TwoFactorDialog() {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        EventDispatcher.subscribe('tfa_factor_auth', () => {
            setVisible(true);
        });
        EventDispatcher.subscribe('hide_two_factor_auth', () => {
            setVisible(false);
        });
    }, []);

    const handleClose = () => {
        setVisible(false);
    };
    if (visible === false) {
        return null;
    }
    return (
        <Dialog
            fullScreen={false}
            fullWidth
            maxWidth="sm"
            open
            disableEscapeKeyDown
            TransitionProps={{
                timeout: 200
            }}
            TransitionComponent={Fade}
        >
            <RbgStack>
                <RbgStack direction="row" justifyContent="space-between" alignItems="center">
                    <RbgStack direction="row" spacing={2} sx={{ marginLeft: '10px' }}>
                        <RbgTypography variant="h3">Two Factor Auth Required</RbgTypography>
                    </RbgStack>
                    <Tooltip title="Close">
                        <Button onClick={handleClose}>
                            <IconX />
                        </Button>
                    </Tooltip>
                </RbgStack>
                <Divider sx={{ width: '100%' }} orientation="horizontal" flexItem />
            </RbgStack>
            <DialogContent sx={{ p: 2 }}>
                <TwoFactorAuth />
            </DialogContent>
            <DialogActions sx={{ px: 2, py: 0, flexWrap: 'wrap' }}>
                <Divider sx={{ width: '100%', mb: 1.5 }} orientation="horizontal" flexItem />
                <RbgStack direction="row" spacing={2}>
                    <Button variant="outlined" startIcon={<IconX />} onClick={handleClose}>
                        Cancel
                    </Button>
                </RbgStack>
            </DialogActions>
        </Dialog>
    );
}

export default TwoFactorDialog;
