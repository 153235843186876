import PropTypes from 'prop-types';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import { useState } from 'react';
import ViewPayslipDetail from './ViewPayslipDetail';
import PayslipHistory from './PayslipHistory';
import PayslipQuery from './PayslipQuery';

function ViewPayslip({ id }) {
    const [tab, setTab] = useState('detail');

    const tabs = [
        {
            value: 'detail',
            label: 'Payslip detail',
            content: <ViewPayslipDetail id={id} setTab={setTab} />
        },
        {
            value: 'history',
            label: 'Payslip History',
            content: <PayslipHistory id={id} />
        },
        {
            value: 'query',
            label: 'Payslip Queries',
            content: <PayslipQuery id={id} />
        }
    ];
    return <RbgTabs currentTab={tab} setCurrentTab={(t) => setTab(t)} tabs={tabs} />;
}

ViewPayslip.propTypes = {
    id: PropTypes.number.isRequired
};

export default ViewPayslip;
