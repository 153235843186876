import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { Paper, useTheme } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Card](https://mui.com/material-ui/react-card/)
 * - [Paper](https://mui.com/material-ui/react-paper/)
 *
 * API:
 *
 * - [Paper API](https://mui.com/material-ui/api/paper/)
 */

const RbgPaper = forwardRef(({ children, hoverEffect, sx = {}, ...others }, ref) => {
    const theme = useTheme();
    return (
        <Paper
            ref={ref}
            sx={
                hoverEffect
                    ? {
                          transition: 'all .2s ease-in-out',
                          '&:hover': { borderColor: theme.palette.primary.main, transition: 'all .2s ease-in-out' },
                          ...sx
                      }
                    : sx
            }
            {...others}
        >
            {children}
        </Paper>
    );
});

export default RbgPaper;

RbgPaper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
    hoverEffect: PropTypes.bool,
    sx: PropTypes.object
};
