import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../../ui_component/loading/CardLoader';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import { useEffect, useState } from 'react';
import DraftPayslipType from './DraftPayslipType';
import dayjs from 'dayjs';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import EventDispatcher from '../../../../../utils/EventDispatcher';

function DraftPayslipAdmin() {
    const [tab, setTab] = useState('1');
    const [year, setYear] = useState('2025');
    const { data, isFetched, error, refetch } = useQuery({
        queryKey: [{ url: '/staff/payroll/periods', params: { page: 1, year } }, 'draft_payslips']
    });
    useEffect(() => {
        const id = EventDispatcher.subscribe('pusher-message', (event) => {
            if (event.event === 'reload-periods') {
                refetch();
            }
        });
        setTimeout(() => {
            EventDispatcher.dispatch('pusher-subscribe', 'private-payroll-periods');
        }, 200);
        return () => {
            EventDispatcher.unsubscribe(id);
        };
    }, [refetch]);
    if (!isFetched) {
        return <CardLoader />;
    }

    const types = data.data
        .map((period) => period.type.data)
        .filter((type, index, self) => index === self.findIndex((t) => t.id === type.id))
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((t) => {
            return {
                ...t,
                items: data.data
                    .filter((item) => item.type.data.id === t.id)
                    .sort((a, b) => {
                        return dayjs(a.start_date).isBefore(dayjs(b.start_date)) ? -1 : 1;
                    })
            };
        });

    const tabs = types.map((type) => ({
        label: type.name,
        value: `${type.id}`,
        content: <DraftPayslipType type={type} />
    }));

    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    return (
        <MainCard
            title="Payslip Admin"
            secondary={
                <RbgSelect
                    label="Financial Year"
                    name="year"
                    value={year}
                    handleChange={(e) => setYear(e.target.value)}
                    values={[2024, 2025, 2026, 2027, 2028, 2029].map((y) => ({
                        value: y,
                        label: `FY ${y}`
                    }))}
                />
            }
        >
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgTabs currentTab={tab} setCurrentTab={(tab) => setTab(tab)} tabs={tabs} />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default DraftPayslipAdmin;
