import PropTypes from 'prop-types';

// material-ui imports
import { Fade, Tooltip } from '@mui/material';

function RbgTooltip({ title, placement = 'top', block, arrow = true, children, style, sx, ...others }) {
    return (
        <Tooltip sx={sx} title={title} placement={placement} arrow={arrow} TransitionComponent={Fade} {...others}>
            <span style={{ display: block ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center', ...style }}>{children}</span>
        </Tooltip>
    );
}

RbgTooltip.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    placement: PropTypes.string,
    style: PropTypes.object,
    sx: PropTypes.object,
    arrow: PropTypes.bool,
    block: PropTypes.bool
};

export default RbgTooltip;
