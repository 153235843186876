import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import { staffNotification } from '../../StaffDataTypes';
import NotificationField from './NotificationField';
import _ from 'lodash';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import NotificationEndpoints from './NotificationEndpoints';
import NotificationRules from './NotificationRules';

function EditNotification({ notification }) {
    const [newNotification, setNewNotification] = useState(
        notification || {
            id: null,
            name: '',
            start_date: '',
            end_date: '',
            type: { data: {} },
            action: { data: {} },
            rules: { data: [] },
            endpoints: { data: [] },
            fields: { data: [] },
            staff_action: { data: [] },
            document_type: { data: [] }
        }
    );

    const [type, setType] = useState(notification ? notification.type.data : null);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation(
                newNotification.id ? 'PATCH' : 'POST',
                newNotification.id ? `/staff/notification/${newNotification.id}` : '/staff/notification',
                newNotification
            );
        },
        onMutate: () => {
            DialogHelper.disableSaveButton();
            const data = {
                type_id: newNotification.type.data.id,
                action_id: newNotification.action.data.id
            };
            if (newNotification.staff_action) {
                newNotification.staff_action_id = newNotification.staff_action.data.id;
            }
            if (newNotification.document_type) {
                newNotification.document_type_id = newNotification.document_type.data.id;
            }
            return data;
        },
        onError: (e) => {
            DialogHelper.enableSaveButton();
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('staff_notifications') > -1;
                }
            });
            DialogHelper.closeDialog();
        }
    });

    const handleFieldChange = (e) => {
        setNewNotification((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const handleSubFieldChange = (field) => {
        let fields = _.extend([], newNotification.fields.data);
        fields = fields.map((f) => {
            if (f.id === field.id) {
                return field;
            }
            return f;
        });
        setNewNotification((prev) => ({ ...prev, fields: { data: fields } }));
    };
    useEffect(() => {
        if (!newNotification.id && newNotification.type.data.id) {
            const fields = newNotification.type.data.type_fields.data.map((field) => {
                return {
                    id: _.uniqueId('new'),
                    value: field.default,
                    type: { data: field }
                };
            });
            setNewNotification((prev) => ({ ...prev, fields: { data: fields } }));
        }
    }, [newNotification.type.data, newNotification.id]);

    useEffect(() => {
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save');
    }, [mutation]);

    useEffect(() => {
        setNewNotification((prev) => ({ ...prev, type_id: newNotification.type.data.id }));
    }, [newNotification.type.data]);
    useEffect(() => {
        setNewNotification((prev) => ({ ...prev, action_id: newNotification.action.data.id }));
    }, [newNotification.action.data]);
    useEffect(() => {
        setNewNotification((prev) => ({ ...prev, staff_action_id: newNotification.staff_action.data.id }));
    }, [newNotification.staff_action.data]);

    const isActionType = newNotification.action.data.requires_action;
    const isDocumentType = newNotification.action.data.requires_document;
    return (
        <RbgGrid container spacing={gridSpacing}>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgTextInput
                    name="name"
                    value={newNotification.name}
                    onChange={handleFieldChange}
                    label="Name"
                    help="A helpful name for the notification"
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgRemoteSelect
                    endpoint="/staff/notifications/types"
                    name="type_id"
                    help="The type of notification to be sent. This cannot be changed after being created, if you are unsure ask I.T"
                    disabled={newNotification.id !== null}
                    label="Notification Type"
                    useAutoComplete
                    handleObjectChange={(e) =>
                        handleFieldChange({
                            target: {
                                name: 'type',
                                value: { data: e.target.object ? e.target.object : {} }
                            }
                        })
                    }
                    value={newNotification.type.data.id}
                    filterData={(data) => {
                        return data.data.map((row) => ({
                            value: row.id,
                            label: row.name,
                            object: row
                        }));
                    }}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgRemoteSelect
                    endpoint="/staff/notifications/actions"
                    name="action"
                    label="Trigger on"
                    useAutoComplete
                    handleObjectChange={(e) =>
                        handleFieldChange({
                            target: {
                                name: 'action',
                                value: { data: e.target.object ? e.target.object : {} }
                            }
                        })
                    }
                    value={newNotification.action.data.id}
                    filterData={(data) => {
                        return data.data.map((row) => ({
                            value: row.id,
                            label: row.name,
                            object: row
                        }));
                    }}
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgDatePicker
                    name="start_date"
                    value={newNotification.start_date}
                    help="The date of when you want the notification to begin. This can be empty to start immediately"
                    onChange={(date) =>
                        handleFieldChange({
                            target: {
                                name: 'start_date',
                                value: date ? date.format('YYYY-MM-DD') : null
                            }
                        })
                    }
                    label="Start Date"
                />
            </RbgGrid>
            <RbgGrid item xs={6}>
                <RbgDatePicker
                    name="end_date"
                    value={newNotification.end_date}
                    help="The date of when you want the notification to stop being triggered. This can be empty to never stop"
                    onChange={(date) =>
                        handleFieldChange({
                            target: {
                                name: 'end_date',
                                value: date ? date.format('YYYY-MM-DD') : null
                            }
                        })
                    }
                    label="End Date"
                />
            </RbgGrid>
            {isActionType && (
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint="/staff/action-types"
                        name="staff_action"
                        label="Action"
                        useAutoComplete
                        required
                        help="Staff action linked to this notification"
                        handleObjectChange={(e) =>
                            handleFieldChange({
                                target: {
                                    name: 'staff_action',
                                    value: { data: e.target.object ? e.target.object : {} }
                                }
                            })
                        }
                        value={newNotification.staff_action.data.id}
                        filterData={(data) => {
                            return data.data.map((row) => ({
                                value: row.id,
                                label: row.name,
                                object: row
                            }));
                        }}
                    />
                </RbgGrid>
            )}
            {isDocumentType && (
                <RbgGrid item xs={12}>
                    <RbgRemoteSelect
                        endpoint="/staff/documents/types"
                        name="staff_document"
                        label="Document Type"
                        useAutoComplete
                        required
                        help="Document type linked to this notification"
                        handleObjectChange={(e) =>
                            handleFieldChange({
                                target: {
                                    name: 'document_type',
                                    value: { data: e.target.object ? e.target.object : {} }
                                }
                            })
                        }
                        value={newNotification.document_type.data.id}
                        filterData={(data) => {
                            return data.data.map((row) => ({
                                value: row.id,
                                label: row.name,
                                object: row
                            }));
                        }}
                    />
                </RbgGrid>
            )}
            {newNotification.type.data.id && newNotification.fields.data.length > 0 && (
                <>
                    {newNotification.fields.data.map((field, index) => {
                        return (
                            <RbgGrid xs={12} key={index} item>
                                <NotificationField field={field} key={index} handleFieldChange={handleSubFieldChange} />
                            </RbgGrid>
                        );
                    })}
                </>
            )}
            <RbgGrid item xs={12}>
                <fieldset style={{ padding: '10px' }}>
                    <legend>Rules</legend>
                    <NotificationRules
                        rules={newNotification.rules.data}
                        handleChange={(rules) => {
                            handleFieldChange({ target: { name: 'rules', value: { data: rules } } });
                        }}
                    />
                </fieldset>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <fieldset style={{ padding: '10px' }}>
                    <legend>Endpoints</legend>
                    <NotificationEndpoints
                        endpoints={newNotification.endpoints.data}
                        type={newNotification?.type?.data}
                        handleChange={(endpoints) => {
                            handleFieldChange({ target: { name: 'endpoints', value: { data: endpoints } } });
                        }}
                    />
                </fieldset>
            </RbgGrid>
        </RbgGrid>
    );
}

EditNotification.propTypes = {
    notification: PropTypes.shape(staffNotification)
};
export default EditNotification;
