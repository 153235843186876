import PropTypes from 'prop-types';
import { useState } from 'react';
// mui imports
import { Collapse, Table, TableBody, useTheme } from '@mui/material';
// 3rd party
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
// mui wrappers
import RbgTableRow from 'ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgButton from 'ui_component/mui-wrappers/Button/RbgButton';
import RbgTableCell from 'ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgDivider from 'ui_component/mui-wrappers/Divider/RbgDivider';
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
// project imports
import CurrencyFormatter from 'utils/currencyFormatter';
import ShiftCell from '../../ShiftCell';

function RotaArea({ area, days }) {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    let totalHours = 0.0;
    let totalCost = 0.0;
    const cellWidth = '110px';

    const handleExpand = () => {
        if (totalCost === 0) return;
        setExpanded(!expanded);
    };

    const dayCells = days.map((d) => {
        const shifts = area.shifts.filter((s) => s.rota_day_id === d.id);
        const cost = shifts.reduce((value, s) => s.cost + value, 0.0);
        const hours = shifts.reduce((value, s) => s.length_hours + value, 0.0);
        totalHours += hours;
        totalCost += cost;
        return (
            <RbgTableCell sx={{ minWidth: cellWidth }} key={d.id} align="center">
                {hours} - <CurrencyFormatter value={cost} />
            </RbgTableCell>
        );
    });

    const areaRows = area.staff.map((s) => {
        const totalHours = area.shifts.filter((sh) => sh.staff_id === s.id).reduce((hours, sh) => sh.length_hours + hours, 0);
        const totalCost = area.shifts.filter((sh) => sh.staff_id === s.id).reduce((cost, sh) => sh.cost + cost, 0);
        return (
            <RbgTableRow key={s.id}>
                <RbgTableCell sx={{ minWidth: '150px' }} align="center">
                    {s.forename} {s.surname}
                </RbgTableCell>
                {days.map((d) => {
                    const shifts = area.shifts.filter((sh) => d.id === sh.rota_day_id && sh.staff_id === s.id);
                    return (
                        <RbgTableCell sx={{ minWidth: cellWidth }} key={d.id} align="center">
                            {shifts.map((s) => (
                                <ShiftCell shifts={shifts} key={s.id} />
                            ))}
                        </RbgTableCell>
                    );
                })}
                <RbgTableCell sx={{ minWidth: cellWidth }} align="center">
                    {totalHours}
                </RbgTableCell>
                <RbgTableCell sx={{ minWidth: cellWidth }} align="center">
                    <CurrencyFormatter value={totalCost} />
                </RbgTableCell>
            </RbgTableRow>
        );
    });

    return (
        <>
            <RbgTableRow sx={{ backgroundColor: theme.palette.mode === 'light' ? '#0001' : '#fff1' }}>
                <RbgTableCell sx={{ p: 0, minWidth: '152px' }} onClick={handleExpand} align="center">
                    <RbgButton sx={{ p: 0, width: '100%', borderRadius: 0 }} color="info" disabled={totalHours === 0}>
                        <RbgStack sx={{ p: 2, width: '100%' }} direction="row" justifyContent="space-between" alignItems="center">
                            <RbgTypography>{area.name}</RbgTypography>
                            {expanded ? <IconChevronUp /> : <IconChevronDown />}
                        </RbgStack>
                    </RbgButton>
                </RbgTableCell>
                {dayCells}
                <RbgTableCell sx={{ minWidth: cellWidth }} align="center">
                    {totalHours}
                </RbgTableCell>
                <RbgTableCell sx={{ minWidth: cellWidth }} align="center">
                    <CurrencyFormatter value={totalCost} />
                </RbgTableCell>
            </RbgTableRow>
            <RbgTableRow>
                <RbgTableCell sx={{ p: 1 }} colSpan={10} align="center">
                    <RbgDivider />
                </RbgTableCell>
            </RbgTableRow>
            <RbgTableRow>
                <RbgTableCell sx={{ p: 0, m: 0 }} colSpan={10} align="center">
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Table sx={{ p: 0, m: 0 }} size="small">
                            <TableBody>{areaRows}</TableBody>
                        </Table>
                    </Collapse>
                </RbgTableCell>
            </RbgTableRow>
        </>
    );
}

RotaArea.propTypes = {
    area: PropTypes.object.isRequired,
    days: PropTypes.array.isRequired
};
export default RotaArea;
