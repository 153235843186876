import PropTypes from 'prop-types';
import { contractSection } from '../../StaffDataTypes';
import { useEffect, useState } from 'react';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import MDEditor from '@uiw/react-md-editor';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';

function ContractEditSection({ section }) {
    const [newSection, setNewSection] = useState(
        section || {
            name: '',
            heading: '',
            content: '',
            archived: false
        }
    );
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', '/staff/staff-contract-admin/section', newSection);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries('contract_sections');
        }
    });

    useEffect(() => {
        if (JSON.stringify(section) !== JSON.stringify(newSection)) {
            DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', false);
            return;
        }
        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', true);
    }, [section, newSection, mutation]);

    return (
        <RbgGrid container>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={5}>
                <RbgTextInput
                    name="name"
                    value={newSection.name}
                    label="Name"
                    onChange={(e) => setNewSection((prev) => ({ ...prev, name: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={5}>
                <RbgTextInput
                    name="heading"
                    value={newSection.heading}
                    label="Heading"
                    onChange={(e) => setNewSection((prev) => ({ ...prev, heading: e.target.value }))}
                />
            </RbgGrid>
            <RbgGrid item xs={2}>
                <RbgCheckbox
                    name="archived"
                    checked={newSection.archived}
                    onChange={(e) => setNewSection((prev) => ({ ...prev, archived: e.target.checked }))}
                    label="Archived"
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <MDEditor
                    value={newSection.content}
                    onChange={(value) => setNewSection((prev) => ({ ...prev, content: value }))}
                    height={500}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

ContractEditSection.propTypes = {
    section: PropTypes.shape(contractSection)
};

export default ContractEditSection;
