// theme constant
export const gridSpacing = 2;
export const gridContainerMarginTop = { mt: -(gridSpacing - 1) };
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const iconSize = 20.8;
export const MUIbtnDisabled = {
    '&.Mui-disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto'
    },
    '&.MuiButton-root:hover': {
        backgroundColor: 'unset'
    }
};
