import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { gridSpacing } from '../../../../store/constant';
import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../ui_component/extended/Form/RbgTextInput';
import MainCard from '../../../../ui_component/cards/MainCard';
import dayjs from 'dayjs';
import RbgRemoteSelect from '../../../../ui_component/remote-select/RbgRemoteSelect';
import RbgCheckbox from '../../../../ui_component/mui-wrappers/RbgCheckbox';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import DatePartPicker from '../../../../ui_component/date-pickers/DatePartPicker';
import RbgTooltip from '../../../../ui_component/tooltip/RbgTooltip';
import { IconExclamationCircle, IconInfoCircle } from '@tabler/icons-react';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTable from '../../../../ui_component/mui-wrappers/Table/RbgTable';
import RbgTableHead from '../../../../ui_component/mui-wrappers/TableHead/RbgTableHead';
import RbgTableRow from '../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from '../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import RbgTableBody from '../../../../ui_component/mui-wrappers/TableBody/RbgTableBody';
import ServerSidePagination from '../../../../ui_component/pagination/ServerSidePagination';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import SortableTableHead from '../../../../ui_component/mui-wrappers/TableCell/SortableTableHead';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import { AlternateEmail, Email, PhoneIphone } from '@mui/icons-material';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { snackbarSuccess } from '../../../../store/reducers/snackbarReducer';
import { useDispatch } from 'react-redux';
import RbgIcon from '../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import RbgLink from '../../../../ui_component/mui-wrappers/Link/RbgLink';
import { useDebounce } from '@uidotdev/usehooks';

dayjs.extend(advancedFormat);
function StaffSearch() {
    const [filter, setFilter] = useState({
        page: 1,
        partial_name: '',
        email: '',
        role_id: '',
        locations: [],
        payroll_ref: '',
        birth_month: '',
        birth_day_of_month: '',
        birth_year: '',
        start_month: '',
        start_day_of_month: '',
        start_year: '',
        leave_month: '',
        leave_day_of_month: '',
        leave_year: '',
        live_only: true,
        order_by: 'hierarchy_level',
        order_dir: 'desc'
    });

    const [pagination, setPagination] = useState({
        count: 1,
        current_page: 1,
        per_page: 1,
        total: 1,
        total_pages: 1
    });

    const filterData = useDebounce(filter, 1000);

    const { data, refetch, isFetching, error } = useQuery({
        queryKey: [{ url: '/staff/search', params: filterData }],
        initialData: {
            data: [],
            meta: {
                pagination
            }
        }
    });
    const dispatch = useDispatch();
    useEffect(() => {
        // Store a state of pagination so the initial data is prefixed with the previous state, to stop the page "jumping" around
        setPagination(data.meta.pagination);
    }, [data.meta.pagination]);

    return (
        <MainCard title="Search Team Members">
            <RbgGrid container spacing={gridSpacing}>
                <RbgGrid item xs={12}>
                    <RbgGrid container spacing={gridSpacing}>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <RbgTextInput
                                name="partial_name"
                                label="Name"
                                value={filter.partial_name}
                                onChange={(e) => setFilter((prev) => ({ ...prev, partial_name: e.target.value }))}
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <RbgTextInput
                                name="email"
                                label="E-Mail"
                                value={filter.email}
                                onChange={(e) => setFilter((prev) => ({ ...prev, email: e.target.value }))}
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <RbgTextInput
                                name="payroll_ref"
                                label="Payroll Ref"
                                value={filter.payroll_ref}
                                onChange={(e) => setFilter((prev) => ({ ...prev, payroll_ref: e.target.value }))}
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <DatePartPicker
                                name="birth_date"
                                label="DOB"
                                onChange={(e) =>
                                    setFilter((prev) => ({
                                        ...prev,
                                        birth_year: e.target.value.year,
                                        birth_month: e.target.value.month,
                                        birth_day_of_month: e.target.value.day
                                    }))
                                }
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <DatePartPicker
                                name="start_date"
                                label="Start Date"
                                onChange={(e) =>
                                    setFilter((prev) => ({
                                        ...prev,
                                        start_year: e.target.value.year,
                                        start_month: e.target.value.month,
                                        start_day_of_month: e.target.value.day
                                    }))
                                }
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <RbgRemoteSelect
                                filterData={(data) => {
                                    return data.data.map((row) => {
                                        return {
                                            value: row.id,
                                            label: row.name
                                        };
                                    });
                                }}
                                useAutoComplete
                                label="Position"
                                endpoint="/staff/positions"
                                name="role_id"
                                value={filter.role_id}
                                handleChange={(e) => setFilter((prev) => ({ ...prev, role_id: e.target.value }))}
                            />
                        </RbgGrid>
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <RbgRemoteSelect
                                useAutoComplete
                                endpoint="/staff/locations"
                                name="location"
                                placeholder="Location"
                                label="Location"
                                value={filter.cost_centre}
                                handleChange={(e) => setFilter((prev) => ({ ...prev, cost_centre: e.target.value }))}
                                filterData={(data) =>
                                    data.data
                                        // .filter((location) => location.type.data.central === false)
                                        .map((location) => ({
                                            id: location.cost_centre,
                                            label: location.name,
                                            value: location.cost_centre,
                                            object: location
                                        }))
                                }
                            />
                        </RbgGrid>
                        {!filter.live_only && (
                            <RbgGrid item xs={12} md={6} lg={3}>
                                <DatePartPicker
                                    name="leave_date"
                                    label="Leave Date"
                                    onChange={(e) =>
                                        setFilter((prev) => ({
                                            ...prev,
                                            leave_year: e.target.value.year,
                                            leave_month: e.target.value.month,
                                            leave_day_of_month: e.target.value.day
                                        }))
                                    }
                                />
                            </RbgGrid>
                        )}
                        <RbgGrid item xs={12} md={6} lg={3}>
                            <RbgCheckbox
                                label="Active Only"
                                checked={filter.live_only}
                                onChange={(e) =>
                                    setFilter((prev) => ({
                                        ...prev,
                                        live_only: e.target.checked,
                                        leave_day: '',
                                        leave_month: '',
                                        leave_year: ''
                                    }))
                                }
                            />
                        </RbgGrid>
                    </RbgGrid>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination
                        pagination={data.meta.pagination}
                        changePage={(page) => setFilter((prev) => ({ ...prev, page }))}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <RbgTable>
                        <RbgTableHead>
                            <RbgTableRow>
                                <RbgTableCell size="10" />
                                <SortableTableHead
                                    currentSortDir={filter.order_dir}
                                    currentSort={filter.order_by}
                                    sortField="display_name"
                                    text="Name"
                                    handleSortChange={(field, dir) => {
                                        setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                    }}
                                />
                                <RbgTableCell>Contacts</RbgTableCell>
                                <SortableTableHead
                                    currentSortDir={filter.order_dir}
                                    currentSort={filter.order_by}
                                    sortField="payroll_ref"
                                    text="Payroll Ref"
                                    handleSortChange={(field, dir) => {
                                        setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                    }}
                                />
                                <SortableTableHead
                                    currentSortDir={filter.order_dir}
                                    currentSort={filter.order_by}
                                    sortField="hierarchy_level"
                                    text="Position"
                                    handleSortChange={(field, dir) => {
                                        setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                    }}
                                />
                                <SortableTableHead
                                    currentSortDir={filter.order_dir}
                                    currentSort={filter.order_by}
                                    sortField="location_name"
                                    text="Location"
                                    handleSortChange={(field, dir) => {
                                        setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                    }}
                                />
                                <SortableTableHead
                                    currentSortDir={filter.order_dir}
                                    currentSort={filter.order_by}
                                    sortField="birth_date"
                                    text="Birth Date"
                                    handleSortChange={(field, dir) => {
                                        setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                    }}
                                />
                                <SortableTableHead
                                    currentSortDir={filter.order_dir}
                                    currentSort={filter.order_by}
                                    sortField="start_date"
                                    text="Start Date"
                                    handleSortChange={(field, dir) => {
                                        setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                    }}
                                />
                                {!filter.live_only && (
                                    <SortableTableHead
                                        currentSortDir={filter.order_dir}
                                        currentSort={filter.order_by}
                                        sortField="leave_date"
                                        text="Leave Date"
                                        handleSortChange={(field, dir) => {
                                            setFilter((prev) => ({ ...prev, order_by: field, order_dir: dir }));
                                        }}
                                    />
                                )}
                            </RbgTableRow>
                        </RbgTableHead>
                        <RbgTableBody>
                            {isFetching && (
                                <RbgTableRow>
                                    <RbgTableCell colSpan={filter.live_only ? 8 : 9}>
                                        <CardLoader />
                                    </RbgTableCell>
                                </RbgTableRow>
                            )}
                            {error && (
                                <RbgTableRow>
                                    <RbgTableCell colSpan={filter.live_only ? 8 : 9}>
                                        <ErrorCard>{error}</ErrorCard>
                                    </RbgTableCell>
                                </RbgTableRow>
                            )}
                            {data.data.map((row) => {
                                const errorActions = row.actions.data.filter((action) => action.severity_id === 3);
                                const warningActions = row.actions.data.filter((action) => action.severity_id === 2);
                                const infoActions = row.actions.data.filter((action) => action.severity_id === 1);
                                const icons = row.actions.data.filter((action) => action.severity_id === 4);
                                return (
                                    <RbgTableRow key={row.id}>
                                        <RbgTableCell size="5">
                                            <RbgStack direction="row" justifyContent="center">
                                                {errorActions.length > 0 && (
                                                    <RbgTooltip
                                                        title={errorActions.map((action) => action.description_rendered).join(', ')}
                                                    >
                                                        <IconExclamationCircle color="red" size={15} />
                                                    </RbgTooltip>
                                                )}
                                                {warningActions.length > 0 && (
                                                    <RbgTooltip
                                                        title={warningActions.map((action) => action.description_rendered).join(', ')}
                                                    >
                                                        <IconExclamationCircle color="orange" size={15} />
                                                    </RbgTooltip>
                                                )}
                                                {infoActions.length > 0 && (
                                                    <RbgTooltip title={infoActions.map((action) => action.description_rendered).join(', ')}>
                                                        <IconInfoCircle color="blue" size={15} />
                                                    </RbgTooltip>
                                                )}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>
                                            <RbgLink router to={`/staff/view/${row.id}`}>
                                                {row.display_name}
                                            </RbgLink>
                                            <RbgStack direction="row" spacing={1} useflexgap justifyContent="center">
                                                {icons.map((icon) => {
                                                    return (
                                                        <RbgTooltip
                                                            key={icon.id}
                                                            title={icon.description_rendered}
                                                            sx={{ display: 'inline' }}
                                                        >
                                                            <RbgIcon name={icon.icon} />
                                                        </RbgTooltip>
                                                    );
                                                })}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>
                                            <RbgStack direction="row">
                                                {row.email_address && (
                                                    <RbgTooltip title={`Personal Email: ${row.email_address}`}>
                                                        <RbgButton
                                                            onClick={() => {
                                                                dispatch(snackbarSuccess('Personal Email Copied'));
                                                                window.navigator.clipboard.writeText(row.email_address);
                                                            }}
                                                            icon={<AlternateEmail />}
                                                        />
                                                    </RbgTooltip>
                                                )}
                                                {row.work_email_address && (
                                                    <RbgTooltip title={`Work Email: ${row.work_email_address}`}>
                                                        <RbgButton
                                                            onClick={() => {
                                                                dispatch(snackbarSuccess('Work Email Copied'));
                                                                window.navigator.clipboard.writeText(row.work_email_address);
                                                            }}
                                                            icon={<Email />}
                                                        />
                                                    </RbgTooltip>
                                                )}
                                                {row.mobile && (
                                                    <RbgTooltip title={row.mobile}>
                                                        <RbgButton
                                                            onClick={() => {
                                                                dispatch(snackbarSuccess('Mobile copied'));
                                                                window.navigator.clipboard.writeText(row.mobile);
                                                            }}
                                                            icon={<PhoneIphone />}
                                                        />
                                                    </RbgTooltip>
                                                )}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>{row.payroll_ref}</RbgTableCell>
                                        <RbgTableCell>{row.role_name}</RbgTableCell>
                                        <RbgTableCell>{row.location_name}</RbgTableCell>
                                        <RbgTableCell>{dayjs(row.birth_date).format('DD/MM/YYYY')}</RbgTableCell>
                                        <RbgTableCell>{dayjs(row.start_date).format('DD/MM/YYYY')}</RbgTableCell>
                                        {!filter.live_only && (
                                            <RbgTableCell>
                                                {row.leave_date ? dayjs(row.leave_date).format('DD/MM/YYYY') : 'N/A'}
                                            </RbgTableCell>
                                        )}
                                    </RbgTableRow>
                                );
                            })}
                        </RbgTableBody>
                    </RbgTable>
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <ServerSidePagination
                        pagination={data.meta.pagination}
                        changePage={(page) => setFilter((prev) => ({ ...prev, page }))}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default StaffSearch;
