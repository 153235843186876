import PropTypes from 'prop-types';
// mui wrappers
import RbgButton from 'ui_component/mui-wrappers/Button/RbgButton';
import RbgDivider from 'ui_component/mui-wrappers/Divider/RbgDivider';
import RbgList from 'ui_component/mui-wrappers/List/RbgList';
import RbgListItem from 'ui_component/mui-wrappers/ListItem/RbgListItem';
import RbgListItemText from 'ui_component/mui-wrappers/ListItemText/RbgListItemText';
// project
import MainCard from 'ui_component/cards/MainCard';
import EventDispatcher from 'utils/EventDispatcher';
import { DialogHelper } from 'ui_component/modals/RbgDialog';
import EditDetails from './EditGroups/EditDetails';
import dayjs from 'dayjs';
import { staffDetails } from '../../../StaffDataTypes';

function DetailsGroup({ staffDetails, getError, hasEditPermissions }) {
    const handleSave = () => {
        EventDispatcher.dispatch('reload-staff-member', staffDetails.staff_id);
        DialogHelper.closeDialog();
    };

    const openEdit = () => {
        DialogHelper.updateDialog({ maxWidth: 'sm', saveAction: handleSave, height: 'fit-content' });
        DialogHelper.openDialog('Edit Details', <EditDetails staffDetails={staffDetails} getError={getError} />);
    };

    const addressParts = ['address_line1', 'address_line2', 'address_line3', 'address_county', 'address_postal_code'];
    const address = addressParts.map((name) => staffDetails[name]);
    return (
        <MainCard
            sx={{ height: '100%' }}
            title="Details"
            secondary={
                <RbgButton size="small" variant="contained" color="primary" onClick={openEdit} disabled={hasEditPermissions === false}>
                    Edit
                </RbgButton>
            }
        >
            <RbgList>
                <RbgListItem>
                    <RbgListItemText primary="Name" secondary={`${staffDetails.full_legal_name}`} />
                </RbgListItem>
                <RbgDivider />
                {staffDetails.known_as && (
                    <>
                        <RbgListItem>
                            <RbgListItemText primary="Known As" secondary={staffDetails.known_as} />
                        </RbgListItem>
                        <RbgDivider />
                    </>
                )}
                <RbgListItem>
                    <RbgListItemText
                        primary="Date of Birth"
                        secondary={
                            staffDetails.birth_date ? `${dayjs(staffDetails.birth_date).format('DD/MM/YYYY')} (${staffDetails.age})` : 'N/A'
                        }
                    />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="Ethnicity" secondary={staffDetails.ethnicity_name} />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="Gender" secondary={staffDetails.gender_name} />
                </RbgListItem>
                <RbgDivider />
                <RbgListItem>
                    <RbgListItemText primary="Address" secondary={address.filter((item) => item && item.length > 1).join(', ')} />
                </RbgListItem>
            </RbgList>
        </MainCard>
    );
}

export default DetailsGroup;

DetailsGroup.propTypes = {
    staffDetails: PropTypes.shape(staffDetails),
    getError: PropTypes.func,
    hasEditPermissions: PropTypes.bool
};
