import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui imports
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';

function RbgDatePicker({
    label,
    value,
    onChange,
    orientation,
    sx,
    inputFormat = 'DD/MM/YYYY',
    disabled,
    InputProps,
    size = 'small',
    error,
    minDate,
    maxDate,
    ...others
}) {
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);

    const styles = error
        ? {
              '.MuiFormLabel-root': { color: 'red' },
              fieldset: { borderColor: 'red' },
              '.MuiFormHelperText-root': { color: 'red' }
          }
        : sx;

    if (matchesMd) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    label={label}
                    orientation={orientation}
                    format={inputFormat}
                    value={value ? dayjs(value) : null}
                    onChange={onChange}
                    disabled={disabled}
                    minDate={minDate ? dayjs(minDate) : null}
                    maxDate={maxDate ? dayjs(maxDate) : null}
                    slotProps={{
                        textField: {
                            sx: styles,
                            variant: 'outlined',
                            fullWidth: true,
                            disabled,
                            size,
                            error: !!error,
                            ...InputProps
                        }
                    }}
                    {...others}
                />
            </LocalizationProvider>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                label={label}
                orientation={orientation}
                open={disabled ? false : open}
                onClose={() => setOpen(false)}
                value={value ? dayjs(value) : null}
                format={inputFormat}
                onChange={onChange}
                disabled={disabled}
                minDate={minDate ? dayjs(minDate) : null}
                maxDate={maxDate ? dayjs(maxDate) : null}
                slotProps={{
                    textField: {
                        sx: styles,
                        variant: 'outlined',
                        fullWidth: true,
                        disabled,
                        size,
                        error: !!error,
                        onClick: () => setOpen(true),
                        helperText: error || null,
                        ...InputProps
                    }
                }}
                {...others}
            />
        </LocalizationProvider>
    );
}

export default RbgDatePicker;

RbgDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    orientation: PropTypes.string,
    inputFormat: PropTypes.string,
    InputProps: PropTypes.object,
    sx: PropTypes.object,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    minDate: PropTypes.any,
    maxDate: PropTypes.any
};
