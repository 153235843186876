import PropTypes from 'prop-types';
import RbgMenu from '../mui-wrappers/Menu/RbgMenu';
import RbgMenuItem from '../mui-wrappers/Menu/RbgMenuItem';
import { useState } from 'react';
import RbgTooltip from '../tooltip/RbgTooltip';
import RbgButton from '../mui-wrappers/Button/RbgButton';
import { IconChevronDown } from '@tabler/icons-react';
import { iconSize } from '../../store/constant';

export default function DropdownMenu({
    menuItems,
    tooltipTitle = '',
    menuProps,
    menuLabel = '',
    buttonColor = 'primary',
    buttonSize = 'small'
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <RbgTooltip title={tooltipTitle} placement="top" arrow>
                {menuLabel ? (
                    <RbgButton disableRipple color={buttonColor} size={buttonSize} onClick={handleOpenMenu}>
                        {menuLabel}
                        <IconChevronDown style={{ marginLeft: '4px' }} size={iconSize} />
                    </RbgButton>
                ) : (
                    <RbgButton
                        disableRipple
                        color={buttonColor}
                        size={buttonSize}
                        onClick={handleOpenMenu}
                        icon={<IconChevronDown size={iconSize} />}
                    />
                )}
            </RbgTooltip>
            <RbgMenu anchorEl={anchorEl} open={open} onClose={handleClose} {...menuProps}>
                {menuItems.map((item, idx) => {
                    const handleClick = () => {
                        if (item.onClick) {
                            item.onClick();
                        }
                        handleClose();
                    };
                    return (
                        <RbgMenuItem key={idx} onClick={handleClick} {...item.menuItemProps}>
                            {item.content}
                        </RbgMenuItem>
                    );
                })}
            </RbgMenu>
        </>
    );
}

DropdownMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    tooltipTitle: PropTypes.string,
    menuProps: PropTypes.object,
    menuLabel: PropTypes.string,
    buttonColor: PropTypes.oneOf(['secondary', 'success', 'inherit', 'warning', 'error', 'primary', 'info']),
    buttonSize: PropTypes.oneOf(['small', 'medium', 'large'])
};
