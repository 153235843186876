import PropTypes from 'prop-types';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import { gridSpacing } from '../../../../../store/constant';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { IconPlus } from '@tabler/icons-react';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import AddEditAccreditation from './AddEditAccreditation';
import AddStaffTrainingCourse from './AddStaffTrainingCourse';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import RcloudAuthContext from '../../../../../contexts/RcloudAuthContext';

function StaffTraining({ staffMember }) {
    const { user } = useContext(RcloudAuthContext);

    const courseIds = [];
    staffMember.training.data.forEach((course) => {
        if (courseIds.indexOf(course.course.data.id) === -1) {
            courseIds.push(course.course.data.id);
        }
    });
    const courses = courseIds.map((courseId) => {
        const attempts = staffMember.training.data
            .filter((course) => course.course.data.id === courseId)
            .sort((course1, course2) => {
                return course1.date_completed_ts > course2.date_completed_ts ? -1 : 1;
            });
        return {
            course: attempts[0].course.data,
            attempts
        };
    });
    return (
        <RbgGrid container spacing={gridSpacing}>
            <RbgGrid item xs={6}>
                <MainCard
                    title="Training Courses"
                    secondary={
                        user.permissions.indexOf('hr_manual_training') > -1 ? (
                            <RbgButton
                                onClick={() => {
                                    DialogHelper.openSmDialog(
                                        'Add Manual Training Course',
                                        <AddStaffTrainingCourse staffMember={staffMember} />
                                    );
                                }}
                            >
                                <IconPlus /> Add
                            </RbgButton>
                        ) : (
                            ''
                        )
                    }
                >
                    <RbgSimpleTable
                        stickyHeader
                        headers={['Course', 'Records', 'Last Completed']}
                        keys={courses.map((course) => course.course.title)}
                        rows={courses.map((training) => {
                            const last = training.attempts[0];
                            return [
                                training.course.title,
                                training.attempts.length,
                                last.date_completed ? dayjs(last.date_completed).format('DD/MM/YYYY HH:mm') : 'N/A'
                            ];
                        })}
                    />
                </MainCard>
            </RbgGrid>
            <RbgGrid item xs={6}>
                <MainCard
                    title="Certifications"
                    secondary={
                        <RbgButton
                            onClick={() => {
                                DialogHelper.openSmDialog('Add New Accreditation', <AddEditAccreditation staffMember={staffMember} />);
                            }}
                        >
                            <IconPlus /> Add New
                        </RbgButton>
                    }
                >
                    <RbgSimpleTable
                        headers={['Course', 'Valid From', 'Valid To']}
                        rows={staffMember.accreditation.data.map((accreditation) => {
                            return [
                                <Link
                                    to="#"
                                    onClick={() => {
                                        DialogHelper.openSmDialog(
                                            'Add New Accreditation',
                                            <AddEditAccreditation staffMember={staffMember} accreditation={accreditation} />
                                        );
                                    }}
                                >
                                    {accreditation.type.data.name}
                                </Link>,
                                dayjs(accreditation.valid_from).format('DD/MM/YYYY'),
                                accreditation.valid_to ? dayjs(accreditation.valid_to).format('DD/MM/YYYY') : 'N/A'
                            ];
                        })}
                    />
                </MainCard>
            </RbgGrid>
        </RbgGrid>
    );
}

StaffTraining.propTypes = {
    staffMember: PropTypes.object.isRequired
};
export default StaffTraining;
