import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// mui wrappers
import RbgTextInput from 'ui_component/extended/Form/RbgTextInput';
import RbgStack from 'ui_component/mui-wrappers/Stack/RbgStack';
import { DialogHelper } from '../../../../../../../ui_component/modals/RbgDialog';
import Api from '../../../../../../../utils/api';

function EditBank({ staffDetails, getError }) {
    const [fieldErrors, setFieldErrors] = useState([]);
    const [bankDetails, setBankDetails] = useState({
        sort_code: staffDetails.sort_code,
        ni_number: staffDetails.ni_number,
        bank_account: staffDetails.bank_account
    });

    useEffect(() => {
        const handleSave = () => {
            setFieldErrors([]);
            Api.patch(`/staff/${staffDetails.staff_id}/financials`, bankDetails).then((response) => {
                if (response.ok) {
                    DialogHelper.closeDialog();
                    DialogHelper.successNotification('Staff member bank details updated');
                    return;
                }
                setFieldErrors(response.field_errors);
            });
        };
        DialogHelper.addSaveButton(() => handleSave(), 'Save');
    }, [bankDetails, staffDetails.staff_id]);

    return (
        <RbgStack sx={{ py: 2 }} spacing={2}>
            <RbgTextInput
                size="small"
                name="sort_code"
                label="Sort Code"
                value={bankDetails.sort_code}
                error={fieldErrors.length > 0 && getError('sort_code', fieldErrors)}
                onChange={(e) => setBankDetails((prev) => ({ ...prev, sort_code: e.target.value }))}
            />
            <RbgTextInput
                size="small"
                name="bank_account"
                label="Bank Account"
                value={bankDetails.bank_account}
                error={fieldErrors.length > 0 && getError('bank_account', fieldErrors)}
                onChange={(e) => setBankDetails((prev) => ({ ...prev, bank_account: e.target.value }))}
            />
            <RbgTextInput
                size="small"
                name="ni_number"
                label="NI Number"
                value={bankDetails.ni_number}
                error={fieldErrors.length > 0 && getError('ni_number', fieldErrors)}
                onChange={(e) => setBankDetails((prev) => ({ ...prev, ni_number: e.target.value }))}
            />
        </RbgStack>
    );
}

export default EditBank;

EditBank.propTypes = {
    getError: PropTypes.func,
    staffDetails: PropTypes.object
};
