import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import MainCard from '../../../../../ui_component/cards/MainCard';
import PageableTable from '../../../../../ui_component/pageable-table/PageableTable';
import RbgFilter from '../../../../../ui_component/extended/Form/RbgFilter';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import EditContractTemplate from './EditContractTemplate';
import { IconCopy, IconEdit, IconPlus } from '@tabler/icons-react';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';

function ContractTemplates() {
    const [filter, setFilter] = useState({
        page: 1,
        include_archived: false,
        search: ''
    });
    const { data } = useQuery({
        queryKey: [{ url: '/staff/staff-contract-admin/templates', params: filter }, 'contract_templates']
    });

    const editTemplate = (template) => {
        DialogHelper.openLgDialog(
            template ? 'Edit Contract Template' : 'Add Contract Template',
            <EditContractTemplate template={template} />
        );
    };
    const copyTemplate = (template) => {
        const newTemplate = { ...template, id: null };
        DialogHelper.openLgDialog('Duplicate Contract Template', <EditContractTemplate template={newTemplate} />);
    };
    const filters = [
        {
            type: 'text',
            name: 'search',
            label: 'Search',
            value: filter.search,
            useDebounce: true
        },
        {
            type: 'checkbox',
            name: 'include_archived',
            label: 'Include Archived',
            value: filter.include_archived
        }
    ];
    return (
        <MainCard title="Contract Templates">
            <RbgGrid container>
                <RbgGrid item xs={12}>
                    <RbgFilter filter={filter} onChange={(filter) => setFilter(filter)} filters={filters} />
                </RbgGrid>
                <RbgGrid item xs={12}>
                    <PageableTable
                        pagination={data.meta.pagination}
                        handlePageChange={(page) => setFilter((prev) => ({ ...prev, page }))}
                        data={data.data}
                        rowDecorator={(row) => {
                            let hours = `${row.min_contracted_hours} - ${row.max_contracted_hours}`;
                            if (row.max_contracted_hours === 0 && row.min_contracted_hours === 0) {
                                hours = '0';
                            }
                            return [
                                row.id,
                                row.name,
                                hours,
                                row.pay_type.data.name,
                                row.min_hierarchy_level,
                                row.locations.length,
                                <BooleanIcon value={row.archived} />,
                                <RbgStack direction="row" justifyContent="center">
                                    <RbgButton onClick={() => editTemplate(row)} icon={<IconEdit size={20} />} variant="outlined" />
                                    <RbgButton icon={<IconCopy size={20} />} onClick={() => copyTemplate(row)} />
                                </RbgStack>
                            ];
                        }}
                        headers={[
                            'ID',
                            'Name',
                            'Hours',
                            'Pay Type',
                            'Hierarchy Level',
                            'Locations',
                            'Archived',
                            <RbgButton onClick={() => editTemplate(null)}>
                                <IconPlus size={20} /> Add
                            </RbgButton>
                        ]}
                    />
                </RbgGrid>
            </RbgGrid>
        </MainCard>
    );
}

export default ContractTemplates;
