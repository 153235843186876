import { createSlice } from '@reduxjs/toolkit';

// ==============================|| ACTIONS REDUCER ||============================== //

export const actionsReducer = createSlice({
    name: 'actions',
    initialState: {
        actions: [],
        page: 1,
        filterCategories: [],
        perPage: 9999,
        pagination: {},
        categories: [],
        severities: [],
        stats: {},
        types: []
    },
    reducers: {
        setActions: (state, action) => {
            state.actions = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setFilterCategories: (state, action) => {
            state.filterCategories = action.payload;
        },
        setPerPage: (state, action) => {
            state.perPage = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setSeverities: (state, action) => {
            state.severities = action.payload;
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
        setTypes: (state, action) => {
            state.types = action.payload;
        }
    }
});

export const { setActions, setPage, setFilterCategories, setPerPage, setPagination, setCategories, setSeverities, setStats, setTypes } =
    actionsReducer.actions;
