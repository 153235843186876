import PropTypes from 'prop-types';
import { payrollPeriod } from './draftPayslipTypes';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';

function EditPayrollPeriod({ payrollPeriod }) {
    const queryClient = useQueryClient();
    const draftMutation = useMutation({
        mutationFn: () => {
            let method = 'POST';
            if (payrollPeriod.draft === true) {
                method = 'DELETE';
            }
            return Api.reactQueryMutation(method, `/staff/payroll/period/${payrollPeriod.id}/draft`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('draft_payslips') > -1;
                }
            });
        }
    });
    const completeMutation = useMutation({
        mutationFn: () => {
            let method = 'POST';
            if (payrollPeriod.completed === true) {
                method = 'DELETE';
            }
            return Api.reactQueryMutation(method, `/staff/payroll/period/${payrollPeriod.id}/complete`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return query.queryKey.indexOf('draft_payslips') > -1;
                }
            });
        }
    });
    return (
        <RbgGrid container>
            {payrollPeriod.draft === false && (
                <RbgGrid item xs={12}>
                    <RbgButton fullWidth color="warning" onClick={() => draftMutation.mutate()}>
                        Mark this period as draft
                    </RbgButton>
                </RbgGrid>
            )}
            {payrollPeriod.draft === true && (
                <RbgGrid item xs={12}>
                    <RbgButton fullWidth color="warning" onClick={() => draftMutation.mutate()}>
                        Remove Draft status
                    </RbgButton>
                </RbgGrid>
            )}
            {payrollPeriod.completed === false && (
                <RbgGrid item xs={12}>
                    <RbgButton fullWidth color="success" onClick={() => completeMutation.mutate()}>
                        Mark this period as complete
                    </RbgButton>
                </RbgGrid>
            )}
            {payrollPeriod.completed === true && (
                <RbgGrid item xs={12}>
                    <RbgButton fullWidth color="error" onClick={() => completeMutation.mutate()}>
                        Remove Complete Status
                    </RbgButton>
                </RbgGrid>
            )}
        </RbgGrid>
    );
}

EditPayrollPeriod.propTypes = {
    payrollPeriod: PropTypes.shape(payrollPeriod).isRequired
};
export default EditPayrollPeriod;
