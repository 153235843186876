import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RbgList from '../../../../ui_component/mui-wrappers/List/RbgList';
import RbgListItem from '../../../../ui_component/mui-wrappers/ListItem/RbgListItem';
import { Alert, Badge, Button, ListItemButton } from '@mui/material';
import IntroCardPrimary from '../../../../ui_component/cards/IntroCardPrimary';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgIcon from '../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTooltip from '../../../../ui_component/tooltip/RbgTooltip';
import PropTypes from 'prop-types';

function ActionList({ data }) {
    const { bar } = useSelector((state) => state.bar);
    const [severity, setSeverity] = useState(null);
    const [filter, setFilter] = useState({
        page: 1,
        include: 'staff_member,type',
        type_id: null,
        location: bar.cost_centre,
        per_page: 9999,
        category_id: null
    });
    const hasLocation = !!bar.cost_centre;
    let immediateCount = 0;
    let warningCount = 0;
    let infoCount = 0;
    data.meta.severity_totals.forEach((item) => {
        if (item.severity_id === 1) {
            infoCount = item.count;
        }
        if (item.severity_id === 3) {
            immediateCount = item.count;
        }
        if (item.severity_id === 2) {
            warningCount = item.count;
        }
    });
    useEffect(() => {
        setFilter((prev) => ({ ...prev, location: bar.cost_centre }));
    }, [bar]);
    useEffect(() => {
        if (severity === null) {
            if (warningCount > 0) {
                setSeverity(2);
            } else if (infoCount > 0) {
                setSeverity(1);
            }
        }
    }, [infoCount, immediateCount, warningCount, severity]);

    const actionList = data.data.filter((item) => {
        return item.type.data.severity.data.id === severity;
    });
    return (
        <RbgGrid container>
            {(warningCount > 0 || immediateCount > 0 || infoCount > 0) && (
                <RbgGrid item xs={12}>
                    <RbgStack direction="row" justifyContent="center" spacing={2}>
                        {warningCount > 0 && (
                            <RbgTooltip title={`${warningCount} warnings (you probably want to do something about this)`}>
                                <Badge badgeContent={warningCount} color="warning">
                                    <Button
                                        variant={severity === 2 ? 'contained' : 'outlined'}
                                        color="warning"
                                        onClick={() => setSeverity(2)}
                                    >
                                        <RbgIcon name="warning" />
                                    </Button>
                                </Badge>
                            </RbgTooltip>
                        )}
                        {infoCount > 0 && (
                            <RbgTooltip title="Information">
                                <Badge badgeContent={infoCount} color="info">
                                    <Button variant={severity === 1 ? 'contained' : 'outlined'} color="info" onClick={() => setSeverity(1)}>
                                        <RbgIcon name="info" />
                                    </Button>
                                </Badge>
                            </RbgTooltip>
                        )}
                    </RbgStack>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgList>
                    {actionList.length === 0 && (
                        <IntroCardPrimary>
                            <RbgTypography>No actions to show</RbgTypography>
                        </IntroCardPrimary>
                    )}
                    {actionList.map((item) => {
                        return (
                            <RbgListItem key={item.id}>
                                <ListItemButton
                                    variant="contained"
                                    sx={{ background: 'none', padding: 0, margin: 0, ':hover': { background: 'none' } }}
                                    onClick={() => {
                                        document.location.href = `/staff/view/${item.staff_id}`;
                                    }}
                                >
                                    <Alert
                                        severity={item.type.data.severity.data.status_class}
                                        icon={<RbgIcon name={item.type.data.icon} />}
                                    >
                                        {item.rendered}
                                    </Alert>
                                </ListItemButton>
                            </RbgListItem>
                        );
                    })}
                </RbgList>
            </RbgGrid>
        </RbgGrid>
    );
}

ActionList.propTypes = {
    data: PropTypes.object.isRequired
};
export default ActionList;
