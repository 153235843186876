import PropTypes from 'prop-types';
import RbgTableCell from '../../../../../ui_component/mui-wrappers/TableCell/RbgTableCell';
import dayjs from 'dayjs';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import CurrencyFormatter from '../../../../../utils/currencyFormatter';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgTableRow from '../../../../../ui_component/mui-wrappers/TableRow/RbgTableRow';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import NewStarterApprovalReject from './NewStarterApprovalReject';

function NewStarterApprovalRow({ newStarter, preview, refetch }) {
    const navigate = useNavigate();
    const fullName = `${newStarter.fields.forename} ${newStarter.fields.surname}`;
    const approveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${newStarter.id}/approve`);
        },
        onSuccess: () => {
            refetch();
        }
    });
    const handleReject = () => {
        DialogHelper.openMdDialog('Reject new starter', <NewStarterApprovalReject newStarterId={newStarter.id} refetch={refetch} />);
    };
    const loading = approveMutation.isPending;
    return (
        <RbgTableRow>
            {!preview && <RbgTableCell>{newStarter.status.data.name}</RbgTableCell>}
            <RbgTableCell>{fullName}</RbgTableCell>
            {!preview && (
                <>
                    <RbgTableCell>{newStarter.location_name}</RbgTableCell>
                    <RbgTableCell>{newStarter.position.data.name}</RbgTableCell>
                </>
            )}
            <RbgTableCell
                sx={{
                    color: dayjs(newStarter.fields.start_date).isBefore(dayjs().add(1, 'day')) ? 'red' : 'inherit'
                }}
            >
                <RbgTypography>{dayjs(newStarter.fields.start_date).format('DD/MM/YYYY')}</RbgTypography>
                <RbgTypography>{dayjs(newStarter.fields.start_date).fromNow()}</RbgTypography>
            </RbgTableCell>
            <RbgTableCell>
                <CurrencyFormatter value={newStarter.pay_rate} /> {newStarter.position.data.pay_type.data.display_type}
            </RbgTableCell>
            <RbgTableCell>{newStarter.contracted_hours}</RbgTableCell>
            <RbgTableCell>
                <RbgStack gap={1}>
                    <RbgButton
                        disabled={loading}
                        onClick={() => {
                            navigate(`/staff/onboarding/${newStarter.id}`);
                        }}
                    >
                        Open
                    </RbgButton>
                    <RbgButton color="success" onClick={() => approveMutation.mutate()} disabled={loading}>
                        Approve
                    </RbgButton>
                    <RbgButton color="error" disabled={loading} onClick={() => handleReject()}>
                        Decline
                    </RbgButton>
                </RbgStack>
            </RbgTableCell>
        </RbgTableRow>
    );
}

NewStarterApprovalRow.propTypes = {
    newStarter: PropTypes.object.isRequired,
    preview: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired
};
export default NewStarterApprovalRow;
