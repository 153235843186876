import PropTypes from 'prop-types';
import RbgButton from './RbgButton';
import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import _ from 'lodash';
import { IconChevronDown } from '@tabler/icons-react';

function RbgButtonDropdown({ label, items, size = '' }) {
    const [el, setEl] = useState(null);

    return (
        <>
            <RbgButton onClick={(e) => setEl(e.currentTarget)} sx={{ fontSize: '12px' }} size={size}>
                {label} <IconChevronDown sx={{ fontSize: '12px' }} />
            </RbgButton>
            <Menu open={Boolean(el)} keepMounted anchorEl={el} onClose={() => setEl(null)}>
                {items.map((item) => {
                    return (
                        <MenuItem
                            onClick={(e) => {
                                setEl(null);
                                item.onClick(e);
                            }}
                            key={_.uniqueId()}
                        >
                            {item.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

RbgButtonDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired
};
export default RbgButtonDropdown;
