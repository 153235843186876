import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import { List } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Lists](https://mui.com/material-ui/react-list/)
 * - [Transfer List](https://mui.com/material-ui/react-transfer-list/)
 *
 * API:
 *
 * - [List API](https://mui.com/material-ui/api/list/)
 */

const RbgList = forwardRef(({ children, ...others }, ref) => (
    <List ref={ref} {...others}>
        {children}
    </List>
));

export default RbgList;

RbgList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array])
};
