import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material-ui imports
import { Badge, Button, IconButton, useTheme } from '@mui/material';
import RbgTooltip from '../../tooltip/RbgTooltip';

/**
 *
 * Demos:
 *
 * - [Button Group](https://mui.com/material-ui/react-button-group/)
 * - [Button](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [Button API](https://mui.com/material-ui/api/button/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */

const RbgButton = forwardRef(
    ({ type, color = 'primary', variant = 'outlined', icon, children, onClick, sx, tooltip, showBadge, badgeContent, ...others }, ref) => {
        const theme = useTheme();

        const containedBtnStyles = {
            background: theme.palette[color].main,
            '&:hover': {
                background: theme.palette[color].dark
            },
            color: 'white'
        };

        const outlinedIconStyles = {
            transition: 'cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms',
            border: `1px solid ${theme.palette[color].main}70`,
            '&:hover': {
                borderColor: theme.palette[color].main
            }
        };

        let sizes;
        let buttonType;
        const COMPONENT_TYPES = {
            button: Button,
            iconButton: IconButton
        };

        if (!icon) {
            sizes = {
                ...(variant === 'contained' ? containedBtnStyles : undefined),
                ...sx
            };
            buttonType = 'button';
        } else {
            sizes = {
                padding: 0.5,
                ...(variant === 'outlined' ? outlinedIconStyles : undefined),
                ...(variant === 'contained' ? containedBtnStyles : undefined),
                ...sx
            };
            buttonType = 'iconButton';
        }

        const ButtonComponent = COMPONENT_TYPES[buttonType] || Button;
        const content = (
            <ButtonComponent ref={ref} type={type} sx={sizes} color={color} onClick={onClick} variant={variant} {...others}>
                {buttonType === 'iconButton' ? icon : children}
                {showBadge && <Badge badgeContent={badgeContent} color="error" overlap="circular" sx={{ top: '-12px', right: '-12px' }} />}
            </ButtonComponent>
        );

        if (!tooltip) return content;
        return <RbgTooltip title={tooltip}>{content}</RbgTooltip>;
    }
);

RbgButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    onClick: PropTypes.func,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    type: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'info', 'success']),
    variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
    tooltip: PropTypes.string,
    badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showBadge: PropTypes.bool
};

export default RbgButton;
