import RbgGrid from '../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { gridSpacing } from '../../../../store/constant';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import PayslipSection from '../Component/Payslip/PayslipSection';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgSimpleTable from '../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import RbgPaper from '../../../../ui_component/mui-wrappers/Paper/RbgPaper';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import { useTheme } from '@mui/material/styles';
import { currencyGBP } from '../../../../utils/currencyFormatter';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useState } from 'react';

function ViewPayslipDetail({ id, setTab }) {
    const theme = useTheme();
    const [addQuery, setAddQuery] = useState(false);
    const [query, setQuery] = useState('');
    const { data, isFetched } = useQuery({
        queryKey: [{ url: `/staff/payslip/${id}` }]
    });
    const queryMutation = useMutation({
        mutationFn: () => {}
    });
    if (!isFetched) {
        return <CardLoader />;
    }
    const payments = data.data.items.data.filter((i) => i.code.data.payment === true);
    const deductions = data.data.items.data.filter((i) => i.code.data.deduction === true);
    if (data.data.tax !== 0) {
        deductions.push({
            code: {
                data: {
                    description: 'P.A.Y.E'
                }
            },
            value: data.data.tax
        });
    }
    if (data.data.ees_ni !== 0) {
        deductions.push({
            code: {
                data: {
                    description: 'NI'
                }
            },
            value: data.data.ees_ni
        });
    }
    return (
        <RbgStack gap={2}>
            <RbgPaper sx={{ p: 2, backgroundColor: theme.palette.error.main }}>
                <RbgGrid container spacing={gridSpacing}>
                    <RbgGrid item xs={12}>
                        <RbgStack sx={{ width: '100%' }} spacing={2} direction="row" justifyContent="space-between">
                            <PayslipSection title="Reference">
                                <RbgTypography>{data.data.payroll_ref}</RbgTypography>
                            </PayslipSection>
                            <PayslipSection title="Employee">
                                <RbgTypography>
                                    {data.data.forename} {data.data.surname}
                                </RbgTypography>
                            </PayslipSection>
                            <PayslipSection title="Employer">
                                <RbgTypography>Revolution Bars Group PLC</RbgTypography>
                            </PayslipSection>
                            <PayslipSection title="Site">
                                <RbgTypography>{data.data.department}</RbgTypography>
                            </PayslipSection>
                            <PayslipSection title="Tax Code">
                                <RbgTypography>{data.data.tax_code}</RbgTypography>
                            </PayslipSection>
                        </RbgStack>
                    </RbgGrid>
                    <RbgGrid item xs={12}>
                        <RbgStack sx={{ width: '100%' }} spacing={2} direction="row" justifyContent="space-between">
                            <PayslipSection title="Payments">
                                <RbgSimpleTable
                                    tableContainerSx={{ td: { padding: '0 16px' }, th: { padding: '4px 16px' } }}
                                    headers={['Type', 'Units', 'Rate', 'Value']}
                                    keys={payments.map((_, idx) => idx)}
                                    rows={[
                                        ...payments.map((row) => [
                                            row.code.data.description,
                                            row.units,
                                            row.rate,
                                            row.value ? row.value.toFixed(2) : 0
                                        ]),
                                        [
                                            <strong>TOTAL</strong>,
                                            null,
                                            null,
                                            <strong>{currencyGBP().format(data.data.payment_total)}</strong>
                                        ]
                                    ]}
                                />
                            </PayslipSection>
                            <PayslipSection title="Deductions">
                                <RbgSimpleTable
                                    tableContainerSx={{ td: { padding: '0 16px' }, th: { padding: '4px 16px' } }}
                                    headers={['Type', 'Value']}
                                    keys={deductions.map((_, idx) => idx)}
                                    rows={[
                                        ...deductions.map((row) => [row.code.data.description, row.value ? row.value.toFixed(2) : 0]),
                                        [<strong>TOTAL</strong>, <strong>{currencyGBP().format(data.data.deduction_total)}</strong>]
                                    ]}
                                />
                            </PayslipSection>
                            <PayslipSection title="NET PAY">
                                <RbgTypography variant="h3">{currencyGBP().format(data.data.net_pay)}</RbgTypography>
                            </PayslipSection>
                        </RbgStack>
                    </RbgGrid>
                </RbgGrid>
            </RbgPaper>
            <RbgButton fullWidth color="primary" onClick={() => setTab('query')}>
                Query this payslip with payroll
            </RbgButton>
        </RbgStack>
    );
}

ViewPayslipDetail.propTypes = {
    id: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired
};

export default ViewPayslipDetail;
