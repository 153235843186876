import { forwardRef } from 'react';
// mui imports
import { Divider } from '@mui/material';

/**
 *
 * Demos:
 *
 * - [Divider](https://mui.com/material-ui/react-divider/)
 * - [Lists](https://mui.com/material-ui/react-list/)
 *
 * API:
 *
 * - [Divider API](https://mui.com/material-ui/api/divider/)
 */

const RbgDivider = forwardRef(({ ...others }, ref) => <Divider ref={ref} {...others} />);

export default RbgDivider;
