import { useState } from 'react';
import MainCard from '../../../../../ui_component/cards/MainCard';
import RbgTabs from '../../../../../ui_component/tabs/RbgTabs';
import StaffAdminEmailTemplates from './StaffAdminEmailTemplates';
import StaffAdminEmailTemplateMapping from './StaffAdminEmailTemplateMapping';

function StaffAdminEmails() {
    const [tab, setTab] = useState('templates');

    const tabs = [
        {
            value: 'templates',
            label: 'Templates',
            content: <StaffAdminEmailTemplates />
        },
        {
            value: 'mapping',
            label: 'Mapping',
            content: <StaffAdminEmailTemplateMapping />
        }
    ];
    return (
        <MainCard title="Emails">
            <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} />
        </MainCard>
    );
}

export default StaffAdminEmails;
