import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgSimpleTable from '../../../../../ui_component/mui-wrappers/Table/RbgSimpleTable';
import dayjs from 'dayjs';
import BooleanIcon from '../../../../../ui_component/icons/boolean-icon';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import UploadDraftPayslips from './UploadDraftPayslips';
import EditPayrollPeriod from './EditPayrollPeriod';
import { payrollPeriod } from './draftPayslipTypes';
import RbgTypography from '../../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import CurrencyFormatter from '../../../../../utils/currencyFormatter';
import RbgButtonDropdown from '../../../../../ui_component/mui-wrappers/Button/RbgButtonDropdown';
import DeletePayslipSlips from './DeletePayslipSlips';
import PayrollPeriodReport from './PayrollPeriodReport';

function DraftPayslipType({ type }) {
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <RbgSimpleTable
                    headers={['Period', 'Weeks', 'Draft', 'Completed', 'Processing', 'Slips', 'Gross', '']}
                    keys={type.items.map((item) => item.id)}
                    rows={type.items.map((item) => {
                        const options = [
                            {
                                label: 'Edit Status',
                                onClick: () => {
                                    DialogHelper.openMdDialog('Edit Period', <EditPayrollPeriod payrollPeriod={item} />);
                                }
                            },
                            {
                                label: 'Upload new payslip export',
                                onClick: () => {
                                    DialogHelper.openMdDialog('Upload Payslip Data', <UploadDraftPayslips payrollPeriod={item} />);
                                }
                            },
                            {
                                label: 'View Report',
                                onClick: () => {
                                    DialogHelper.openMdDialog('View Period Report', <PayrollPeriodReport id={item.id} />);
                                }
                            }
                        ];
                        if (item.slips > 0) {
                            options.push({
                                label: 'Delete all payslip data',
                                onClick: () => {
                                    DialogHelper.openMdDialog('Delete payslip data', <DeletePayslipSlips payrollPeriod={item} />);
                                }
                            });
                        }

                        return [
                            `${item.pay_period}-${item.financial_year}`,
                            <RbgStack>
                                <RbgTypography>{item.full_weeks.join(', ')}</RbgTypography>
                                <RbgTypography>Start: {dayjs(item.start_date).format('DD/MM/YYYY')}</RbgTypography>
                                <RbgTypography>End: {dayjs(item.end_date).format('DD/MM/YYYY')}</RbgTypography>
                            </RbgStack>,
                            <BooleanIcon value={item.draft} />,
                            <BooleanIcon value={item.completed} />,
                            <BooleanIcon value={item.processing} />,
                            item.slips,
                            CurrencyFormatter({ value: item.gross_pay, minDigits: 0, maxDigits: 0 }),
                            <RbgStack gap={2} direction="row">
                                <RbgButtonDropdown label="Options" items={options} />
                            </RbgStack>
                        ];
                    })}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

DraftPayslipType.propTypes = {
    type: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape(payrollPeriod))
    })
};

export default DraftPayslipType;
