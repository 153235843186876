import { useMutation, useQuery } from '@tanstack/react-query';
import CardLoader from '../../../../ui_component/loading/CardLoader';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainCard from '../../../../ui_component/cards/MainCard';
import StaticBreadcrumb from '../../../../ui_component/extended/StaticBreadcrumb';
import RbgTabs from '../../../../ui_component/tabs/RbgTabs';
import EditNewStarterFields from './EditNewStarter/EditNewStarterFields';
import NewStarterRightToWorkCheck from './EditNewStarter/NewStarterRightToWorkCheck';
import NewStartConfirmation from './EditNewStarter/NewStartConfirmation';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import InfoCard from '../../../../ui_component/error/InfoCard';
import WarningCard from '../../../../ui_component/error/WarningCard';
import _ from 'lodash';
import EventDispatcher from '../../../../utils/EventDispatcher';
import { useDispatch } from 'react-redux';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import Api from '../../../../utils/api';
import NewStarterHistory from './NewStarterHistory';
import { snackbarSuccess } from '../../../../store/reducers/snackbarReducer';
import MoveLocation from './EditNewStarter/MoveLocation';
import RcloudAuthContext from '../../../../contexts/RcloudAuthContext';

function EditNewStarter() {
    const params = useParams();
    const [fields, setFields] = useState({});
    const id = params.id;
    const [tab, setTab] = useState('fields');
    const { data, error, loading, isFetched, refetch } = useQuery({
        queryKey: [{ url: `/staff/onboarding/team-member/${id}` }]
    });
    const { user } = useContext(RcloudAuthContext);

    useEffect(() => {
        if (data.data.fields) {
            if (Array.isArray(data.data.fields)) {
                setFields({});
            } else {
                setFields(data.data.fields);
            }
        }
    }, [data]);
    useEffect(() => {
        if (data.data.id) {
            EventDispatcher.dispatch('pusher-subscribe', `private-new_starter-${data.data.id}`);
        }
    }, [data]);
    useEffect(() => {
        EventDispatcher.subscribe('pusher-message', (data) => {
            if (data.event === 'new-starter-updated') {
                refetch();
            }
        });
    }, [refetch]);
    const approveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${data.data.id}/approve`);
        },
        onSuccess: () => {
            refetch();
        }
    });
    const dispatch = useDispatch();
    const resendWelcomeMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/onboarding/team-member/${data.data.id}/resend-email`);
        },
        onSuccess: () => {
            dispatch(snackbarSuccess('Welcome e-mail has been resent'));
            refetch();
        }
    });
    const handleFieldChange = (name, value) => {
        setFields((prev) => ({ ...prev, [name]: value }));
    };
    if (loading || !isFetched) {
        return <CardLoader />;
    }
    if (error) {
        return <ErrorCard>{error}</ErrorCard>;
    }
    let rtwDisabled = false;
    let selfieDisabled = false;
    let confirmationDisabled = false;
    const getFieldByFieldId = (id) => {
        return data.data.manager_fieldset.data.fields.data.find((field) => {
            return field.field_id === parseInt(id, 10);
        });
    };

    if (data.data.manager_fieldset.data.id) {
        const fieldData = data.data.manager_fieldset.data.fields.data.filter((field) => {
            if (field.dependent_field) {
                const dep = getFieldByFieldId(field.dependent_field);
                if (!dep) {
                    return false;
                }
                const value = fields[dep.name];
                if (field.dependent_value !== value) {
                    return false;
                }
            }
            return field;
        });
        fieldData.forEach((field) => {
            const value = fields[field.name];
            if ((field.required === true && typeof value === 'undefined') || value === '') {
                rtwDisabled = true;
            }
        });
    } else {
        rtwDisabled = true;
        confirmationDisabled = true;
    }
    if (data.data.right_to_work_checked === false) {
        selfieDisabled = true;
        confirmationDisabled = true;
    }
    if (!(data.data.allow_custom_selfie === true || data.data.selfie_id === null)) {
        confirmationDisabled = true;
    }
    const tabs = [
        {
            value: 'fields',
            label: 'Information',
            content: (
                <EditNewStarterFields data={_.extend({}, data)} fields={fields} handleFieldChange={handleFieldChange} setTab={setTab} />
            )
        },
        {
            value: 'rtw',
            label: 'Right to Work',
            disabled: rtwDisabled,
            content: <NewStarterRightToWorkCheck data={_.extend({}, data)} newStarterId={data.data.id} setTab={setTab} />
        },
        {
            value: 'confirmation',
            disabled: confirmationDisabled,
            label: 'Confirmation',
            content: <NewStartConfirmation data={_.extend({}, data)} setTab={setTab} />
        },
        {
            value: 'history',
            disabled: false,
            label: 'History',
            content: <NewStarterHistory data={_.extend({}, data)} id={data.data.id} />
        }
    ];
    const importData = data.data.data;
    let rtwPending = false;
    data.data.rtw_check.data.forEach((check) => {
        if (check.status.data.completed === false) {
            rtwPending = true;
        }
    });
    const status = data.data.status.data;
    const canMove = status.approval === false && status.completed === false && status.staff_member_action === false;
    if (canMove) {
        tabs.push({
            value: 'move',
            disable: false,
            label: 'Move Location',
            content: <MoveLocation data={_.extend({}, data)} />
        });
    }
    return (
        <>
            <StaticBreadcrumb
                items={[
                    { link: '/staff', label: 'Team' },
                    { link: '/staff', label: 'Process New Starter' },
                    { label: importData.forename ? `${importData.forename} ${importData.surname}` : 'New Starter' }
                ]}
            />
            <MainCard>
                <RbgStack gap={2}>
                    <InfoCard>
                        Please ensure all of this information is correct before continuing. Incorrect information could lead to invalid
                        contracts being issued to the team member
                    </InfoCard>
                    {data.data.status.data.id === 7 && <ErrorCard>This new Starter has been cancelled</ErrorCard>}
                    {data.data.status.data.id === 2 && (
                        <WarningCard>
                            <RbgStack direction="row" gap={1}>
                                <RbgTypography variant="p" sx={{ lineHeight: '40px' }}>
                                    This new starter is currently awaiting approval from <strong>{data.data.auth_name}</strong>.
                                </RbgTypography>
                                {user.permissions.indexOf('hr_staff_auth') > 1 && (
                                    <RbgButton
                                        color="success"
                                        disabled={approveMutation.isPending}
                                        onClick={() => {
                                            approveMutation.mutate();
                                        }}
                                    >
                                        Approve
                                    </RbgButton>
                                )}
                            </RbgStack>
                        </WarningCard>
                    )}
                    {data.data.status.data.id === 3 && (
                        <WarningCard>The approval for this new starter was declined. Reason: {data.data.reject_reason}</WarningCard>
                    )}
                    {data.data.status.data.id === 4 && (
                        <WarningCard>
                            <RbgStack direction="row" gap={1} justify="space-between" alignItems="center">
                                <RbgTypography>
                                    This team member is currently completing the onboarding process. You will not be able to change anything
                                    while they are doing this.
                                </RbgTypography>
                                <RbgButton
                                    disabled={resendWelcomeMutation.isPending}
                                    onClick={() => {
                                        resendWelcomeMutation.mutate();
                                    }}
                                >
                                    Re-send welcome e-mail
                                </RbgButton>
                            </RbgStack>
                        </WarningCard>
                    )}
                    {rtwPending && <WarningCard>The right to work check is still pending for this team member</WarningCard>}
                    <RbgTabs setCurrentTab={(tab) => setTab(tab)} currentTab={tab} tabs={tabs} orientation="vertical" />
                </RbgStack>
            </MainCard>
        </>
    );
}

export default EditNewStarter;
