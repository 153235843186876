import { forwardRef } from 'react';
// mui imports
import { Chip } from '@mui/material';

/**
 * Chips represent complex entities in small blocks, such as a contact.
 *
 * Demos:
 *
 * - [Chip](https://mui.com/material-ui/react-chip/)
 *
 * API:
 *
 * - [Chip API](https://mui.com/material-ui/api/chip/)
 */

const RbgChip = forwardRef(({ ...others }, ref) => <Chip ref={ref} {...others} />);

export default RbgChip;
