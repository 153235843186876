import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { useEffect, useState } from 'react';
import RbgLocationSelect from '../../../../../ui_component/location-select/RbgLocationSelect';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import { staffMember } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function AddTempTransfer({ staffMember }) {
    const [transfer, setTransfer] = useState({
        to_location: '',
        start_date: '',
        end_date: ''
    });
    const handleFieldChange = (name, value) => {
        setTransfer((prev) => ({ ...prev, [name]: value }));
    };
    const saveMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/temp-transfer`, transfer);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });
    useEffect(() => {
        let disabled = false;
        if (!transfer.to_location) {
            disabled = true;
        }
        if (!transfer.start_date) {
            disabled = true;
        }
        if (!transfer.end_date) {
            disabled = true;
        }
        DialogHelper.addSaveButton(() => saveMutation.mutate(), 'Save', disabled);
    }, [transfer, saveMutation]);
    return (
        <RbgGrid container>
            {saveMutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{saveMutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgLocationSelect
                    name="to_location"
                    label="Temp Transfer To"
                    onChange={(e) => handleFieldChange('to_location', e.target.value)}
                    value={transfer.to_location}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    name="start_date"
                    value={transfer.start_date}
                    onChange={(date) => handleFieldChange('start_date', date)}
                    minDate={dayjs()}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    name="end_date"
                    value={transfer.end_date}
                    onChange={(date) => handleFieldChange('end_date', date)}
                    minDate={dayjs()}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

AddTempTransfer.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default AddTempTransfer;
