import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// mui imports
import RbgTypography from 'ui_component/mui-wrappers/Typography/RbgTypography';
import { Chip, useTheme } from '@mui/material';
// 3rd party
import { IconUserCircle } from '@tabler/icons-react';

const UserChip = forwardRef(({ open, label, onClick }, ref) => {
    const theme = useTheme();
    return (
        <Chip
            sx={{
                height: '48px',
                alignItems: 'center',
                borderRadius: '27px',
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.main,
                    background: `${theme.palette.primary.main}!important`,
                    color: theme.palette.primary.light,
                    '& svg': {
                        stroke: theme.palette.primary.light,
                        transition: 'all .2s ease-in-out'
                    }
                }
            }}
            icon={
                <RbgTypography
                    sx={{ cursor: 'pointer', padding: '8px' }}
                    ref={ref}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                >
                    {label}
                </RbgTypography>
            }
            label={<IconUserCircle stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
            variant="outlined"
            ref={ref}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={onClick}
            color="primary"
        />
    );
});

export default UserChip;

UserChip.propTypes = {
    open: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func
};
