import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import IntroCardPrimary from '../../../../../ui_component/cards/IntroCardPrimary';
import RbgStack from '../../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import { payRateAuth } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import ChangeTable from './ChangeTable';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';

function RejectAuthorisation({ rate }) {
    const queryClient = useQueryClient();
    const [reason, setReason] = useState('');
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/pay-rate-approval/${rate.id}`, {
                status: 2,
                reject_reason: reason
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['pay_rate_approvals']
            });
            DialogHelper.closeDialog();
        }
    });
    return (
        <RbgGrid container>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <IntroCardPrimary>
                    Are you sure you want to reject the following change(s) for <strong>{rate.staff_member_name}</strong>?
                </IntroCardPrimary>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <ChangeTable change={rate} />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgTextInput name="reason" value={reason} onChange={(e) => setReason(e.target.value)} label="Reject Reason" />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgStack direction="row" spacing={4} justifyContent="center">
                    <RbgButton color="error" onClick={() => mutation.mutate()} disabled={reason === ''} fullWidth>
                        Reject
                    </RbgButton>
                </RbgStack>
            </RbgGrid>
        </RbgGrid>
    );
}

RejectAuthorisation.propTypes = {
    rate: PropTypes.shape(payRateAuth)
};

export default RejectAuthorisation;
