import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import RbgSelect from '../../../../../ui_component/extended/Form/RbgSelect';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';
import RbgButton from '../../../../../ui_component/mui-wrappers/Button/RbgButton';
import DeleteConfirm from '../../../../../ui_component/buttons/DeleteConfirm';

function EditOnboardFieldsetField({ field, handleFieldChange, handleFieldDelete }) {
    const handleChange = (e) => {
        const value = e?.target?.type === 'checkbox' ? e.target.checked : e.target.value;
        let data = field;
        if (e.target.name) {
            data = { ...data, [e.target.name]: value };
        }
        if (e.target.name === 'dependent_field') {
            if (value === '') {
                data.dependent_field = null;
            }
            data.dependent_value = null;
        }
        handleFieldChange(data);
    };

    const { data: fields } = useQuery({
        queryKey: [{ url: '/staff/admin/onboard-fields', params: { page: 1 } }]
    });
    const dependentFields = fields.data
        .filter((field) => {
            return field.new_starter_field !== null;
        })
        .map((field) => {
            return {
                value: field.id,
                label: field.name
            };
        });
    return (
        <RbgGrid container item spacing={1}>
            <RbgGrid container item xs={11}>
                <RbgGrid item xs={3}>
                    <RbgSelect
                        values={fields.data.map((field) => ({ value: field.id, label: field.name, object: field }))}
                        name="field_id"
                        useAutoComplete
                        label="Field Type"
                        value={field.field_id}
                        handleChange={(e) => {
                            if (!e.target.value) {
                                const newField = { ...field, field_id: '', field: { data: {} } };
                                handleFieldChange(newField);
                            }
                        }}
                        handleObjectChange={(e) => {
                            const newField = { ...field, field_id: e.target.value, field: { data: e.target.object } };
                            handleFieldChange(newField);
                        }}
                    />
                </RbgGrid>
                <RbgGrid item xs={3}>
                    <RbgCheckbox label="Required" checked={field.required} name="required" onChange={handleChange} />
                </RbgGrid>
                <RbgGrid item xs={field.dependent_field ? 3 : 6}>
                    <RbgSelect
                        label="Dependent Field"
                        values={dependentFields}
                        name="dependent_field"
                        value={field.dependent_field || ''}
                        handleChange={handleChange}
                    />
                </RbgGrid>
                {field.dependent_field && (
                    <RbgGrid item xs={3}>
                        <RbgTextInput
                            name="dependent_value"
                            value={field.dependent_value || ''}
                            onChange={handleChange}
                            label="Dependent Field Value"
                        />
                    </RbgGrid>
                )}
                <RbgGrid item xs={12}>
                    <RbgTextInput
                        name="label"
                        value={field.label}
                        onChange={handleChange}
                        label={field.field.data.type === 'content' || field.field.data.type === 'title' ? 'Content' : 'Label'}
                    />
                </RbgGrid>
            </RbgGrid>
            <RbgGrid item xs={1}>
                <DeleteConfirm onDelete={() => handleFieldDelete(field)} />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <Divider sx={{ margin: '5px 0' }} />
            </RbgGrid>
        </RbgGrid>
    );
}

EditOnboardFieldsetField.propTypes = {
    field: PropTypes.object.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    handleFieldDelete: PropTypes.func.isRequired
};
export default EditOnboardFieldsetField;
