import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../../utils/api';
import RbgGrid from '../../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import InfoCard from '../../../../../../ui_component/error/InfoCard';
import PropTypes from 'prop-types';
import { staffMember } from '../../../StaffDataTypes';
import RbgButton from '../../../../../../ui_component/mui-wrappers/Button/RbgButton';
import { DialogHelper } from '../../../../../../ui_component/modals/RbgDialog';

function RegeneratePayrollReference({ staffMember }) {
    const regenPayrollRefMutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('POST', `/staff/staff-member/${staffMember.id}/regenerate-payroll-ref`);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                <InfoCard>
                    This will change the payroll reference of this team member. Only do this if you are sure that&apos;s the action you want
                    to take!
                </InfoCard>
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgButton
                    fullWidth
                    onClick={() => {
                        regenPayrollRefMutation.mutate();
                    }}
                >
                    I&apos;m Sure
                </RbgButton>
            </RbgGrid>
        </RbgGrid>
    );
}

RegeneratePayrollReference.propTypes = {
    staffMember: PropTypes.shape(staffMember).isRequired
};
export default RegeneratePayrollReference;
