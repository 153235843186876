import { staffNotificationField } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import RbgTextArea from '../../../../../ui_component/text-area/RbgTextArea';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import _ from 'lodash';
import RbgTextInput from '../../../../../ui_component/extended/Form/RbgTextInput';
import RbgCheckbox from '../../../../../ui_component/mui-wrappers/RbgCheckbox';

function NotificationField({ field, handleFieldChange }) {
    let input = <ErrorCard>Invalid Field Type</ErrorCard>;
    const handleSubFieldChange = (e) => {
        const newField = _.extend({}, field);
        newField[e.target.name] = `${e.target.value}`;
        handleFieldChange(newField);
    };
    const type = field.type.data;
    if (type.type === 'text') {
        input = <RbgTextInput name="value" label={type.label} value={field.value} onChange={handleSubFieldChange} />;
    }
    if (type.type === 'boolean') {
        input = (
            <RbgCheckbox
                name="value"
                label={type.label}
                value={field.value}
                onChange={(e) => handleFieldChange({ target: { name: e.target.name, value: e.target.checked } })}
            />
        );
    }
    if (type.type === 'json') {
        let error = null;
        try {
            JSON.parse(field.value);
        } catch (e) {
            error = e.message;
        }
        input = (
            <RbgTextArea
                help="The JSON data to send to each endpoint"
                name="value"
                label={type.label}
                value={field.value}
                onChange={handleSubFieldChange}
                error={error}
            />
        );
    }
    if (type.type === 'text' || type.type === 'string') {
        input = <RbgTextInput label={type.label} value={field.value} handleChange={handleSubFieldChange} />;
    }
    if (type.type === 'email_select') {
        input = (
            <RbgRemoteSelect
                label={type.label}
                filterData={(data) => {
                    return data.data.map((row) => {
                        return {
                            value: `${row.id}`,
                            label: row.name
                        };
                    });
                }}
                help="The e-mail template that you want to use for this notification. E-mail templates can be edited within the HR System Configuration section"
                value={`${field.value}`}
                useAutoComplete
                handleChange={handleSubFieldChange}
                endpointParameters={{ page: 1, per_page: '300' }}
                endpoint="/staff/staff-admin/email-templates"
                name="value"
            />
        );
    }
    return (
        <RbgGrid container>
            <RbgGrid item xs={12}>
                {input}
            </RbgGrid>
        </RbgGrid>
    );
}

NotificationField.propTypes = {
    field: PropTypes.shape(staffNotificationField).isRequired,
    handleFieldChange: PropTypes.func.isRequired
};
export default NotificationField;
