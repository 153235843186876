import RbgGrid from '../../../../../ui_component/mui-wrappers/Grid/RbgGrid';
import { staffRole } from '../../StaffDataTypes';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RbgDatePicker from '../../../../../ui_component/date-pickers/RbgDatePicker';
import RbgRemoteSelect from '../../../../../ui_component/remote-select/RbgRemoteSelect';
import { useMutation } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import ErrorCard from '../../../../../ui_component/error/ErrorCard';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import RbgLocationSelect from '../../../../../ui_component/location-select/RbgLocationSelect';
import dayjs from 'dayjs';
import WarningCard from '../../../../../ui_component/error/WarningCard';

function EditFutureRole({ role }) {
    const [newRole, setNewRole] = useState({
        start_date: role.start_date,
        position: role.position.data.id,
        cost_centre: role.cost_centre
    });
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('PATCH', `/staff/staff-member/${role.staff_id}/role/${role.id}`, newRole);
        },
        onSuccess: () => {
            DialogHelper.closeDialog();
        }
    });

    useEffect(() => {
        let disabled = true;
        if (
            JSON.stringify({
                start_date: role.start_date,
                position: role.position.data.id,
                cost_centre: role.cost_centre
            }) !== JSON.stringify(newRole)
        ) {
            disabled = false;
        }

        DialogHelper.addSaveButton(() => mutation.mutate(), 'Save', disabled);
    }, [newRole, role, mutation]);
    return (
        <RbgGrid container>
            {mutation.error && (
                <RbgGrid item xs={12}>
                    <ErrorCard>{mutation.error}</ErrorCard>
                </RbgGrid>
            )}
            {newRole.start_date === dayjs().format('YYYY-MM-DD') && (
                <RbgGrid item xs={12}>
                    <WarningCard>This change will take place immediately after clicking save</WarningCard>
                </RbgGrid>
            )}
            <RbgGrid item xs={12}>
                <RbgDatePicker
                    name="start_date"
                    minDate={new Date()}
                    value={newRole.start_date}
                    onChange={(e) => setNewRole((prev) => ({ ...prev, start_date: e ? e.format('YYYY-MM-DD') : '' }))}
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgLocationSelect
                    onChange={(e) => setNewRole((prev) => ({ ...prev, cost_centre: e.target.value }))}
                    name="location"
                    value={newRole.cost_centre}
                    label="Location"
                    onlyAssignedLocations
                />
            </RbgGrid>
            <RbgGrid item xs={12}>
                <RbgRemoteSelect
                    filterData={(data) => {
                        return data.data
                            .sort((r1, r2) => {
                                if (r1.hierarchy_level === r2.hierarchy_level) {
                                    return r1.name.localeCompare(r2.name);
                                }
                                return r1.hierarchy_level > r2.hierarchy_level;
                            })
                            .map((r) => {
                                return {
                                    value: r.id,
                                    label: `${r.name} (${r.pay_type.data.name})`,
                                    object: r
                                };
                            });
                    }}
                    value={newRole.position}
                    label="Role"
                    endpoint="/staff/onboarding/positions"
                    endpointParameters={{ cost_centre: newRole.cost_centre }}
                    name="role_id"
                    useAutoComplete
                    handleChange={(e) => {
                        setNewRole({ ...newRole, position: e.target.value ? e.target.value : {} });
                    }}
                />
            </RbgGrid>
        </RbgGrid>
    );
}

EditFutureRole.propTypes = {
    role: PropTypes.shape(staffRole).isRequired
};
export default EditFutureRole;
