import PropTypes from 'prop-types';
import RbgButton from '../mui-wrappers/Button/RbgButton';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IconDownload } from '@tabler/icons-react';
import Loader from '../loading/Loader';
import Api from '../../utils/api';

function FileDownload({ file, showFilename = false }) {
    const mutation = useMutation({
        mutationFn: () => {
            return Api.reactQueryMutation('GET', `/rest/file/download/${file.id}`);
        },
        onSuccess: (data) => {
            window.open(data.data.uri);
        }
    });

    return (
        <RbgButton disabled={mutation.isPending} onClick={() => mutation.mutate()}>
            {mutation.isPending && <Loader />}
            <>
                {showFilename && `${file.filename} (${file.size})`}
                <IconDownload />
            </>
        </RbgButton>
    );
}

export const S3File = {
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    date_uploaded: PropTypes.string,
    original_filename: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    image: PropTypes.bool
};
FileDownload.propTypes = {
    file: PropTypes.shape(S3File),
    showFilename: PropTypes.bool
};
export default FileDownload;
