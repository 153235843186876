import PropTypes from 'prop-types';

export const staffDetails = {
    id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    date_created: PropTypes.string.isRequired,
    forename: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    email_address: PropTypes.string,
    mobile: PropTypes.string,
    display_name: PropTypes.string.isRequired,
    known_as: PropTypes.string,
    birth_date: PropTypes.string,
    age: PropTypes.number,
    gender: PropTypes.number,
    gender_name: PropTypes.string,
    gender_other: PropTypes.string,
    ethnicity: PropTypes.number,
    ethnicity_other: PropTypes.string,
    ethnicity_name: PropTypes.string,
    location: PropTypes.string.isRequired,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    address_line3: PropTypes.string,
    address_county: PropTypes.string,
    address_postal_code: PropTypes.string,
    sort_code: PropTypes.string,
    bank_account: PropTypes.string,
    ni_number: PropTypes.string,
    nok_name: PropTypes.string,
    nok_phone: PropTypes.string,
    passport_number: PropTypes.string,
    location_name: PropTypes.string,
    pronoun_id: PropTypes.number,
    full_legal_name: PropTypes.string
};
export const staffNewStarterRecord = {};
export const staffEmployment = {
    id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    leave_date: PropTypes.string,
    leave_reason: PropTypes.number,
    tenure_total_days: PropTypes.number.isRequired,
    tenure_years: PropTypes.number.isRequired,
    personal_details_complete: PropTypes.bool,
    initial_training_complete: PropTypes.bool,
    right_to_work_complete: PropTypes.bool,
    onboard_complete: PropTypes.bool,
    share_code: PropTypes.string,
    right_to_work_id: PropTypes.number,
    right_to_work_status: PropTypes.number,
    probation_end_date: PropTypes.string
    // new_starter_record: {
    //     data: []
    // }
};
export const staffContract = {
    id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    contract_key: PropTypes.string.isRequired,
    added_by: PropTypes.number.isRequired,
    status_id: PropTypes.number.isRequired,
    contracted_hours: PropTypes.number.isRequired,
    notes: PropTypes.string,
    date_accepted: PropTypes.string,
    pdf_url: PropTypes.string,
    signature_url: PropTypes.string,
    active: PropTypes.bool,
    objected: PropTypes.bool,
    object_reason: PropTypes.string,
    date_expired: PropTypes.string,
    cost_centre: PropTypes.string,
    accept_selected: PropTypes.bool,
    rate: PropTypes.number
};
export const staffRole = {
    id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    position_id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    payroll_ref: PropTypes.string.isRequired,
    cost_centre: PropTypes.string.isRequired,
    sc_department: PropTypes.number,
    sc_how_help: PropTypes.string,
    sc_department_name: PropTypes.string.isRequired,
    sc_role: PropTypes.number,
    sc_role_name: PropTypes.string,
    sc_line_manager_id: PropTypes.number,
    position_name: PropTypes.string.isRequired,
    hierarchy_level: PropTypes.number.isRequired,
    location_name: PropTypes.string,
    additional_locations: PropTypes.array,
    additional_positions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export const staffDisciplinaryType = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired
};

export const staffDisciplinary = {
    id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    type_id: PropTypes.number.isRequired,
    logged_by: PropTypes.number.isRequired,
    logged_by_name: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    expiry_date: PropTypes.string,
    deleted: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    location_name: PropTypes.string.isRequired,
    type: PropTypes.shape({
        data: PropTypes.shape(staffDisciplinaryType)
    })
};

export const staffPayType = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    interval_type: PropTypes.string.isRequired,
    interval: PropTypes.number.isRequired,
    salary: PropTypes.bool.isRequired,
    hourly: PropTypes.bool.isRequired,
    display_type: PropTypes.string
};
export const staffPayRate = {
    id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    rate: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    type: PropTypes.shape({
        data: PropTypes.shape(staffPayType)
    })
};
export const staffPayRateAuthStatus = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired
};
export const staffPayRateAuth = {
    authed_by_name: PropTypes.string,
    created_by_name: PropTypes.string,
    location_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    current_rate: PropTypes.number,
    effective_from_date: PropTypes.string.isRequired,
    staff_id: PropTypes.number.isRequired,
    rate: PropTypes.number.isRequired,
    status: PropTypes.shape({
        data: PropTypes.shape(staffPayRateAuthStatus)
    })
};

export const staffActionFlat = {
    id: PropTypes.number.isRequired,
    severity_id: PropTypes.number.isRequired,
    severity_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    description_rendered: PropTypes.string.isRequired,
    date_added: PropTypes.string.isRequired,
    last_checked: PropTypes.string.isRequired,
    date_removed: PropTypes.string,
    hidden: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    category_id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    category_name: PropTypes.string.isRequired,
    staff_name: PropTypes.string.isRequired,
    location_name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired
};

export const staffTempTransfer = {
    id: PropTypes.number,
    from: PropTypes.string,
    to: PropTypes.string,
    from_name: PropTypes.string,
    to_name: PropTypes.string,
    from_date: PropTypes.string,
    to_date: PropTypes.string
};

export const staffAgreement = {
    staff_id: PropTypes.number.isRequired,
    cost_centre: PropTypes.string,
    type_id: PropTypes.number.isRequired,
    date_created: PropTypes.string,
    created_by: PropTypes.number,
    key: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    pdf: PropTypes.object,
    signature: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
export const staffMember = {
    id: PropTypes.number.isRequired,
    current_pay_rate_id: PropTypes.number,
    leave_date: PropTypes.string,
    status: PropTypes.string,
    has_future_role: PropTypes.bool,
    has_future_rate: PropTypes.bool,
    can_reinstate: PropTypes.bool,
    display_forename: PropTypes.string,
    suspended: PropTypes.bool.isRequired,
    new_starter: PropTypes.bool,
    pay_rates: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffPayRate))
    }),
    pay_rate_auths: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffPayRateAuth))
    }),
    temp_transfers: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffTempTransfer))
    }),
    details: PropTypes.shape({
        data: PropTypes.shape(staffDetails)
    }),
    contract: PropTypes.shape({
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape(staffContract)])
    }),
    role: PropTypes.shape({
        data: PropTypes.shape(staffRole)
    }),
    all_roles: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffRole))
    }),
    employment: PropTypes.shape({
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape(staffEmployment)])
    }),
    all_employment: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffEmployment))
    }),
    actions: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffActionFlat))
    }),
    agreements: PropTypes.shape({
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape(staffAgreement)])
    }),
    location: PropTypes.shape({
        data: PropTypes.object.isRequired
    }),
    external_identifiers: PropTypes.shape({
        data: PropTypes.object.isRequired
    })
};

export const StaffEmail = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    date_created: PropTypes.string.isRequired,
    date_updated: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    text_content: PropTypes.string
};

export const StaffContract = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    permanent: PropTypes.bool.isRequired,
    hourly: PropTypes.bool.isRequired,
    location_ids: PropTypes.arrayOf([PropTypes.string]).isRequired,
    location_name: PropTypes.arrayOf([PropTypes.string]).isRequired
};

export const StaffAccreditationType = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
};
const s3File = {
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    date_uploaded: PropTypes.string.isRequired,
    original_filename: PropTypes.string,
    uri: PropTypes.string,
    size: PropTypes.number,
    image: PropTypes.bool
};
export const staffAccreditationFile = {
    id: PropTypes.number,
    date_uploaded: PropTypes.string,
    file: PropTypes.shape({ data: PropTypes.shape(s3File) })
};

export const staffAccreditation = {
    id: PropTypes.number,
    staff_id: PropTypes.number.isRequired,
    date_added: PropTypes.string.isRequired,
    valid_from: PropTypes.string.isRequired,
    valid_to: PropTypes.string,
    type: PropTypes.shape({ data: PropTypes.shape(StaffAccreditationType) }),
    files: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape(staffAccreditationFile)) })
};

export const staffDiField = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    help_text: PropTypes.string,
    type: PropTypes.oneOf(['text', 'select', 'boolean']),
    allowed_values: PropTypes.array,
    required: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    dependent_field: PropTypes.number,
    dependent_value: PropTypes.string,
    display_order: PropTypes.number
};

export const staffNotificationTypeField = {
    id: PropTypes.number,
    type_id: PropTypes.number,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'json', 'select', 'boolean', 'email_select']),
    default: PropTypes.string,
    required: PropTypes.bool
};
export const staffNotificationType = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    system: PropTypes.bool.isRequired,
    type_fields: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape(staffNotificationTypeField)) })
};

export const staffNotificationAction = {
    id: PropTypes.number,
    name: PropTypes.string,
    event: PropTypes.string
};

export const staffNotificationEndpoint = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    notification_id: PropTypes.number,
    target: PropTypes.string,
    enabled: PropTypes.bool
};

export const staffNotificationField = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type_field_id: PropTypes.number,
    value: PropTypes.string,
    type: PropTypes.shape({
        data: PropTypes.shape(staffNotificationTypeField)
    })
};

export const staffNotificationRule = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    notification_id: PropTypes.number,
    field: PropTypes.string,
    operator: PropTypes.oneOf(['', '=', '>', '<', '>=', '<=', 'in', '<>', 'empty', 'not empty', 'not in']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const staffActionType = {
    active: PropTypes.bool,
    archived: PropTypes.bool,
    description: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.number,
    last_run: PropTypes.string,
    min_hierarchy_level: PropTypes.number,
    name: PropTypes.string,
    on_save: PropTypes.bool,
    schedule: PropTypes.string
};
export const staffNotification = {
    id: PropTypes.number,
    name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    type: PropTypes.shape({
        data: PropTypes.shape(staffNotificationType)
    }),
    action: PropTypes.shape({
        data: PropTypes.shape(staffNotificationAction)
    }),
    endpoints: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffNotificationEndpoint))
    }),
    rules: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffNotificationRule))
    }),
    fields: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(staffNotificationField))
    }),
    staff_action: PropTypes.shape({
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape(staffActionType)])
    })
};
export const staffRoleType = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
};

export const staffPosition = {
    id: PropTypes.number.isRequired,
    type_id: PropTypes.number,
    name: PropTypes.string,
    display_order: PropTypes.number,
    site_based: PropTypes.bool,
    create_clerk: PropTypes.bool,
    pay_type: PropTypes.shape({
        data: PropTypes.shape(staffPayType)
    }),
    archived: PropTypes.bool,
    hierarchy_level: PropTypes.number,
    support_centre: PropTypes.bool,
    type: PropTypes.shape({
        data: PropTypes.shape(staffRoleType)
    })
};

export const staffDocumentCategory = {
    id: PropTypes.number.isRequired,
    category_breadcrumb: PropTypes.array,
    hierarchy_level: PropTypes.number,
    icon: PropTypes.string,
    name: PropTypes.string,
    parent_category: PropTypes.number
};
export const staffDocumentType = {
    id: PropTypes.number.isRequired,
    category: PropTypes.shape({ data: PropTypes.shape(staffDocumentCategory) }),
    min_hierarchy_level: PropTypes.number,
    name: PropTypes.string.isRequired,
    sensitive: PropTypes.bool
};
export const staffDocument = {
    id: PropTypes.number.isRequired,
    type_id: PropTypes.number.isRequired,
    staff_id: PropTypes.number,
    date_uploaded: PropTypes.string,
    added_by: PropTypes.number,
    added_by_name: PropTypes.string,
    type: PropTypes.shape({ data: PropTypes.shape(staffDocumentType) }),
    file: PropTypes.shape({ data: PropTypes.shape(s3File) })
};
export const trainingCourse = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date_added: PropTypes.string.isRequired
};
export const initialTrainingConfig = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    courses: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape(trainingCourse)) })
};

export const locationConfig = {
    location: PropTypes.string.isRequired,
    payroll_ref_prefix: PropTypes.string,
    s4_site_id: PropTypes.number
};
export const locationWithConfig = {
    cost_centre: PropTypes.string.isRequired,
    bar_id: PropTypes.number,
    brand: PropTypes.number.isRequired,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    address_line3: PropTypes.string,
    address_town: PropTypes.string,
    address_county: PropTypes.string,
    address_postcode: PropTypes.string,
    site_email: PropTypes.string,
    area_manager_id: PropTypes.string,
    reporting_manager_id: PropTypes.string,
    legacy_site_id: PropTypes.string,
    id: PropTypes.string
};

export const payRateAuthStatus = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired
};

export const payRateAuth = {
    authed_by_name: PropTypes.string,
    authorised_by: PropTypes.string,
    created_by_name: PropTypes.string,
    current_rate: PropTypes.number.isRequired,
    effective_from_date: PropTypes.string.isRequired,
    id: PropTypes.number,
    location_name: PropTypes.string,
    rate: PropTypes.string.isRequired,
    reject_reason: PropTypes.string,
    staff_id: PropTypes.number.isRequired,
    staff_member_name: PropTypes.string,
    status: PropTypes.shape({ data: PropTypes.shape(payRateAuthStatus) })
};

export const contractSection = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    heading: PropTypes.string,
    content: PropTypes.string,
    preview: PropTypes.string,
    plain_text: PropTypes.string,
    date_created: PropTypes.string,
    date_updated: PropTypes.string
};
export const contractTemplateSection = {
    section_id: PropTypes.number,
    display_order: PropTypes.number,
    section: PropTypes.shape({ data: PropTypes.shape(contractSection) })
};

export const contractTemplate = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    date_created: PropTypes.string,
    min_contracted_hours: PropTypes.number,
    max_contracted_hours: PropTypes.number,
    allow_manual_upload: PropTypes.bool,
    min_hierarchy_level: PropTypes.number,
    locked: PropTypes.bool,
    locations: PropTypes.array,
    pay_type: PropTypes.shape({ data: PropTypes.shape(staffPayType) }),
    sections: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape(contractTemplateSection)) })
};

export const onboardFieldsetField = {
    id: PropTypes.number,
    field_id: PropTypes.number,
    required: PropTypes.bool,
    dependent_field: PropTypes.string,
    dependent_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display_order: PropTypes.number,
    field_name: PropTypes.string,
    label: PropTypes.string
};
export const onboardFieldset = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    fields: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(onboardFieldsetField))
    })
};
