import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
// third party
import { Provider } from 'react-redux';
// project imports
import App from './App';
import { persistor, store } from './store/rootReducer';
// style + assets
import './assets/scss/style.scss';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: 'https://9beb75051be7451b8fdcc174f00ba6b3@sentry-relay.revbars.cloud/24',
    maxBreadcrumbs: 50,
    debug: true
});

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
// ==============================|| REACT DOM RENDER  ||============================== //
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
