import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Api from '../../../../../utils/api';
import { DialogHelper } from '../../../../../ui_component/modals/RbgDialog';
import ObjectForm from '../../../../../ui_component/extended/Form/ObjectForm';

function EditDepartment({ department, onSave }) {
    const [newDepartment, setNewDepartment] = useState(
        department || {
            name: '',
            manager_id: '',
            parent_department: '',
            reporting_manager_id: ''
        }
    );
    const {
        error,
        data: departments,
        isFetching,
        refetch
    } = useQuery({
        queryKey: [{ method: 'GET', url: '/staff/sc-departments' }]
    });

    const mutation = useMutation({
        mutationFn: (data) => {
            const url = newDepartment.id ? `/staff/sc-department/${newDepartment.id}` : '/staff/sc-department';
            return Api.reactQueryMutation(newDepartment.id ? 'PATCH' : 'POST', url, data);
        },
        onSuccess: (data) => {
            if (onSave) {
                onSave(data);
            }
            DialogHelper.closeDialog();
        }
    });

    const handleSubmit = () => {
        mutation.mutate(newDepartment);
    };

    const staffFilter = (data) => {
        return data.data
            .map((staffMember) => {
                return {
                    value: staffMember.id,
                    label: `${staffMember.display_name} (${staffMember.support_centre_role})`
                };
            })
            .sort((staffMember1, staffMember2) => {
                return staffMember1.label.localeCompare(staffMember2.label);
            });
    };

    useEffect(() => {
        DialogHelper.addSaveButton(() => {
            mutation.mutate(newDepartment);
        }, 'Save');
    }, [newDepartment, mutation]);

    const fields = [
        {
            type: 'text',
            name: 'name',
            label: 'Department Name',
            value: newDepartment.name,
            xs: 12
        },
        {
            type: 'remote_select',
            endpoint: '/staff/sc-departments',
            label: 'Parent Department',
            name: 'parent_department',
            value: newDepartment.parent_department
        },
        {
            type: 'remote_select',
            endpoint: '/staff/list',
            endpointParameters: { cost_centre: '0001', live_only: true, per_page: 200 },
            label: 'Department Manager',
            name: 'manager_id',
            filterData: staffFilter,
            value: newDepartment.manager_id
        },
        {
            type: 'remote_select',
            endpoint: '/staff/list',
            endpointParameters: { cost_centre: '0001', live_only: true, per_page: 200 },
            label: 'Reporting Manager',
            name: 'reporting_manager_id',
            filterData: staffFilter,
            value: newDepartment.reporting_manager_id
        }
    ];
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <ObjectForm elements={fields} debug={false} stateSetter={setNewDepartment} error={mutation.error} />
        </form>
    );
}

EditDepartment.propTypes = {
    department: PropTypes.object,
    onSave: PropTypes.func
};

export default EditDepartment;
